import AuthLayout from '../layouts/Auth';
import AuthLogin from "../pages/Auth/Login";
import AuthCallbackKeycloak from "../pages/Auth/Callback/Keycloak"

const authRoutes = [
  {
    path: '/',
    element: <AuthLayout />,
    children: [
      { path: '/login', element: <AuthLogin /> },
      { path: '/Keycloak/callback', element: <AuthCallbackKeycloak /> },
    ]
  }
];

export default authRoutes;
