import { PlusOutlined, SearchOutlined, UploadOutlined } from "@ant-design/icons";
import { useState } from "@hookstate/core";
import { Tabs } from "antd";
import Button from "antd/es/button";
import Col from "antd/es/grid/col";
import Row from "antd/es/grid/row";
import Input from "antd/es/input";
import Space from "antd/es/space";
import Table from "antd/es/table";
import TabPane from "antd/es/tabs/TabPane";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useIsMounted } from "../../../../../../@framework/utilities/hooks";
import useDebounce from "../../../../../../@framework/utilities/hooks/useDebounce";
import WuiContainer from "../../../../../../@framework/wui/components/Container";
import WuiModalImport from "../../../../../../@framework/wui/components/Modal/Import";
import WuiSectionTitle from "../../../../../../@framework/wui/components/Sections/Title";
import {AxiosError, AxiosResponse} from "axios";
import KPITargetItemRepository from "../../../../../../repositories/KPITargetItemRepository";
import {permissions} from "../../../../../../constant/permissions";
import Accessible from "../../../../../../@framework/wui/components/Accessible";
import {CHANNEL_TABS, CHANNEL_TYPE_B2B, CHANNEL_TYPE_B2C} from "../../../../../../constant";
import _ from "lodash"
import {handleBackendError, haveAccess, openNotification} from "../../../../../../functions/global";
import {KPI_TARGET_ITEM_IMPORT} from "../../../../../../constant/import";
import moment from "moment/moment";
import useAccess from "../../../../../../@framework/utilities/hooks/useAccess";

var qs = require("qs");

const breadcrumbs = [
    {
        label: 'KPI',
        link: '/kpi'
    },
    {
        label: 'Target Item KPI',
        link: '/kpi/target'
    },
]

const permissionMapping: any = {
    [CHANNEL_TABS[0].id]: {
      read: permissions.read_target_kpi_b2b,
      write: permissions.write_target_kpi_b2b
    },
    [CHANNEL_TABS[1].id]: {
      read: permissions.read_target_kpi_b2c,
      write: permissions.write_target_kpi_b2c
    }
  };

const tabs = CHANNEL_TABS;

const AppKPITargetItemList: React.FC<any> = () => {
    const {t} = useTranslation()
    const isMounted = useIsMounted()
    const navigate = useNavigate()
    const acl = useAccess()
    const [searchParams, setSearchParams] = useSearchParams();
    let fullPath = window.location.pathname;

    const tabKey = useState<string>(
        tabs.find((tab) => haveAccess(acl, permissionMapping[tab.id].read))?.id?.toString() || searchParams.get('channel') || tabs[0].id.toString()
    );

    const channel = useState<any>(
        _.find(CHANNEL_TABS, {id: parseInt(tabKey.get())})
    )

    // Table
    const tablePage = useState<number>(parseInt(searchParams.get("page") || "1"));
    const tablePerPage = useState<number>(
        parseInt(searchParams.get("perPage") || "10")
    );
    const [tableLoading, setTableLoading] = React.useState<boolean>(false);
    const tableSort = useState<{
        sortField: any;
        sortOrder: any;
    }>({
        sortField: searchParams.get("sortField"),
        sortOrder: searchParams.get("sortOrder"),
    });
    const tableData = useState<any[]>([]);
    const totalData = useState<number>(0);

    const columns: any = [
        {
            title: "Pengguna",
            dataIndex: "user_name",
            key: "user_name",
            sorter: true,
            defaultSortOrder: tableSort.sortField.get() === "user_name" && tableSort.sortOrder.get(),
        },
        {
            title: "NIK",
            dataIndex: "user_nik",
            key: "user_nik",
            sorter: true,
            defaultSortOrder: tableSort.sortField.get() === "nik" && tableSort.sortOrder.get(),
        },
        {
            title: "Period",
            dataIndex: "start_date",
            key: "start_date",
            sorter: true,
            defaultSortOrder:
                tableSort.sortField.get() === "start_date" && tableSort.sortOrder.get(),
            render: (text: any, item: any) => {
                return moment(item.start_date).format("D MMM YYYY")+' - '+moment(item.end_date).format("D MMM YYYY");
            },
        },
        {
            title: "Target",
            dataIndex: "target",
            key: "target",
            sorter: true,
            defaultSortOrder: tableSort.sortField.get() === "target" && tableSort.sortOrder.get(),
        },
        {
            title: "Action",
            key: "action",
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Link to={fullPath + "/" + record.id}>Lihat Detail</Link>
                </Space>
            ),
        },
    ]

    const handleTableChange = (
        pagination: any,
        filters: any,
        sorter: any,
        extra: any
    ) => {
        tablePage.set(pagination.current);
        tablePerPage.set(pagination.pageSize);

        if (sorter.order) {
            tableSort.sortField.set(sorter.field);
            tableSort.sortOrder.set(sorter.order);
        } else {
            tableSort.sortField.set(null);
            tableSort.sortOrder.set(null);
        }

        setFilterDataToQuery();
    };

    const getTableData = async (reset: boolean = false) => {
        setTableLoading(true);

        if (reset) tablePage.set(1);

        let params = {
            page: tablePage.get(),
            search: debouncedSearch,
            order_by: tableSort.sortField.get(),
            sorted_by: tableSort.sortOrder.get(),
            per_page: tablePerPage.get(),
        };

        if (!params.order_by) {
            params.order_by = "created_at";
            params.sorted_by = "descend";
        }

        if (channel.get()) {
            Object.assign(params, {
                channel: channel.get().value
            })
        }

        await KPITargetItemRepository.all(params).then((res: AxiosResponse) => {
            if (res.data.data) {
                tableData.set(res.data.data)
            }

            totalData.set(res.data?.total || 0)

            setTableLoading(false)
        }).catch((e: AxiosError) => {

            setTableLoading(false)
        })
    };

    // For Search Input
    const [search, setSearch] = React.useState<string>("");
    const debouncedSearch = useDebounce<string>(search, 1000);

    const setFilterDataToQuery = () => {
        let params = {};
        let reset = false;

        if (debouncedSearch) {
            Object.assign(params, {
                keyword: debouncedSearch,
            });

            if (debouncedSearch !== searchParams.get("keyword")) {
                reset = true;
            }
        }

        if (tablePage.get() !== 1 && !reset) {
            Object.assign(params, {
                page: tablePage.get(),
            });
        }

        if (tablePerPage.get() !== 10 && !reset) {
            Object.assign(params, {
                perPage: tablePerPage.get(),
            });
        }

        if (tableSort.sortField.get() && !reset) {
            Object.assign(params, {
                sortField: tableSort.sortField.get(),
            });
        }

        if (tableSort.sortOrder.get() && !reset) {
            Object.assign(params, {
                sortOrder: tableSort.sortOrder.get(),
            });
        }

        if (tabKey.get()) {
            Object.assign(params, {
                channel: tabKey.get(),
            });
        }

        const queryParams = qs.stringify(params, { indices: false });

        if (queryParams) {
            setSearchParams(`?${queryParams}`);
        } else {
            navigate("");
        }

        getTableData(reset);
    };

    const getFilterDataFromQuery = () => {
        const keyword = searchParams.get("keyword");

        if (keyword) {
            setSearch(keyword);
        }

        getTableData()
    };

    // Import Section
    const showImportModal = useState(false);

    const importModalCallback = async (files: File[]) => {
        const formData = new FormData();

        if (files) {
            files.forEach((file: any) => {
                formData.append('file_import', file);
            })
        }

        channel.get()?.value.forEach((c: any) => {
            formData.append('channel[]', c);
        })

        await KPITargetItemRepository.import(formData).then((res: AxiosResponse) => {
            showImportModal.set(false)
            openNotification('success', t('notification.success.importItem', {item: 'Target Item'}))
            getTableData(true)
        }).catch((e: AxiosError) => {
            handleBackendError(e, t('notification.error.default'))
        })
    };

    const importModalDownload = async () => {
        let url = ''
        if (channel.get().name == CHANNEL_TYPE_B2B) {
            url = KPI_TARGET_ITEM_IMPORT.TEMPLATE_URL.CHANNEL_TYPE_B2B
        } else if (channel.get().name == CHANNEL_TYPE_B2C) {
            url = KPI_TARGET_ITEM_IMPORT.TEMPLATE_URL.CHANNEL_TYPE_B2C
        } else {
            url = KPI_TARGET_ITEM_IMPORT.TEMPLATE_URL.CHANNEL_TYPE_B2B_B2C
        }

        window.open(url + `?${_.random(100,900)}`);
    };


    useMemo(() => {
        getFilterDataFromQuery();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!isMounted) {
            channel.set(_.find(CHANNEL_TABS, {id: parseInt(tabKey.get())}))
            setFilterDataToQuery();
        }
        // eslint-disable-next-line
    }, [debouncedSearch, tabKey.get()]);

    return (
        <>
            <WuiContainer>
                <WuiSectionTitle title={'Target Item KPI'} breadcrumbs={breadcrumbs}>
                    <Accessible access={permissionMapping[tabKey.get()] ? permissionMapping[tabKey.get()].write  : [permissions.write_target_kpi_b2b, permissions.write_target_kpi_b2c]}>
                        <Button
                            icon={<UploadOutlined />}
                            onClick={() => showImportModal.set(true)}
                        >
                            {t("common.button.import")}
                        </Button>
                    </Accessible>
                    <Accessible access={permissionMapping[tabKey.get()] ? permissionMapping[tabKey.get()].write  : [permissions.write_target_kpi_b2b, permissions.write_target_kpi_b2c]}>
                        <Button onClick={() => navigate(fullPath+'/form')} type="primary" icon={<PlusOutlined />}>{t('common.button.addNew')}</Button>
                    </Accessible>
                </WuiSectionTitle>

                <Tabs
                    defaultActiveKey={tabKey.get()}
                    onChange={(key) => {
                        tabKey.set(key);
                    }}
                >
                    {tabs.filter((item) => {
                        if (permissionMapping[item.id]) {
                            return haveAccess(acl, permissionMapping[item.id]?.read);
                        }
                            return (
                                haveAccess(acl, permissionMapping[CHANNEL_TABS[0].id].read) &&
                                haveAccess(acl, permissionMapping[CHANNEL_TABS[1].id].read)
                            );
                        }).map((tab) => {
                            return (
                                <TabPane tab={tab.name.toUpperCase()} key={tab.id}></TabPane>
                            );
                    })}
                </Tabs>

                <Row gutter={[10, 10]} className="mb16">
                    <Col className="gutter-row" span={6} xs={24} md={12} lg={10}>
                        <Input
                            allowClear
                            placeholder={t("common.filter.search.placeholder")}
                            prefix={<SearchOutlined />}
                            value={search}
                            onChange={(value) => {
                                setSearch(value.target.value);
                            }}
                        />
                    </Col>
                </Row>

                <Table
                    rowKey={"id"}
                    bordered
                    columns={columns}
                    dataSource={tableData.get()}
                    loading={tableLoading}
                    onChange={handleTableChange}
                    pagination={{
                        current: tablePage.get(),
                        simple: true,
                        pageSize: tablePerPage.get(),
                        total: totalData.get(),
                    }}
                />
            </WuiContainer>

            <WuiModalImport
                show={showImportModal.get()}
                onUpload={importModalCallback}
                onCancel={() => showImportModal.set(false)}
                onDownload={importModalDownload}
                headerTitle="Import KPI Target"
                maxFileOnMB={10}
            />
        </>
    )
}

export default AppKPITargetItemList;