const importEndPoint = () =>
  process.env.REACT_APP_PASSPORT_END_POINT + "/import-template";

export const REGION_IMPORT = {
  TEMPLATE_URL: `${importEndPoint()}/Template-Region.xlsx`,
  TEMPLATE_NAME: `template-region.xlsx`,
};

export const AREA_IMPORT = {
  TEMPLATE_URL: `${importEndPoint()}/Template-Area.xlsx`,
  TEMPLATE_NAME: `template-area.xlsx`,
};

export const CLUSTER_IMPORT = {
  TEMPLATE_URL: `${importEndPoint()}/Template-Cluster.xlsx`,
  TEMPLATE_NAME: `template-cluster.xlsx`,
};

export const SUBTERRITORY_IMPORT = {
  TEMPLATE_URL: `${importEndPoint()}/Template-Subterritory.xlsx`,
  TEMPLATE_NAME: `template-subterritory.xlsx`,
};

export const TERRITORY_IMPORT = {
  TEMPLATE_URL: `${importEndPoint()}/Template-Territory.xlsx`,
  TEMPLATE_NAME: `template-territory.xlsx`,
};

export const BRAND_IMPORT = {
  TEMPLATE_URL: `${importEndPoint()}/Template-Brand.xlsx`,
  TEMPLATE_NAME: `template-brand.xlsx`,
};

export const PRODUCT_IMPORT = {
  TEMPLATE_URL: `${importEndPoint()}/Template-Product.xlsx`,
  TEMPLATE_NAME: `template-product.xlsx`,
};

export const PRODUCT_TYPE_IMPORT = {
  TEMPLATE_URL: `${importEndPoint()}/Template-Product-Type.xlsx`,
  TEMPLATE_NAME: `template-product-type.xlsx`,
};

export const DEALER_IMPORT = {
  TEMPLATE_URL: `${importEndPoint()}/Template-Dealer.xlsx`,
  TEMPLATE_NAME: `template-dealer.xlsx`,
};

export const DEALER_PRODUCT_IMPORT = {
  TEMPLATE_URL: `${importEndPoint()}/Template-Dealer-Product.xlsx`,
  TEMPLATE_NAME: `template-dealer-product.xlsx`,
};

export const DEALER_BRAND_IMPORT = {
  TEMPLATE_URL: `${importEndPoint()}/Template-Dealer-Brand.xlsx`,
  TEMPLATE_NAME: `template-dealer-brand.xlsx`,
};

export const SALES_IMPORT_DEALER = {
  TEMPLATE_TEXT: "Mapping Dealer",
  TEMPLATE_URL: `${importEndPoint()}/Template-User-Dealer.xlsx`,
  TEMPLATE_NAME: `template-user-dealer.xlsx`,
};

export const SALES_IMPORT_TARGET = {
  TEMPLATE_TEXT: "Mapping Target",
  TEMPLATE_URL: `${importEndPoint()}/Template-User-Target.xlsx`,
  TEMPLATE_NAME: `template-user-target.xlsx`,
};

export const JOURNEY_IMPORT = {
  TEMPLATE_URL: `${importEndPoint()}/Template-Journey-Plan.xlsx`,
  TEMPLATE_NAME: `template-journey-plan.xlsx`,
};

export const B2C_JOURNEY_IMPORT = {
  TEMPLATE_URL: `${importEndPoint()}/Template-Journey-Plan-B2C.xlsx`,
  TEMPLATE_NAME: `template-journey-plan-b2c.xlsx`,
};

export const STOCK_OPNAME_PRODUCT_IMPORT = {
  TEMPLATE_URL: `${importEndPoint()}/Template-Stock-Opname-Product.xlsx`,
  TEMPLATE_NAME: `template-stock-opname-product.xlsx`,
};

export const ASSISTANT_STORE_LEADER_IMPORT_MAPPING_STORE = {
  TEMPLATE_TEXT: "Mapping Store",
  TEMPLATE_URL: `${importEndPoint()}/Template-User-Store.xlsx`,
  TEMPLATE_NAME: `template-user-store.xlsx`,
};

export const ASSISTANT_STORE_LEADER_IMPORT_SALES_HIERARCHY = {
  TEMPLATE_TEXT: "Sales Hierarchy",
  TEMPLATE_URL: `${importEndPoint()}/Template-Sales-Hierarchy.xlsx`,
  TEMPLATE_NAME: `template-sales-hierarchy.xlsx`,
};

export const USER_KPI_FRAUD_IMPORT = {
  TEMPLATE_TEXT: "Fraud",
  TEMPLATE_URL: `${importEndPoint()}/Template-Kpi-User-Fraud.xlsx`,
  TEMPLATE_NAME: `template-kpi-user-fraud.xlsx`,
};

export const USER_KPI_BAD_DEBT_IMPORT = {
  TEMPLATE_TEXT: "Bad Debt",
  TEMPLATE_URL: `${importEndPoint()}/Template-Kpi-User-Bad-Debt.xlsx`,
  TEMPLATE_NAME: `template-kpi-user-bad-debt.xlsx`,
};

export const USER_KPI_ONTIME_PAYMENT_IMPORT = {
  TEMPLATE_TEXT: "Ontime Payment",
  TEMPLATE_URL: `${importEndPoint()}/Template-Kpi-User-Ontime-Payment.xlsx`,
  TEMPLATE_NAME: `template-kpi-user-ontime-payment.xlsx`,
};

export const USER_KPI_GROWTH_EXISTING_IMPORT = {
  TEMPLATE_TEXT: "Growth Existing",
  TEMPLATE_URL: `${importEndPoint()}/Template-Kpi-User-Growth-Existing.xlsx`,
  TEMPLATE_NAME: `template-kpi-user-growth-existing.xlsx`,
};

export const KPI_TARGET_ITEM_IMPORT = {
  TEMPLATE_TEXT: "KPI Target",
  TEMPLATE_URL: {
    CHANNEL_TYPE_B2B: `${importEndPoint()}/Template-Target-KPI-B2B.xlsx`,
    CHANNEL_TYPE_B2C: `${importEndPoint()}/Template-Target-KPI-B2C.xlsx`,
    CHANNEL_TYPE_B2B_B2C: `${importEndPoint()}/Template-Target-KPI-B2B-B2C.xlsx`,
  },
  TEMPLATE_NAME: `template-kpi-target.xlsx`,
};

export const KPI_TARGET_STORE_IMPORT = {
  TEMPLATE_TEXT: "KPI Target Store",
  TEMPLATE_URL: `${importEndPoint()}/Template-Kpi-Store.xlsx`,
  TEMPLATE_NAME: `template-kpi-target-store.xlsx`,
};
