import {api, createCancelTokenHandler} from "../@framework/services";
import {TChannels} from "../utils";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
    totalSales: function (channel: TChannels, params: any = null) {
        return api.get(`${endPoint()}/api/v1/${channel}/dashboard/total-sales`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.totalSales.name].handleRequestCancellation().token
        });
    },
    totalBrand: function (channel: TChannels, params: any = null) {
        return api.get(`${endPoint()}/api/v1/${channel}/dashboard/total-brand`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.totalBrand.name].handleRequestCancellation().token
        });
    },
    totalPromoter: function (channel: TChannels, params: any = null) {
        return api.get(`${endPoint()}/api/v1/${channel}/dashboard/total-promoter`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.totalPromoter.name].handleRequestCancellation().token
        });
    },
    totalProduct: function (channel: TChannels, params: any = null) {
        return api.get(`${endPoint()}/api/v1/${channel}/dashboard/total-product`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.totalProduct.name].handleRequestCancellation().token
        });
    },
    totalDealer: function (channel: TChannels, params: any = null) {
        return api.get(`${endPoint()}/api/v1/${channel}/dashboard/total-dealer`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.totalDealer.name].handleRequestCancellation().token
        });
    },
    reportVisitSales: function (channel: TChannels, params: any = null) {
        return api.get(`${endPoint()}/api/v1/${channel}/dashboard/report-visit-sales`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.reportVisitSales.name].handleRequestCancellation().token
        });
    },
    reportStockProduct: function (channel: TChannels, params: any = null) {
        return api.get(`${endPoint()}/api/v1/${channel}/dashboard/report-stock-product`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.reportStockProduct.name].handleRequestCancellation().token
        });
    },
    allReportCourier: function (params: any = null) {
        return api.get(`${endPoint()}/api/v1/b2c/dashboard/report-courier`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.allReportCourier.name].handleRequestCancellation().token
        });
    },
    showReportCourier: function (id: string | number, params: any = null) {
        return api.get(`${endPoint()}/api/v1/b2c/dashboard/report-courier/${id}`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.showReportCourier.name].handleRequestCancellation().token
        });
    },
    allReportForecast: function (params: any = null) {
        return api.get(`${endPoint()}/api/v1/b2c/dashboard/report-product-forecast`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.allReportForecast.name].handleRequestCancellation().token
        });
    },
}

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;