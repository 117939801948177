import {api, createCancelTokenHandler} from "../@framework/services";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
    token: function () {
        return api.get(`${endPoint()}/api/v1/tableau/token`, {
            cancelToken: cancelTokenHandlerObject[this.token.name].handleRequestCancellation().token,
        });
    },
}

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;