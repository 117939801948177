import { useState } from "@hookstate/core";
import Tabs from "antd/lib/tabs";
import React, {useEffect} from "react";
import { useSearchParams } from "react-router-dom";
import WuiContainer from "../../../../@framework/wui/components/Container";
import WuiSectionTitle from "../../../../@framework/wui/components/Sections/Title";
import DashboardB2CTabA from "./Tabs/A";
import DashboardB2CForecast from "./Tabs/Forecast";
import DashboardB2CO2O from "./Tabs/O2O";
import {haveAccess} from "../../../../functions/global";
import {permissions} from "../../../../constant/permissions";
import useAccess from "../../../../@framework/utilities/hooks/useAccess";

const { TabPane } = Tabs;

const breadcrumbs = [
    {
        label: 'Dashboard B2C',
        link: '/dashboard/B2C'
    },
]

const AppDashboardB2C: React.FC<any> = () => {
    const [searchParams] = useSearchParams();
    const tabKey = useState(searchParams.get('tab') || 'Tab A')

    const acl = useAccess()

    useEffect(() => {
        if(!haveAccess(acl, permissions.read_grafik_b2c_tab_o2o) && !haveAccess(acl, permissions.read_grafik_b2c_tab_a)){
            tabKey.set('Forecast')
        } else if (!haveAccess(acl, permissions.read_grafik_b2c_tab_a)) {
            tabKey.set('O2O')
        }
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <WuiContainer>
                <WuiSectionTitle title={'Dashboard B2C'} breadcrumbs={breadcrumbs}/>

                <Tabs defaultActiveKey={tabKey.get()} onChange={(key) => {
                    tabKey.set(key)
                }}>
                    {
                        haveAccess(acl, permissions.read_grafik_b2c_tab_a) &&
                        <TabPane tab="Tab A" key="Tab A">
                            <DashboardB2CTabA active={tabKey.get() === 'Tab A'}/>
                        </TabPane>
                    }

                    {
                        haveAccess(acl, permissions.read_grafik_b2c_tab_o2o) &&
                        <TabPane tab="O2O" key="O2O">
                            <DashboardB2CO2O active={tabKey.get() === 'O2O'}/>
                        </TabPane>
                    }

                    {
                        haveAccess(acl, permissions.read_grafik_b2c_tab_forecast) &&
                        <TabPane tab="Forecast" key="Forecast">
                            <DashboardB2CForecast active={tabKey.get() === 'Forecast'}/>
                        </TabPane>
                    }
                </Tabs>
            </WuiContainer>
        </>
    )
}

export default AppDashboardB2C;