import { SearchOutlined } from "@ant-design/icons";
import { useState } from "@hookstate/core";
import Button from "antd/es/button";
import Input from "antd/es/input";
import InputNumber from "antd/es/input-number";
import PageHeader from "antd/es/page-header";
import Card from "antd/lib/card";
import Form from "antd/lib/form";
import { Col, Row } from "antd/lib/grid";
import Space from "antd/lib/space";
import Spin from "antd/lib/spin";
import Tabs from "antd/lib/tabs";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { $clone } from "../../../../../../../@framework/utilities";
import useAccess from "../../../../../../../@framework/utilities/hooks/useAccess";
import useDebounce from "../../../../../../../@framework/utilities/hooks/useDebounce";
import Accessible from "../../../../../../../@framework/wui/components/Accessible";
import WuiContainer from "../../../../../../../@framework/wui/components/Container";
import WuiFilterDateRange from "../../../../../../../@framework/wui/components/Filter/DateRange";
import WuiFormTitle from "../../../../../../../@framework/wui/components/Form/Title";
import WuiSelectSingle from "../../../../../../../@framework/wui/components/Select/Single";
import {
  handleBackendError,
  haveAccess,
  openNotification,
  pluckArrayObject,
} from "../../../../../../../functions/global";
import ClusterRepository from "../../../../../../../repositories/ClusterRepository";
import DealerRepository from "../../../../../../../repositories/DealerRepository";
import BrandRepository from "../../../../../../../repositories/BrandRepository";
import {
  TableGAP,
  TableMaps,
  TableProduct,
  TableSold,
  TableStock,
  TableTransaction,
} from "./Component";
import { TChannels } from "../../../../../../../utils";
import { permissions } from "../../../../../../../constant/permissions";
import AppDefaultTableau from "../../../../../../../components/Tableau";
import WuiSelectMulti from "../../../../../../../@framework/wui/components/Select/Multi";
import { NumericFormat } from "react-number-format";

const { TabPane } = Tabs;

let title = "Dealer";

const AppSettingsDataMasterDealerForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const pageTitle = id
    ? t("common.text.editItem", { item: title })
    : t("common.text.addNewItem", { item: title });
  const channel = useState<TChannels>("b2b");

  // Form
  const [form] = Form.useForm();
  const disable = useState(false);
  const loading = useState(false);
  const minStock = useState(0);
  const acl = useAccess();

  const onFinish = async (data: any) => {
    loading.set(true);

    let payload = {
      min_stock: minStock.get(),
      cluster_id: data.cluster?.value,
      brand_ids: _.map(data.brands, "id"),
    };

    await (!id
      ? DealerRepository.create(payload)
      : DealerRepository.update(channel.get(), id, payload)
    )
      .then((res: AxiosResponse) => {
        navigate(-1);

        if (!id) {
          openNotification(
            "success",
            t("notification.success.createItem", { item: title })
          );
        } else {
          openNotification(
            "success",
            t("notification.success.updateItem", { item: title })
          );
          loading.set(false);
        }
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));

        loading.set(false);
      });
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const dealerCode = useState("");
  const formData = useState({
    classification: "",
  });
  const locations = useState<any>([]);

  const getData = async () => {
    disable.set(true);

    await DealerRepository.show(channel.get(), id, {
      with: ["cluster", "brands", "dealerLocationHistories", "dealerStocks"],
    })
      .then((res: AxiosResponse) => {
        let brands = pluckArrayObject(res.data?.data?.brands, ["id", "name"]);

        form.setFieldsValue({
          ...(res.data?.data || {}),
          code: res.data?.data?.code,
          name: res.data?.data?.name,
          email: res.data?.data?.email,
          address: res.data?.data?.address,
          post_code: res.data?.data?.post_code,
          classification: res.data?.data?.classification,
          cluster: {
            value: res.data?.data?.cluster?.id,
            label: res.data?.data?.cluster?.name,
          },
          brands: brands,
        });

        dealerCode.set(res.data?.data?.code);
        formData.classification.set(res.data?.data?.classification);
        if ((res.data?.data?.dealer_location_histories || []).length > 0) {
          locations.set(res.data?.data?.dealer_location_histories);
        } else {
          if (res.data?.data?.lng_lat) {
            locations.set([
              {
                lng_lat: res.data?.data?.lng_lat,
                created_at: res.data.data.updated_at,
              },
            ]);
          }
        }

        minStock.set(res.data?.data?.min_stock);

        if (haveAccess(acl, [permissions.write_dealer_b2b])) {
          disable.set(false);
        }
      })
      .catch((e: AxiosError) => {
        openNotification("error", t("notification.error.default"));
      });

    await getDataFinancial();
  };

  const getDataFinancial = async () => {
    await DealerRepository.financialInfo(channel.get(), id)
      .then((res: AxiosResponse) => {
        let data = res.data?.data || {};
        form.setFieldsValue(data);
      })
      .catch((e: AxiosError) => {
        openNotification("error", t("notification.error.default"));
      });
  };

  // For Search Input
  const [search, setSearch] = React.useState<string>("");
  const debouncedSearch = useDebounce<string>(search, 1000);

  const tabKey = useState("1");

  // For DateRange Picker
  const [filterDate, setFilterDate] = React.useState<any>([moment(), moment()]);

  const handleDateRangeCallback = (dates: any, dateString?: any) => {
    setFilterDate(dates);
  };

  return (
    <>
      <WuiContainer>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
        />

        <Form form={form} layout={"vertical"} onFinish={onFinish}>
          <Row>
            <Col md={16} xs={24}>
              <Card
                title={t("common.text.itemInformation", { item: title })}
                className="mb16"
              >
                <Space
                  style={{
                    width: "100%",
                  }}
                  direction="vertical"
                  size={5}
                >
                  <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                      <div className="wui-form-group type-column">
                        <div className="input-section">
                          <Form.Item
                            name="code"
                            label={"Kode"}
                            rules={[
                              {
                                required: true,
                                message: t("validation.required", {
                                  item: "Kode",
                                }),
                              },
                            ]}
                          >
                            <Input
                              disabled
                              size={"large"}
                              placeholder={t("common.text.input", {
                                item: "Kode",
                              })}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} md={12}>
                      <div className="wui-form-group type-column">
                        <div className="input-section">
                          <Form.Item
                            name="name"
                            label={"Nama"}
                            rules={[
                              {
                                required: true,
                                message: t("validation.required", {
                                  item: "Nama",
                                }),
                              },
                            ]}
                          >
                            <Input
                              disabled
                              size={"large"}
                              placeholder={t("common.text.input", {
                                item: "Nama",
                              })}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} md={12}>
                      <div className="wui-form-group type-column">
                        <div className="input-section">
                          <Form.Item
                            name="email"
                            label={"Email"}
                            rules={[
                              {
                                required: true,
                                message: t("validation.required", {
                                  item: "Email",
                                }),
                              },
                              {
                                type: "email",
                                message: t("validation.type.email", {
                                  item: "Email",
                                }),
                              },
                            ]}
                          >
                            <Input
                              disabled
                              size={"large"}
                              placeholder={t("common.text.input", {
                                item: "Email",
                              })}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} md={12}>
                      <div className="wui-form-group type-column">
                        <div className="input-section">
                          <Form.Item
                            name="mobile_phone"
                            label={"Nomor Handphone"}
                            rules={[
                              {
                                required: true,
                                message: t("validation.required", {
                                  item: "Nomor Handphone",
                                }),
                              },
                              {
                                pattern: new RegExp("^[+][0-9]*$"),
                                message:
                                  "Nomor handphone tidak valid. Contoh: +628131234567",
                              },
                              {
                                min: 11,
                                message: t("validation.type.min", {
                                  item: "Nomor Handphone",
                                  min: 11,
                                }),
                              },
                              {
                                max: 14,
                                message: t("validation.type.max", {
                                  item: "Nomor Handphone",
                                  max: 14,
                                }),
                              },
                            ]}
                          >
                            <Input
                              disabled
                              size={"large"}
                              placeholder={t("common.text.input", {
                                item: "Nomor Handphone",
                              })}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24}>
                      <div className="wui-form-group type-column">
                        <div className="input-section">
                          <Form.Item
                            name="pic_name"
                            label={"PIC"}
                            rules={[
                              {
                                required: true,
                                message: t("validation.required", {
                                  item: "PIC",
                                }),
                              },
                            ]}
                          >
                            <Input
                              disabled={disable.get()}
                              size={"large"}
                              placeholder={t("common.text.input", {
                                item: "PIC",
                              })}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24}>
                      <div className="wui-form-group type-column">
                        <div className="input-section">
                          <Form.Item
                            name="brands"
                            label={"Brand"}
                            rules={[
                              {
                                required: true,
                                message: t("validation.required", {
                                  item: "Brand",
                                }),
                              },
                            ]}
                          >
                            <WuiSelectMulti
                              disabled={disable.get()}
                              repository={BrandRepository}
                              placeholder={t("select.placeholder", {
                                item: "Brand",
                              })}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24}>
                      <div className="wui-form-group type-column">
                        <WuiFormTitle title={"Klasifikasi"} />
                        <div className="input-section">
                          {/*<Tag color={form.getFieldValue('classification') === "BO" ? "#FAAD14" : "#40A9FF"}> {form.getFieldValue('classification')}</Tag>*/}

                          {dealerCode.get() ? (
                            <AppDefaultTableau
                              tab={"PerformanceBO_QVO"}
                              filters={`Dealer=00${dealerCode.get()}`}
                              height={"400"}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Space>
              </Card>

              <Card className={"mb16"}>
                <Space
                  style={{
                    width: "100%",
                  }}
                  direction="vertical"
                  size={5}
                >
                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="cluster"
                        label={"Cluster"}

                        // rules={[{
                        //     required: true,
                        //     message: t('validation.required', {item: 'Cluster'})
                        // }]}
                      >
                        <WuiSelectSingle
                          allowClear
                          disabled={disable.get()}
                          repository={ClusterRepository}
                          showOptionDescription={true}
                          placeholder={t("select.placeholder", {
                            item: "Cluster",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="post_code"
                        label={"Kode Pos"}
                        rules={[
                          {
                            max: 5,
                            message: t("validation.type.max", {
                              item: "Kode Pos",
                              max: 5,
                            }),
                          },
                          {
                            pattern: new RegExp("^[0-9]*$"),
                            message: "Kode pos tidak valid",
                          },
                        ]}
                      >
                        <Input
                          disabled
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Kode Pos",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item name="address" label={"Alamat"} rules={[{}]}>
                        <Input.TextArea
                          disabled
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Alamat",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <TableMaps dataSource={$clone(locations.get())} />
                </Space>
              </Card>

              <Card title="Informasi Finansial" className={"mb16"}>
                <Space
                  style={{
                    width: "100%",
                  }}
                  direction="vertical"
                  size={5}
                >
                  <div>
                    <div className="mb8">
                      <WuiFormTitle title="Limit Kredit" />
                    </div>

                    <Row
                      wrap={false}
                      className="mb18"
                      justify="start"
                      align="middle"
                    >
                      <Col flex="none" className="mr16">
                        <strong>1010 :</strong>
                      </Col>
                      <Col flex="auto">
                        <Form.Item name="credit_limit_1010" className="mb0">
                          <NumericFormat
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            displayType="text"
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row
                      wrap={false}
                      className="mb18"
                      justify="start"
                      align="middle"
                    >
                      <Col flex="none" className="mr16">
                        <strong>1220 :</strong>
                      </Col>
                      <Col flex="auto">
                        <Form.Item name="credit_limit_1220" className="mb0">
                          <NumericFormat
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            displayType="text"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>

                  <div>
                    <div className="mb8">
                      <WuiFormTitle title="Outstanding Payment" />
                    </div>
                    <Row
                      wrap={false}
                      className="mb18"
                      justify="start"
                      align="middle"
                    >
                      <Col flex="none" className="mr16">
                        <strong>1010 :</strong>
                      </Col>
                      <Col flex="auto">
                        <Form.Item name="outstanding_1010" className="mb0">
                          <NumericFormat
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            displayType="text"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row
                      wrap={false}
                      className="mb18"
                      justify="start"
                      align="middle"
                    >
                      <Col flex="none" className="mr16">
                        <strong>1220 :</strong>
                      </Col>
                      <Col flex="auto">
                        <Form.Item name="outstanding_1220" className="mb0">
                          <NumericFormat
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            displayType="text"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>

                  <div>
                    <div className="mb8">
                      <WuiFormTitle title="Nota Kredit" />
                    </div>
                    <Row
                      wrap={false}
                      className="mb18"
                      justify="start"
                      align="middle"
                    >
                      <Col flex="none" className="mr16">
                        <strong>1010 :</strong>
                      </Col>
                      <Col flex="auto">
                        <Form.Item name="credit_note_1010" className="mb0">
                          <NumericFormat
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            displayType="text"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row
                      wrap={false}
                      className="mb18"
                      justify="start"
                      align="middle"
                    >
                      <Col flex="none" className="mr16">
                        <strong>1220 :</strong>
                      </Col>
                      <Col flex="auto">
                        <Form.Item name="credit_note_1220" className="mb0">
                          <NumericFormat
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            displayType="text"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>

                  <TableGAP
                    channel={$clone(channel.get())}
                    dealerId={id}
                    type="dealer"
                  />
                </Space>
              </Card>

              <Card title="Informasi Transaksi" className={"mb16"}>
                <TableTransaction channel={channel.get()} dealerId={id} />
              </Card>

              <Card title={t("common.text.product")} className="mb16">
                <Space
                  style={{
                    width: "100%",
                  }}
                  direction="vertical"
                  size={25}
                >
                  <Tabs
                    defaultActiveKey="1"
                    onChange={(key) => {
                      tabKey.set(key);
                      setSearch("");
                      setFilterDate([moment(), moment()]);
                    }}
                  >
                    <TabPane tab={t("common.text.product")} key="1">
                      <Space
                        style={{
                          width: "100%",
                        }}
                        direction="vertical"
                        size={25}
                      >
                        <Row>
                          <Col md={12} xs={24}>
                            <Input
                              allowClear
                              placeholder={t(
                                "common.filter.search.placeholder"
                              )}
                              prefix={<SearchOutlined />}
                              value={search}
                              onChange={(value) => {
                                setSearch(value.target.value);
                              }}
                            />
                          </Col>
                        </Row>

                        <TableProduct
                          dealerId={id}
                          active={tabKey.get() === "1"}
                          search={debouncedSearch}
                          sourceChannel={channel.get()}
                        />
                      </Space>
                    </TabPane>

                    <TabPane tab={t("common.text.stock")} key="2">
                      <Space
                        style={{
                          width: "100%",
                        }}
                        direction="vertical"
                        size={25}
                      >
                        <Row>
                          <Col md={12} xs={24}>
                            <Input
                              allowClear
                              placeholder={t(
                                "common.filter.search.placeholder"
                              )}
                              prefix={<SearchOutlined />}
                              value={search}
                              onChange={(value) => {
                                setSearch(value.target.value);
                              }}
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col md={12} xs={24}>
                            <WuiFilterDateRange
                              defaultValue={filterDate}
                              callback={handleDateRangeCallback}
                            />
                          </Col>
                        </Row>

                        <div className="wui-form-group type-column">
                          <WuiFormTitle title={"Stok Minimum"} />

                          <div className="input-section">
                            <InputNumber
                              defaultValue={minStock.get()}
                              onChange={(val) => minStock.set(val ?? 0)}
                            />
                          </div>
                        </div>

                        <TableStock
                          dealerId={id}
                          active={tabKey.get() === "2"}
                          search={debouncedSearch}
                          dateRange={filterDate}
                          sourceChannel={channel.get()}
                        />
                      </Space>
                    </TabPane>
                    <TabPane tab={t("common.text.sold")} key="3">
                      <Space
                        style={{
                          width: "100%",
                        }}
                        direction="vertical"
                        size={25}
                      >
                        <Row>
                          <Col md={12} xs={24}>
                            <Input
                              allowClear
                              placeholder={t(
                                "common.filter.search.placeholder"
                              )}
                              prefix={<SearchOutlined />}
                              value={search}
                              onChange={(value) => {
                                setSearch(value.target.value);
                              }}
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col md={12} xs={24}>
                            <WuiFilterDateRange
                              defaultValue={filterDate}
                              callback={handleDateRangeCallback}
                            />
                          </Col>
                        </Row>

                        <TableSold
                          dealerId={id}
                          active={tabKey.get() === "3"}
                          search={debouncedSearch}
                          dateRange={filterDate}
                          sourceChannel={channel.get()}
                        />
                      </Space>
                    </TabPane>
                  </Tabs>
                </Space>
              </Card>
            </Col>
          </Row>

          <div className="wui-form-btn-group">
            <Button
              className="wui-btn"
              size={"large"}
              onClick={() => navigate(-1)}
            >
              {t("common.button.cancel")}
            </Button>
            <Accessible access={[permissions.write_dealer_b2b]}>
              <Spin spinning={loading.get()}>
                <Button
                  className="wui-btn"
                  htmlType="submit"
                  type="primary"
                  size={"large"}
                  disabled={disable.get()}
                >
                  {t("common.button.save")}
                </Button>
              </Spin>
            </Accessible>
          </div>
        </Form>
      </WuiContainer>
    </>
  );
};

export default AppSettingsDataMasterDealerForm;
