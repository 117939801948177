import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PageHeader from "antd/es/page-header";
import { useNavigate, useParams } from "react-router-dom";
import WuiContainer from "../../../../../../@framework/wui/components/Container";
import Card from "antd/lib/card";
import Space from "antd/lib/space";
import Input from "antd/es/input";
import Form from "antd/lib/form";
import { useState } from "@hookstate/core";
import Button from "antd/es/button";
import ProductRepository from "../../../../../../repositories/ProductRepository";
import { AxiosError, AxiosResponse } from "axios";
import {
  handleBackendError,
  haveAccess,
  openNotification,
  toFileList,
} from "../../../../../../functions/global";
import Spin from "antd/lib/spin";
import { Col, Row } from "antd/lib/grid";
import WuiUploadImages from "../../../../../../@framework/wui/components/UploadImages";
import WuiSelectSingle from "../../../../../../@framework/wui/components/Select/Single";
import BrandRepository from "../../../../../../repositories/BrandRepository";
import ProductTypeRepository from "../../../../../../repositories/ProductTypeRepository";
import Switch from "antd/es/switch";
import Accessible from "../../../../../../@framework/wui/components/Accessible";
import useAccess from "../../../../../../@framework/utilities/hooks/useAccess";
import { permissions } from "../../../../../../constant/permissions";

let title = "Produk";

const AppSettingsDataMasterProductForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const pageTitle = id
    ? t("common.text.editItem", { item: title })
    : t("common.text.addNewItem", { item: title });

  // Form
  const [form] = Form.useForm();
  const disable = useState(false);
  const loading = useState(false);
  const acl = useAccess();

  const onFinish = async (data: any) => {
    loading.set(true);

    const formData = new FormData();

    formData.append("product_type_id", data.product_type.value);

    if (data.image) {
      data.image.forEach((file: any) => {
        if (file?.status === "done") {
          formData.append("image_keep", file.id);
          formData.append("image", file);
        } else {
          formData.append("image", file);
        }
      });
    }

    await (!id
      ? ProductRepository.create(formData)
      : ProductRepository.update(id, formData)
    )
      .then((res: AxiosResponse) => {
        if (!id) {
          openNotification(
            "success",
            t("notification.success.createItem", { item: title })
          );
        } else {
          openNotification(
            "success",
            t("notification.success.updateItem", { item: title })
          );
          loading.set(false);
          //   navigate(-1);
        }
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));
        loading.set(false);
      });
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    disable.set(true);

    await ProductRepository.show(id, {
      with: ["brand", "image", "productType"],
    })
      .then((res: AxiosResponse) => {
        const data = res?.data?.data;

        const fieldValue: any = {
          image: toFileList(data?.image),
          code: data?.code,
          name: data?.name,
          description: data?.description,
          is_active: data?.is_active,
        };
        if (data?.brand) {
          fieldValue.brand = {
            value: data?.brand?.id,
            label: data?.brand?.name,
          };
        }

        if (data?.product_type) {
          fieldValue.product_type = {
            value: data?.product_type_id,
            label: data?.product_type?.name,
          };
        }

        form.setFieldsValue(fieldValue);
        if (
          haveAccess(acl, [
            permissions.write_product_b2b,
            permissions.write_product_b2c,
          ])
        ) {
          disable.set(false);
        }
      })
      .catch((e: AxiosError) => {});
  };

  return (
    <>
      <WuiContainer>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
        />

        <Form form={form} layout={"vertical"} onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col
              className="gutter-row"
              md={{
                span: 16,
                order: 1,
              }}
              xs={{
                span: 24,
                order: 2,
              }}
            >
              <Card title={t("common.text.itemInformation", { item: title })}>
                <Space
                  style={{
                    width: "100%",
                  }}
                  direction="vertical"
                  size={5}
                >
                  {id && (
                    <div className="wui-form-group type-column">
                      <div className="input-section">
                        <Form.Item name="id" label={"ID"}>
                          <Input
                            defaultValue={id}
                            disabled
                            size={"large"}
                            placeholder={t("common.text.input", { item: "ID" })}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  )}
                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="code"
                        label={"Kode"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", { item: "Kode" }),
                          },
                        ]}
                      >
                        <Input
                          disabled
                          size={"large"}
                          placeholder={t("common.text.input", { item: "Kode" })}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="name"
                        label={"Nama"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", { item: "Nama" }),
                          },
                        ]}
                      >
                        <Input
                          disabled
                          size={"large"}
                          placeholder={t("common.text.input", { item: "Nama" })}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="brand"
                        label={"Brand"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Brand",
                            }),
                          },
                        ]}
                      >
                        <WuiSelectSingle
                          disabled
                          repository={BrandRepository}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  {form.getFieldValue("brand")?.value && (
                    <div className="wui-form-group type-column">
                      <div className="input-section">
                        <Form.Item
                          name="product_type"
                          label={"Tipe Produk"}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: "Tipe Produk",
                              }),
                            },
                          ]}
                        >
                          <WuiSelectSingle
                            disabled={disable.get()}
                            repository={ProductTypeRepository}
                            selectParams={{
                              brands: [form.getFieldValue("brand").value],
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  )}
                  <div className="wui-form-group">
                    <div className="input-section">
                      <Form.Item
                        name="description"
                        label={"Deskripsi"}
                        rules={[{}]}
                      >
                        <Input.TextArea
                          disabled
                          size={"large"}
                          rows={4}
                          placeholder={t("common.text.input", {
                            item: "Deskripsi",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="is_active"
                        label={"Status"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Status",
                            }),
                          },
                        ]}
                        valuePropName="checked"
                      >
                        <Switch disabled />
                      </Form.Item>
                    </div>
                  </div>
                </Space>
              </Card>
            </Col>

            <Col
              className="gutter-row"
              md={{
                span: 8,
                order: 2,
              }}
              xs={{
                span: 24,
                order: 1,
              }}
            >
              <Card title={t("common.text.image")}>
                <Form.Item name="image">
                  {!disable.get() && <WuiUploadImages />}
                </Form.Item>
              </Card>
            </Col>
          </Row>

          <div className="wui-form-btn-group">
            <Button
              className="wui-btn"
              size={"large"}
              onClick={() => navigate(-1)}
            >
              {t("common.button.cancel")}
            </Button>
            <Accessible
              access={[
                permissions.write_product_b2b,
                permissions.write_product_b2c,
              ]}
            >
              <Spin spinning={loading.get()}>
                <Button
                  className="wui-btn"
                  htmlType="submit"
                  type="primary"
                  size={"large"}
                  disabled={disable.get()}
                >
                  {t("common.button.save")}
                </Button>
              </Spin>
            </Accessible>
          </div>
        </Form>
      </WuiContainer>
    </>
  );
};

export default AppSettingsDataMasterProductForm;
