import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {useState} from "@hookstate/core";
import WuiSectionTitle from "../../../../../@framework/wui/components/Sections/Title";
import WuiContainer from "../../../../../@framework/wui/components/Container";
import Button from "antd/es/button";
import PageHeader from "antd/es/page-header";
import Card from "antd/es/card/Card";
import Row from "antd/es/grid/row";
import WuiFormTitle from "../../../../../@framework/wui/components/Form/Title";
import Text from "antd/es/typography/Text";
import Col from "antd/es/grid/col";
import Table from "antd/es/table";
import {NumericFormat} from "react-number-format";
import Space from "antd/es/space";
import { ModalFraud } from "../Component/Modal";
import {AxiosError, AxiosResponse} from "axios";
import moment from "moment/moment";
import {handleBackendError, inputNumberThousandSeparator} from "../../../../../functions/global";
import KPIUserRepository from "../../../../../repositories/KPIUserRepository";
import Input from "antd/es/input";
import {Checkbox} from "antd";
import {InputNumber} from "antd/es";
import _ from "lodash";

const AppKPIUserDetail: React.FC<any> = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const pageTitle = useState<string>('Detail User KPI')
    const [showFraud, setShowFraud] = React.useState(false)

    const breadcrumbs = useState<any[]>([
        {
            label: 'KPI',
            link: '/kpi'
        },
        {
            label: 'User KPI',
            link: '/kpi/user-kpi'
        },
    ])

    // Data
    const [data, setData] = React.useState<any>(null);

    // Focus process Section
    const focusProcessColumns: any = [
        {
            title: "No",
            dataIndex: "index",
            key: "index",
            width: 100,
            render: (text: any, item: any, index: number) => {
                return index+1
            }
        },
        {
            title: "Item KPI",
            dataIndex: "item_kpi",
            key: "item_kpi",
            render: (text: any, record: any) => {
                return (
                    <>
                        <div>
                            {record?.kpi_item?.name}
                        </div>
                        {
                            record?.multiplier?.length > 0 && (
                                <div className="mt16">
                                    {record?.multiplier?.map((e: any) => {
                                        return (
                                            <div className="mt4 mb4">
                                                {e.first_operator + e.first_value}
                                                {e.second_operator ? ' － ' + e.second_operator + e.second_value : ''} =
                                                {' ' + e.weight_percentage}
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        }
                        {
                            record?.ratio && (
                                <div className="mt16">
                                    Ratio = {record?.ratio}%
                                </div>
                            )
                        }
                    </>
                )
            }
        },
        {
            title: "Target",
            dataIndex: "target",
            key: "target",
            render: (text: any) => {
                return text ? <NumericFormat value={text} thousandSeparator={'.'} decimalSeparator={','} displayType="text"/> : null
            }
        },
        {
            title: "Weight",
            dataIndex: "weight",
            key: "weight",
            render: (text: any) => {
                return <NumericFormat value={text} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} displayType="text"/>
            }
        },
    ]

    // Focus Result Section
    const focusResultColumns: any = [
        {
            title: "No",
            dataIndex: "index",
            key: "index",
            width: 100,
            render: (text: any, item: any, index: number) => {
                return index+1
            }
        },
        {
            title: "Item KPI",
            dataIndex: "item_kpi",
            key: "item_kpi",
            render: (text: any, record: any) => {
                return (
                    <>
                        <div>
                            {record?.kpi_item?.name}
                        </div>
                        {
                            record?.multiplier?.length > 0 && (
                                <div className="mt16">
                                    {record?.multiplier?.map((e: any) => {
                                        return (
                                            <div className="mt4 mb4">
                                                {e.first_operator + e.first_value}
                                                {e.second_operator ? ' － ' + e.second_operator + e.second_value : ''} =
                                                {' ' + e.weight_percentage}
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        }
                        {
                            record?.ratio && (
                                <div className="mt16">
                                    Ratio = {record?.ratio}%
                                </div>
                            )
                        }
                    </>
                )
            }
        },
        {
            title: "Target",
            dataIndex: "target",
            key: "target",
            render: (text: any) => {
                return text ? <NumericFormat value={text} thousandSeparator={'.'} decimalSeparator={','} displayType="text"/> : null
            }
        },
        {
            title: "Weight",
            dataIndex: "weight",
            key: "weight",
            render: (text: any) => {
                return <NumericFormat value={text} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} displayType="text"/>
            }
        },
    ]

    // Focus Result Section
    const brandTargetColumns: any = [
        {
            title: "Brand",
            dataIndex: "brand_name",
            key: "brand_name",
            render: (text: any, record: any) => {
                return (
                    <Space
                        style={{
                            width: "100%",
                            minWidth: 240
                        }}
                        direction="vertical"
                        size={0}
                    >
                        {record.brand.name}

                        <Checkbox checked={record.is_primary}>
                            <Text>Brand Utama</Text>
                        </Checkbox>
                    </Space>
                );
            }
        },
        {
            title: "Target",
            dataIndex: "target",
            key: "target",
            render: (text: any) => {
                return text ? <NumericFormat value={text} thousandSeparator={'.'} decimalSeparator={','} displayType="text"/> : null
            }
        },
        {
            title: "Bobot",
            dataIndex: "weight_percentage",
            key: "weight_percentage",
            render: (text: any) => {
                return <NumericFormat value={text} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} displayType="text"/>
            }
        },
    ]

    // Business Acumen Section
    const businessAcumenColumns: any = [
        {
            title: "No",
            dataIndex: "index",
            key: "index",
            width: 100,
            render: (text: any, item: any, index: number) => {
                return index+1
            }
        },
        {
            title: "Item KPI",
            dataIndex: "item_kpi",
            key: "item_kpi",
            render: (text: any, record: any) => {
                return (
                    <>
                        <div>
                            {record?.kpi_item?.name}
                        </div>
                        {
                            record?.multiplier?.length > 0 && (
                                <div className="mt16">
                                    {record?.multiplier?.map((e: any) => {
                                        return (
                                            <div className="mt4 mb4">
                                                {e.first_operator + e.first_value}
                                                {e.second_operator ? ' － ' + e.second_operator + e.second_value : ''} =
                                                {' ' + e.weight_percentage}
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        }
                        {
                            record?.ratio && (
                                <div className="mt16">
                                    Ratio = {record?.ratio}%
                                </div>
                            )
                        }
                    </>
                )
            }
        },
        {
            title: "Target",
            dataIndex: "target",
            key: "target",
            render: (text: any) => {
                return text ? <NumericFormat value={text} thousandSeparator={'.'} decimalSeparator={','} displayType="text"/> : null
            }
        },
        {
            title: "Weight",
            dataIndex: "weight",
            key: "weight",
            render: (text: any) => {
                return <NumericFormat value={text} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} displayType="text"/>
            }
        },
    ]

    // BU Balance Section
    const buBalanceColumns: any = [
        {
            title: "No",
            dataIndex: "index",
            key: "index",
            width: 100,
            render: (text: any, item: any, index: number) => {
                return index+1
            }
        },
        {
            title: "BU",
            dataIndex: "bu_name",
            key: "bu_name",
            render: (text: any, record: any) => {
                return (
                    <>
                        <div>
                            {record?.business_unit?.name}
                        </div>
                        {
                            record?.multiplier?.length > 0 && (
                                <div className="mt16">
                                    {record?.multiplier?.map((e: any) => {
                                        return (
                                            <div className="mt4 mb4">
                                                {e.first_operator + e.first_value}
                                                {e.second_operator ? ' － ' + e.second_operator + e.second_value : ''} =
                                                {' ' + e.weight_percentage}
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        }
                    </>
                )
            }
        },
        {
            title: "Weight",
            dataIndex: "weight",
            key: "weight",
            render: (text: any) => {
                return <NumericFormat value={text} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} displayType="text"/>
            }
        },
    ]

    // Target Store section
    const targetStoreColumns: any = [
        {
            title: "No",
            dataIndex: "index",
            key: "index",
            width: 100,
            render: (text: any, item: any, index: number) => {
                return index+1
            }
        },
        {
            title: "Toko",
            dataIndex: "store_name",
            key: "store_name",
            render: (text: any, record: any) => {
                return record?.store?.name
            }
        },
        {
            title: "Business Unit",
            dataIndex: "business_unit_name",
            key: "business_unit_name",
            render: (text: any, record: any) => {
                return record?.store?.business_unit?.name
            }
        },
        {
            title: "Periode",
            dataIndex: "period",
            key: "period",
            render: (text: any) => {
                return moment(text).format("MMMM YYYY")
            }
        },
        {
            title: "Target",
            dataIndex: "target",
            key: "target",
            render: (text: any) => {
                return <NumericFormat value={text} thousandSeparator={'.'} decimalSeparator={','} displayType="text"/>
            }
        }
    ]

    // Finance parameter section
    const financeParameterColumns: any = [
        {
            title: "No",
            dataIndex: "index",
            key: "index",
            width: 100,
            render: (text: any, item: any, index: number) => {
                return index+1
            }
        },
        {
            title: "Item KPI",
            dataIndex: "item_kpi",
            key: "item_kpi",
            render: (text: any, record: any) => {
                return (
                    <>
                        <div>
                            {record?.kpi_item?.name}
                        </div>
                        {
                            record?.multiplier?.length > 0 && (
                                <div className="mt16">
                                    {record?.multiplier?.map((e: any) => {
                                        return (
                                            <div className="mt4 mb4">
                                                {e.first_operator + e.first_value}
                                                {e.second_operator ? ' － ' + e.second_operator + e.second_value : ''} =
                                                {' ' + e.weight_percentage}
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        }
                        {
                            record?.ratio && (
                                <div className="mt16">
                                    Ratio = {record?.ratio}%
                                </div>
                            )
                        }
                    </>
                )
            }
        },
        {
            title: "Target",
            dataIndex: "target",
            key: "target",
            render: (text: any) => {
                return text ? <NumericFormat value={text} thousandSeparator={'.'} decimalSeparator={','} displayType="text"/> : null
            }
        },
        {
            title: "Weight",
            dataIndex: "weight",
            key: "weight",
            render: (text: any) => {
                return <NumericFormat value={text} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} displayType="text"/>
            }
        }
    ]

    // Fraud section
    const fraudColumns: any = [
        {
            title: "No",
            dataIndex: "index",
            key: "index",
            width: 100,
            render: (text: any, item: any, index: number) => {
                return index+1
            }
        },
        {
            title: "Jumlah Fraud",
            dataIndex: "total_fraud",
            key: "total_fraud",
            render: (text: any) => {
                return <Text underline onClick={() => setShowFraud(true)}><a>{text}</a></Text>  // eslint-disable-line jsx-a11y/anchor-is-valid
            }
        },
        {
            title: "Parameter",
            dataIndex: "total_fraud_parameter",
            key: "total_fraud_parameter",
            render: (text: any) => {
                return text ? <NumericFormat value={text} thousandSeparator={'.'} decimalSeparator={','} displayType="text"/> : null
            }
        },
        {
            title: "Percentage",
            dataIndex: "fraud_percentage",
            key: "fraud_percentage",
            render: (text: any) => {
                return <NumericFormat value={text} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} displayType="text"/>
            }
        }
    ]

    useEffect(() => {
        getData().then();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getData = async () => {

        await KPIUserRepository.show(id, {
            with: ['superior', 'channels', 'kpiMaster', 'kpiTarget', 'user.userSuperiors.cluster' ,
                'user.userSuperiors.area', 'user.userSuperiors.subterritory', 'user.userSuperiors.territory',
                'user.userSuperiors.region']
        }).then((res: AxiosResponse) => {
            const data = res.data?.data || {}
            
            data.detail = {
                ...data.detail,
                fraud_table: [
                    {
                        total_fraud: data?.detail?.total_fraud,
                        total_fraud_parameter: data?.detail?.total_fraud_parameter,
                        fraud_percentage: data?.detail?.fraud_percentage
                    }
                ],
                frauds: data?.detail?.frauds && data?.detail?.frauds.length > 0 ? data?.detail?.frauds.map((v: any) => {
                    return {
                        ...v,
                        store_name: v.store.name,
                        user_name: data?.user?.name,
                        user_nik: data?.user?.code
                    }
                }) : []
            }
            setData(data)

        }).catch((e: AxiosError) => {
            handleBackendError(e);
        });
    }

    return (
        <>
            <WuiContainer>
                <WuiSectionTitle title={''} breadcrumbs={breadcrumbs.get()}/>

                <PageHeader
                    className="default-page-header"
                    onBack={() => navigate(-1)}
                    title={pageTitle.get()}
                />

                <Card
                    className="mb16"
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={24}>
                            <div className="wui-form-group type-column">
                                <WuiFormTitle title={"Channel"} />

                                <div className="input-section">
                                    <Text strong>{data?.channels?.join(", ")}</Text>
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} md={24}>
                            <div className="wui-form-group type-column">
                                <WuiFormTitle title={"Nik"} />

                                <div className="input-section">
                                    <Text strong>{data?.user?.code ?? '-'}</Text>
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} md={12}>
                            <div className="wui-form-group type-column">
                                <WuiFormTitle title={"Kode"} />

                                <div className="input-section">
                                    <Text strong>{data?.kpi_master?.code}</Text>
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} md={12}>
                            <div className="wui-form-group type-column">
                                <WuiFormTitle title={"Nama Master Kpi"} />

                                <div className="input-section">
                                    <Text strong>{data?.kpi_master?.name}</Text>
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} md={12}>
                            <div className="wui-form-group type-column">
                                <WuiFormTitle title={"Peran"} />

                                <div className="input-section">
                                    <Text strong>{data?.superior?.name}</Text>
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} md={12}>
                            <div className="wui-form-group type-column">
                                <WuiFormTitle title={"Nama"} />

                                <div className="input-section">
                                    <Text strong>{data?.user?.name}</Text>
                                </div>
                            </div>
                        </Col>

                        {
                            _.isEqual(data?.channels, ['B2B']) ? (
                                <Col xs={24} md={24}>
                                    <div className="wui-form-group type-column">
                                        <WuiFormTitle title={"Brand"} />

                                        <div className="input-section">
                                            <Text strong>{_.map(data?.detail?.brands, "brand.name").join(', ')}</Text>
                                        </div>
                                    </div>
                                </Col>
                            ) : ''
                        }

                        {
                            data?.user?.user_superiors?.map((superior: any) => {
                                return (<>
                                    <Col xs={24} md={12}>
                                        <div className="wui-form-group type-column">
                                            <WuiFormTitle title={"Hirarki "+superior?.channel} />

                                            <div className="input-section">
                                                <Text strong>{superior?.area_hierarchy}</Text>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={24} md={12}>
                                        <div className="wui-form-group type-column">
                                            <WuiFormTitle title={"Area "+superior?.channel} />

                                            <div className="input-section">
                                                <Text strong>{superior[superior?.area_hierarchy.toLowerCase()]?.name}</Text>
                                            </div>
                                        </div>
                                    </Col>
                                </>)
                            })
                        }

                        <Col xs={24} md={12}>
                            <div className="wui-form-group type-column">
                                <WuiFormTitle title={"Tanggal Mulai"} />

                                <div className="input-section">
                                    <Text strong>{moment(data?.start_date).format("D MMM YYYY")}</Text>
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} md={12}>
                            <div className="wui-form-group type-column">
                                <WuiFormTitle title={"Tanggal Berakhir"} />

                                <div className="input-section">
                                    <Text strong>{moment(data?.end_date).format("D MMM YYYY")}</Text>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card>

                {
                    _.isEqual(data?.channels, ['B2B']) || _.isEqual(data?.channels,['B2B','B2C'])  ? (
                        <>
                            <Card
                                title={'Fokus Proses'}
                                className="mb16"
                                extra={
                                    <Text strong>
                                        <NumericFormat value={data?.detail?.process_focus_weight} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} displayType="text"/>
                                    </Text>
                                }
                            >
                                <Table
                                    rowKey={"id"}
                                    pagination={false}
                                    columns={focusProcessColumns}
                                    dataSource={data?.detail?.process_focus}
                                />

                                {
                                    _.isEqual(data?.channels,['B2B','B2C']) && (
                                        <Table
                                            className="mt16"
                                            rowKey={"id"}
                                            columns={fraudColumns}
                                            pagination={false}
                                            style={{ whiteSpace: 'pre'}}
                                            dataSource={data?.detail?.fraud_table}
                                        />
                                    )
                                }
                            </Card>

                            <Card
                                title={'Fokus Hasil'}
                                className="mb16"
                                extra={
                                    <Text strong>
                                        <NumericFormat value={data?.detail?.result_focus_weight} thousandSeparator={'.'} decimalSeparator={','} suffix={'%'} displayType="text"/>
                                    </Text>
                                }
                            >
                                <Table
                                    rowKey={"id"}
                                    pagination={false}
                                    columns={focusResultColumns}
                                    dataSource={data?.detail?.result_focus}
                                />
                            </Card>

                            {
                                _.isEqual(data?.channels, ['B2B']) ?
                                    (<>
                                        <Table
                                            rowKey={"id"}
                                            pagination={false}
                                            columns={brandTargetColumns}
                                            dataSource={data?.detail?.brands}
                                        />
                                    </>): (<>
                                        <Card
                                            title={'Target Store'}
                                            className="mb16"
                                        >
                                            <Table
                                                rowKey={"id"}
                                                columns={targetStoreColumns}
                                                pagination={false}
                                                style={{ whiteSpace: 'pre'}}
                                                dataSource={data?.detail?.stores}
                                            />
                                        </Card>
                                    </>)
                            }
                        </>
                    ) : (
                        <>
                            <Card
                                title={'Business Acumen'}
                                className="mb16"
                            >
                                <Table
                                    rowKey={"id"}
                                    pagination={false}
                                    style={{ whiteSpace: 'pre'}}
                                    columns={businessAcumenColumns}
                                    dataSource={data?.detail?.business_acumen}
                                />
                            </Card>

                            <Card
                                title={'BU Balance'}
                                className="mb16"
                            >
                                <Table
                                    rowKey={"id"}
                                    columns={buBalanceColumns}
                                    pagination={false}
                                    style={{ whiteSpace: 'pre'}}
                                    dataSource={data?.detail?.business_units}
                                />
                            </Card>

                            <Card
                                title={'Target Store'}
                                className="mb16"
                            >
                                <Table
                                    rowKey={"id"}
                                    columns={targetStoreColumns}
                                    pagination={false}
                                    style={{ whiteSpace: 'pre'}}
                                    dataSource={data?.detail?.stores}
                                />
                            </Card>

                            <Card
                                title={'Finance Parameter'}
                                className="mb16"
                            >
                                <Table
                                    rowKey={"id"}
                                    columns={financeParameterColumns}
                                    pagination={false}
                                    style={{ whiteSpace: 'pre'}}
                                    dataSource={data?.detail?.finance_parameter}
                                />

                                <Table
                                    className="mt16"
                                    rowKey={"id"}
                                    columns={fraudColumns}
                                    pagination={false}
                                    style={{ whiteSpace: 'pre'}}
                                    dataSource={data?.detail?.fraud_table}
                                />
                            </Card>
                        </>
                    )
                }

                <Input.Group className="mt24">
                    <Row align={"middle"}>
                        <Col span={6}>
                            Variabel Pengali
                        </Col>
                        <Col span={18}>
                            <InputNumber
                                formatter={inputNumberThousandSeparator.formatter}
                                parser={inputNumberThousandSeparator.parser}
                                defaultValue={data?.detail?.multiplier_variable}
                                value={data?.detail?.multiplier_variable}
                                style={{ width: '100%' }}
                                readOnly
                            />
                        </Col>
                    </Row>
                </Input.Group>

                <div className="wui-form-btn-group">
                    <Button
                        className="wui-btn"
                        size={"large"}
                        type="primary"
                        onClick={() => navigate(-1)}
                    >
                        {t("common.button.back")}
                    </Button>
                </div>
            </WuiContainer>

            <ModalFraud
                show={showFraud}
                dataSource={data?.detail?.frauds}
                handleClose={() => setShowFraud(false)}
            />
        </>
    )
}


export default AppKPIUserDetail