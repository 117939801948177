import {api, createCancelTokenHandler} from "../@framework/services";
import { TChannels } from "../utils";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
    all: function (channel: TChannels, params: any = null) {
        return api.get(`${endPoint()}/api/v1/${channel}/request-journey-plans`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.all.name].handleRequestCancellation().token
        });
    },
    show: function (id: number | string, channel: TChannels, params: any = null) {
        return api.get(`${endPoint()}/api/v1/${channel}/request-journey-plan/${id}`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.show.name].handleRequestCancellation().token
        });
    },
    create: function (channel: TChannels, payload: any, params: any = null) {
        return api.post(`${endPoint()}/api/v1/${channel}/request-journey-plan`, payload, {
            params,
            cancelToken: cancelTokenHandlerObject[this.create.name].handleRequestCancellation().token
        });
    },
    update: function (id: number | string, channel: TChannels, payload: any, params: any = null) {
        return api.put(`${endPoint()}/api/v1/${channel}/request-journey-plan/${id}`, payload, {
            params,
            cancelToken: cancelTokenHandlerObject[this.update.name].handleRequestCancellation().token
        });
    },
    updateStatus: function (id: number | string, channel: TChannels, payload: any, params: any = null) {
        return api.put(`${endPoint()}/api/v1/${channel}/request-journey-plan/${id}/status`, payload, {
            params,
            cancelToken: cancelTokenHandlerObject[this.updateStatus.name].handleRequestCancellation().token
        });
    },
    updateMultipleStatus: function (channel: TChannels, payload: any, params: any = null) {
        return api.post(`${endPoint()}/api/v1/${channel}/request-journey-plan/status`, payload, {
            params,
            cancelToken: cancelTokenHandlerObject[this.updateStatus.name].handleRequestCancellation().token
        });
    },
    delete: function (id: number | string, channel: TChannels, params: any = null) {
        return api.delete(`${endPoint()}/api/v1/${channel}/request-journey-plan/${id}`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.delete.name].handleRequestCancellation().token
        });
    }
}

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;