import React, { useEffect } from "react";
import WuiContainer from "../../../../../@framework/wui/components/Container";
import PageHeader from "antd/es/page-header";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Card from "antd/es/card";
import Space from "antd/es/space";
import Button from "antd/es/button";
import WuiFormTitle from "../../../../../@framework/wui/components/Form/Title";
import Form from "antd/lib/form";
import { Col, Row } from "antd";
import { useState } from "@hookstate/core";
import {handleBackendError, openNotification} from "../../../../../functions/global";
import { AxiosError, AxiosResponse } from "axios";
import UniformRequestRepository from "../../../../../repositories/UniformRequestRepository";
import Text from "antd/es/typography/Text";
import moment from "moment";


let title = "Permintaan Seragam";

const AppPromotorUniformRequestForm: React.FC<any> = () => {
    const { t } = useTranslation()
    const { id } = useParams();
    const navigate = useNavigate();
    const pageTitle = id ? t('common.text.editItem', { item: title }) : t('common.text.addNewItem', { item: title })
    // Form
    const [form] = Form.useForm();
    const disable = useState(false);
    const loading = useState(false);
    const tabKey = useState('1')

    const [data, setData] = React.useState<any>({})

    const onFinish = async (data: any) => {
        loading.set(true)

        const formData = new FormData();

        await (!id ? (UniformRequestRepository.create(formData)) : UniformRequestRepository.update(id, formData)).then((res: AxiosResponse) => {
            if (!id) {
                navigate(-1)
                openNotification('success', t('notification.success.createItem', { item: title }))
            } else {
                openNotification('success', t('notification.success.updateItem', { item: title }))
                loading.set(false);
            }
        }).catch((e: AxiosError) => {
            handleBackendError(e, t('notification.error.default'))
            loading.set(false);
        })
    }

    useEffect(() => {
        if (id) {
            getData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getData = async () => {
        disable.set(true)

        await UniformRequestRepository.show(id, { with: ['sales'] }).then((res: AxiosResponse) => {
            const data = res.data?.data || {}

            setData({
                ...data,
                sales_name: data.sales?.name,
                date: moment(data.date).format('D MMM YYYY | HH:mm'),
            })


            disable.set(false)
        }).catch((e: AxiosError) => {
            console.log(e)

        })
    }
    return (
        <>
            <WuiContainer>
                <PageHeader
                    className="default-page-header"
                    onBack={() => navigate(-1)}
                    title={pageTitle}
                />

                <Form
                    form={form}
                    layout={"vertical"}
                    onFinish={onFinish}
                >

                    <Row gutter={[16, 16]}>
                        <Col className="gutter-row" md={{
                            span: 16,
                            order: 1
                        }} xs={{
                            span: 24,
                            order: 2
                        }}>
                            <Card title="Informasi">
                                <Space style={{
                                    width: '100%',
                                    maxHeight: tabKey.get() === '1' ? '100%' : 0,
                                    opacity: tabKey.get() === '1' ? 1 : 0,
                                }} direction="vertical" size={25}>
                                    <div className="wui-form-group">
                                        <WuiFormTitle title={"ID"} />

                                        <div className="input-section">
                                            <Text>{data.number}</Text>
                                        </div>
                                    </div>
                                    <div className="wui-form-group">
                                        <WuiFormTitle title={"Nama Sales"} />

                                        <div className="input-section">
                                            <Text>{data.sales_name}</Text>
                                        </div>
                                    </div>
                                    <div className="wui-form-group">
                                        <WuiFormTitle title={"Tanggal Permintaan"} />

                                        <div className="input-section">
                                            <Text>{data.date}</Text>
                                        </div>
                                    </div>

                                    <div className="wui-form-group">
                                        <WuiFormTitle title={"Warna Seragam"} />

                                        <div className="input-section">
                                            <Text>{data.color}</Text>
                                        </div>
                                    </div>

                                    <div className="wui-form-group">
                                        <WuiFormTitle title={"Ukuran Seragam"} />

                                        <div className="input-section">
                                            <Text>{data.size}</Text>
                                        </div>
                                    </div>
                                </Space>
                            </Card>
                        </Col>
                    </Row>

                    <div className="wui-form-btn-group">
                        <Button className="wui-btn" size={"large"} onClick={() => navigate(-1)}>{t('common.button.back')}</Button>
                    </div>
                </Form>
            </WuiContainer>
        </>
    )
}

export default AppPromotorUniformRequestForm
