import {api, createCancelTokenHandler} from "../@framework/services";
import {TChannels} from "../utils";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
    select: function (params: any = null) {
        let url = `${endPoint()}/api/v1/select/assistant-store-leader`;
        if (params?.channel) {
        url = `${endPoint()}/api/v1/${params.channel}/select/assistant-store-leader`;
        }
        return api.get(url, {
            params,
            cancelToken: cancelTokenHandlerObject[this.select.name].handleRequestCancellation().token
        });
    },
    all: function (channel: TChannels = 'b2c', params: any = null) {
        return api.get(`${endPoint()}/api/v1/${channel}/assistant-store-leaders`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.all.name].handleRequestCancellation().token
        });
    },
    show: function (id: number | string, channel: TChannels = 'b2c', params: any = null) {
        return api.get(`${endPoint()}/api/v1/${channel}/assistant-store-leader/${id}`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.show.name].handleRequestCancellation().token
        });
    },
    create: function (channel: TChannels = 'b2c', payload: any, params: any = null) {
        return api.post(`${endPoint()}/api/v1/${channel}/assistant-store-leader`, payload, {
            params,
            cancelToken: cancelTokenHandlerObject[this.create.name].handleRequestCancellation().token
        });
    },
    update: function (id: number | string, channel: TChannels = 'b2c',  payload: any, params: any = null) {
        return api.put(`${endPoint()}/api/v1/${channel}/assistant-store-leader/${id}`, payload, {
            params,
            cancelToken: cancelTokenHandlerObject[this.update.name].handleRequestCancellation().token
        });
    },
    delete: function (id: number | string, channel: TChannels = 'b2c', params: any = null) {
        return api.delete(`${endPoint()}/api/v1/${channel}/assistant-store-leader/${id}`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.delete.name].handleRequestCancellation().token
        });
    },
    pendingIssue: function (channel: TChannels = 'b2c', payload: any, params: any = null) {
        return api.post(`${endPoint()}/api/v1/${channel}/assistant-store-leader/pending-issue`, payload, {
            params,
            cancelToken: cancelTokenHandlerObject[this.pendingIssue.name].handleRequestCancellation().token
        });
    },
    importMappingStore: function (payload: any, params: any = null, channel: TChannels = 'b2c') {
        return api.post(`${endPoint()}/api/v1/${channel}/user-store-import`, payload, {
            params,
            cancelToken:
            cancelTokenHandlerObject[this.importMappingStore.name].handleRequestCancellation()
                .token,
        });
    },
    importSalesHierarchy: function (payload: any, params: any = null, channel: TChannels = 'b2c') {
        return api.post(`${endPoint()}/api/v1/${channel}/assistant-store-import`, payload, {
            params,
            cancelToken:
            cancelTokenHandlerObject[this.importSalesHierarchy.name].handleRequestCancellation()
                .token,
        });
    },
    deleteStore: function (id: number | string, storeId: number | string,  params: any = null) {
        return api.delete(`${endPoint()}/api/v1/b2c/assistant-store-leader/${id}/${storeId}`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.delete.name].handleRequestCancellation().token
        });
    },
}

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;