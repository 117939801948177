import {api, createCancelTokenHandler} from "../@framework/services";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
    all: function (params: any = null) {
        return api.get(`${endPoint()}/api/v1/b2b/dealer-prospects`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.all.name].handleRequestCancellation().token
        });
    },
    show: function (id: number | string, params: any = null) {
        return api.get(`${endPoint()}/api/v1/b2b/dealer-prospect/${id}`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.show.name].handleRequestCancellation().token
        });
    },
    delete: function (id: number | string, params: any = null) {
        return api.delete(`${endPoint()}/api/v1/b2b/dealer-prospect/${id}`, {
            params,
            cancelToken: cancelTokenHandlerObject[this.delete.name].handleRequestCancellation().token
        });
    },
    export : function (params: any = null) {
        return api({
            url: `${endPoint()}/api/v1/b2b/dealer-prospects`,
            params,
            method: 'GET',
            responseType: 'blob',
        })
    },
}

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;