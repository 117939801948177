import Upload from "antd/lib/upload";
import React from "react";
import {
  ShowUploadListInterface,
  UploadListType,
} from "antd/lib/upload/interface";

interface Props {
  value?: any[];
  disabled?: boolean;
  onChange?: (value: any[]) => void;
  max?: number;
  listType?: UploadListType;
  accept?: string;
  customButton?: any;
  showUploadList?: boolean | ShowUploadListInterface;
}

const WuiUploadImages: React.FC<Props> = ({
  value = [],
  onChange,
  disabled,
  max = 1,
  listType = "picture-card",
  accept = "image/*",
  customButton = null,
  showUploadList = true,
}) => {
  const [fileList, setFileList] = React.useState<any>(value);

  const handleOnChange: any = ({ fileList: newFileList }: any) => {
    setFileList(newFileList);

    let originalsFileObjects = newFileList.map((item: any) => {
      if (item?.originFileObj) {
        return item?.originFileObj;
      } else {
        return item;
      }
    });

    if (onChange) {
      onChange(originalsFileObjects);
    }
  };

  const handleOnPreview = async (file: any) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();

    image.src = src;
    const imgWindow = window.open(src);

    if (imgWindow) {
      imgWindow.document.write(
        `<body style="margin: 0"><iframe src="${src}" style="display: block;" width="100%" height="100%" frameBorder="0"></body>`
      );
    }
  };

  return (
    <Upload
      disabled={disabled}
      accept={accept}
      listType={listType}
      fileList={fileList}
      onChange={handleOnChange}
      onPreview={handleOnPreview}
      beforeUpload={(test) => {
        return false;
      }}
      showUploadList={showUploadList}
    >
      {customButton
        ? fileList.length < max && !disabled && customButton
        : fileList.length < max && !disabled && "+ Upload"}
    </Upload>
  );
};

export default WuiUploadImages;
