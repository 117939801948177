/* eslint-disable eqeqeq */
/* eslint-disable no-mixed-operators */
import React, { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "antd/es/page-header";
import WuiContainer from "../../../../@framework/wui/components/Container";
import WuiSectionTitle from "../../../../@framework/wui/components/Sections/Title";
import Row from "antd/es/grid/row";
import Col from "antd/es/grid/col";
import Card from "antd/es/card/Card";
import WuiFormTitle from "../../../../@framework/wui/components/Form/Title";
import Text from "antd/es/typography/Text";
import Space from "antd/es/space";
import Form from "antd/lib/form";
import WuiSelectSingle from "../../../../@framework/wui/components/Select/Single";
import { useState } from "@hookstate/core";
import {Empty, InputNumber, Pagination, Tabs} from "antd";
import TabPane from "antd/es/tabs/TabPane";
import {
  DeleteOutlined,
  DownloadOutlined,
  FileExcelOutlined,
  PlusOutlined, SearchOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Button from "antd/es/button";
import Accessible from "../../../../@framework/wui/components/Accessible";
import Spin from "antd/lib/spin";
import WuiModalImport from "../../../../@framework/wui/components/Modal/Import";
import { STOCK_OPNAME_PRODUCT_IMPORT } from "../../../../constant/import";
import WuiModalConfirmation from "../../../../@framework/wui/components/Modal/Confirmation";
import moment from "moment";
import StoreRepository from "../../../../repositories/StoreRepository";
import StockOpnameRepository from "../../../../repositories/StockOpnameRepository";
import { AxiosError, AxiosResponse } from "axios";
import {
  handleBackendError,
  openNotification,
} from "../../../../functions/global";
import { permissions } from "../../../../constant/permissions";
import ProductStockRepository from "../../../../repositories/ProductStockRepository";
import { $clone } from "../../../../@framework/utilities";
import { useAppSelector } from "../../../../stores/hooks";
import { useReactToPrint } from "react-to-print";
import StockOpnameProductExport from "../../../../components/stock-opname-product-export";
import WuiUploadImages from "../../../../@framework/wui/components/UploadImages";
import _ from "lodash";
import ProductRepository from "../../../../repositories/ProductRepository";
import Input from "antd/es/input";
import VuiSelect from "../../../../components/select";
import {
  ProductStock,
  StockOpnameProduct,
  StockOpnameProductStatus,
} from "../../../../components/stock-opname-product-export/interface";
import fileDownload from "js-file-download";
import useDebounce from "../../../../@framework/utilities/hooks/useDebounce";

let title = "Stock Opname";

const breadcrumbs = [
  {
    label: "Stock Opname",
    link: "/stock-opname",
  },
  {
    label: "Tambah",
    link: "/stock-opname/add",
  },
];

const AppStockOpnameAdd: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const pageTitle = t("common.text.addNewItem", { item: title });
  const system = useAppSelector((state) => state.system);

  // Form
  const [form] = Form.useForm();
  const watchStoreCode = Form.useWatch("store_code_id", form);
  const watchStoreName = Form.useWatch("store_name_id", form);
  const disable = useState(false);
  const loading = useState(false);

  const [searchImport, setSearchImport] = React.useState<string>("");
  const debouncedSearchImport = useDebounce<string>(searchImport, 400);
  const [currentPageImport, setCurrentPageImport] = React.useState<number>(1);

  const [searchManual, setSearchManual] = React.useState<string>("");
  const debouncedSearchManual = useDebounce<string>(searchManual, 400);
  const [currentPageManual, setCurrentPageManual] = React.useState<number>(1);

  const mappingStatusProduct = useCallback(
    (data: any[], status: StockOpnameProductStatus) => {
      return _.map(data, (collection) => ({
        ...collection,
        status: status,
      }));
    },
    []
  );

  const onFinish = useCallback(
    async (data: any) => {
      if (!isNextStep.get() && !id) {
        showModalNext.set(true);
        return;
      }

      if (data.attachments && data.attachments.length == 0) {
        openNotification("error", "Wajib isi lampiran");
        return;
      }

      loading.set(true);

      const formData = new FormData();

      formData.append("store_id", data.store_code_id.value);

      const importProducts = [
        ...mappingStatusProduct(
          form.getFieldValue("good_stock_import_products"),
          "Good Stock"
        ),
        ...mappingStatusProduct(
          form.getFieldValue("damage_import_products"),
          "Damage"
        ),
        ...mappingStatusProduct(
          form.getFieldValue("demo_import_products"),
          "Demo"
        ),
      ];

      if (importProducts.length > 0) {
        formData.append('import_products', JSON.stringify(importProducts));
      }

      const manualProducts = [
        ...mappingStatusProduct(
          form.getFieldValue("good_stock_manual_products"),
          "Good Stock"
        ),
        ...mappingStatusProduct(
          form.getFieldValue("damage_manual_products"),
          "Damage"
        ),
        ...mappingStatusProduct(
          form.getFieldValue("demo_manual_products"),
          "Demo"
        ),
      ];

      if (manualProducts.length > 0) {
        formData.append('manual_products', JSON.stringify(manualProducts));
      }

      if (data.attachments && data.attachments.length > 0) {
        data.attachments.forEach((attachment: any) => {
          formData.append("attachments[]", attachment);
        });
      }

      await StockOpnameRepository.create(formData)
        .then((response: AxiosResponse) => {
          navigate(-1);

          openNotification(
            "success",
            t(`notification.success.createItem`, {
              item: title,
            })
          );

          loading.set(false);
        })
        .catch((e: AxiosError) => {
          handleBackendError(e, t("notification.error.default"));
          loading.set(false);
        });
    },
    [id, form] // eslint-disable-line react-hooks/exhaustive-deps
  );

  // Tabs
  const tabKey = useState("1");

  // Import Modal
  const showImportModal = useState(false);

  const [excludeGoodStockIds, setExcludeGoodStockIds] = React.useState<
    number[]
  >([]);
  const [excludeDamageIds, setExcludeDamageIds] = React.useState<number[]>([]);
  const [excludeDemoIds, setExcludeDemoIds] = React.useState<number[]>([]);

  const importModalCallback = useCallback(
    async (files: File[]) => {
      const formData = new FormData();

      if (files) {
        files.forEach((file: any) => {
          formData.append("file_import", file);
        });

        formData.append("store_id", watchStoreCode.value);

        await StockOpnameRepository.import(formData)
          .then((response: AxiosResponse) => {
            const data: StockOpnameProduct[] = response.data.data;
            const damageData = data.filter((item) => item.status === "Damage");
            const goodStockData = data.filter(
              (item) => item.status === "Good Stock"
            );
            const demoData = data.filter((item) => item.status === "Demo");

            const goodStockIds = goodStockData.map((item) =>
              Number(item.product_id)
            );
            const damageIds = damageData.map((item) => Number(item.product_id));
            const demoIds = demoData.map((item) => Number(item.product_id));

            setExcludeGoodStockIds(goodStockIds);
            setExcludeDamageIds(damageIds);
            setExcludeDemoIds(demoIds);

            const goodStockManualProducts = form.getFieldValue(
              "good_stock_manual_products"
            );
            const damageManualProducts = form.getFieldValue(
              "damage_manual_products"
            );
            const demoManualProducts = form.getFieldValue(
              "demo_manual_products"
            );

            form.setFieldsValue({
              ...form.getFieldsValue(),
              good_stock_import_products: goodStockData,
              damage_import_products: damageData,
              demo_import_products: demoData,
              good_stock_manual_products: goodStockManualProducts.filter(
                (product: StockOpnameProduct) =>
                  !goodStockIds.includes(Number(product.product_id))
              ),
              damage_manual_products: damageManualProducts.filter(
                (product: StockOpnameProduct) =>
                  !damageIds.includes(Number(product.product_id))
              ),
              demo_manual_products: demoManualProducts.filter(
                (product: StockOpnameProduct) =>
                  !demoIds.includes(Number(product.product_id))
              ),
            });

            isNextStep.set(false);
            setTimeout(() => {
              showImportModal.set(false);
            }, 500);
          })
          .catch((e: AxiosError) => {
            handleBackendError(e, t("notification.error.default"));
          });
      }
    },
    [watchStoreCode, id, form] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const importModalDownload = async () => {
    let params = {
      store_id: watchStoreCode.value,
      date: moment().subtract(1, "days").format("YYYY-MM-DD"),
    };
    try {
      let res = await ProductStockRepository.export(params);
      await fileDownload(res.data, `${Date.now()} - Template Stock Opname Product.xlsx`);
    } catch (err) {}
  }

  const exportProductRef = useRef(null);
  const handleTableProductExport = useReactToPrint({
    content: () => exportProductRef.current,
    documentTitle: `stock-opname-${moment().format("DD-MMMM-YYYY")}`,
  });

  const renderTableProductButton = () => {
    return (
      <Space wrap>
        {isNextStep.get() && (
          <Button
            icon={<FileExcelOutlined />}
            onClick={handleTableProductExport}
          >
            Export
          </Button>
        )}

        <Button
          disabled={isNextStep.get()}
          icon={<DownloadOutlined />}
          type="primary"
          onClick={() => showImportModal.set(true)}
        >
          Import
        </Button>
      </Space>
    );
  };

  // Next Confirmation
  const isNextStep = useState(false);
  const showModalNext = useState(false);

  const mappingProductStock = useCallback(
    (products: StockOpnameProduct[], productStocks: ProductStock[]) => {
      if (products.length < 1) {
        return [];
      }

      return products.map((product) => {
        const findData = productStocks.find(
          (item) =>
            item.product_id == product.product_id &&
            item.status == product.status
        );
        return {
          ...product,
          system_stock: findData ? findData.stock : 0,
          description: "",
        };
      });
    },
    []
  );

  const handleNextConfirmation = useCallback(async () => {
    isNextStep.set(true);

    const goodStockImportProducts = form.getFieldValue(
      "good_stock_import_products"
    );
    const damageImportProducts = form.getFieldValue("damage_import_products");
    const demoImportProducts = form.getFieldValue("demo_import_products");
    const goodStockManualProducts = form.getFieldValue(
      "good_stock_manual_products"
    );
    const damageManualProducts = form.getFieldValue("damage_manual_products");
    const demoManualProducts = form.getFieldValue("demo_manual_products");

    const products = [
      ...goodStockImportProducts,
      ...damageImportProducts,
      ...demoImportProducts,
      ...goodStockManualProducts,
      ...damageManualProducts,
      ...demoManualProducts,
    ];

    const productIds = Array.from(
      new Set(products.map((product) => Number(product.product_id)))
    );

    const params = {
      per_page: 999999,
      page: 1,
      stock_date: moment().subtract(1, "days").format("YYYY-MM-DD"),
      product_ids: productIds,
      store: watchStoreCode.value,
    };

    await ProductStockRepository.all(params)
      .then((response: AxiosResponse) => {
        const data: ProductStock[] = response.data.data;

        form.setFieldsValue({
          ...form.getFieldsValue(),
          good_stock_import_products: mappingProductStock(
            goodStockImportProducts,
            data
          ),
          damage_import_products: mappingProductStock(
            damageImportProducts,
            data
          ),
          demo_import_products: mappingProductStock(demoImportProducts, data),
          good_stock_manual_products: mappingProductStock(
            goodStockManualProducts,
            data
          ),
          damage_manual_products: mappingProductStock(
            damageManualProducts,
            data
          ),
          demo_manual_products: mappingProductStock(demoManualProducts, data),
        });
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));
      });

    showModalNext.set(false);
  }, [watchStoreCode]); // eslint-disable-line react-hooks/exhaustive-deps

  const storeName = useState("");

  const onChangeStore = useCallback(
    (data: any, isCode: boolean) => {
      const splitKey = data.key.split("-");
      const additionalKey = splitKey[splitKey.length - 1];

      if (isCode) {
        form.setFieldValue("store_name_id", {
          value: data.value,
          label: additionalKey,
        });

        storeName.set(additionalKey);
      } else {
        form.setFieldValue("store_code_id", {
          value: data.value,
          label: additionalKey,
        });

        storeName.set(splitKey[1]);
      }

      form.setFieldsValue({
        good_stock_import_products: [],
        damage_import_products: [],
        demo_import_products: [],
        good_stock_manual_products: [],
        damage_manual_products: [],
        demo_manual_products: [],
      });
    },
    [form] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onChangeProduct = useCallback(
    (index: number, parentFieldName: string, fieldName: string, value: any) => {
      const manualProducts = $clone(form.getFieldValue(parentFieldName));
      manualProducts[index][fieldName] = value;
      manualProducts[index]["product_id"] = value ? value.value : undefined;
      form.setFieldsValue({
        ...form.getFieldsValue(),
        [parentFieldName]: manualProducts,
      });
    },
    []
  );

  const filterStockOpnameManualProduct = (item: any): boolean => {
    if (!item.product_code || !item.product_name) {
      return true
    }

    return item.product_code?.label?.toLowerCase()?.includes(debouncedSearchManual) ||
        item.product_name?.label?.toLowerCase()?.includes(debouncedSearchManual)
  }

  const filterStockOpnameImportProduct = (item: any): boolean => {
    if (!item.product_code || !item.product_name) {
      return true
    }

    return item.product_code?.toLowerCase()?.includes(debouncedSearchImport) ||
        item.product_name?.toLowerCase()?.includes(debouncedSearchImport)
  }

  const renderStockOpnameManualProduct = useCallback(
    (fieldName: string) => {
      const status = fieldName.includes("demo")
        ? "Demo"
        : fieldName.includes("damage")
        ? "Damage"
        : "Good Stock";

      const excludeIds = fieldName.includes("demo")
        ? excludeDemoIds
        : fieldName.includes("damage")
        ? excludeDamageIds
        : excludeGoodStockIds;

      return (
        <Form.List
          name={[fieldName]}
          rules={[
            {
              validator: async (_, names) => {
                const products = [
                  ...form.getFieldValue("good_stock_import_products"),
                  ...form.getFieldValue("damage_import_products"),
                  ...form.getFieldValue("demo_import_products"),
                  ...form.getFieldValue("good_stock_manual_products"),
                  ...form.getFieldValue("damage_manual_products"),
                  ...form.getFieldValue("demo_manual_products"),
                ];

                if (products.length < 1) {
                  return Promise.reject(
                    new Error(
                      t("validation.required", {
                        item: "Produk",
                      })
                    )
                  );
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => {
            fields = fields.filter(({ name }) => filterStockOpnameManualProduct(form.getFieldValue(fieldName)[name]))
            const perPage = 5;
            const isLastPage = currentPageManual == Math.ceil(fields.length / perPage)
            const startSlice = currentPageManual === 1 ? 0 : (currentPageManual - 1) * perPage
            const modulus = fields.length % perPage
            const endSlice = (isLastPage ? (((currentPageManual - 1) * perPage) + (modulus === 0 ? perPage : modulus)) : (currentPageManual * perPage))

            return (
                <>
                  <Space
                      style={{
                        width: "100%",
                      }}
                      direction="vertical"
                      size={15}
                  >
                    <Form.ErrorList errors={errors} />
                    <div className="ant-table-wrapper">
                      <div className="ant-table ant-table-bordered">
                        <div className="ant-table-container">
                          <div
                              className="ant-table-content"
                              style={{ overflowX: "auto" }}
                          >
                            <table style={{ tableLayout: "auto" }}>
                              <thead className="ant-table-thead">
                              <tr>
                                <th className="ant-table-cell">Kode Produk</th>
                                <th className="ant-table-cell">Nama Produk</th>
                                {isNextStep.get() && (
                                    <th className="ant-table-cell">Stok Sistem</th>
                                )}
                                <th className="ant-table-cell">Stok Toko</th>
                                {isNextStep.get() && (
                                    <>
                                      <th className="ant-table-cell">Selisih</th>
                                      <th className="ant-table-cell">Keterangan</th>
                                    </>
                                )}
                                {fields.length > 0 && (
                                    <th className="ant-table-cell" />
                                )}
                              </tr>
                              </thead>
                              <tbody className="ant-table-tbody">
                              {fields.length > 0 ? (
                                  fields
                                  .slice(startSlice, endSlice)
                                  .map(({ key, name, ...restField }) => {
                                    const manualProducts =
                                        form.getFieldValue(fieldName);
                                    const systemStock: any =
                                        manualProducts[name]?.system_stock || 0;

                                    return (
                                        <tr
                                            key={key}
                                            className="ant-table-row ant-table-row-level-0"
                                        >
                                          <td className="ant-table-cell">
                                            <Form.Item
                                                className="hidden"
                                                name={[name, "product_id"]}
                                            />
                                            <Form.Item className="mb0" shouldUpdate>
                                              {() => {
                                                return (
                                                    <Form.Item
                                                        className="mb0"
                                                        {...restField}
                                                        name={[name, "product_code"]}
                                                        rules={[
                                                          {
                                                            required: true,
                                                            message: t(
                                                                "validation.required",
                                                                {
                                                                  item: "Kode Produk",
                                                                }
                                                            ),
                                                          },
                                                        ]}
                                                    >
                                                      <VuiSelect
                                                          labelKey="code"
                                                          repository={ProductRepository}
                                                          repositoryParams={{
                                                            exclude: excludeIds,
                                                          }}
                                                          disabled={isNextStep.get()}
                                                          onChange={(value) => {
                                                            onChangeProduct(
                                                                name,
                                                                fieldName,
                                                                "product_name",
                                                                value
                                                                    ? {
                                                                      value: value.value,
                                                                      label:
                                                                      value.anotherData
                                                                          .name,
                                                                    }
                                                                    : undefined
                                                            );
                                                          }}
                                                      />
                                                    </Form.Item>
                                                );
                                              }}
                                            </Form.Item>
                                          </td>
                                          <td className="ant-table-cell">
                                            <Form.Item className="mb0" shouldUpdate>
                                              {() => {
                                                return (
                                                    <Form.Item
                                                        {...restField}
                                                        className="mb0"
                                                        name={[name, "product_name"]}
                                                        rules={[
                                                          {
                                                            required: true,
                                                            message: t(
                                                                "validation.required",
                                                                {
                                                                  item: "Nama Produk",
                                                                }
                                                            ),
                                                          },
                                                        ]}
                                                    >
                                                      <VuiSelect
                                                          repository={ProductRepository}
                                                          repositoryParams={{
                                                            exclude: excludeIds,
                                                          }}
                                                          disabled={isNextStep.get()}
                                                          onChange={(value) => {
                                                            onChangeProduct(
                                                                name,
                                                                fieldName,
                                                                "product_code",
                                                                value
                                                                    ? {
                                                                      value: value.value,
                                                                      label:
                                                                      value.anotherData
                                                                          .code,
                                                                    }
                                                                    : undefined
                                                            );
                                                          }}
                                                      />
                                                    </Form.Item>
                                                );
                                              }}
                                            </Form.Item>
                                          </td>
                                          {isNextStep.get() && (
                                              <td className="ant-table-cell">
                                                {systemStock}
                                              </td>
                                          )}
                                          <td className="ant-table-cell">
                                            <Form.Item
                                                {...restField}
                                                className="mb0"
                                                name={[name, "store_stock"]}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: t("validation.required", {
                                                      item: "Stok Toko",
                                                    }),
                                                  },
                                                ]}
                                            >
                                              <InputNumber
                                                  disabled={isNextStep.get()}
                                              />
                                            </Form.Item>
                                          </td>
                                          {isNextStep.get() && (
                                              <>
                                                <td className="ant-table-cell">
                                                  {systemStock -
                                                      manualProducts[name].store_stock}
                                                </td>
                                                <td className="ant-table-cell">
                                                  <Form.Item
                                                      {...restField}
                                                      className="mb0"
                                                      name={[name, "description"]}
                                                  >
                                                    <Input size={"large"} />
                                                  </Form.Item>
                                                </td>
                                              </>
                                          )}
                                          {fields.length > 0 && (
                                              <td className="ant-table-cell">
                                                <DeleteOutlined
                                                    onClick={() => {
                                                      remove(name)

                                                      if (fields.length % perPage === 1 && currentPageManual !== 1) {
                                                        setCurrentPageManual(currentPageManual - 1)
                                                      }
                                                    }}
                                                />
                                              </td>
                                          )}
                                        </tr>
                                    );
                                  })
                              ) : (
                                  <tr>
                                    <td colSpan={isNextStep.get() ? 7 : 4}>
                                      <Empty />
                                    </td>
                                  </tr>
                              )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Space>

                  {!isNextStep.get() && (
                      <Button
                          icon={<PlusOutlined />}
                          type="primary"
                          ghost
                          block
                          className={"mt16"}
                          onClick={() => {
                            add({
                              id: Date.now(),
                              product_code: undefined,
                              product_name: undefined,
                              store_stock: 0,
                              description: "",
                              system_stock: 0,
                              status: status,
                              product_id: null,
                            })

                            if (fields.length % perPage === 0 && fields.length >= perPage) {
                              setCurrentPageManual(currentPageManual + 1)
                            }
                          }}
                      >
                        Tambah Produk
                      </Button>
                  )}

                  <Row className="mt16" justify="end">
                    <Pagination
                        current={currentPageManual}
                        pageSize={perPage}
                        showSizeChanger={false}
                        onChange={(page, _) => setCurrentPageManual(page)}
                        total={fields.length} />
                  </Row>
                </>
            )
          }}
        </Form.List>
      );
    },
    [isNextStep.get(), excludeDemoIds, excludeDamageIds, excludeGoodStockIds, debouncedSearchManual, currentPageManual]
  );

  const renderStockOpnameImportProduct = useCallback(
    (fieldName: string) => {
      return (
        <Form.List
          name={[fieldName]}
          rules={[
            {
              validator: async (_, names) => {
                const products = [
                  ...form.getFieldValue("good_stock_import_products"),
                  ...form.getFieldValue("damage_import_products"),
                  ...form.getFieldValue("demo_import_products"),
                  ...form.getFieldValue("good_stock_manual_products"),
                  ...form.getFieldValue("damage_manual_products"),
                  ...form.getFieldValue("demo_manual_products"),
                ];

                if (products.length < 1) {
                  return Promise.reject(
                    new Error(
                      t("validation.required", {
                        item: "Import Produk",
                      })
                    )
                  );
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => {
            fields = fields.filter(({ name }) => filterStockOpnameImportProduct(form.getFieldValue(fieldName)[name]))
            const perPage = 5;
            const isLastPage = currentPageImport == Math.ceil(fields.length / perPage)
            const startSlice = currentPageImport === 1 ? 0 : (currentPageImport - 1) * perPage
            const modulus = fields.length % perPage
            const endSlice = (isLastPage ? (((currentPageImport - 1) * perPage) + (modulus === 0 ? perPage : modulus)) : (currentPageImport * perPage))

            return (
                <>
                  <Space
                      style={{
                        width: "100%",
                      }}
                      direction="vertical"
                      size={15}
                  >
                    <Form.ErrorList errors={errors} />
                    <div className="ant-table-wrapper">
                      <div className="ant-table ant-table-bordered" style={{ overflowX: "auto" }}>
                        <div className="ant-table-container">
                          <div className="ant-table-content">
                            <table style={{ tableLayout: "auto" }}>
                              <thead className="ant-table-thead">
                              <tr>
                                <th className="ant-table-cell">Kode Produk</th>
                                <th className="ant-table-cell">Nama Produk</th>
                                {isNextStep.get() && (
                                    <th className="ant-table-cell">Stok Sistem</th>
                                )}
                                <th className="ant-table-cell">Stok Toko</th>
                                {isNextStep.get() && (
                                    <>
                                      <th className="ant-table-cell">Selisih</th>
                                      <th className="ant-table-cell">Keterangan</th>
                                    </>
                                )}
                              </tr>
                              </thead>
                              <tbody className="ant-table-tbody">
                              {fields.length > 0 ? (
                                  fields
                                  .slice(startSlice, endSlice)
                                  .map(({ key, name, ...restField }) => {
                                    const importProducts =
                                        form.getFieldValue(fieldName);
                                    const systemStock: any =
                                        importProducts[name]?.system_stock || 0;

                                    return (
                                        <tr
                                            key={key}
                                            className="ant-table-row ant-table-row-level-0"
                                        >
                                          <td className="ant-table-cell">
                                            <Form.Item
                                                className="hidden"
                                                name={[name, "product_id"]}
                                            />
                                            {importProducts[name].product_code}
                                          </td>
                                          <td className="ant-table-cell">
                                            {importProducts[name].product_name}
                                          </td>
                                          {isNextStep.get() && (
                                              <td className="ant-table-cell">
                                                {systemStock}
                                              </td>
                                          )}
                                          <td className="ant-table-cell">
                                            <Form.Item
                                                {...restField}
                                                className="mb0"
                                                name={[name, "store_stock"]}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: t("validation.required", {
                                                      item: "Stok Toko",
                                                    }),
                                                  },
                                                ]}
                                            >
                                              <InputNumber
                                                  disabled={isNextStep.get()}
                                              />
                                            </Form.Item>
                                          </td>
                                          {isNextStep.get() && (
                                              <>
                                                <td className="ant-table-cell">
                                                  {systemStock -
                                                      importProducts[name].store_stock}
                                                </td>
                                                <td className="ant-table-cell">
                                                  <Form.Item
                                                      {...restField}
                                                      className="mb0"
                                                      name={[name, "description"]}
                                                  >
                                                    <Input size={"large"} />
                                                  </Form.Item>
                                                </td>
                                              </>
                                          )}
                                        </tr>
                                    );
                                  })
                              ) : (
                                  <tr>
                                    <td colSpan={isNextStep.get() ? 7 : 4}>
                                      <Empty />
                                    </td>
                                  </tr>
                              )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <Row justify="end">
                      <Pagination
                          current={currentPageImport}
                          pageSize={perPage}
                          showSizeChanger={false}
                          onChange={(page, _) => setCurrentPageImport(page)}
                          total={fields.length} />
                    </Row>
                  </Space>
                </>
            )
          }}
        </Form.List>
      );
    },
    [isNextStep.get(), debouncedSearchImport, currentPageImport]
  );

  const tabProductManualKey = useState("1");

  return (
    <>
      {storeName.get() && isNextStep.get() && (
        <div className="visually-hidden">
          <StockOpnameProductExport
            demoImportProduct={form.getFieldValue("demo_import_products") || []}
            demoManualProduct={form.getFieldValue("demo_manual_products") || []}
            damageImportProduct={
              form.getFieldValue("damage_import_products") || []
            }
            damageManualProduct={
              form.getFieldValue("damage_manual_products") || []
            }
            goodStockImportProduct={
              form.getFieldValue("good_stock_import_products") || []
            }
            goodStockManualProduct={
              form.getFieldValue("good_stock_manual_products") || []
            }
            ref={exportProductRef}
            store={storeName.get()}
          />
        </div>
      )}

      <WuiContainer>
        <WuiSectionTitle title={""} breadcrumbs={breadcrumbs} />

        <Row gutter={[16, 16]}>
          <Col md={24} xs={24}>
            <Row>
              <Col
                md={{
                  span: 16,
                  order: 1,
                }}
                xs={{
                  span: 24,
                  order: 2,
                }}
              >
                <PageHeader
                  className="default-page-header"
                  onBack={() => navigate(-1)}
                  title={pageTitle}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Form
          preserve
          form={form}
          layout={"vertical"}
          onFinish={onFinish}
          requiredMark={false}
          initialValues={{
            good_stock_import_products: [],
            damage_import_products: [],
            demo_import_products: [],
            good_stock_manual_products: [],
            damage_manual_products: [],
            demo_manual_products: [],
          }}
        >
          <Row gutter={[16, 16]}>
            <Col
              className="gutter-row"
              md={{
                span: 16,
                order: 1,
              }}
              xs={{
                span: 24,
                order: 2,
              }}
            >
              <div>
                <Card
                  title={t("common.text.itemInformation", { item: "Toko" })}
                  className="mb16"
                >
                  <Space
                    style={{
                      width: "100%",
                    }}
                    direction="vertical"
                    size={25}
                  >
                    <Row gutter={[16, 16]}>
                      <Col xs={24} md={isNextStep.get() ? 12 : 24}>
                        <div className="wui-form-group type-column">
                          <WuiFormTitle title={"Tanggal"} />

                          <div className="input-section">
                            <Text strong>
                              {moment().format("DD MMMM YYYY")}
                            </Text>
                          </div>
                        </div>
                      </Col>

                      {isNextStep.get() ? (
                        <Col xs={24} md={id ? 8 : 12}>
                          <div className="wui-form-group type-column">
                            <WuiFormTitle title={"Jam"} />

                            <div className="input-section">
                              <Text strong>{moment().format("HH:mm")}{" "} WIB</Text>
                            </div>
                          </div>
                        </Col>
                      ) : null}

                      <Col xs={24} md={!isNextStep.get() ? 24 : 12}>
                        <div className="wui-form-group type-column">
                          <div className="input-section">
                            <Form.Item
                              name="store_code_id"
                              label={"Kode Toko"}
                              rules={[
                                {
                                  required: true,
                                  message: t("validation.required", {
                                    item: "Kode Toko",
                                  }),
                                },
                              ]}
                              style={{ marginBottom: 0 }}
                            >
                              {isNextStep.get() ? (
                                <Text strong>
                                  {watchStoreCode?.label || "-"}
                                </Text>
                              ) : (
                                <WuiSelectSingle
                                  disabled={disable.get()}
                                  repository={StoreRepository}
                                  onChange={(data) => onChangeStore(data, true)}
                                  labelKey="code"
                                  additionalKey="name"
                                  selectParams={{
                                    channel: "b2c"
                                  }}
                                />
                              )}
                            </Form.Item>
                          </div>
                        </div>
                      </Col>

                      <Col xs={24} md={isNextStep.get() ? 12 : 24}>
                        <div className="wui-form-group type-column">
                          <div className="input-section">
                            <Form.Item
                              name="store_name_id"
                              label={"Nama Toko"}
                              rules={[
                                {
                                  required: true,
                                  message: t("validation.required", {
                                    item: "Nama Toko",
                                  }),
                                },
                              ]}
                              style={{ marginBottom: 0 }}
                            >
                              {isNextStep.get() ? (
                                <Text strong>
                                  {watchStoreName?.label || "-"}
                                </Text>
                              ) : (
                                <WuiSelectSingle
                                  disabled={disable.get()}
                                  repository={StoreRepository}
                                  onChange={(data) =>
                                    onChangeStore(data, false)
                                  }
                                  additionalKey={"code"}
                                  selectParams={{
                                    channel: "b2c"
                                  }}
                                />
                              )}
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Space>
                </Card>

                {watchStoreCode && (
                  <>
                    {isNextStep.get() &&
                    form.getFieldValue("good_stock_import_products").length ===
                      0 &&
                    form.getFieldValue("demo_import_products").length === 0 &&
                    form.getFieldValue("damage_import_products").length ===
                      0 ? null : (
                      <Card
                        title={"Tabel Produk"}
                        className="mb16"
                        extra={renderTableProductButton()}
                      >
                        <Space
                          style={{
                            width: "100%",
                          }}
                          direction="vertical"
                          size={25}
                        >
                          <Tabs
                            defaultActiveKey={tabKey.get()}
                            onChange={(key) => {
                              tabKey.set(key);
                              setCurrentPageImport(1)
                            }}
                          >
                            <TabPane tab={"Good Stock"} key="1">
                              <Input
                                  allowClear
                                  placeholder={t("common.filter.search.placeholder")}
                                  prefix={<SearchOutlined />}
                                  value={searchImport}
                                  onChange={(value) => {
                                    setSearchImport(value.target.value);
                                    setCurrentPageImport(1)
                                  }}
                                  style={{ marginBottom: 20 }}
                              />
                              {renderStockOpnameImportProduct(
                                "good_stock_import_products"
                              )}
                            </TabPane>

                            <TabPane tab={"Damage"} key="2">
                              <Input
                                  allowClear
                                  placeholder={t("common.filter.search.placeholder")}
                                  prefix={<SearchOutlined />}
                                  value={searchImport}
                                  onChange={(value) => {
                                    setSearchImport(value.target.value);
                                    setCurrentPageImport(1)
                                  }}
                                  style={{ marginBottom: 20 }}
                              />
                              {renderStockOpnameImportProduct(
                                "damage_import_products"
                              )}
                            </TabPane>

                            <TabPane tab={"Demo"} key="3">
                              <Input
                                  allowClear
                                  placeholder={t("common.filter.search.placeholder")}
                                  prefix={<SearchOutlined />}
                                  value={searchImport}
                                  onChange={(value) => {
                                    setSearchImport(value.target.value);
                                    setCurrentPageImport(1)
                                  }}
                                  style={{ marginBottom: 20 }}
                              />
                              {renderStockOpnameImportProduct(
                                "demo_import_products"
                              )}
                            </TabPane>
                          </Tabs>
                        </Space>
                      </Card>
                    )}

                    {isNextStep.get() &&
                    form.getFieldValue("good_stock_manual_products").length ===
                      0 &&
                    form.getFieldValue("demo_manual_products").length === 0 &&
                    form.getFieldValue("damage_manual_products").length ===
                      0 ? null : (
                      <Card title={"Tabel Produk Manual"} className="mb16">
                        <Tabs
                          defaultActiveKey={tabProductManualKey.get()}
                          onChange={(key) => {
                            tabProductManualKey.set(key);
                            setCurrentPageManual(1)
                          }}
                        >
                          <TabPane tab={"Good Stock"} key="1">
                            <Input
                                allowClear
                                placeholder={t("common.filter.search.placeholder")}
                                prefix={<SearchOutlined />}
                                value={searchManual}
                                onChange={(value) => {
                                  setSearchManual(value.target.value);
                                  setCurrentPageManual(1)
                                }}
                                style={{ marginBottom: 20 }}
                            />
                            {renderStockOpnameManualProduct(
                              "good_stock_manual_products"
                            )}
                          </TabPane>

                          <TabPane tab={"Damage"} key="2">
                            <Input
                                allowClear
                                placeholder={t("common.filter.search.placeholder")}
                                prefix={<SearchOutlined />}
                                value={searchManual}
                                onChange={(value) => {
                                  setSearchManual(value.target.value);
                                  setCurrentPageManual(1)
                                }}
                                style={{ marginBottom: 20 }}
                            />
                            {renderStockOpnameManualProduct(
                              "damage_manual_products"
                            )}
                          </TabPane>

                          <TabPane tab={"Demo"} key="3">
                            <Input
                                allowClear
                                placeholder={t("common.filter.search.placeholder")}
                                prefix={<SearchOutlined />}
                                value={searchManual}
                                onChange={(value) => {
                                  setSearchManual(value.target.value);
                                  setCurrentPageManual(1)
                                }}
                                style={{ marginBottom: 20 }}
                            />
                            {renderStockOpnameManualProduct(
                              "demo_manual_products"
                            )}
                          </TabPane>
                        </Tabs>
                      </Card>
                    )}
                  </>
                )}

                <div className="wui-form-btn-group">
                  <Button
                    className="wui-btn"
                    size={"large"}
                    onClick={() => navigate(-1)}
                  >
                    {t("common.button.cancel")}
                  </Button>

                  <Accessible access={permissions.write_stock_opname_b2c}>
                    <Spin spinning={loading.get()}>
                      <Button
                        className="wui-btn"
                        htmlType="submit"
                        type="primary"
                        size={"large"}
                        disabled={disable.get()}
                      >
                        {isNextStep.get()
                          ? t("common.button.save")
                          : t("common.button.next")}
                      </Button>
                    </Spin>
                  </Accessible>
                </div>
              </div>
            </Col>

            <Col
              className="gutter-row"
              md={{
                span: 8,
                order: 1,
              }}
              xs={{
                span: 24,
                order: 2,
              }}
              style={{ visibility: isNextStep.get() ? "visible" : "hidden" }}
            >
              <Card
                id="stock-opname-attachments"
                title={t("common.text.attachment")}
              >
                <Form.Item
                  name="attachments"
                  label={"Lampiran"}
                  className="mb0"
                  rules={[
                    {
                      required: false,
                      message: t("validation.required", {
                        item: "Lampiran",
                      }),
                    },
                  ]}
                >
                  <WuiUploadImages
                    max={5}
                    listType={"picture"}
                    accept={"image/*, application/pdf, .docx"}
                    customButton={
                      <Button
                        icon={<UploadOutlined />}
                        type="primary"
                        ghost
                        block
                        className="mt8"
                      >
                        Upload
                      </Button>
                    }
                  />
                </Form.Item>
              </Card>
            </Col>
          </Row>
        </Form>
      </WuiContainer>

      <WuiModalImport
        show={showImportModal.get()}
        onUpload={importModalCallback}
        onCancel={() => showImportModal.set(false)}
        onDownload={importModalDownload}
        headerTitle={"Import Product"}
        maxFileOnMB={10}
      />

      <WuiModalConfirmation
        show={showModalNext.get()}
        onOk={handleNextConfirmation}
        onCancel={() => showModalNext.set(false)}
        title={"Apakah anda yakin untuk melanjutkan ?"}
        subtitle={"Jika ya maka data yang sudah diinput tidak dapat diubah"}
        confirmLabel={"Lanjut"}
        isDanger={false}
      />
    </>
  );
};

export default AppStockOpnameAdd;
