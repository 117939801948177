import { api, createCancelTokenHandler } from "../@framework/services";
import { TChannels } from "../utils";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  select: function (params: any = null) {
    let url = `${endPoint()}/api/v1/select/territory`;
    if (params?.channel) {
      url = `${endPoint()}/api/v1/${params.channel}/select/territory`;
    }

    return api.get(url, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.select.name].handleRequestCancellation()
          .token,
    });
  },
  all: function (channel: TChannels, params: any = null) {
    return api.get(`${endPoint()}/api/v1/${channel}/territories`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.all.name].handleRequestCancellation()
          .token,
    });
  },
  show: function (id: number | string, channel: TChannels, params: any = null) {
    return api.get(`${endPoint()}/api/v1/${channel}/territory/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.show.name].handleRequestCancellation()
          .token,
    });
  },
  create: function (channel: TChannels, payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/v1/${channel}/territory`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.create.name].handleRequestCancellation()
          .token,
    });
  },
  update: function (id: number | string, channel: TChannels, payload: any, params: any = null) {
    return api.put(`${endPoint()}/api/v1/${channel}/territory/${id}`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.update.name].handleRequestCancellation()
          .token,
    });
  },
  delete: function (id: number | string, channel: TChannels, params: any = null) {
    return api.delete(`${endPoint()}/api/v1/${channel}/territory/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.delete.name].handleRequestCancellation()
          .token,
    });
  },
  import: function (payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/v1/territory-import`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.create.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;
