import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import WuiContainer from "../../../../@framework/wui/components/Container";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import WuiSectionTitle from "../../../../@framework/wui/components/Sections/Title";
import React, { useEffect, useMemo } from "react";
import Col from "antd/es/grid/col";
import Input from "antd/es/input";
import Row from "antd/es/grid/row";
import Button from "antd/es/button";
import Table from "antd/es/table";
import Space from "antd/es/space";
import useDebounce from "../../../../@framework/utilities/hooks/useDebounce";
import { useState } from "@hookstate/core";
import moment from "moment";
import Collapse from "antd/lib/collapse";
import _ from "lodash";
import WuiSelectMulti from "../../../../@framework/wui/components/Select/Multi";
import Accessible from "../../../../@framework/wui/components/Accessible";
import Tag from "antd/es/tag";
import { STOCK_OPNAME_STATUS_COLOR } from "../../../../constant";
import StockOpnameRepository from "../../../../repositories/StockOpnameRepository";
import { AxiosError, AxiosResponse } from "axios";
import StoreRepository from "../../../../repositories/StoreRepository";
import StockOpnameStatusRepository from "../../../../repositories/StockOpnameStatusRepository";
import { useAppSelector } from "../../../../stores/hooks";
import { SALES_ADMIN } from "../../../../constant";
import { SALES_SUPERIOR } from "../../../../constant";

const { Panel } = Collapse;

var qs = require("qs");

const breadcrumbs = [
  {
    label: "Stock Opname",
    link: "/stock-opname",
  },
];

const AppStockOpnameList: React.FC<any> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  let fullPath = window.location.pathname;
  const system = useAppSelector((state) => state.system);

  const tablePage = useState<number>(parseInt(searchParams.get("page") || "1"));
  const tablePerPage = useState<number>(
    parseInt(searchParams.get("perPage") || "10")
  );
  const [tableLoading, setTableLoading] = React.useState<boolean>(false);
  const tableSort = useState<{
    sortField: any;
    sortOrder: any;
  }>({
    sortField: searchParams.get("sortField"),
    sortOrder: searchParams.get("sortOrder"),
  });
  const tableData = useState<any[]>([]);
  const totalData = useState<number>(0);

  // Filters
  const localSelectStoreNames = JSON.parse(
    localStorage.getItem("stock_opname.store_names") || "[]"
  );
  const [selectedStoreNames, setSelectedStoreNames] = React.useState<any[]>([]);

  const localSelectStatus = JSON.parse(
    localStorage.getItem("stock_opname.status") || "[]"
  );
  const [selectedStatus, setSelectedStatus] = React.useState<any[]>([]);

  const getTableData = async (reset: boolean = false) => {
    setTableLoading(true);

    if (reset) tablePage.set(1);

    let params = {
      page: tablePage.get(),
      search: debouncedSearch,
      order_by: tableSort.sortField.get(),
      sorted_by: tableSort.sortOrder.get(),
      per_page: tablePerPage.get(),
    };

    if (!params.order_by) {
      params.order_by = "created_at";
      params.sorted_by = "descend";
    }

    const storeNames = _.map(selectedStoreNames, "id");

    if (storeNames) {
      Object.assign(params, {
        store: storeNames,
      });
    }

    const status = _.map(selectedStatus, "id");

    if (status) {
      Object.assign(params, {
        status: status,
      });
    }

    await StockOpnameRepository.all(params)
      .then((response: AxiosResponse) => {
        const { data: responseData } = response.data;
        tableData.set(responseData);
        totalData.set(response.data?.total || 0)
        setTableLoading(false);
      })
      .catch((e: AxiosError) => {});
  };

  const handleTableChange = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {
    tablePage.set(pagination.current);
    tablePerPage.set(pagination.pageSize);

    if (sorter.order) {
      tableSort.sortField.set(sorter.field);
      tableSort.sortOrder.set(sorter.order);
    } else {
      tableSort.sortField.set(null);
      tableSort.sortOrder.set(null);
    }

    setFilterDataToQuery();
  };

  // For Search Input
  const [search, setSearch] = React.useState<string>("");
  const debouncedSearch = useDebounce<string>(search, 1000);

  useEffect(() => {
    setFilterDataToQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatus, selectedStoreNames, debouncedSearch]);

  const getFilterDataFromQuery = () => {
    const keyword = searchParams.get("keyword");

    if (keyword) {
      setSearch(keyword);
    }

    if (!localSelectStoreNames) {
      searchParams.delete("stores");
    } else {
      const storeNames = _.map(searchParams.getAll("stores"), (item) =>
        parseInt(item)
      );
      if (storeNames) {
        let storeNamesFromStorage = _.filter(localSelectStoreNames, (data) => {
          return storeNames.includes(data.id);
        });
        setSelectedStoreNames(storeNamesFromStorage);
      } else {
        localStorage.removeItem("stock_opname.store_names");
      }
    }

    if (!localSelectStatus) {
      searchParams.delete("status");
    } else {
      const status = _.map(searchParams.getAll("status"), (item) => item);

      if (status) {
        let filtered = _.filter(localSelectStatus, (item) => {
          return status.includes(item.name);
        });
        setSelectedStatus(filtered);
      } else {
        localStorage.removeItem("stock-opname.status");
      }
    }
  };

  useMemo(() => {
    getFilterDataFromQuery();
    // eslint-disable-next-line
  }, []);

  const setFilterDataToQuery = () => {
    let params = {};
    let reset = false;

    if (debouncedSearch) {
      Object.assign(params, {
        keyword: debouncedSearch,
      });

      if (debouncedSearch !== searchParams.get("keyword")) {
        reset = true;
      }
    }

    if (tablePage.get() !== 1 && !reset) {
      Object.assign(params, {
        page: tablePage.get(),
      });
    }

    if (tablePerPage.get() !== 10 && !reset) {
      Object.assign(params, {
        perPage: tablePerPage.get(),
      });
    }

    if (tableSort.sortField.get() && !reset) {
      Object.assign(params, {
        sortField: tableSort.sortField.get(),
      });
    }

    if (tableSort.sortOrder.get() && !reset) {
      Object.assign(params, {
        sortOrder: tableSort.sortOrder.get(),
      });
    }

    if (selectedStoreNames) {
      const multiValue = _.map(selectedStoreNames, "id");

      Object.assign(params, {
        store: multiValue,
      });
    }

    if (selectedStatus) {
      const multiValue = _.map(selectedStatus, "id");

      Object.assign(params, {
        status: multiValue,
      });
    }

    const queryParams = qs.stringify(params, { indices: false });

    if (queryParams) {
      setSearchParams(`?${queryParams}`);
    } else {
      navigate("");
    }

    getTableData(reset);
  };

  const handleReset = () => {
    setSelectedStatus([]);
    setSelectedStoreNames([]);
  };

  const columns: any = [
    {
      title: "Kode Stock Opname",
      dataIndex: "stock_opname_code",
      key: "stock_opname_code",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "stock_opname_code" &&
        tableSort.sortOrder.get(),
    },
    {
      title: "Kode Toko",
      dataIndex: "store_code",
      key: "store_code",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "store_code" && tableSort.sortOrder.get(),
    },
    {
      title: "Nama Toko",
      dataIndex: "store_name",
      key: "store_name",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "store_name" && tableSort.sortOrder.get(),
    },
    {
      title: "Tanggal",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "created_at" && tableSort.sortOrder.get(),
      render: (text: any) => {
        return moment(text).format("D MMM YYYY | HH:mm");
      },
    },
    {
      title: "Revisi",
      dataIndex: "latest_revision_number",
      key: "latest_revision_number",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "latest_revision_number" &&
        tableSort.sortOrder.get(),
      render: (text: any) => {
        return <>Revisi {text}</>;
      },
    },
    {
      title: "Status",
      dataIndex: "latest_revision_status",
      key: "latest_revision_status",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "latest_revision_status" &&
        tableSort.sortOrder.get(),
      render: (text: any) => {
        return <Tag color={STOCK_OPNAME_STATUS_COLOR[text]}>{text}</Tag>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => {
        const defaultLink = fullPath + "/" + record.id + "/detail";
        const editLink = fullPath + "/" + record.id + "/edit";
        const usedLink = record.can_update ? editLink : defaultLink;

        return (
          <Space size="middle">
            <Link to={usedLink}>Lihat Detail</Link>
          </Space>
        );
      },
    },
  ];

  const renderTabContent = () => {
    return (
      <>
        <Row gutter={[10, 10]} className="mb16">
          <Col className="gutter-row" span={6} xs={24} md={12} lg={10}>
            <Input
              allowClear
              placeholder={t("common.filter.search.placeholder")}
              prefix={<SearchOutlined />}
              value={search}
              onChange={(value) => {
                setSearch(value.target.value);
              }}
            />
          </Col>
        </Row>

        <Collapse
          className="mb16"
          defaultActiveKey={["1"]}
          expandIconPosition={"right"}
        >
          <Panel header="Filter" key="1">
            <Row className="mb6" gutter={[10, 10]}>
              <Col className="gutter-row" xs={24} md={24} lg={10}>
                <WuiSelectMulti
                  value={selectedStoreNames}
                  onChange={(val: any) => {
                    tablePage.set(1);
                    setSelectedStoreNames(val);
                    localStorage.setItem(
                      "stock_opname.store_names",
                      JSON.stringify(val)
                    );
                  }}
                  repository={StoreRepository}
                  selectParams={{
                    channel: "b2c",
                    assistant:
                      system?.account?.level === SALES_ADMIN ||
                      system?.account?.level === SALES_SUPERIOR
                        ? null
                        : system?.account.id,
                  }}
                  placeholder={t("select.placeholder", { item: "Nama Store" })}
                />
              </Col>

              <Col className="gutter-row" xs={24} md={24} lg={8}>
                <WuiSelectMulti
                  value={selectedStatus}
                  onChange={(val: any) => {
                    setSelectedStatus(val);
                    localStorage.setItem(
                      "stock-opname.status",
                      JSON.stringify(val)
                    );
                  }}
                  placeholder={t("select.placeholder", { item: "Status" })}
                  repository={StockOpnameStatusRepository}
                />
              </Col>

              <Col>
                <Button type="link" onClick={handleReset}>
                  Reset
                </Button>
              </Col>
            </Row>
          </Panel>
        </Collapse>

        <Table
          rowKey={"id"}
          bordered
          columns={columns}
          dataSource={tableData.get()}
          loading={tableLoading}
          onChange={handleTableChange}
          pagination={{
            current: tablePage.get(),
            simple: true,
            pageSize: tablePerPage.get(),
            total: totalData.get(),
          }}
        />
      </>
    );
  };

  return (
    <>
      <WuiContainer>
        <WuiSectionTitle title={"Stock Opname"} breadcrumbs={breadcrumbs}>
          <Accessible access="write stock opname b2c">
            <Button
              onClick={() => navigate(fullPath + "/add")}
              type="primary"
              icon={<PlusOutlined />}
            >
              {t("common.button.addNew")}
            </Button>
          </Accessible>
        </WuiSectionTitle>

        {renderTabContent()}
      </WuiContainer>
    </>
  );
};

export default AppStockOpnameList;
