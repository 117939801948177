import React, {useEffect} from "react";
import {Link, useSearchParams} from "react-router-dom";
import moment from "moment";
import Col from "antd/es/grid/col";
import WuiFilterDateRange from "../../../../../../@framework/wui/components/Filter/DateRange";
import Button from "antd/es/button";
import Row from "antd/es/grid/row";
import Card from "antd/es/card";
import {Tabs} from "antd";
import {useState} from "@hookstate/core";
import TabPane from "antd/es/tabs/TabPane";
import Input from "antd/es/input";
import {SearchOutlined} from "@ant-design/icons";
import useDebounce from "../../../../../../@framework/utilities/hooks/useDebounce";
import {useTranslation} from "react-i18next";
import Collapse from "antd/es/collapse";
import WuiSelectMulti from "../../../../../../@framework/wui/components/Select/Multi";
import _ from "lodash";
import {AxiosError, AxiosResponse} from "axios";
import Tag from "antd/es/tag";
import {
    DASHBOARD_B2C_O2O_STATUS_COLOR,
    DASHBOARD_B2C_O2O_STATUS_OPTIONS,
} from "../../../../../../constant";
import Space from "antd/es/space";
import {NumericFormat} from "react-number-format";
import Table from "antd/es/table";
import DashboardRepository from "../../../../../../repositories/DashboardRepository";
import StoreRepository from "../../../../../../repositories/StoreRepository";

var qs = require('qs');
const { Panel } = Collapse;

interface Props {
    active: boolean
    setParams?: Function
}

const statusOptions = DASHBOARD_B2C_O2O_STATUS_OPTIONS
const tabTypes = ["Click n Pick Up", "EraXpress"];

const DashboardB2CO2O: React.FC<Props> = (
    {
        active,
        setParams
    }
) => {
    const {t} = useTranslation()
    const [searchParams, setSearchParams] = useSearchParams();
    const tabKey = useState(searchParams.get('tabType') || tabTypes[0])
    let fullPath = window.location.pathname;

    // Table
    const tablePage = useState<number>(parseInt(searchParams.get('page') || '1'))
    const tablePerPage = useState<number>(parseInt(searchParams.get('perPage') || '10'))
    const [tableLoading, setTableLoading] = React.useState<boolean>(false)
    const tableSort = useState<{
        sortField: any,
        sortOrder: any,
    }>({
        sortField: searchParams.get('sortField'),
        sortOrder: searchParams.get('sortOrder')
    })
    const tableData = useState<any[]>([]);
    const totalData = useState<number>(0)

    const getTableData = async (reset: boolean = false) => {
        setTableLoading(true)

        if (reset) tablePage.set(1);

        let params = {
            page: tablePage.get(),
            search: debouncedSearch,
            order_by: tableSort.sortField.get(),
            sorted_by: tableSort.sortOrder.get(),
            per_page: tablePerPage.get(),
            start_date: filterDate[0].format('YYYY-MM-DD'),
            end_date: filterDate[1].format('YYYY-MM-DD'),
            courier: tabKey.get()
        }

        if(!params.order_by){
            params.order_by = 'created_at';
            params.sorted_by = 'descend';
        }

        const status = _.map(selectedStatus, "name");

        if (status) {
            Object.assign(params, {
                status: status,
            });
        }

        if (selectedStores) {
            const multiValue = _.map(selectedStores, "id");

            Object.assign(params, {
                store: multiValue,
            });
        }

        await DashboardRepository.allReportCourier(params).then((res: AxiosResponse) => {
            if (res.data.data) {
                tableData.set(res.data.data)
            }

            totalData.set(res.data?.total || 0)

            setTableLoading(false)
        }).catch((e: AxiosError) => {
            setTableLoading(false)
        })
    }

    const columns: any = [
        {
            title: "Courier",
            dataIndex: "courier",
            key: "courier",
            sorter: true,
            defaultSortOrder: tableSort.sortField.get() === "courier" && tableSort.sortOrder.get(),
        },
        {
            title: "Nama Toko",
            dataIndex: "store_name",
            key: "store_name",
            sorter: true,
            defaultSortOrder: tableSort.sortField.get() === "store_name" && tableSort.sortOrder.get(),
        },
        {
            title: "No Transaksi",
            dataIndex: "no",
            key: "no",
            sorter: true,
            defaultSortOrder: tableSort.sortField.get() === "no" && tableSort.sortOrder.get(),
        },
        {
            title: "Tanggal",
            dataIndex: "date",
            key: "date",
            sorter: true,
            defaultSortOrder: tableSort.sortField.get() === "date" && tableSort.sortOrder.get(),
            render: (text: any) => {
                return moment(text).format("D MMM YYYY | HH:mm");
            },
        },
        {
            title: "Total Transaksi",
            dataIndex: "total_amount",
            key: "total_amount",
            sorter: true,
            defaultSortOrder: tableSort.sortField.get() === "total_amount" && tableSort.sortOrder.get(),
            render: (text: any) => {
                return (
                    <NumericFormat
                        prefix="Rp. "
                        value={text}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        displayType="text"
                    />
                );
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            sorter: true,
            defaultSortOrder: tableSort.sortField.get() === "status" && tableSort.sortOrder.get(),
            render: (text: any) => {
                return (
                    <Tag color={DASHBOARD_B2C_O2O_STATUS_COLOR[text]}>{text}</Tag>
                )
            }
        },
        {
            title: "Action",
            key: "action",
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Link to={fullPath + "/O2O/" + record.id}>Lihat Detail</Link>
                </Space>
            ),
        },
    ]

    const handleTableChange = (
        pagination: any,
        filters: any,
        sorter: any,
        extra: any
    ) => {
        tablePage.set(pagination.current);
        tablePerPage.set(pagination.pageSize);

        if (sorter.order) {
            tableSort.sortField.set(sorter.field);
            tableSort.sortOrder.set(sorter.order);
        } else {
            tableSort.sortField.set(null);
            tableSort.sortOrder.set(null);
        }

        setFilterDataToQuery();
    };


    // For Search Input
    const [search, setSearch] = React.useState<string>("");
    const debouncedSearch = useDebounce<string>(search, 1000);

    // For DateRange Picker
    const [filterDate, setFilterDate] = React.useState<any>([
        (searchParams.get('start_date') ? moment((searchParams.get('start_date')), 'YYYY-MM-DD') : moment().startOf('month')),
        (searchParams.get('end_date') ? moment((searchParams.get('end_date')), 'YYYY-MM-DD') : moment().endOf('month'))
    ])

    const localSelectedStatus = JSON.parse(
        localStorage.getItem("dashboard.b2c.o2o.status") || "[]"
    );
    const [selectedStatus, setSelectedStatus] = React.useState<any[]>([]);

    const localSelectedStores = JSON.parse(
        localStorage.getItem("dashboard.b2c.o2o.stores") || "[]"
    );
    const [selectedStores, setSelectedStores] = React.useState<any[]>([]);

    const handleDateRangeCallback = async (dates: any, dateString?: any) => {
        await setFilterDate(dates)
    }

    const setFilterDataToQuery = () => {
        let params = {
            tab: 'O2O',
            tabType: tabKey.get()
        }

        let reset = false;

        if (debouncedSearch) {
            Object.assign(params, {
                keyword: debouncedSearch,
            });

            if (debouncedSearch !== searchParams.get("keyword")) {
                reset = true;
            }
        }

        if (filterDate) {
            Object.assign(params, {
                start_date: filterDate[0].format('YYYY-MM-DD'),
                end_date: filterDate[1].format('YYYY-MM-DD')
            })
        }

        if (selectedStatus) {
            const multiValue = _.map(selectedStatus, "name");

            Object.assign(params, {
                status: multiValue,
            });
        }

        if (selectedStores) {
            const multiValue = _.map(selectedStores, "id");

            Object.assign(params, {
                stores: multiValue,
            });
        }

        const queryParams = qs.stringify(params, { indices: false });

        if (queryParams) {
            setSearchParams(`?${queryParams}`)
        }

        getTableData(reset)
    }

    const getFilterDataFromQuery = () => {
        const keyword = searchParams.get("keyword");

        if (keyword) {
            setSearch(keyword);
        }

        const startDate = searchParams.get("start_date");
        const endDate = searchParams.get("end_date");

        setFilterDate([
            startDate ? moment(startDate) : moment().startOf('month'),
            endDate ? moment(endDate) : moment().endOf('month')
        ])

        if (!localSelectedStatus) {
            searchParams.delete("status");
        } else {
            const items = _.map(searchParams.getAll("status"), (item) =>
                item
            );
            if (items) {
                let itemFromStorage = _.filter(localSelectedStatus, (data) => {
                    return items.includes(data.name);
                });
                setSelectedStatus(itemFromStorage);
            } else {
                localStorage.removeItem("dashboard.b2c.o2o.status");
            }
        }

        if (!localSelectedStores) {
            searchParams.delete("stores");
        } else {
            const stores = _.map(searchParams.getAll("stores"), (item) =>
                parseInt(item)
            );
            if (stores) {
                let storeFromStorage = _.filter(localSelectedStores, (data) => {
                    return stores.includes(data.id);
                });
                setSelectedStores(storeFromStorage);
            } else {
                localStorage.removeItem("dashboard.b2c.o2o.stores");
            }
        }
    }

    const handleReset = () => {
        setFilterDate([moment().startOf('month'), moment().endOf('month')])
        setSelectedStatus([])
        setSelectedStores([])
    }

    useEffect(() => {
        setFilterDataToQuery()
    }, [debouncedSearch, filterDate, selectedStatus, selectedStores]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        handleReset()
    }, [tabKey.get()]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (active) {
            getFilterDataFromQuery()
        }
        // eslint-disable-next-line
    }, [active])


    return (
        <>
            <Card>
                <div className={'mb16'}>List Transaksi</div>

                <Tabs
                    defaultActiveKey={tabKey.get()}
                    onChange={(key) => {
                    tabKey.set(key)
                }}>
                    {tabTypes.map((tab) => {
                        return (
                            <TabPane tab={tab} key={tab}></TabPane>
                        );
                    })}
                </Tabs>

                <Row gutter={[10, 10]} className="mb16">
                    <Col className="gutter-row" span={6} xs={24} md={12} lg={10}>
                        <Input
                            allowClear
                            placeholder={t("common.filter.search.placeholder")}
                            prefix={<SearchOutlined />}
                            value={search}
                            onChange={(value) => {
                                setSearch(value.target.value);
                            }}
                        />
                    </Col>
                </Row>

                <Collapse
                    className="mb16"
                    defaultActiveKey={["1"]}
                    expandIconPosition={"right"}
                >
                    <Panel header="Filter" key="1">
                        <Row className="mb6" gutter={[10, 10]}>
                            <Col className="gutter-row" xs={24} md={12} lg={8}>
                                <WuiFilterDateRange defaultValue={filterDate} callback={handleDateRangeCallback} />
                            </Col>

                            <Col className="gutter-row" xs={24} md={12} lg={6}>
                                <WuiSelectMulti
                                    value={selectedStatus}
                                    onChange={(val: any) => {
                                        tablePage.set(1);
                                        setSelectedStatus(val);
                                        localStorage.setItem('dashboard.b2c.o2o.status', JSON.stringify(val))
                                    }}
                                    options={statusOptions}
                                    placeholder={'Semua Status'}
                                />
                            </Col>

                            <Col className="gutter-row" xs={24} md={24} lg={12}>
                                <WuiSelectMulti
                                    value={selectedStores}
                                    onChange={(val: any) => {
                                        tablePage.set(1);
                                        setSelectedStores(val);
                                        localStorage.setItem(
                                            "dashboard.b2c.o2o.stores",
                                            JSON.stringify(val)
                                        );
                                    }}
                                    repository={StoreRepository}
                                    selectParams={{
                                        channel: 'b2c'
                                    }}
                                    placeholder={'Semua Store'}
                                />
                            </Col>

                            <Col>
                                <Button type="link" onClick={handleReset}>Reset</Button>
                            </Col>
                        </Row>
                    </Panel>
                </Collapse>

                <Table
                    rowKey={"id"}
                    bordered
                    columns={columns}
                    dataSource={tableData.get()}
                    loading={tableLoading}
                    onChange={handleTableChange}
                    pagination={{
                        current: tablePage.get(),
                        simple: true,
                        pageSize: tablePerPage.get(),
                        total: totalData.get(),
                    }}
                />
            </Card>
        </>
    )
}

export default DashboardB2CO2O
