import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useState } from "@hookstate/core";
import Button from "antd/es/button";
import Collapse from "antd/es/collapse";
import Col from "antd/es/grid/col";
import Row from "antd/es/grid/row";
import Input from "antd/es/input";
import Space from "antd/es/space";
import Table from "antd/es/table";
import Tag from "antd/es/tag";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useIsMounted } from "../../../../../@framework/utilities/hooks";
import useDebounce from "../../../../../@framework/utilities/hooks/useDebounce";
import Accessible from "../../../../../@framework/wui/components/Accessible";
import WuiContainer from "../../../../../@framework/wui/components/Container";
import WuiSectionTitle from "../../../../../@framework/wui/components/Sections/Title";
import WuiSelectMulti from "../../../../../@framework/wui/components/Select/Multi";
import WuiSelectSingle from "../../../../../@framework/wui/components/Select/Single";
import {
    CHANNEL_TYPE_SELECT_OPTIONS, KPI_MASTER_STATUS_COLOR,
    KPI_MASTER_STATUS_SELECT_OPTIONS,
} from "../../../../../constant";
import SuperiorRepository from "../../../../../repositories/SuperiorRepository";
import KPIMasterRepository from "../../../../../repositories/KPIMasterRepository";
import {AxiosError, AxiosResponse} from "axios";
import {permissions} from "../../../../../constant/permissions";
import {handleBackendError, haveAccess, openNotification} from "../../../../../functions/global";
import Text from "antd/es/typography/Text";
import WuiModalConfirmation from "../../../../../@framework/wui/components/Modal/Confirmation";
import useAccess from "../../../../../@framework/utilities/hooks/useAccess";

const channelOptions = CHANNEL_TYPE_SELECT_OPTIONS
const statusOptions = KPI_MASTER_STATUS_SELECT_OPTIONS

const { Panel } = Collapse;

var qs = require('qs');

const breadcrumbs = [
    {
        label: 'KPI',
        link: '/kpi'
    },
    {
        label: 'Master KPI',
        link: '/kpi/master-kpi'
    },
]

const permissionMapping: any = {
    b2b: {
      read: permissions.read_master_kpi_b2b,
    },
    b2c: {
      read: permissions.read_master_kpi_b2c,
    },
  };

const AppKPIMasterList: React.FC<any> = () => {
    const {t} = useTranslation()
    const acl = useAccess()
    const isMounted = useIsMounted()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    let fullPath = window.location.pathname;

    // Table
    const tablePage = useState<number>(parseInt(searchParams.get('page') || '1'))
    const tablePerPage = useState<number>(parseInt(searchParams.get('perPage') || '10'))
    const [tableLoading, setTableLoading] = React.useState<boolean>(false)
    const tableSort = useState<{
        sortField: any,
        sortOrder: any,
    }>({
        sortField: searchParams.get('sortField'),
        sortOrder: searchParams.get('sortOrder')
    })
    const tableData = useState<any[]>([]);
    const totalData = useState<number>(0)

    const getTableData = async (reset: boolean = false) => {
        setTableLoading(true)

        if (reset) tablePage.set(1);

        let params = {
            page: tablePage.get(),
            search: debouncedSearch,
            order_by: tableSort.sortField.get(),
            sorted_by: tableSort.sortOrder.get(),
            per_page: tablePerPage.get(),

        }

        if(!params.order_by){
            params.order_by = 'created_at';
            params.sorted_by = 'descend';
        }

        const channels = _.map(selectedChannels, "name");

        if (channels) {
            Object.assign(params, {
                channel: channels,
            });
        }

        if (filterSuperior.get()) {
            Object.assign(params, {
                superior: [filterSuperior.get()]
            });
        }

        const status = _.map(selectedStatus, "name");

        if (status) {
            Object.assign(params, {
                status: status,
            });
        }

        await KPIMasterRepository.all(params).then((res: AxiosResponse) => {
            if (res.data.data) {
                tableData.set(res.data.data)
            }

            totalData.set(res.data?.total || 0)

            setTableLoading(false)
        }).catch((e: AxiosError) => {

            setTableLoading(false)
        })
    }

    const columns: any = [
        {
            title: "Kode",
            dataIndex: "code",
            key: "code",
            sorter: true,
            defaultSortOrder:
                tableSort.sortField.get() === "code" && tableSort.sortOrder.get(),
        },
        {
            title: "Nama",
            dataIndex: "name",
            key: "name",
            sorter: true,
            defaultSortOrder:
                tableSort.sortField.get() === "name" && tableSort.sortOrder.get(),
        },
        {
            title: "Peran",
            dataIndex: "superior_name",
            key: "superior_name",
            sorter: true,
            defaultSortOrder:
                tableSort.sortField.get() === "superior_name" && tableSort.sortOrder.get(),
        },
        {
            title: "Channel",
            dataIndex: "channel",
            key: "channel",
            sorter: true,
            defaultSortOrder:
                tableSort.sortField.get() === "channel" && tableSort.sortOrder.get(),
        },
        {
            title: "Tanggal Dibuat",
            dataIndex: "created_at",
            key: "created_at",
            sorter: true,
            defaultSortOrder:
                tableSort.sortField.get() === "created_at" && tableSort.sortOrder.get(),
            render: (text: any) => {
                return moment(text).format("D MMM YYYY | HH:mm");
            },
        },
        {
            title: "Period",
            dataIndex: "start_date",
            key: "start_date",
            sorter: true,
            defaultSortOrder:
            tableSort.sortField.get() === "start_date" && tableSort.sortOrder.get(),
            render: (text: any, item: any) => {
                return moment(item.start_date).format("D MMM YYYY")+' - '+moment(item.end_date).format("D MMM YYYY");
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            sorter: true,
            defaultSortOrder: tableSort.sortField.get() === "status" && tableSort.sortOrder.get(),
            render: (text: any) => {
                return (
                    <Tag color={KPI_MASTER_STATUS_COLOR[text]}>{text}</Tag>
                )
            }
        },
        {
            title: "Action",
            key: "action",
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Link to={fullPath + "/" + record.id}>Lihat Detail</Link>
                    <Accessible access={[permissions.delete_master_kpi_b2b, permissions.delete_master_kpi_b2c]}>
                        <Text
                            className="cursor-pointer"
                            type={"danger"}
                            onClick={() => setDeleteModal(record.id)}
                        >
                            Hapus
                        </Text>
                    </Accessible>
                </Space>
            ),
        },
    ]

    const handleTableChange = (
        pagination: any,
        filters: any,
        sorter: any,
        extra: any
    ) => {
        tablePage.set(pagination.current);
        tablePerPage.set(pagination.pageSize);

        if (sorter.order) {
            tableSort.sortField.set(sorter.field);
            tableSort.sortOrder.set(sorter.order);
        } else {
            tableSort.sortField.set(null);
            tableSort.sortOrder.set(null);
        }

        setFilterDataToQuery();
    };

    // Modal
    const showDeleteModal = useState(false);
    const selectedDeleteId = useState(0);

    const setDeleteModal = (id: any) => {
        selectedDeleteId.set(id);
        showDeleteModal.set(true);
    };

    const deleteModalCallback = async () => {
        if (selectedDeleteId.get()) {
            await KPIMasterRepository.delete(selectedDeleteId.get())
            .then((res: AxiosResponse) => {
                showDeleteModal.set(false);
                selectedDeleteId.set(0);
                openNotification(
                    "success",
                    t("notification.success.deleteItem", { item: "Kpi Master" })
                );
                getTableData(true);
            })
            .catch((e: AxiosError) => {
                showDeleteModal.set(false);
                selectedDeleteId.set(0);
                handleBackendError(e, t("notification.error.default"));
            });
        }
    };

    // For Search Input
    const [search, setSearch] = React.useState<string>('')
    const debouncedSearch = useDebounce<string>(search, 1000)

    // For Filter
    const localSelectedChannels = JSON.parse(
        localStorage.getItem("kpi.master.channels") || "[]"
    );
    const [selectedChannels, setSelectedChannels] = React.useState<any[]>([]);

    const filterSuperior = useState(
        searchParams.get("superior") ? parseInt(searchParams.get("superior")!) : ""
    );

    const localSelectedStatus = JSON.parse(
        localStorage.getItem("kpi.master.status") || "[]"
    );
    const [selectedStatus, setSelectedStatus] = React.useState<any[]>([]);

    useEffect(() => {
        if (!isMounted) {
            setFilterDataToQuery()
        }
        // eslint-disable-next-line
    }, [debouncedSearch, filterSuperior.get(), selectedChannels, selectedStatus])

    useEffect(() => {
        setFilterDataToQuery()
        // eslint-disable-next-line
    }, [filterSuperior.get(), selectedChannels, selectedStatus])

    const setFilterDataToQuery = () => {
        let params = {}
        let reset = false;

        if (debouncedSearch) {
            Object.assign(params, {
                keyword: debouncedSearch
            })

            if(debouncedSearch !== searchParams.get('keyword')){
                reset = true
            }
        }

        if (tablePage.get() !== 1 && !reset) {
            Object.assign(params, {
                page: tablePage.get(),
            });
        }

        if (tablePerPage.get() !== 10 && !reset) {
            Object.assign(params, {
                perPage: tablePerPage.get(),
            });
        }

        if (tableSort.sortField.get() && !reset) {
            Object.assign(params, {
                sortField: tableSort.sortField.get(),
            });
        }

        if (tableSort.sortOrder.get() && !reset) {
            Object.assign(params, {
                sortOrder: tableSort.sortOrder.get(),
            });
        }

        if (selectedChannels) {
            const multiValue = _.map(selectedChannels, "name");

            Object.assign(params, {
                channels: multiValue,
            });
        }

        if (filterSuperior.get()) {
            Object.assign(params, {
                superior: filterSuperior.get(),
            });
        }

        if (selectedStatus) {
            const multiValue = _.map(selectedStatus, "name");

            Object.assign(params, {
                status: multiValue,
            });
        }

        const queryParams = qs.stringify(params , { indices: false });

        if (queryParams) {
            setSearchParams(`?${queryParams}`)
        } else {
            navigate('')
        }

        getTableData(reset)
    }

    const getFilterDataFromQuery = () => {
        const keyword = searchParams.get("keyword");

        if (keyword) {
            setSearch(keyword);
        }

        if (!localSelectedChannels) {
            searchParams.delete("channels");
        } else {
            const items = _.map(searchParams.getAll("channels"), (item) =>
                item
            );
            if (items) {
                let itemFromStorage = _.filter(localSelectedChannels, (data) => {
                    return items.includes(data.name);
                });
                setSelectedChannels(itemFromStorage);
            } else {
                localStorage.removeItem("kpi.master.channels");
            }
        }

        if (!localSelectedStatus) {
            searchParams.delete("status");
        } else {
            const items = _.map(searchParams.getAll("status"), (item) =>
                item
            );
            if (items) {
                let itemFromStorage = _.filter(localSelectedStatus, (data) => {
                    return items.includes(data.name);
                });
                setSelectedStatus(itemFromStorage);
            } else {
                localStorage.removeItem("kpi.master.status");
            }
        }
    };

    const handleReset = () => {
        filterSuperior.set('')
        setSelectedStatus([])
        setSelectedChannels([])
    }

    useMemo(() => {
        getFilterDataFromQuery();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <WuiContainer>
                <WuiSectionTitle title={'Master KPI'} breadcrumbs={breadcrumbs}>
                    <Accessible access={[permissions.write_master_kpi_b2b, permissions.write_master_kpi_b2c]}>
                        <Button onClick={() => navigate(fullPath+'/form')} type="primary" icon={<PlusOutlined />}>{t('common.button.addNew')}</Button>
                    </Accessible>
                </WuiSectionTitle>

                <Row gutter={[10, 10]} className="mb16">
                    <Col className="gutter-row" span={6} xs={24} md={12} lg={10}>
                        <Input
                            allowClear
                            placeholder={t('common.filter.search.placeholder')}
                            prefix={<SearchOutlined />}
                            value={search}
                            onChange={(value) => {
                                setSearch(value.target.value)
                            }}
                        />
                    </Col>
                </Row>

                <Collapse
                    className="mb16"
                    defaultActiveKey={['1']}
                    expandIconPosition={"right"}
                >
                    <Panel header="Filter" key="1">
                        <Row className="mb6" gutter={[10, 10]}>
                            <Col className="gutter-row" xs={24} md={12} lg={6}>
                                <WuiSelectMulti
                                    value={selectedChannels}
                                    onChange={(val: any) => {
                                        tablePage.set(1);
                                        setSelectedChannels(val);
                                        localStorage.setItem('kpi.master.channels', JSON.stringify(val))

                                        filterSuperior.set('')
                                    }}
                                    options={channelOptions.filter(item => haveAccess(acl, permissionMapping[item.name.toLowerCase()]?.read || ''))}
                                    placeholder={'Semua Channel'}
                                />
                            </Col>

                            <Col className="gutter-row" xs={24} md={12} lg={6}>
                                <WuiSelectSingle
                                    labelInValue={false}
                                    value={filterSuperior.get()}
                                    onChange={(value: any) => {
                                        filterSuperior.set(value);
                                    }}
                                    selectParams={{
                                        strict_channel: _.map(selectedChannels, "name")
                                    }}
                                    style={{ width: "100%" }}
                                    defaultOptions={[{ id: "", name: "Semua Peran" }]}
                                    placeholder={'Semua Peran'}
                                    repository={SuperiorRepository}
                                    allowClear={true}
                                ></WuiSelectSingle>
                            </Col>

                            <Col className="gutter-row" xs={24} md={12} lg={6}>
                                <WuiSelectMulti
                                    value={selectedStatus}
                                    onChange={(val: any) => {
                                        tablePage.set(1);
                                        setSelectedStatus(val);
                                        localStorage.setItem('kpi.master.status', JSON.stringify(val))
                                    }}
                                    options={statusOptions}
                                    placeholder={'Semua Status'}
                                />
                            </Col>

                            <Col>
                                <Button type="link" onClick={handleReset}>Reset</Button>
                            </Col>
                        </Row>
                    </Panel>
                </Collapse>

                <Table
                    rowKey={"id"}
                    bordered
                    columns={columns}
                    dataSource={tableData.get()}
                    loading={tableLoading}
                    onChange={handleTableChange}
                    pagination={{
                        current: tablePage.get(),
                        simple: true,
                        pageSize: tablePerPage.get(),
                        total: totalData.get(),
                    }}
                />
            </WuiContainer>

            <WuiModalConfirmation
                show={showDeleteModal.get()}
                onOk={deleteModalCallback}
                onCancel={() => showDeleteModal.set(false)}
            />
        </>
    )
}

export default AppKPIMasterList