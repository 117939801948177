import React from 'react';
import Tabs from "antd/lib/tabs";
import { useNavigate } from 'react-router-dom';
import { haveAccess } from '../../../functions/global';
import useAccess from '../../../@framework/utilities/hooks/useAccess';
import { permissions } from '../../../constant/permissions';

const { TabPane } = Tabs;

const PromotorTab = ({ activeTab = '1' }) => {
    const navigate = useNavigate()
    const acl = useAccess()

    return <>
        <Tabs defaultActiveKey={activeTab} onChange={(key) => {
            if (activeTab === '1') {
                navigate('/promotor/uniform-request')
            } else {
                navigate('/promotor/handover')
            }
        }}>
            {
                haveAccess(acl, permissions.read_handover_stuff_b2b) && 
                <TabPane tab="Serah Terima Barang" key="1"></TabPane>
            }
            {
                haveAccess(acl, permissions.read_uniform_request_b2b) && 
                <TabPane tab="Permintaan Seragam" key="2"></TabPane>
            }
        </Tabs>
    </>

}

export default PromotorTab;