import { useState } from "@hookstate/core";
import { Col, Row } from "antd";
import Button from "antd/es/button";
import Card from "antd/es/card";
import PageHeader from "antd/es/page-header";
import Space from "antd/es/space";
import Text from "antd/es/typography/Text";
import Modal from "antd/lib/modal/Modal";
import { AxiosError, AxiosResponse } from "axios";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import WuiContainer from "../../../../../../@framework/wui/components/Container";
import WuiFormTitle from "../../../../../../@framework/wui/components/Form/Title";
import { TChannels } from "../../../../../../utils";
import { TablePendingIssue } from "../Component";
import AssistantStoreLeaderRepository from "../../../../../../repositories/AssistantStoreLeaderRepository";
import StoreRepository from "../../../../../../repositories/StoreRepository";

const AppSettingsAccountAssistantStoreLeaderStoreForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id, storeId } = useParams();
  const navigate = useNavigate();
  const pageTitle = useState("");
  const channel = useState<TChannels>('b2c')

  // Form
  const disable = useState(false);

  const [sales, setSales] = React.useState<any>({});
  const [store, setStore] = React.useState<any>({});

  const showProductModal = useState(false);
  const tempActiveProduct: any = useState({});

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    disable.set(true);
    let salesName = '';
    let storeName = '';

    await AssistantStoreLeaderRepository.show(id, channel.get(), { with: ["brands"] })
      .then((res: AxiosResponse) => {
        const data = res.data?.data || {};

        setSales({
          ...data,
        });

        salesName = data?.name
      })
      .catch((e: AxiosError) => {});

    await StoreRepository.show(channel.get(), storeId, { with: ['businessUnit']})
      .then((res: AxiosResponse) => {
        const data = res.data?.data || {};
        setStore({
          ...data,
          business_unit_name: data?.business_unit?.name
        });

        storeName = data.name
      })
      .catch((e: AxiosError) => {});

    pageTitle.set(`${salesName} (${storeName})`)
    disable.set(false);
  };

  return (
    <>
      <WuiContainer>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle.get()}
        />

        <Row gutter={[16, 16]}>
          <Col
            className="gutter-row"
            md={{
              span: 16,
              order: 1,
            }}
            xs={{
              span: 24,
              order: 2,
            }}
          >
            <Card title="Informasi Sales">
              <Space style={{ width: "100%" }} direction="vertical" size={25}>
                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Level"} />

                  <div className="input-section">
                    <Text strong>{sales.level || '-'}</Text>
                  </div>
                </div>

                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Kode"} />

                  <div className="input-section">
                    <Text strong>{sales.code}</Text>
                  </div>
                </div>

                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Nama"} />

                  <div className="input-section">
                    <Text strong>{sales.name}</Text>
                  </div>
                </div>

                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Email"} />

                  <div className="input-section">
                    <Text strong>{sales.email}</Text>
                  </div>
                </div>
              </Space>
            </Card>

            <br />

            <Card title="Informasi Store">
              <Space style={{ width: "100%" }} direction="vertical" size={25}>
                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Kode"} />

                  <div className="input-section">
                    <Text strong>{store.code}</Text>
                  </div>
                </div>

                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Nama"} />

                  <div className="input-section">
                    <Text strong>{store.name}</Text>
                  </div>
                </div>

                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Business Unit"} />

                  <div className="input-section">
                    <Text strong>{store.business_unit_name || '-'}</Text>
                  </div>
                </div>
              </Space>
            </Card>

            <br />
            <Card title="Pending Issue">
              <Space
                style={{
                  width: "100%",
                }}
                direction="vertical"
                size={25}
              >
                <TablePendingIssue
                    channel={channel.get()}
                    assistantId={parseInt(id)}
                    storeId={parseInt(storeId)}
                />
              </Space>
            </Card>
          </Col>
        </Row>

        <div className="wui-form-btn-group">
          <Button
            className="wui-btn"
            size={"large"}
            onClick={() => navigate(-1)}
          >
            {t("common.button.back")}
          </Button>
        </div>
      </WuiContainer>

      <Modal
        title={`IMEI ${tempActiveProduct.get().name}`}
        centered
        onCancel={() => {
          showProductModal.set(false);
        }}
        visible={showProductModal.get()}
        footer={[
          <Button
            key="back"
            onClick={() => {
              showProductModal.set(false);
            }}
          >
            {t("common.button.back")}
          </Button>,
        ]}
      >
        <Space direction="vertical" size={20}>
          {(tempActiveProduct.get().imei_list || []).map(
            (item: any, index: number) => (
              <div key={item}>
                <h4>Produk {index + 1}</h4>
                <Text>{item}</Text>
              </div>
            )
          )}
        </Space>
      </Modal>
    </>
  );
};

export default AppSettingsAccountAssistantStoreLeaderStoreForm;
