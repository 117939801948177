import { useTranslation } from "react-i18next";
import { useIsMounted } from "../../../../../../@framework/utilities/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import WuiContainer from "../../../../../../@framework/wui/components/Container";
import Button from "antd/es/button";
import {
  DownOutlined,
  SearchOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import WuiSectionTitle from "../../../../../../@framework/wui/components/Sections/Title";
import React, { useEffect, useMemo } from "react";
import Col from "antd/es/grid/col";
import Input from "antd/es/input";
import Row from "antd/es/grid/row";
import useDebounce from "../../../../../../@framework/utilities/hooks/useDebounce";
import { useState } from "@hookstate/core";
import Table from "antd/es/table";
import Space from "antd/es/space";
import { AxiosError, AxiosResponse } from "axios";
import Text from "antd/es/typography/Text";
import WuiModalConfirmation from "../../../../../../@framework/wui/components/Modal/Confirmation";
import {
  handleBackendError,
  openNotification,
} from "../../../../../../functions/global";
import {Collapse, Dropdown, Menu} from "antd";
import WuiModalImport from "../../../../../../@framework/wui/components/Modal/Import";
import {
  ASSISTANT_STORE_LEADER_IMPORT_MAPPING_STORE,
  ASSISTANT_STORE_LEADER_IMPORT_SALES_HIERARCHY
} from "../../../../../../constant/import";
import Accessible from "../../../../../../@framework/wui/components/Accessible";
import {permissions} from "../../../../../../constant/permissions";
import AssistantStoreLeaderRepository from "../../../../../../repositories/AssistantStoreLeaderRepository";
import _ from "lodash";
import WuiSelectSingle from "../../../../../../@framework/wui/components/Select/Single";
import {ASSISTANT_STORE_LEADER, SALES_CONSULTANT, SALES_PROMOTER, STORE_LEADER} from "../../../../../../constant";
import SuperiorRepository from "../../../../../../repositories/SuperiorRepository";

const { Panel } = Collapse;

var qs = require("qs");

const breadcrumbs = [
  {
    label: "Pengaturan",
  },
  {
    label: "Akun",
  },
  {
    label: "Assistant Store Leader",
    link: "/pengaturan/akun/assistant-store-leader",
  },
];

const AppSettingsAccountAssistantStoreLeaderList: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  let fullPath = window.location.pathname;
  const channel = useState('b2c');

  const tablePage = useState<number>(parseInt(searchParams.get("page") || "1"));
  const tablePerPage = useState<number>(
    parseInt(searchParams.get("perPage") || "10")
  );
  const [tableLoading, setTableLoading] = React.useState<boolean>(false);
  const tableSort = useState<{
    sortField: any;
    sortOrder: any;
  }>({
    sortField: searchParams.get("sortField"),
    sortOrder: searchParams.get("sortOrder"),
  });
  const tableData = useState([]);
  const totalData = useState<number>(0);

  const filterSuperior = useState(searchParams.get('superior') ? parseInt(searchParams.get('superior')!) : '');

  const getTableData = async (reset: boolean = false) => {
    setTableLoading(true);

    if (reset) tablePage.set(1);

    let params = {
      page: tablePage.get(),
      search: debouncedSearch,
      order_by: tableSort.sortField.get(),
      sorted_by: tableSort.sortOrder.get(),
      per_page: tablePerPage.get(),
    };

    if(filterSuperior.get()){
      Object.assign(params, {
        superior: filterSuperior.get()
      })
    }

    await AssistantStoreLeaderRepository.all(channel.get(), params)
      .then((res: AxiosResponse) => {
        if (res.data.data) {
          tableData.set(res.data.data);
        }

        totalData.set(res.data?.total || 0);

        setTableLoading(false);
      })
      .catch((e: AxiosError) => {
        setTableLoading(false);
      });
  };

  const handleTableChange = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {
    tablePage.set(pagination.current);
    tablePerPage.set(pagination.pageSize);

    if (sorter.order) {
      tableSort.sortField.set(sorter.field);
      tableSort.sortOrder.set(sorter.order);
    } else {
      tableSort.sortField.set(null);
      tableSort.sortOrder.set(null);
    }

    setFilterDataToQuery();
  };

  // For Search Input
  const [search, setSearch] = React.useState<string>("");
  const debouncedSearch = useDebounce<string>(search, 1000);

  useEffect(() => {
    if (!isMounted) {
      setFilterDataToQuery();
    }
    // eslint-disable-next-line
  }, [debouncedSearch, filterSuperior.get()]);

  const getFilterDataFromQuery = () => {
    const keyword = searchParams.get("keyword");

    if (keyword) {
      setSearch(keyword);
    }

    getTableData();
  };

  useMemo(() => {
    getFilterDataFromQuery();
    // eslint-disable-next-line
  }, []);

  const setFilterDataToQuery = () => {
    let params = {};
    let reset = false;

    if (debouncedSearch) {
      Object.assign(params, {
        keyword: debouncedSearch,
      });

      if (debouncedSearch !== searchParams.get("keyword")) {
        reset = true;
      }
    }

    if (tablePage.get() !== 1) {
      if (reset) {
        Object.assign(params, {
          page: 1,
        });
      } else {
        Object.assign(params, {
          page: tablePage.get(),
        });
      }
    }

    if (tablePerPage.get() !== 10) {
      Object.assign(params, {
        perPage: tablePerPage.get(),
      });
    }

    if (tableSort.sortField.get()) {
      Object.assign(params, {
        sortField: tableSort.sortField.get(),
      });
    }

    if (tableSort.sortOrder.get()) {
      Object.assign(params, {
        sortOrder: tableSort.sortOrder.get(),
      });
    }

    if (filterSuperior.get()) {
      Object.assign(params, {
        superior: filterSuperior.get()
      })
    }

    const queryParams = qs.stringify(params, { indices: false });

    if (queryParams) {
      setSearchParams(`?${queryParams}`);
    } else {
      navigate("");
    }

    getTableData(reset);
  };

  const columns: any = [
    {
      title: "Kode",
      dataIndex: "code",
      key: "code",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "code" && tableSort.sortOrder.get(),
    },
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "name" && tableSort.sortOrder.get(),
    },
    {
      title: "Peran",
      dataIndex: "superior_name",
      key: "superior_name",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "superior_name" &&
        tableSort.sortOrder.get(),
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          <Link to={fullPath + "/" + record.id}>Lihat Detail</Link>
          <Accessible access={[permissions.delete_assistant_store_leader_b2c]}>
            <Text
              className="cursor-pointer"
              type={"danger"}
              onClick={() => setDeleteModal(record.id)}
            >
              Hapus
            </Text>
          </Accessible>
        </Space>
      ),
    },
  ];

  // Modal
  const showDeleteModal = useState(false);
  const selectedDeleteId = useState(0);

  const setDeleteModal = (id: any) => {
    selectedDeleteId.set(id);
    showDeleteModal.set(true);
  };

  const deleteModalCallback = async () => {
    if (selectedDeleteId.get()) {
      await AssistantStoreLeaderRepository.delete(selectedDeleteId.get())
        .then((res: AxiosResponse) => {
          showDeleteModal.set(false);
          selectedDeleteId.set(0);
          openNotification(
            "success",
            t("notification.success.deleteItem", { item: "Assistant Store Leader" })
          );
          getTableData(true);
        })
        .catch((e: AxiosError) => {
          showDeleteModal.set(false);
          selectedDeleteId.set(0);
          handleBackendError(e, t("notification.error.default"));
        });
    }
  };

  const handleReset = () => {
    filterSuperior.set('')
    setSearch('')
  }

  const importType = useState('');
  const showImportModal = useState(false);

  const handleShowImportModal = (type: string) => {
    importType.set(type)
    showImportModal.set(true);
  }

  const importModalCallback = async (files: File[]) => {
    const formData = new FormData();

    if (files) {
      files.forEach((file: any) => {
        formData.append("file_import", file);
      });
    }

    if (importType.get() === ASSISTANT_STORE_LEADER_IMPORT_MAPPING_STORE.TEMPLATE_TEXT) {
      await AssistantStoreLeaderRepository.importMappingStore(formData)
          .then((res: AxiosResponse) => {
            showImportModal.set(false);
            openNotification(
                "success",
                t("notification.success.importItem", { item: importType.get() })
            );
            getTableData(true);
          })
          .catch((e: AxiosError) => {
            handleBackendError(e, t("notification.error.default"));
          });
    } else if (importType.get() === ASSISTANT_STORE_LEADER_IMPORT_SALES_HIERARCHY.TEMPLATE_TEXT) {
      await AssistantStoreLeaderRepository.importSalesHierarchy(formData)
          .then((res: AxiosResponse) => {
            showImportModal.set(false);
            openNotification(
                "success",
                t("notification.success.importItem", { item: importType.get() })
            );
            getTableData(true);
          })
          .catch((e: AxiosError) => {
            handleBackendError(e, t("notification.error.default"));
          });
    }
  };

  const importModalDownload = async () => {
    if (importType.get() === ASSISTANT_STORE_LEADER_IMPORT_MAPPING_STORE.TEMPLATE_TEXT) {
      window.open(ASSISTANT_STORE_LEADER_IMPORT_MAPPING_STORE.TEMPLATE_URL + `?${_.random(100,900)}`);
    } else if (importType.get() === ASSISTANT_STORE_LEADER_IMPORT_SALES_HIERARCHY.TEMPLATE_TEXT) {
      window.open(ASSISTANT_STORE_LEADER_IMPORT_SALES_HIERARCHY.TEMPLATE_URL + `?${_.random(100,900)}`);
    }
  };

  return (
    <>
      <WuiContainer>
        <WuiSectionTitle title={"Assistant Store Leader"} breadcrumbs={breadcrumbs}>
          <Accessible access={[permissions.write_assistant_store_leader_b2c]}>
            <Dropdown
              placement="bottomRight"
              trigger={["click"]}
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item
                      key="1"
                      icon={<UploadOutlined />}
                      onClick={() => handleShowImportModal(ASSISTANT_STORE_LEADER_IMPORT_MAPPING_STORE.TEMPLATE_TEXT)}
                    >
                      Mapping Store
                    </Menu.Item>
                    <Menu.Item
                      key="2"
                      icon={<UploadOutlined />}
                      onClick={() => handleShowImportModal(ASSISTANT_STORE_LEADER_IMPORT_SALES_HIERARCHY.TEMPLATE_TEXT)}
                    >
                      Sales Hierarchy
                    </Menu.Item>
                  </Menu>
                );
              }}
            >
              <Button>
                Import <DownOutlined />
              </Button>
            </Dropdown>
          </Accessible>
        </WuiSectionTitle>

        <Row gutter={[10, 10]} className="mb16">
          <Col className="gutter-row" span={6} xs={24} md={12} lg={10}>
            <Input
              allowClear
              placeholder={t("common.filter.search.placeholder")}
              prefix={<SearchOutlined />}
              value={search}
              onChange={(value) => {
                setSearch(value.target.value);
              }}
            />
          </Col>
        </Row>

        <Collapse
            className="mb16"
            defaultActiveKey={['1']}
            onChange={(key) => {
              console.log(key)
            }}
            expandIconPosition={"right"}
        >
          <Panel header="Filter" key="1">
            <Row className="mb6" gutter={[10, 10]}>
              <Col className="gutter-row" xs={24} md={12} lg={6}>
                <WuiSelectSingle
                    labelInValue={false}
                    value={filterSuperior.get()}
                    onChange={(value: any) => {
                      filterSuperior.set(value)
                    }}
                    selectParams={{level: [STORE_LEADER, ASSISTANT_STORE_LEADER], channel: ["B2C"],}}
                    style={{ width: '100%' }} defaultOptions={[{ id: '', name: 'Semua Peran' }]} repository={SuperiorRepository}></WuiSelectSingle>
              </Col>
              <Col>
                <Button type="link" onClick={handleReset}>Reset</Button>
              </Col>
            </Row>

          </Panel>
        </Collapse>

        <Table
          rowKey={"id"}
          bordered
          columns={columns}
          dataSource={tableData.get()}
          loading={tableLoading}
          onChange={handleTableChange}
          pagination={{
            current: tablePage.get(),
            simple: true,
            pageSize: tablePerPage.get(),
            total: totalData.get(),
          }}
        />
      </WuiContainer>

      <WuiModalConfirmation
        show={showDeleteModal.get()}
        onOk={deleteModalCallback}
        onCancel={() => showDeleteModal.set(false)}
      />

      <WuiModalImport
        show={showImportModal.get()}
        onUpload={importModalCallback}
        onCancel={() => showImportModal.set(false)}
        onDownload={importModalDownload}
        headerTitle={`Import ${importType.get()}`}
        maxFileOnMB={10}
      />
    </>
  );
};

export default AppSettingsAccountAssistantStoreLeaderList;
