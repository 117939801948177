import { useTranslation } from "react-i18next";
import { useIsMounted } from "../../../../@framework/utilities/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import WuiContainer from "../../../../@framework/wui/components/Container";
import Button from "antd/es/button";
import { SearchOutlined } from "@ant-design/icons";
import WuiSectionTitle from "../../../../@framework/wui/components/Sections/Title";
import React, { useEffect, useMemo } from "react";
import Col from "antd/es/grid/col";
import Input from "antd/es/input";
import Row from "antd/es/grid/row";
import useDebounce from "../../../../@framework/utilities/hooks/useDebounce";
import { useState } from "@hookstate/core";
import Table from "antd/es/table";
import Space from "antd/es/space";
import IssueTrackingRepository from "../../../../repositories/IssueTrackingRepository";
import { AxiosError, AxiosResponse } from "axios";
import WuiModalConfirmation from "../../../../@framework/wui/components/Modal/Confirmation";
import {
  handleBackendError,
  haveAccess,
  openNotification,
} from "../../../../functions/global";
import moment from "moment";
import { Collapse, Tabs, Tag } from "antd";
import WuiFilterDateRange from "../../../../@framework/wui/components/Filter/DateRange";
import {
  ISSUE_TRACKING_CATEGORY_OPTIONS_B2B,
  ISSUE_TRACKING_CATEGORY_OPTIONS_B2C,
  ISSUE_TRACKING_COMPLETED,
  ISSUE_TRACKING_OPEN,
  ISSUE_TRACKING_PROCESSING,
  ISSUE_TRACKING_STATUS_OPTIONS,
  ISSUE_TRACKING_TAG_COLOR,
} from "../../../../constant";
import { TChannels } from "../../../../utils";
import { Select } from "antd/es";
import { toLower } from "lodash";
import _ from "lodash";
import { permissions } from "../../../../constant/permissions";
import useAccess from "../../../../@framework/utilities/hooks/useAccess";

var qs = require("qs");

const statusOptions = ISSUE_TRACKING_STATUS_OPTIONS;

const breadcrumbs = [
  {
    label: "Issue Tracking",
    link: "/issue-tracking",
  },
];

const { Panel } = Collapse;
const { TabPane } = Tabs;

const tabs: TChannels[] = ["b2b", "b2c"];

const permissionMapping: any = {
  b2b: {
    read: permissions.read_issue_tracking_b2b,
  },
  b2c: {
    read: permissions.read_issue_tracking_b2c,
  },
};

const AppIssueTrackingList: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const acl = useAccess();
  const [searchParams, setSearchParams] = useSearchParams();
  let fullPath = window.location.pathname;
  const tabKey = useState<TChannels>(
    searchParams.get("tab") ||
      tabs.find((tab) => haveAccess(acl, permissionMapping[tab].read)) ||
      tabs[0]
  );
  const tablePage = useState<number>(parseInt(searchParams.get("page") || "1"));
  const tablePerPage = useState<number>(
    parseInt(searchParams.get("perPage") || "10")
  );
  const [tableLoading, setTableLoading] = React.useState<boolean>(false);
  const tableSort = useState<{
    sortField: any;
    sortOrder: any;
  }>({
    sortField: searchParams.get("sortField"),
    sortOrder: searchParams.get("sortOrder"),
  });
  const tableData = useState([]);
  const totalData = useState<number>(0);

  const [filterDate, setFilterDate] = React.useState<any>([
    searchParams.get("start_date")
      ? moment(searchParams.get("start_date"), "YYYY-MM-DD")
      : moment().startOf("month"),
    searchParams.get("end_date")
      ? moment(searchParams.get("end_date"), "YYYY-MM-DD")
      : moment().endOf("month"),
  ]);

  const filterStatus = useState(searchParams.get("status") || null);
  const filterCategory = useState(searchParams.get("category") || null);

  const categoryOptions =
    tabKey.get() === "b2b"
      ? ISSUE_TRACKING_CATEGORY_OPTIONS_B2B
      : ISSUE_TRACKING_CATEGORY_OPTIONS_B2C;

  const getTableData = async (reset: boolean = false) => {
    setTableLoading(true);

    if (reset) tablePage.set(1);

    let params = {
      page: tablePage.get(),
      search: debouncedSearch,
      order_by: tableSort.sortField.get(),
      sorted_by: tableSort.sortOrder.get(),
      per_page: tablePerPage.get(),
      status: filterStatus.get() ? filterStatus.get() : "",
      category: filterCategory.get() ? filterCategory.get() : "",
    };

    if (!params.order_by) {
      params.order_by = "created_at";
      params.sorted_by = "descend";
    }

    if (filterDate.length > 0 && filterDate[0] !== null) {
      Object.assign(params, {
        start_date: filterDate[0].format("YYYY-MM-DD"),
      });
      Object.assign(params, {
        end_date: filterDate[1].format("YYYY-MM-DD"),
      });
    }

    await IssueTrackingRepository.all(tabKey.get(), params)
      .then((res: AxiosResponse) => {
        if (res.data.data) {
          tableData.set(res.data.data);
        }

        totalData.set(res.data?.total || 0);

        setTableLoading(false);
      })
      .catch((e: AxiosError) => {
        setTableLoading(false);
      });
  };

  const handleTableChange = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {
    tablePage.set(pagination.current);
    tablePerPage.set(pagination.pageSize);

    if (sorter.order) {
      tableSort.sortField.set(sorter.field);
      tableSort.sortOrder.set(sorter.order);
    } else {
      tableSort.sortField.set(null);
      tableSort.sortOrder.set(null);
    }

    setFilterDataToQuery();
  };

  // For Search Input
  const [search, setSearch] = React.useState<string>("");
  const debouncedSearch = useDebounce<string>(search, 1000);

  useEffect(() => {
    if (!isMounted) {
      setFilterDataToQuery();
    }
    // eslint-disable-next-line
  }, [debouncedSearch, filterDate, filterCategory.get(), filterStatus.get()]);

  const getFilterDataFromQuery = () => {
    const keyword = searchParams.get("keyword");

    if (keyword) {
      setSearch(keyword);
    }
    // setInitialChannel()
    getTableData();
  };

  useMemo(() => {
    getFilterDataFromQuery();

    // eslint-disable-next-line
  }, []);

  const setFilterDataToQuery = () => {
    let params = {};
    let reset = false;

    if (debouncedSearch) {
      Object.assign(params, {
        keyword: debouncedSearch,
      });

      if (debouncedSearch !== searchParams.get("keyword")) {
        reset = true;
      }
    }

    if(tabKey.get()) {
      Object.assign(params, {
        tab: tabKey.get(),
      });
  
      if(tabKey.get() !== searchParams.get('tab')) {
        reset = true
      }
    }

    if (filterDate.length > 0 && filterDate[0] !== null) {
      Object.assign(params, {
        start_date: filterDate[0].format("YYYY-MM-DD"),
      });
      Object.assign(params, {
        end_date: filterDate[1].format("YYYY-MM-DD"),
      });
    }

    if (tablePage.get() !== 1) {
      if (reset) {
        Object.assign(params, {
          page: 1,
        });
      } else {
        Object.assign(params, {
          page: tablePage.get(),
        });
      }
    }

    if (tablePerPage.get() !== 10) {
      Object.assign(params, {
        perPage: tablePerPage.get(),
      });
    }

    if (tableSort.sortField.get()) {
      Object.assign(params, {
        sortField: tableSort.sortField.get(),
      });
    }

    if (tableSort.sortOrder.get()) {
      Object.assign(params, {
        sortOrder: tableSort.sortOrder.get(),
      });
    }

    if (filterCategory.get()) {
      Object.assign(params, {
        category: filterCategory.get(),
      });
    }

    if (filterStatus.get()) {
      Object.assign(params, {
        status: filterStatus.get(),
      });
    }

    const queryParams = qs.stringify(params, { indices: false });

    if (queryParams) {
      setSearchParams(`?${queryParams}`);
    } else {
      navigate("");
    }
    // setInitialChannel()
    getTableData(reset);
  };

  const columns: any = [
    {
      title: tabKey.get() === "b2b" ? "Dealer" : "Toko",
      dataIndex: tabKey.get() === "b2b" ? "dealer_name" : "store_name",
      key: tabKey.get() === "b2b" ? "dealer_name" : "store_name",
    },
    {
      title: "Kategori",
      dataIndex: "category",
      key: "category",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "category" && tableSort.sortOrder.get(),
    },
    {
      title: "Tanggal",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "created_at" && tableSort.sortOrder.get(),
      render: (text: any) => {
        return moment(text).format("D MMM YYYY | HH:mm");
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "status" && tableSort.sortOrder.get(),
      render: (text: any) => {
        const allowedStatus = [
          ISSUE_TRACKING_OPEN,
          ISSUE_TRACKING_PROCESSING,
          ISSUE_TRACKING_COMPLETED,
        ];
        if (!allowedStatus.includes(text)) {
          return <span />;
        }
        return <Tag color={ISSUE_TRACKING_TAG_COLOR[text]}>{text}</Tag>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => {
        return (
          <Space size="middle">
            <Link to={`${fullPath}/${toLower(record.channel)}/${record.id}`}>
              Lihat Detail
            </Link>
          </Space>
        );
      },
    },
  ];

  // Modal
  const showDeleteModal = useState(false);
  const selectedDeleteId = useState(0);

  const deleteModalCallback = async () => {
    if (selectedDeleteId.get()) {
      await IssueTrackingRepository.delete(tabKey.get(), selectedDeleteId.get())
        .then((res: AxiosResponse) => {
          showDeleteModal.set(false);
          selectedDeleteId.set(0);
          openNotification(
            "success",
            t("notification.success.deleteItem", { item: "Issue Tracking" })
          );
          getTableData(true);
        })
        .catch((e: AxiosError) => {
          showDeleteModal.set(false);
          selectedDeleteId.set(0);
          handleBackendError(e, t("notification.error.default"));
        });
    }
  };

  const handleDateRangeCallback = (dates: any, dateString?: any) => {
    tablePage.set(1)
    setFilterDate(dates);
  };

  const handleReset = () => {
    filterCategory.set(null);
    filterStatus.set(null);
    setSearch("");
    setFilterDate([moment().startOf("month"), moment().endOf("month")]);
  };

  const renderTabContent = () => {
    return (
      <>
        <Row gutter={[10, 10]} className="mb16">
          <Col className="gutter-row" span={6} xs={24} md={12} lg={10}>
            <Input
              allowClear
              placeholder={t("common.filter.search.placeholder")}
              prefix={<SearchOutlined />}
              value={search}
              onChange={(value) => {
                setSearch(value.target.value);
              }}
            />
          </Col>
        </Row>

        <Collapse
          className="mb16"
          defaultActiveKey={["1"]}
          onChange={(key) => {
            console.log(key);
          }}
          expandIconPosition={"right"}
        >
          <Panel header="Filter" key="1">
            <Row className="mb6" gutter={[10, 10]}>
              <Col className="gutter-row" xs={24} md={12} lg={6}>
                <Select
                  value={filterCategory.get()}
                  onChange={(val) => {
                    tablePage.set(1);
                    filterCategory.set(val);
                  }}
                  size={"large"}
                  style={{
                    width: "100%",
                  }}
                  placeholder="Pilih Kategori"
                  allowClear={true}
                  options={categoryOptions}
                />
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Select
                  value={filterStatus.get()}
                  onChange={(val) => {
                    tablePage.set(1);
                    filterStatus.set(val);
                  }}
                  size={"large"}
                  style={{
                    width: "100%",
                  }}
                  placeholder="Pilih Status"
                  allowClear={true}
                  options={statusOptions}
                />
              </Col>
              <Col className="gutter-row" span={6} xs={24} md={12} lg={8}>
                <WuiFilterDateRange
                  defaultValue={filterDate}
                  callback={handleDateRangeCallback}
                />
              </Col>
              <Col>
                <Button type="link" onClick={handleReset}>
                  Reset
                </Button>
              </Col>
            </Row>
          </Panel>
        </Collapse>

        <Table
          rowKey={"id"}
          bordered
          columns={columns}
          dataSource={tableData.get()}
          loading={tableLoading}
          onChange={handleTableChange}
          pagination={{
            current: tablePage.get(),
            simple: true,
            pageSize: tablePerPage.get(),
            total: totalData.get(),
          }}
        />
      </>
    );
  };

  return (
    <>
      <WuiContainer>
        <WuiSectionTitle title={"Issue Tracking"} breadcrumbs={breadcrumbs} />

        <Tabs
          defaultActiveKey={tabKey.get()}
          onChange={(key) => {
            tabKey.set(key);
            handleReset();
          }}
        >
          {tabs
            .filter((tab) => haveAccess(acl, permissionMapping[tab].read))
            .map((tab) => {
              return (
                <TabPane tab={tab.toUpperCase()} key={tab}>
                  {renderTabContent()}
                </TabPane>
              );
            })}
        </Tabs>
      </WuiContainer>

      <WuiModalConfirmation
        show={showDeleteModal.get()}
        onOk={deleteModalCallback}
        onCancel={() => showDeleteModal.set(false)}
      />
    </>
  );
};

export default AppIssueTrackingList;
