import React, {useEffect} from "react";
import WuiContainer from "../../../../../../@framework/wui/components/Container";
import PageHeader from "antd/es/page-header";
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import { useTranslation } from "react-i18next";
import Card from "antd/es/card";
import Space from "antd/es/space";
import Button from "antd/es/button";
import WuiFormTitle from "../../../../../../@framework/wui/components/Form/Title";
import {none, useState} from "@hookstate/core";
import { AxiosError, AxiosResponse } from "axios";
import SalesRepository from "../../../../../../repositories/SalesRepository";
import {Col, Input, Pagination, Row} from "antd";
import Text from "antd/es/typography/Text";
import Table, {ColumnType} from "antd/es/table";
import { SearchOutlined } from "@ant-design/icons";
import useDebounce from "../../../../../../@framework/utilities/hooks/useDebounce";
import GoogleMapReact from "google-map-react";
import { MdLocationPin } from "react-icons/md";
import WuiModalConfirmation from "../../../../../../@framework/wui/components/Modal/Confirmation";
import { handleBackendError, openNotification } from "../../../../../../functions/global";
import {NumericFormat} from "react-number-format";
import {MONTH_OPTIONS} from "../../../../../../constant";
import Select from "antd/es/select";
import Moment from "react-moment";
import _ from "lodash"
import {DatePicker, PaginationProps} from "antd/es";
import moment from "moment";
import BrandRepository from "../../../../../../repositories/BrandRepository";
import WuiSelectMulti from "../../../../../../@framework/wui/components/Select/Multi";
import DealerRepository from "../../../../../../repositories/DealerRepository";

var qs = require('qs');
const { Option } = Select;

const AppSettingsAccountSalesForm: React.FC<any> = () => {
    const { t } = useTranslation()
    const { id } = useParams();
    const navigate = useNavigate();
    const pageTitle = useState('')
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();
    const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY || 'NO_KEY'


    // Form
    const disable = useState(false);

    const [search, setSearch] = React.useState<string>('')
    const debouncedSearch = useDebounce<string>(search, 1000)
    let fullPath = window.location.pathname;

    const [data, setData] = React.useState<any>({
        dealers: []
    })
    const [dealers, setDealers] = React.useState<any>([])

    const [activeMarker, setActiveMarker] = React.useState('')


    useEffect(() => {
        if (id) {
            getData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const newDealers = [...data.dealers].filter((item: any) => item.name.toLowerCase().includes(search.toLowerCase()))
        setDealers(newDealers)
    }, [debouncedSearch]) // eslint-disable-line react-hooks/exhaustive-deps

    const getData = async () => {
        disable.set(true)

        await SalesRepository.show(id, { with: ['dealers', 'superior', 'brands', 'superior.parent.users'] }).then((res: AxiosResponse) => {
            const data = res.data?.data || {}
            const superior = data.my_superior || {}

            setData({
                ...data,
                superior_code: superior.code || '-',
                superior_name: superior.name || '-',
                superior_email: superior.email || '-',
                dealers: (data.dealers || []).map((item: any, index: number) => {
                    if (item.lng_lat) {
                        let latLng = item.lng_lat.split(',')
                        let lat = parseFloat(latLng[1])
                        let lng = parseFloat(latLng[0])
                        return { ...item, lat: lat, lng: lng }
                    } else {
                        return item
                    }
                }),
                brand_names: (data.brands || []).map((item: any) => item.name).join(', '),
            })

            setDealers(data.dealers)

            pageTitle.set(data.name)

            disable.set(false)
        }).catch((e: AxiosError) => {

        })
    }

    const dealerColumns = [
        {
            title: 'Kode',
            dataIndex: 'code',
            key: 'code',
            width: '18%'
        },
        {
            title: 'Nama',
            dataIndex: 'name',
            key: 'name',
            width: '60%'
        },
        {
            key: 'action',
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Link to={fullPath + '/dealer/' + record.id}>Lihat Detail</Link>
                    <Text
                        className="cursor-pointer"
                        type={"danger"}
                        onClick={() => setDeleteModal(record.id)}
                    >
                        Hapus
                    </Text>
                </Space>
            ),
        },
    ];

    // Modal
    const showDeleteModal = useState(false)
    const selectedDeleteId = useState(0)

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const setDeleteModal = (id: any) => {
        selectedDeleteId.set(id);
        showDeleteModal.set(true)
    }

    const bindResizeListener = (map: any, maps: any, bounds: any) => {
        maps.event.addDomListenerOnce(map, 'idle', () => {
            maps.event.addDomListener(window, 'resize', () => {
                map.fitBounds(bounds);
            });
        });
    };

    const handleApiLoaded = (map: any, maps: any, places: any) => {
        const bounds = getMapBounds(map, maps, places);
        map.fitBounds(bounds);
        if(places.length === 1){
            map.setZoom(17);
        }
        bindResizeListener(map, maps, bounds);
    };


    const Marker = (props: any) => {
        const data = props.data || {}
        return <div style={{ fontSize: '40px' }}>
            {
                activeMarker === data.id &&
                <div className="marker-info">
                    <div>
                        {data.name}
                    </div>
                    <div>
                        <b>{data.code}</b>
                    </div>
                </div>
            }

            <MdLocationPin onClick={() => {
                if(activeMarker === data.id){
                    setActiveMarker('')
                } else {
                    setActiveMarker(data.id)
                }
            }}/>
        </div>
    }

    const getMapBounds = (map: any, maps: any, places: any) => {
        const bounds = new maps.LatLngBounds();

        places.forEach((place: any) => {
            if (place.lat && place.lng) {
                bounds.extend(new maps.LatLng(
                    place.lat,
                    place.lng,
                ));
            }
        });
        return bounds;
    };

    const deleteModalCallback = async () => {
        if (selectedDeleteId.get()) {
            await SalesRepository.deleteDealer(id, selectedDeleteId.get()).then((res: AxiosResponse) => {
                showDeleteModal.set(false);
                selectedDeleteId.set(0)
                openNotification('success', t('notification.success.deleteItem', { item: 'Dealer' }))
                getData()
            }).catch((e: AxiosError) => {
                showDeleteModal.set(false);
                selectedDeleteId.set(0)
                handleBackendError(e, t('notification.error.default'))
            });
        }
    }

    // Target Brand per Dealer
    const currentPage = useState(1);
    const targetDealerPeriodTotal = useState(0);
    const tableTargetLoading = useState(false);

    const [targetSearch, setTargetSearch] = React.useState<string>('')
    const debouncedTargetSearch = useDebounce<string>(targetSearch, 1000)
    const [targetFilterBrands, setTargetFilterBrands] = React.useState<any[]>([])
    const [targetFilterDealers, setTargetFilterDealers] = React.useState<any[]>([])
    const [targetFilterYear, setTargetFilterYear] = React.useState<any>(moment())
    const [targetFilterMonth, setTargetFilterMonth] = React.useState<number>(parseInt(moment().format('M')));

    const [targetBrandPeriod, setTargetBrandPeriod] = React.useState([])
    const [targetDealerPeriod, setTargetDealerPeriod] = React.useState<any[]>([])

    const targetDealerColumns = useState<ColumnType<any>[]>([
        {
            title: 'Periode',
            dataIndex: 'period',
            key: 'period',
            render: (text: any, record: any) => (
                <Moment format="MMMM YYYY">
                    {text}
                </Moment>
            ),
            fixed: 'left'
        },
        {
            title: 'Brand',
            dataIndex: 'brand_name',
            key: 'brand_name',
            fixed: 'left'
        }
    ])

    const handlePaginationChange: PaginationProps['onChange'] = page => {
        currentPage.set(page)
        getTargetDealerData()
    }
    
    const getTargetPayload = () => {
        let payload: any = {
            search: debouncedTargetSearch,
            month: targetFilterMonth,
            year: targetFilterYear.format('YYYY'),
            brand: _.map(targetFilterBrands, 'id') || [],
            dealer: _.map(targetFilterDealers, 'id') || [],
        }

        return _.pickBy(payload, _.identity)
    }

    const getTargetBrandData = async () => {
        tableTargetLoading.set(true)
        currentPage.set(1)

        let payload = getTargetPayload()

        await SalesRepository.allBrandTargetPeriod(id, payload).then((res: AxiosResponse) => {
            setTargetBrandPeriod(res?.data?.data || [])
            tableTargetLoading.set(false)

            getTargetDealerData()
        }).catch((e: AxiosError) => {
            handleBackendError(e, t('notification.error.default'))
            tableTargetLoading.set(false)
        })
    }

    const getTargetDealerData = async () => {
        tableTargetLoading.set(true)

        let payload = getTargetPayload()

        Object.assign(payload, {
            page: currentPage.get(),
            per_page: 5
        })

        await SalesRepository.allDealerTargetPeriod(id, payload).then((res: AxiosResponse) => {
            setTargetDealerPeriod(res?.data?.data || [])
            targetDealerPeriodTotal.set(res?.data?.total || 0)

            tableTargetLoading.set(false)
        }).catch((e: AxiosError) => {
            handleBackendError(e, t('notification.error.default'))
            tableTargetLoading.set(false)
        })
    }

    const mergeTargetDealerPeriodIntoTable = () => {
        tableTargetLoading.set(true)

        targetDealerColumns[2].set(none)

        let mergeColumn: any = {
            title: 'Dealer',
            children: []
        }

        let params = {
            month: targetFilterMonth,
            year: targetFilterYear.format('YYYY'),
            brand: _.map(targetFilterBrands, 'id') || [],
        }

        const queryParams = qs.stringify(_.pickBy(params, _.identity) , { indices: false });

        targetDealerPeriod.forEach((item) => {
            mergeColumn.children.push(
                {
                    title: () => {
                        return <Link to={fullPath + '/target-dealer/' + item.dealer_id + `?${queryParams}`}>{item.dealer_name}</Link>
                    },
                    key: 'action',
                    render: (text: any, record: any) => {
                        let selectedTarget = _.find(item?.targets || [], (e) => {
                            return e.brand_id === record.brand_id && e.period === record.period
                        });

                        return (
                            <NumericFormat
                                prefix={'Rp '}
                                value={selectedTarget?.target || 0}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                displayType="text"
                            />
                        )
                    },
                }
            )
        })

        targetDealerColumns.merge([mergeColumn])

        tableTargetLoading.set(false)
    }

    const handleReset = () => {
        setTargetSearch('')
        setTargetFilterBrands([])
        setTargetFilterDealers([])
        setTargetFilterYear(moment())
        setTargetFilterMonth(parseInt(moment().format('M')))
    }

    useEffect(() => {
        getTargetBrandData()
    }, [targetFilterYear, targetFilterMonth, debouncedTargetSearch, targetFilterBrands, targetFilterDealers, ''])     // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        mergeTargetDealerPeriodIntoTable()
    }, [targetDealerPeriod]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <WuiContainer>
                <PageHeader
                    className="default-page-header"
                    onBack={() => navigate(-1)}
                    title={pageTitle.get()}
                />

                <Row gutter={[16, 16]}>
                    <Col className="gutter-row" md={{
                        span: 16,
                        order: 1
                    }} xs={{
                        span: 24,
                        order: 2
                    }}>
                        <Card title="Informasi Sales">
                            <Space style={{ width: '100%' }} direction="vertical" size={25}>
                                <div className="wui-form-group type-column">
                                    <WuiFormTitle title={"Level"} />

                                    <div className="input-section">
                                        <Text><strong>{data.level}</strong></Text>
                                    </div>
                                </div>
                                <div className="wui-form-group type-column">
                                    <WuiFormTitle title={"Kode"} />

                                    <div className="input-section">
                                        <Text><strong>{data.code}</strong></Text>
                                    </div>
                                </div>
                                <div className="wui-form-group type-column">
                                    <WuiFormTitle title={"Nama"} />

                                    <div className="input-section">
                                        <Text><strong>{data.name}</strong></Text>
                                    </div>
                                </div>

                                <div className="wui-form-group type-column">
                                    <WuiFormTitle title={"Email"} />

                                    <div className="input-section">
                                        <Text><strong>{data.email}</strong></Text>
                                    </div>
                                </div>

                                <div className="wui-form-group type-column">
                                    <WuiFormTitle title={"Brand"} />

                                    <div className="input-section">
                                        <Text><strong>{data.brand_names}</strong></Text>
                                    </div>
                                </div>
                            </Space>
                        </Card>
                    </Col>

                    <Col className="gutter-row" md={{
                        span: 8,
                        order: 1
                    }} xs={{
                        span: 24,
                        order: 2
                    }}>
                        <Card title="Informasi Superior">
                            <Space style={{ width: '100%' }} direction="vertical" size={25}>
                                <div className="wui-form-group type-column">
                                    <WuiFormTitle title={"Kode"} />

                                    <div className="input-section">
                                        <Text><strong>{data.superior_code}</strong></Text>
                                    </div>
                                </div>
                                <div className="wui-form-group type-column">
                                    <WuiFormTitle title={"Nama"} />

                                    <div className="input-section">
                                        <Text><strong>{data.superior_name}</strong></Text>
                                    </div>
                                </div>

                                <div className="wui-form-group type-column">
                                    <WuiFormTitle title={"Email"} />

                                    <div className="input-section">
                                        <Text><strong>{data.superior_email}</strong></Text>
                                    </div>
                                </div>
                            </Space>
                        </Card>
                    </Col>

                    <Col className="gutter-row" span={24} order={3}>
                        <Card bodyStyle={{ padding: 0 }}>
                            {data.dealers.length > 0 &&
                                <div style={{ height: '475px', width: '100%' }}>
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY }}
                                        defaultCenter={{
                                            lat: -6.200000,
                                            lng: 106.816666
                                        }}
                                        defaultZoom={17}
                                        yesIWantToUseGoogleMapApiInternals
                                        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps, data.dealers)}
                                    >
                                        {
                                            // eslint-disable-next-line array-callback-return
                                            data.dealers.map((item: any) => {
                                                if (item?.lat && item?.lng) {
                                                    return <Marker lat={item.lat} lng={item.lng} key={item.id} data={item}/>
                                                }
                                            })
                                        }
                                    </GoogleMapReact>
                                </div>
                            }
                            <div style={{ padding: '24px' }}>
                                <Space direction={"vertical"} size={36} style={{ display: 'flex' }}>
                                    <div>
                                        <h3>Dealer</h3>
                                        <Row gutter={[10, 10]} className="mb16">
                                            <Col className="gutter-row" span={6} xs={24} md={12} lg={10}>
                                                <Input
                                                    allowClear
                                                    placeholder={t('common.filter.search.placeholder')}
                                                    prefix={<SearchOutlined />}
                                                    value={search}
                                                    onChange={(value) => {
                                                        setSearch(value.target.value)
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Table
                                            rowKey="id"
                                            columns={dealerColumns}
                                            dataSource={dealers}
                                            pagination={false}
                                        />
                                    </div>

                                    <div>
                                        <h3>Target Brand per Dealer</h3>
                                        <Row gutter={[10, 10]} className="mb16">
                                            <Col className="gutter-row" span={6} xs={24} md={12} lg={10}>
                                                <Input
                                                    allowClear
                                                    placeholder={t('common.filter.search.placeholder')}
                                                    prefix={<SearchOutlined />}
                                                    value={targetSearch}
                                                    onChange={(value) => {
                                                        setTargetSearch(value.target.value)
                                                    }}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb6" gutter={[10, 10]}>
                                            <Col className="gutter-row" xs={24} md={12} lg={10}>
                                                <WuiSelectMulti
                                                    value={targetFilterDealers}
                                                    onChange={
                                                        (val: any) => {
                                                            setTargetFilterDealers(val)
                                                        }
                                                    }
                                                    repository={DealerRepository}
                                                    selectParams={{
                                                        channel: 'b2b',
                                                        sales_id: id
                                                    }}
                                                    placeholder={'Semua Dealer'}
                                                />
                                            </Col>
                                            <Col className="gutter-row" xs={24} md={12} lg={10}>
                                                <WuiSelectMulti
                                                    value={targetFilterBrands}
                                                    onChange={
                                                        (val: any) => {
                                                            setTargetFilterBrands(val)
                                                        }
                                                    }
                                                    repository={BrandRepository}
                                                    selectParams={{
                                                        sales_id: id
                                                    }}
                                                    placeholder={'Semua Brand'}
                                                />
                                            </Col>
                                            <Col className="gutter-row" xs={24} md={12} lg={5}>
                                                <DatePicker
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    allowClear={false}
                                                    picker="year"
                                                    placeholder={'Semua Tahun'}
                                                    value={targetFilterYear}
                                                    onChange={(date) => {setTargetFilterYear(date)}}
                                                />
                                            </Col>
                                            <Col className="gutter-row" xs={24} md={12} lg={5}>
                                                <Select
                                                    value={targetFilterMonth}
                                                    onChange={(val) => {
                                                        setTargetFilterMonth(val)
                                                    }}
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    placeholder="Semua Bulan"
                                                >
                                                    {MONTH_OPTIONS.map((item: any, index) => (
                                                        <Option key={item} value={index+1}>
                                                            {item}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Col>
                                            <Col>
                                                <Button type="link" onClick={handleReset}>Reset</Button>
                                            </Col>
                                        </Row>


                                        <Table
                                            rowKey="brand_id"
                                            columns={targetDealerColumns.get()}
                                            dataSource={targetBrandPeriod}
                                            pagination={false}
                                            loading={tableTargetLoading.get()}
                                            scroll={{ x: 'max-content' }}
                                        />

                                        <div className="mt16">
                                            <Row justify={"end"}>
                                                <Col>
                                                    <Pagination
                                                        simple
                                                        current={currentPage.get()}
                                                        onChange={handlePaginationChange}
                                                        pageSize={5}
                                                        total={targetDealerPeriodTotal.get()}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Space>
                            </div>
                        </Card>
                    </Col>
                </Row>

                <div className="wui-form-btn-group">
                    <Button className="wui-btn" size={"large"} onClick={() => navigate(-1)}>{t('common.button.back')}</Button>
                </div>
                
            </WuiContainer>

            <WuiModalConfirmation
                show={showDeleteModal.get()}
                onOk={deleteModalCallback}
                onCancel={() => showDeleteModal.set(false)}
            />
        </>
    )
}

export default AppSettingsAccountSalesForm
