import React, {useEffect} from "react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {useState} from "@hookstate/core";
import RequestJourneyPlanRepository from "../../../../../../../repositories/RequestJourneyPlanRepository";
import {AxiosError, AxiosResponse} from "axios";
import Moment from "react-moment";
import Space from "antd/es/space";
import Table from "antd/es/table";

var qs = require('qs');

interface Props {
    active: boolean
}

const TabPermintaan: React.FC<Props> = (
    {
        active
    }
) => {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    let fullPath = window.location.pathname;

    useEffect(() => {
        if (active) {
            setFilterDataToQuery()
        }
        // eslint-disable-next-line
    }, [active])


    // Table Config
    const tablePage = useState<number>(parseInt(searchParams.get('page') || '1'))
    const tablePerPage = useState<number>(parseInt(searchParams.get('perPage') || '10'))
    const [tableLoading, setTableLoading] = React.useState<boolean>(false)
    const tableSort = useState<{
        sortField: any,
        sortOrder: any,
    }>({
        sortField: searchParams.get('sortField'),
        sortOrder: searchParams.get('sortOrder')
    })
    const tableData = useState([]);
    const totalData = useState<number>(0)

    const setFilterDataToQuery = (isReset: boolean = false) => {
        let params = {
            tab: 'Permintaan'
        }
        let reset = isReset;

        if (tablePage.get() !== 1) {
            if(reset){
                Object.assign(params, {
                    page: 1
                })
            } else {
                Object.assign(params, {
                    page: tablePage.get()
                })
            }
        }

        if (tablePerPage.get() !== 10) {
            Object.assign(params, {
                perPage: tablePerPage.get()
            })
        }

        if (tableSort.sortField.get()) {
            Object.assign(params, {
                sortField: tableSort.sortField.get()
            })
        }

        if (tableSort.sortOrder.get()) {
            Object.assign(params, {
                sortOrder: tableSort.sortOrder.get()
            })
        }

        const queryParams = qs.stringify(params , { indices: false });

        if (queryParams) {
            setSearchParams(`?${queryParams}`)
        } else {
            navigate('')
        }

        getTableData(reset)
    }

    const getTableData = async (reset: boolean = false) => {
        setTableLoading(true)

        if (reset) tablePage.set(1);

        let params = {
            page: tablePage.get(),
            order_by: tableSort.sortField.get(),
            sorted_by: tableSort.sortOrder.get(),
            per_page: tablePerPage.get(),
        }
        
        if(!params.order_by){
            params.order_by = 'created_at';
            params.sorted_by = 'descend';
        }

        await RequestJourneyPlanRepository.all('b2b', params).then((res: AxiosResponse) => {
            if (res.data.data) {
                tableData.set(res.data.data)
            }

            totalData.set(res.data?.total || 0)

            setTableLoading(false)
        }).catch((e: AxiosError) => {

            setTableLoading(false)
        })
    }

    const handleTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        tablePage.set(pagination.current)
        tablePerPage.set(pagination.pageSize)

        if (sorter.order) {
            tableSort.sortField.set(sorter.field)
            tableSort.sortOrder.set(sorter.order)
        } else {
            tableSort.sortField.set(null)
            tableSort.sortOrder.set(null)
        }

        setFilterDataToQuery()
    }

    const columns: any = [
        {
            title: "Tanggal Kunjungan",
            dataIndex: "visit_date",
            key: "visit_date",
            sorter: true,
            defaultSortOrder:
                tableSort.sortField.get() === "visit_date" && tableSort.sortOrder.get(),
            render: (text: any, record: any) => {
                return <Moment format="DD MMMM YYYY">{text}</Moment>;
            },
        },
        {
            title: 'Sales Consultant',
            dataIndex: 'sales_name',
            key: 'sales_name',
            sorter: true,
            defaultSortOrder: tableSort.sortField.get() === 'sales_name' && tableSort.sortOrder.get(),
        },
        {
            title: 'Dealer',
            dataIndex: 'dealer_name',
            key: 'dealer_name',
            sorter: true,
            defaultSortOrder: tableSort.sortField.get() === 'dealer_name' && tableSort.sortOrder.get(),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            defaultSortOrder: tableSort.sortField.get() === 'status' && tableSort.sortOrder.get(),
        },
        {
            title: 'Tanggal Dibuat',
            dataIndex: 'created_at',
            key: 'created_at',
            sorter: true,
            defaultSortOrder: tableSort.sortField.get() === 'created_at' && tableSort.sortOrder.get(),
            render: (text: any, record: any) => {
                return (
                    <Moment format="DD MMMM YYYY | HH:mm">
                        {text}
                    </Moment>
                )
            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Link to={fullPath+'/permintaan/'+record.id}>Lihat Detail</Link>
                </Space>
            ),
        },
    ];
    // End Table Config

    return (
        <>
            <Table
                rowKey={"id"}
                bordered
                columns={columns}
                dataSource={tableData.get()}
                loading={tableLoading}
                onChange={handleTableChange}
                pagination={{
                    current: tablePage.get(),
                    simple: true,
                    pageSize: tablePerPage.get(),
                    total: totalData.get()
                }}
            />
        </>
    )
}


export default TabPermintaan