import React, { useEffect, useRef } from "react";
import WuiContainer from "../../../../@framework/wui/components/Container";
import PageHeader from "antd/es/page-header";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Card from "antd/es/card";
import Space from "antd/es/space";
import Input from "antd/es/input";
import Button from "antd/es/button";
import WuiFormTitle from "../../../../@framework/wui/components/Form/Title";
import DatePicker from "antd/es/date-picker";
import moment from "moment";
import Form from "antd/lib/form";
import WuiUploadImages from "../../../../@framework/wui/components/UploadImages";
import { Col, List, Modal, Radio, Row, Spin } from "antd";
import { useState } from "@hookstate/core";
import {
  handleBackendError,
  haveAccess,
  openNotification,
  toFileList,
} from "../../../../functions/global";
import { AxiosError, AxiosResponse } from "axios";
import PromoRepository from "../../../../repositories/PromoRepository";
import BrandRepository from "../../../../repositories/BrandRepository";
import DealerRepository from "../../../../repositories/DealerRepository";
import ClusterRepository from "../../../../repositories/ClusterRepository";
import { Editor } from "@tinymce/tinymce-react";
import Select from "antd/es/select";
import {
  AREA_HIERARCHY_AREA,
  AREA_HIERARCHY_CLUSTER,
  AREA_HIERARCHY_OPTIONS,
  AREA_HIERARCHY_REGION,
  AREA_HIERARCHY_SUBTERRITORY,
  AREA_HIERARCHY_TERRITORY,
  PROMOTION_TYPE_PROGRAM,
  PROMOTION_TYPE_PROMOTIONAL,
  B2C_AREA_HIERARCHY_OPTIONS,
} from "../../../../constant";
import WuiSelectMulti from "../../../../@framework/wui/components/Select/Multi";
import Text from "antd/es/typography/Text";
import { $clone } from "../../../../@framework/utilities";
import Accessible from "../../../../@framework/wui/components/Accessible";
import useAccess from "../../../../@framework/utilities/hooks/useAccess";
import AreaRepository from "../../../../repositories/AreaRepository";
import SubTerritoryRepository from "../../../../repositories/SubTerritoryRepository";
import TerritoryRepository from "../../../../repositories/TerritoryRepository";
import RegionRepository from "../../../../repositories/RegionRepository";
import BusinessUnitRepository from "../../../../repositories/BusinessUnitRepository";
import BusinessUnitCategoryRepository from "../../../../repositories/BusinessUnitCategoryRepository";
import BusinessUnitSubCategoryRepository from "../../../../repositories/BusinessUnitSubCategoryRepository";
import BusinessUnitChannelRepository from "../../../../repositories/BusinessUnitChannelRepository";
import LocationRepository from "../../../../repositories/LocationRepository";
import StoreRepository from "../../../../repositories/StoreRepository";
import { IChannel, TChannels } from "../../../../utils";
import { permissions } from "../../../../constant/permissions";
import _ from "lodash";

let title = "Promosi/Program";
const tinyApiKey = () => process.env.REACT_APP_TINY_API_KEY;
const { Option } = Select;

// const channelTypes: TChannels[] = ["b2b", "b2c"];

const channels: IChannel[] = [
  {
    key: "b2b",
    permission: {
      write: permissions.write_promotion_b2b,
      read: permissions.read_register_promotion_b2b,
    },
  },
  {
    key: "b2c",
    permission: {
      write: permissions.write_promotion_b2c,
      read: permissions.read_register_promotion_b2c,
    },
  },
];

const AppPromoForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id, channel } = useParams();
  const navigate = useNavigate();
  const pageTitle = id
    ? t("common.text.editItem", { item: title })
    : t("common.text.addNewItem", { item: title });
  const editorRef = useRef<any>(null);
  const termEditorRef = useRef<any>(null);
  const acl = useAccess();

  // Form
  const [form] = Form.useForm();
  const disable = useState(false);
  const loading = useState(false);
  const tabKey = useState("1");
  const selectedChannel = useState<TChannels>(
    channel
      ? channel
      : channels.filter((item) =>
          haveAccess(acl, [item.permission.write || ""])
        )[0].key
  );
  const isB2B = selectedChannel.get() === "b2b";
  const isB2C = selectedChannel.get() === "b2c";

  const showDealerModal = useState(false);

  const tempActiveDealer: any = useState({});
  const tempDealers = useState([]);
  const [renderSelectDealer, setRenderDealer] = React.useState(true);

  const onFinish = async (data: any) => {
    loading.set(true);
    const formData = new FormData();
    if (id) {
      formData.append("_method", "PUT");
    }
    const excludeKeys = [
      "from",
      "to",
      "brands",
      "dealers",
      "area",
      "images",
      "url",
      "cluster",
      "hierarchy",
      "business_units",
      "business_unit_categories",
      "business_unit_sub_categories",
      "business_unit_channels",
      "stores",
      "locations",
    ];
    Object.keys(data).forEach((key) => {
      if (excludeKeys.includes(key)) return;
      formData.append(key, data[key] || "");
    });

    formData.append(
      "from",
      `${moment(data.from)
        .set({ h: 0, m: 0 })
        .format("YYYY-MM-DD[T]HH:mm:ssZ")}`
    );
    formData.append(
      "to",
      `${moment(data.to).set({ h: 0, m: 0 }).format("YYYY-MM-DD[T]HH:mm:ssZ")}`
    );

    formData.append("url", data.url || "");
    formData.append("area_hierarchy_level", data.hierarchy);
    formData.append("channel", selectedChannel.get());

    getArrayValue(data.hierarchy.toLowerCase()).forEach(
      (id: number | string) => {
        formData.append("area_hierarchy_ids[]", `${id}`);
      }
    );

    if (isB2B) {
      data.dealers.forEach((item: any) => {
        formData.append("dealer_ids[]", item.id);
      });
      data.brands.forEach((item: any) => {
        formData.append("brand_ids[]", item.id);
      });
      formData.append("cluster_id", data.cluster?.value);
      formData.append("brand_id", data.brands[0]["id"]);
    }

    if (isB2C) {
      data.business_units.forEach((item: any) => {
        formData.append("business_unit_ids[]", item.id);
      });
      data.business_unit_categories.forEach((item: any) => {
        formData.append("business_unit_category_ids[]", item.id);
      });
      data.business_unit_sub_categories.forEach((item: any) => {
        formData.append("business_unit_sub_category_ids[]", item.id);
      });
      data.business_unit_channels.forEach((item: any) => {
        formData.append("business_unit_channel_ids[]", item.id);
      });
      data.locations.forEach((item: any) => {
        formData.append("location_ids[]", item.id);
      });
      data?.stores?.forEach((item: any) => {
        formData.append("store_ids[]", item.id);
      });
    }

    (data.images || []).forEach((item: any) => {
      if (item.status === "done") {
        formData.append("images_keep[]", item.id);
        formData.append("images[]", item);
      } else {
        formData.append("images[]", item);
      }
    });

    await (!id
      ? PromoRepository.create(selectedChannel.get(), formData)
      : PromoRepository.update(id, selectedChannel.get(), formData)
    )
      .then((res: AxiosResponse) => {
        navigate(-1);

        if (!id) {
          openNotification(
            "success",
            t("notification.success.createItem", { item: title })
          );
        } else {
          openNotification(
            "success",
            t("notification.success.updateItem", { item: title })
          );
          loading.set(false);
        }
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));
        loading.set(false);
      });
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    form.resetFields();
    setHierarchy("");
  }, [selectedChannel.get()]); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    disable.set(true);

    await PromoRepository.show(id, selectedChannel.get(), {
      with: [
        "dealers",
        "brands",
        "cluster",
        "images",
        "promotionAreaHierarchy.areaHierarchy",
        "stores",
        "locations",
        "businessUnits",
        "businessUnitCategories",
        "businessUnitSubCategories",
        "businessUnitChannels",
      ],
    })
      .then((res: AxiosResponse) => {
        const data = res.data?.data || {};
        const modified: any = {
          from: moment(data.from),
          to: moment(data.to),
          hierarchy: data.area_hierarchy_level,
          cluster: data.cluster
            ? {
                value: data.cluster?.id,
                label: data.cluster?.name,
              }
            : null,
          brands: data.brands
            ? data.brands.map((item: any) => ({
                name: item.name,
                id: item.id,
              }))
            : [
                {
                  id: data.brand_id,
                  name: data.brand?.name,
                },
              ],
          dealers: (data.dealers || []).map((item: any) => ({
            name: item.name,
            id: item.id,
          })),
          business_units: (data.business_units || []).map((item: any) => ({
            name: item.name,
            id: item.id,
          })),
          business_unit_categories: (data.business_unit_categories || []).map(
            (item: any) => ({
              name: item.name,
              id: item.id,
            })
          ),
          business_unit_sub_categories: (
            data.business_unit_sub_categories || []
          ).map((item: any) => ({
            name: item.name,
            id: item.id,
          })),
          business_unit_channels: (data.business_unit_channels || []).map(
            (item: any) => ({
              name: item.name,
              id: item.id,
            })
          ),
          locations: (data.locations || []).map((item: any) => ({
            name: item.name,
            id: item.id,
          })),
          stores: (data.stores || []).map((item: any) => ({
            name: item.name,
            id: item.id,
          })),
          images: toFileList(data.images),
        };

        if (data.promotion_area_hierarchy && modified.hierarchy) {
          modified[modified.hierarchy.toLowerCase()] =
            data.promotion_area_hierarchy.map((item: any) => ({
              id: item?.area_hierarchy?.id,
              name: item?.area_hierarchy?.name,
            }));
        }

        form.setFieldsValue({
          ...data,
          ...modified,
        });
        setHierarchy(modified.hierarchy);

        if (haveAccess(acl, getPermission()?.write || "")) {
          disable.set(false);
        }
      })
      .catch((e: AxiosError) => {
        handleBackendError(e);
      });

    if (isB2B) {
      await PromoRepository.registerPromotions("b2b", {
        with: ["dealer", "sales", "signature"],
        promotions: [id],
      })
        .then((res: AxiosResponse) => {
          const data = res.data?.data || [];
          tempDealers.set(data);
        })
        .catch((e: AxiosError) => {
          console.log(e);
        });
    }
  };

  const [hierarchy, setHierarchy] = React.useState("");

  const getArrayValue = (name: string) => {
    try {
      let arr = form.getFieldValue(name) || [];
      return arr.map((item: any) => item.id);
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const getPermission = () => {
    return channels.find((ch) => ch.key === selectedChannel.get())?.permission;
  };

  const onValuesFormChange = (changedValues: any) => {
    if (
      changedValues.cluster ||
      changedValues.area ||
      changedValues.subterritory ||
      changedValues.territory ||
      changedValues.region ||
      changedValues.locations ||
      changedValues.business_units ||
      changedValues.business_unit_categories ||
      changedValues.business_unit_sub_categories ||
      changedValues.business_unit_channels
    ) {
      form.setFieldsValue({
        ...form.getFieldsValue(true),
        dealers: [],
        stores: [],
      });
      setRenderDealer(false);
      setTimeout(() => setRenderDealer(true), 200);
    }

    if (changedValues.business_units) {
      form.resetFields([
        "business_unit_categories",
        "business_unit_sub_categories",
        "business_unit_channels",
      ]);
    }

    if (changedValues.business_unit_categories) {
      form.resetFields([
        "business_unit_sub_categories",
        "business_unit_channels",
      ]);
    }

    if (changedValues.business_unit_sub_categories) {
      form.resetFields(["business_unit_channels"]);
    }
  };

  return (
    <>
      <WuiContainer>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
        />

        <Form
          form={form}
          layout={"vertical"}
          onFinish={onFinish}
          onValuesChange={onValuesFormChange}
          onFinishFailed={({ values, errorFields, outOfDate }) => {
            if (errorFields.length) {
              tabKey.set("1");
            }
          }}
        >
          <Row gutter={[16, 16]}>
            <Col
              className="gutter-row"
              md={{
                span: 16,
                order: 1,
              }}
              xs={{
                span: 24,
                order: 2,
              }}
            >
              <Card
                style={{ borderBottom: 0 }}
                bodyStyle={{ paddingBottom: 0 }}
              >
                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Channel"} />

                  <div className="input-section">
                    <Radio.Group
                      onChange={(e) => selectedChannel.set(e.target.value)}
                      defaultValue={selectedChannel.get()}
                      disabled={!!id}
                    >
                      {channels.map((type) => {
                        return (
                          <Radio
                            disabled={
                              !haveAccess(acl, type.permission.write || "")
                            }
                            key={type.key}
                            value={type.key}
                          >
                            {type.key.toUpperCase()}
                          </Radio>
                        );
                      })}
                    </Radio.Group>
                  </div>
                </div>
              </Card>
              <Card
                style={{ borderTop: 0 }}
                tabList={[
                  { tab: "Informasi", key: "1" },
                  { tab: "Gambar", key: "2" },
                ]}
                activeTabKey={tabKey.get()}
                onTabChange={(key) => tabKey.set(key)}
              >
                <Space
                  style={{
                    width: "100%",
                    maxHeight: tabKey.get() === "1" ? "100%" : 0,
                    opacity: tabKey.get() === "1" ? 1 : 0,
                  }}
                  direction="vertical"
                  size={5}
                >
                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="type"
                        label={"Tipe Promo"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Tipe Promo",
                            }),
                          },
                        ]}
                      >
                        <Select
                          disabled={disable.get()}
                          size={"large"}
                          placeholder="Pilih tipe promo"
                          style={{
                            width: "100%",
                          }}
                          onChange={() => {}}
                        >
                          <Option value={PROMOTION_TYPE_PROMOTIONAL}>
                            {PROMOTION_TYPE_PROMOTIONAL}
                          </Option>
                          {isB2B && (
                            <Option value={PROMOTION_TYPE_PROGRAM}>
                              {PROMOTION_TYPE_PROGRAM}
                            </Option>
                          )}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>

                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="from"
                        label={"Tanggal Mulai"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Tanggal Mulai",
                            }),
                          },
                        ]}
                      >
                        <DatePicker
                          disabled={disable.get()}
                          disabledDate={(current) => {
                            let customDate = moment().format("YYYY-MM-DD");
                            return (
                              current &&
                              current < moment(customDate, "YYYY-MM-DD")
                            );
                          }}
                          format="DD-MM-YYYY"
                          style={{ width: 400, maxWidth: "100%" }}
                          size={"large"}
                          onChange={() => {}}
                          placeholder={t("common.text.input", {
                            item: "Tanggal",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="to"
                        label={"Tanggal Berakhir"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Tanggal Berakhir",
                            }),
                          },
                        ]}
                      >
                        <DatePicker
                          disabled={disable.get()}
                          disabledDate={(current) => {
                            let customDate = moment().format("YYYY-MM-DD");
                            return (
                              current &&
                              current < moment(customDate, "YYYY-MM-DD")
                            );
                          }}
                          format="DD-MM-YYYY"
                          style={{ width: 400, maxWidth: "100%" }}
                          size={"large"}
                          onChange={() => {}}
                          placeholder={t("common.text.input", {
                            item: "Tanggal",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="hierarchy"
                        label={"Hirarki"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Level",
                            }),
                          },
                        ]}
                      >
                        <Select
                          onChange={(value) => {
                            setHierarchy(value);
                            form.setFieldsValue({
                              ...form.getFieldsValue(true),
                              cluster: null,
                              region: null,
                              area: null,
                              territory: null,
                              subterritory: null,
                            });
                          }}
                          disabled={disable.get()}
                          size={"large"}
                          style={{
                            width: "100%",
                          }}
                          placeholder="Pilih Hirarki"
                        >
                          {(isB2C
                            ? B2C_AREA_HIERARCHY_OPTIONS
                            : AREA_HIERARCHY_OPTIONS
                          ).map((item: any) => (
                            <Option key={item} value={item}>
                              {item}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>

                  {hierarchy === AREA_HIERARCHY_CLUSTER && (
                    <div className="wui-form-group type-column">
                      <div className="input-section">
                        <Form.Item
                          name="cluster"
                          label="Cluster"
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: "Cluster",
                              }),
                            },
                          ]}
                        >
                          <WuiSelectMulti
                            disabled={disable.get()}
                            repository={ClusterRepository}
                            showOptionDescription
                            selectParams={{
                              selectAll: true,
                              selectAllLabel: "Semua Cluster",
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  )}

                  {hierarchy === AREA_HIERARCHY_AREA && (
                    <div className="wui-form-group type-column">
                      <div className="input-section">
                        <Form.Item
                          name="area"
                          label="Area"
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: "Area",
                              }),
                            },
                          ]}
                        >
                          <WuiSelectMulti
                            disabled={disable.get()}
                            repository={AreaRepository}
                            showOptionDescription
                            selectParams={{
                              selectAll: true,
                              selectAllLabel: "Semua Area",
                              channel: selectedChannel.get(),
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  )}

                  {hierarchy === AREA_HIERARCHY_REGION && (
                    <div className="wui-form-group type-column">
                      <div className="input-section">
                        <Form.Item
                          name="region"
                          label="Region"
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: "Region",
                              }),
                            },
                          ]}
                        >
                          <WuiSelectMulti
                            disabled={disable.get()}
                            repository={RegionRepository}
                            selectParams={{
                              channel: selectedChannel.get(),
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  )}

                  {hierarchy === AREA_HIERARCHY_TERRITORY && (
                    <div className="wui-form-group type-column">
                      <div className="input-section">
                        <Form.Item
                          name="territory"
                          label="Territory"
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: "Territory",
                              }),
                            },
                          ]}
                        >
                          <WuiSelectMulti
                            disabled={disable.get()}
                            repository={TerritoryRepository}
                            showOptionDescription
                            selectParams={{
                              selectAll: true,
                              selectAllLabel: "Semua Territory",
                              channel: selectedChannel.get(),
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  )}

                  {hierarchy === AREA_HIERARCHY_SUBTERRITORY && (
                    <div className="wui-form-group type-column">
                      <div className="input-section">
                        <Form.Item
                          name="subterritory"
                          label="Subterritory"
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: "Subterritory",
                              }),
                            },
                          ]}
                        >
                          <WuiSelectMulti
                            disabled={disable.get()}
                            repository={SubTerritoryRepository}
                            showOptionDescription
                            selectParams={{
                              selectAll: true,
                              selectAllLabel: "Semua Subterritory",
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  )}

                  {isB2B && (
                    <div className="wui-form-group type-column">
                      <div className="input-section">
                        <Form.Item
                          name="brands"
                          label={"Brand"}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: "Brand",
                              }),
                            },
                          ]}
                        >
                          <WuiSelectMulti
                            disabled={disable.get()}
                            placeholder="Pilih Brand"
                            repository={BrandRepository}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  )}

                  {isB2B && (
                    <div className="wui-form-group type-column">
                      <div className="input-section">
                        <Form.Item
                          name="dealers"
                          label={"Dealer"}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: "Dealer",
                              }),
                            },
                          ]}
                        >
                          {renderSelectDealer ? (
                            <WuiSelectMulti
                              placeholder={`Pilih Dealer`}
                              onChange={(value) => {
                                form.setFields([
                                  { name: "dealers", value: value },
                                ]);
                              }}
                              repository={DealerRepository}
                              selectParams={{
                                selectAll: true,
                                selectAllLabel: `Semua Dealer`,
                                cluster_id: getArrayValue("cluster"),
                                subterritory_id: getArrayValue("subterritory"),
                                territory_id: getArrayValue("territory"),
                                area_id: getArrayValue("area"),
                                region_id: getArrayValue("region"),
                                channel: "b2b",
                              }}
                              disabled={
                                !(
                                  form.getFieldValue("cluster") ||
                                  form.getFieldValue("region") ||
                                  form.getFieldValue("territory") ||
                                  form.getFieldValue("subterritory") ||
                                  form.getFieldValue("area")
                                ) || disable.get()
                              }
                            />
                          ) : (
                            <Input hidden />
                          )}
                        </Form.Item>
                      </div>
                    </div>
                  )}

                  {isB2C && (
                    <div className="wui-form-group type-column">
                      <div className="input-section">
                        <Form.Item
                          name="business_units"
                          label={"Business Unit"}
                        >
                          <WuiSelectMulti
                            disabled={disable.get()}
                            placeholder="Pilih Business Unit"
                            repository={BusinessUnitRepository}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  )}

                  {isB2C && (
                    <div className="wui-form-group type-column">
                      <div className="input-section">
                        <Form.Item
                          className="mb0"
                          shouldUpdate={(prevValues, curValues) =>
                            prevValues[`business_units`] !==
                            curValues[`business_units`]
                          }
                        >
                          {({ getFieldValue }) => (
                            <Form.Item
                              name="business_unit_categories"
                              label={"Category"}
                            >
                              <WuiSelectMulti
                                placeholder="Pilih Category"
                                disabled={
                                  _.isEmpty(getFieldValue("business_units")) ||
                                  disable.get()
                                }
                                selectParams={{
                                  business_units:
                                    getArrayValue("business_units"),
                                }}
                                repository={BusinessUnitCategoryRepository}
                              />
                            </Form.Item>
                          )}
                        </Form.Item>
                      </div>
                    </div>
                  )}

                  {isB2C && (
                    <div className="wui-form-group type-column">
                      <div className="input-section">
                        <Form.Item
                          className="mb0"
                          shouldUpdate={(prevValues, curValues) =>
                            prevValues[`business_units`] !==
                              curValues[`business_units`] ||
                            prevValues[`business_unit_categories`] !==
                              curValues[`business_unit_categories`]
                          }
                        >
                          {({ getFieldValue }) => (
                            <Form.Item
                              name="business_unit_sub_categories"
                              label={"Sub Category"}
                              
                            >
                              <WuiSelectMulti
                                placeholder="Pilih Sub Category"
                                disabled={
                                  _.isEmpty(
                                    getFieldValue("business_unit_categories")
                                  ) || disable.get()
                                }
                                selectParams={{
                                  business_unit_categories: getArrayValue(
                                    "business_unit_categories"
                                  ),
                                }}
                                repository={BusinessUnitSubCategoryRepository}
                              />
                            </Form.Item>
                          )}
                        </Form.Item>
                      </div>
                    </div>
                  )}

                  {isB2C && (
                    <div className="wui-form-group type-column">
                      <div className="input-section">
                        <Form.Item
                          className="mb0"
                          shouldUpdate={(prevValues, curValues) =>
                            prevValues[`business_units`] !==
                              curValues[`business_units`] ||
                            prevValues[`business_unit_categories`] !==
                              curValues[`business_unit_categories`] ||
                            prevValues[`business_unit_sub_categories`] !==
                              curValues[`business_unit_sub_categories`]
                          }
                        >
                          {({ getFieldValue }) => (
                            <Form.Item
                              name="business_unit_channels"
                              label={"Channel"}
                             
                            >
                              <WuiSelectMulti
                                placeholder="Pilih Channel"
                                disabled={
                                  _.isEmpty(
                                    getFieldValue(
                                      "business_unit_sub_categories"
                                    )
                                  ) || disable.get()
                                }
                                selectParams={{
                                  business_unit_sub_categories: getArrayValue(
                                    "business_unit_sub_categories"
                                  ),
                                }}
                                repository={BusinessUnitChannelRepository}
                              />
                            </Form.Item>
                          )}
                        </Form.Item>
                      </div>
                    </div>
                  )}

                  {isB2C && (
                    <div className="wui-form-group type-column">
                      <div className="input-section">
                        <Form.Item
                          name="locations"
                          label={"Location"}
                          
                        >
                          <WuiSelectMulti
                            disabled={disable.get()}
                            placeholder="Pilih Location"
                            repository={LocationRepository}
                            selectParams={{
                              channel: "b2c",
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  )}

                  {isB2C && (
                    <div className="wui-form-group type-column">
                      <div className="input-section">
                        {renderSelectDealer && (
                          <Form.Item name="stores" label={"Store"}>
                            <WuiSelectMulti
                              placeholder="Pilih Store"
                              disabled={disable.get()}
                              selectParams={{
                                area: getArrayValue("area"),
                                territory: getArrayValue("territory"),
                                region: getArrayValue("region"),
                                business_unit: getArrayValue("business_units"),
                                business_unit_category: getArrayValue(
                                  "business_unit_categories"
                                ),
                                business_unit_sub_category: getArrayValue(
                                  "business_unit_sub_categories"
                                ),
                                business_unit_channel: getArrayValue(
                                  "business_unit_channels"
                                ),
                                channel: "b2c",
                                has_location: 1
                              }}
                              repository={StoreRepository}
                            />
                          </Form.Item>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="title"
                        label={"Judul Promosi"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Judul Promosi",
                            }),
                          },
                        ]}
                      >
                        <Input
                          disabled={disable.get()}
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Judul Promosi",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="wui-form-group type-column">
                    <WuiFormTitle title={"Deskripsi"} required />

                    <div className="input-section">
                      <Editor
                        disabled={disable.get()}
                        apiKey={tinyApiKey()}
                        initialValue={form.getFieldValue("description")}
                        onBlur={(e) => {
                          if (!e.target) return;
                          form.setFieldsValue({
                            ...form.getFieldsValue(true),
                            description: e.target.getContent(),
                          });
                        }}
                        cloudChannel="5-stable"
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        init={{
                          branding: false,
                          height: 300,
                          placeholder: "Masukkan deskripsi",
                          menubar: false,
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor | alignleft aligncenter " +
                            "alignright alignjustify | bullist numlist outdent indent | " +
                            "removeformat | help ",
                        }}
                      />
                      <Form.Item
                        className="editor-form-item"
                        name="description"
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Deskripsi",
                            }),
                          },
                        ]}
                      >
                        <Input hidden />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="wui-form-group type-column">
                    <WuiFormTitle title={"Syarat & Ketentuan"} required />
                    <div className="input-section">
                      <Editor
                        disabled={disable.get()}
                        apiKey={tinyApiKey()}
                        initialValue={form.getFieldValue(
                          "terms_and_conditions"
                        )}
                        onBlur={(e) => {
                          if (!e.target) return;
                          form.setFieldsValue({
                            ...form.getFieldsValue(true),
                            terms_and_conditions: e.target.getContent(),
                          });
                        }}
                        cloudChannel="5-stable"
                        onInit={(evt, editor) =>
                          (termEditorRef.current = editor)
                        }
                        init={{
                          branding: false,
                          height: 300,
                          placeholder:
                            "Masukkan syarat & ketentuan promosi/program",
                          menubar: false,
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount link",
                          ],
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor | alignleft aligncenter " +
                            "alignright alignjustify | bullist numlist | link | outdent indent | " +
                            "removeformat | help ",
                        }}
                      />
                      <Form.Item
                        name="terms_and_conditions"
                        className="editor-form-item"
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Syarat & Ketentuan",
                            }),
                          },
                        ]}
                      >
                        <Input hidden />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item name="url" label={"URL"}>
                        <Input
                          disabled={disable.get()}
                          size={"large"}
                          placeholder={t("common.text.input", { item: "URL" })}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Space>

                <Space
                  style={{
                    width: "100%",
                    maxHeight: tabKey.get() === "2" ? "100%" : 0,
                    opacity: tabKey.get() === "2" ? 1 : 0,
                  }}
                  direction="vertical"
                  size={5}
                >
                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item name="images" label={"Gambar"}>
                        {tabKey.get() === "2" ? (
                          <WuiUploadImages max={5} disabled={disable.get()} />
                        ) : (
                          <Input />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                </Space>
              </Card>
            </Col>
            {id && haveAccess(acl, getPermission()?.read || "") && (
              <Col
                className="gutter-row"
                md={{
                  span: 8,
                  order: 2,
                }}
                xs={{
                  span: 24,
                  order: 1,
                }}
              >
                <Card
                  title={`${
                    selectedChannel.get() === "b2b" ? "Dealer" : "Store"
                  } tambahan yang mengikuti promo`}
                  bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
                  className={
                    tempDealers.get().length > 10 ? "dealer-list-paginated" : ""
                  }
                >
                  <List
                    pagination={{
                      simple: true,
                      hideOnSinglePage: true,
                      pageSize: 10,
                    }}
                    dataSource={tempDealers.get()}
                    renderItem={(item: any, dataIndex: number) => (
                      <List.Item>
                        <div>
                          <Text>{item.dealer?.name}</Text>
                          <div>
                            <Button
                              type="link"
                              className="p0"
                              onClick={() => {
                                const dealer = $clone(
                                  tempDealers.get()[dataIndex]
                                );
                                tempActiveDealer.set(dealer);
                                showDealerModal.set(true);
                              }}
                            >
                              Lihat Detail
                            </Button>
                          </div>
                        </div>
                      </List.Item>
                    )}
                  />
                </Card>
              </Col>
            )}
          </Row>

          {id && (
            <Modal
              title={tempActiveDealer.get().dealer?.name}
              centered
              onCancel={() => {
                showDealerModal.set(false);
              }}
              visible={showDealerModal.get()}
              footer={[
                <Button
                  key="back"
                  onClick={() => {
                    showDealerModal.set(false);
                  }}
                >
                  {t("common.button.back")}
                </Button>,
              ]}
            >
              <Space direction="vertical" size={10} style={{ width: "100%" }}>
                <div className="wui-form-group">
                  <WuiFormTitle
                    title={`Nama ${
                      selectedChannel.get() === "b2b" ? "Dealer" : "Store"
                    }`}
                  />

                  <div className="input-section">
                    <Text>{tempActiveDealer.get().dealer?.name}</Text>
                  </div>
                </div>

                <div className="wui-form-group">
                  <WuiFormTitle
                    title={`ID ${
                      selectedChannel.get() === "b2b" ? "Dealer" : "Store"
                    }`}
                  />

                  <div className="input-section">
                    <Text>{tempActiveDealer.get().dealer?.code}</Text>
                  </div>
                </div>

                <div className="wui-form-group">
                  <WuiFormTitle title={"PIC"} />

                  <div className="input-section">
                    <Text>{tempActiveDealer.get().dealer?.pic_name}</Text>
                  </div>
                </div>

                <div className="wui-form-group">
                  <WuiFormTitle title={"Nomor Handphone"} />

                  <div className="input-section">
                    <Text>{tempActiveDealer.get().dealer?.mobile_phone}</Text>
                  </div>
                </div>

                <div className="wui-form-group">
                  <WuiFormTitle title={"Alamat"} />

                  <div className="input-section">
                    <Text>{tempActiveDealer.get().dealer?.address}</Text>
                  </div>
                </div>

                <div className="wui-form-group">
                  <WuiFormTitle title={"Tanda Tangan"} />

                  <div className="input-section">
                    <WuiUploadImages
                      value={toFileList(tempActiveDealer.get().signature)}
                      disabled={true}
                    />
                  </div>
                </div>
              </Space>
            </Modal>
          )}

          <div className="wui-form-btn-group">
            <Button
              className="wui-btn"
              size={"large"}
              onClick={() => navigate(-1)}
            >
              {t("common.button.cancel")}
            </Button>
            <Accessible access={getPermission()?.write || ""}>
              <Spin spinning={loading.get()}>
                <Button
                  className="wui-btn"
                  htmlType="submit"
                  type="primary"
                  size={"large"}
                  disabled={disable.get()}
                >
                  {t("common.button.save")}
                </Button>
              </Spin>
            </Accessible>
          </div>
        </Form>
      </WuiContainer>
    </>
  );
};

export default AppPromoForm;
