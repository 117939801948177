import React from "react";
import {Outlet} from "react-router-dom";
import WuiMenuExtended from "../../../@framework/wui/components/Menu/Extended";
import {SettingOutlined, TeamOutlined} from "@ant-design/icons";

const menuList = [
    {
        name: 'Users',
        link: '/settings/users',
        icon: <TeamOutlined className="icon"/>
    },
    {
        name: 'General',
        link: '/settings/general',
        icon: <SettingOutlined className="icon"/>
    }
]

const AppSettings: React.FC<any> = () => {
    return (
        <div className="extended-main-wrapper">
            <WuiMenuExtended list={menuList}/>

            <div className="main-content">
                <Outlet/>
            </div>
        </div>
    )
}

export default AppSettings
