import AppLayout from "../layouts/App";
import { Navigate } from "react-router-dom";
import AppSettings from "../pages/App/Settings";
import AppSettingsGeneral from "../pages/App/Settings/General";
import AppSettingsUsers from "../pages/App/Settings/Users";
import AppDefaultList from "../pages/App/Default/List";
import AppDefaultForm from "../pages/App/Default/Form";
import AppSettingsDataMasterRegionList from "../pages/App/Settings/DataMaster/Region/List";
import AppSettingsDataMasterRegionForm from "../pages/App/Settings/DataMaster/Region/Form";
import AppSettingsDataMasterTerritoryList from "../pages/App/Settings/DataMaster/Territory/List";
import AppSettingsDataMasterTerritoryForm from "../pages/App/Settings/DataMaster/Territory/Form";
import AppSettingsDataMasterAreaList from "../pages/App/Settings/DataMaster/Area/List";
import AppSettingsDataMasterAreaForm from "../pages/App/Settings/DataMaster/Area/Form";
import AppSettingsDataMasterBrandList from "../pages/App/Settings/DataMaster/Brand/List";
import AppSettingsDataMasterBrandForm from "../pages/App/Settings/DataMaster/Brand/Form";
import AppSettingsDataMasterProductList from "../pages/App/Settings/DataMaster/Product/List";
import AppSettingsDataMasterProductForm from "../pages/App/Settings/DataMaster/Product/Form";
import AppSettingsDataMasterProductTypeList from "../pages/App/Settings/DataMaster/ProductType/List";
import AppSettingsDataMasterProductTypeForm from "../pages/App/Settings/DataMaster/ProductType/Form";
import AppPromoList from "../pages/App/Promo/List";
import AppSurveyList from "../pages/App/Survey/List";
import AppSettingsPushNotificationList from "../pages/App/Settings/PushNotification/List";
import AppSettingsPushNotificationForm from "../pages/App/Settings/PushNotification/Form";
import AppSettingsRadiusForm from "../pages/App/Settings/Radius/Form";
import AppSettingsJourneyForm from "../pages/App/Settings/Journey/Form";
import AppSettingsDataMasterDealerList from "../pages/App/Settings/DataMaster/Dealer/Index/List";
import AppSettingsDataMasterDealerForm from "../pages/App/Settings/DataMaster/Dealer/Index/Form";
import AppSettingsDataMasterNewProspectDealerList from "../pages/App/Settings/DataMaster/Dealer/NewDealerProspect/List";
import AppSettingsDataMasterNewProspectDealerForm from "../pages/App/Settings/DataMaster/Dealer/NewDealerProspect/Form";
import AppSettingsDataMasterStoreList from "../pages/App/Settings/DataMaster/Store/List";
import AppSettingsDataMasterStoreForm from "../pages/App/Settings/DataMaster/Store/Form";
import AppSettingsDataMasterLocationList from "../pages/App/Settings/DataMaster/Location/List";
import AppSettingsDataMasterLocationForm from "../pages/App/Settings/DataMaster/Location/Form";
import AppSettingsDataMasterBusinessUnitList from "../pages/App/Settings/DataMaster/BusinessUnit/Index/List";
import AppSettingsDataMasterBusinessUnitForm from "../pages/App/Settings/DataMaster/BusinessUnit/Index/Form";
import AppSettingsDataMasterBusinessUnitCategoryList from "../pages/App/Settings/DataMaster/BusinessUnit/Category/List";
import AppSettingsDataMasterBusinessUnitCategoryForm from "../pages/App/Settings/DataMaster/BusinessUnit/Category/Form";
import AppSettingsDataMasterBusinessUnitSubCategoryList from "../pages/App/Settings/DataMaster/BusinessUnit/SubCategory/List";
import AppSettingsDataMasterBusinessUnitSubCategoryForm from "../pages/App/Settings/DataMaster/BusinessUnit/SubCategory/Form";
import AppSettingsDataMasterBusinessUnitChannelList from "../pages/App/Settings/DataMaster/BusinessUnit/Channel/List";
import AppSettingsDataMasterBusinessUnitChannelForm from "../pages/App/Settings/DataMaster/BusinessUnit/Channel/Form";
import AppSettingsAccountRoleList from "../pages/App/Settings/Account/Role/List";
import AppSettingsAccountRoleForm from "../pages/App/Settings/Account/Role/Form";
import AppSettingsAccountAccessList from "../pages/App/Settings/Account/Access/List";
import AppSettingsAccountAccessForm from "../pages/App/Settings/Account/Access/Form";
import AppAccountProfile from "../pages/App/Account/Profile";
import AppSettingsAccountUserList from "../pages/App/Settings/Account/User/List";
import AppSettingsAccountUserForm from "../pages/App/Settings/Account/User/Form";
import AppIssueTrackingList from "../pages/App/IssueTracking/List";
import AppIssueTrackingForm from "../pages/App/IssueTracking/Form";
import AppPromoForm from "../pages/App/Promo/Form";
import AppSettingsDataMasterSubTerritoryList from "../pages/App/Settings/DataMaster/SubTerritory/List";
import AppSettingsDataMasterSubTerritoryForm from "../pages/App/Settings/DataMaster/SubTerritory/Form";
import AppSettingsDataMasterClusterList from "../pages/App/Settings/DataMaster/Cluster/List";
import AppSettingsDataMasterClusterForm from "../pages/App/Settings/DataMaster/Cluster/Form";
import AppPromotorHandoverList from "../pages/App/Promotor/Handover/List";
import AppPromotorUniformRequestList from "../pages/App/Promotor/UniformRequest/List";
import AppPromotorHandoverForm from "../pages/App/Promotor/Handover/Form";
import AppPromotorUniformRequestForm from "../pages/App/Promotor/UniformRequest/Form";
import AppSurveyForm from "../pages/App/Survey/Form";
import AppSettingsAccountSalesList from "../pages/App/Settings/Account/Sales/List";
import AppJourneyPlanB2BList from "../pages/App/JourneyPlan/B2B/List";
import AppJourneyPlanB2BFormPermintaan from "../pages/App/JourneyPlan/B2B/Form/Permintaan";
import AppJourneyPlanB2BFormPlan from "../pages/App/JourneyPlan/B2B/Form/Plan";
import AppJourneyPlanB2BFormPlanDetails from "../pages/App/JourneyPlan/B2B/Form/Plan/Details";
import AppJourneyPlanB2CList from "../pages/App/JourneyPlan/B2C/List";
import AppJourneyPlanB2CFormPlan from "../pages/App/JourneyPlan/B2C/Form/Plan";
import AppJourneyPlanB2CFormPlanDetails from "../pages/App/JourneyPlan/B2C/Form/Plan/Detail";
import AppJourneyPlanB2CFormRequestDetails from "../pages/App/JourneyPlan/B2C/Form/Permintaan";
import AppJourneyPlanB2CFormRequestApproval from "../pages/App/JourneyPlan/B2C/Form/Permintaan/Approval";
import AppSettingsAccountSalesForm from "../pages/App/Settings/Account/Sales/Form";
import AppSettingsAccountSalesDealerForm from "../pages/App/Settings/Account/Sales/Dealer";
import AppStockOpnameList from "../pages/App/StockOpname/List";
import AppDashboardB2B from "../pages/App/Dashboard/B2B";
import AppDashboardB2C from "../pages/App/Dashboard/B2C";
import AppDashboardOnline from "../pages/App/Dashboard/Online";
import AppDashboardHeadQuarter from "../pages/App/Dashboard/HeadQuarter";
import AppKPIMasterList from "../pages/App/KPI/Master/List";
import AppKPIMasterForm from "../pages/App/KPI/Master/Form";
import AppKPIUserList from "../pages/App/KPI/User/List";
import AppKPIUserDetail from "../pages/App/KPI/User/Detail";
import AppKPIItemList from "../pages/App/KPI/Item/List";
import AppKPIItemForm from "../pages/App/KPI/Item/Form";
import AppKPITargetItemList from "../pages/App/KPI/Target/Item/List";
import AppKPITargetItemForm from "../pages/App/KPI/Target/Item/Form";
import AppKPITargetStoreList from "../pages/App/KPI/Target/Store/List";
import AppKPITargetStoreForm from "../pages/App/KPI/Target/Store/Form";
import AppKPICalculationResult from "../pages/App/KPI/CalculationResult";
import AppLDUList from "../pages/App/LDU/List";
import AppLDUForm from "../pages/App/LDU/Form";
import AppSettingsAccountAssistantStoreLeaderList from "../pages/App/Settings/Account/AssistantStoreLeader/List";
import AppSettingsAccountAssistantStoreLeaderForm from "../pages/App/Settings/Account/AssistantStoreLeader/Form";
import AppSettingsAccountAssistantStoreLeaderStoreForm from "../pages/App/Settings/Account/AssistantStoreLeader/Store";
import AppSettingsAccountSalesTargetDealer from "../pages/App/Settings/Account/Sales/TargetDealer";
import AppStockOpnameAdd from "../pages/App/StockOpname/Add";
import AppStockOpnameDetail from "../pages/App/StockOpname/Detail";
import AppStockOpnameEdit from "../pages/App/StockOpname/Edit";
import AppDashboardB2CO2ODetail from "../pages/App/Dashboard/B2C/Tabs/O2O/Detail";

const appRoutes = [
  {
    path: "/",
    element: <AppLayout />,
    children: [
      { path: "/", element: <Navigate to={"/dashboard"} /> },
      {
        path: "/dashboard",
        children: [
          { path: "/", element: <Navigate to={"/dashboard/b2b"} /> },
          { path: "/B2B", element: <AppDashboardB2B /> },
          { path: "/B2C", element: <AppDashboardB2C /> },
          { path: "/B2C/O2O/:id", element: <AppDashboardB2CO2ODetail /> },
          { path: "/online", element: <AppDashboardOnline /> },
          { path: "/head-quarter", element: <AppDashboardHeadQuarter /> },
        ],
      },
      {
        path: "/account",
        children: [
          { path: "/", element: <Navigate to={"/account/profile"} /> },
          { path: "/profile", element: <AppAccountProfile /> },
        ],
      },
      {
        path: "/journey-plan",
        children: [
          { path: "/", element: <Navigate to={"/B2B"} /> },
          {
            path: "/B2B",
            children: [
              { path: "/", element: <AppJourneyPlanB2BList /> },
              { path: "/form", element: <AppJourneyPlanB2BFormPlan /> },
              { path: "/:id", element: <AppJourneyPlanB2BFormPlanDetails /> },
              {
                path: "/permintaan/:id",
                element: <AppJourneyPlanB2BFormPermintaan />,
              },
            ],
          },
          {
            path: "/B2C",
            children: [
              { path: "/", element: <AppJourneyPlanB2CList /> },
              { path: "/form", element: <AppJourneyPlanB2CFormPlan /> },
              { path: "/:id", element: <AppJourneyPlanB2CFormPlanDetails /> },
              {
                path: "/permintaan/:id",
                element: <AppJourneyPlanB2CFormRequestDetails />,
              },
              {
                path: "/permintaan/approval/:id",
                element: <AppJourneyPlanB2CFormRequestApproval />,
              },
            ],
          },
        ],
      },
      {
        path: "/promo",
        children: [
          { path: "/", element: <AppPromoList /> },
          { path: "/:channel/:id", element: <AppPromoForm /> },
          { path: "/form", element: <AppPromoForm /> },
        ],
      },
      {
        path: "/promotor",
        children: [
          { path: "/", element: <Navigate to={"/promotor/handover"} /> },
          {
            path: "/handover",
            children: [
              { path: "/", element: <AppPromotorHandoverList /> },
              { path: "/:id", element: <AppPromotorHandoverForm /> },
            ],
          },
          {
            path: "/uniform-request",
            children: [
              { path: "/", element: <AppPromotorUniformRequestList /> },
              { path: "/:id", element: <AppPromotorUniformRequestForm /> },
            ],
          },
        ],
      },
      {
        path: "/issue-tracking",
        children: [
          { path: "/", element: <AppIssueTrackingList /> },
          { path: "/:channel/:id", element: <AppIssueTrackingForm /> },
        ],
      },
      {
        path: "/survei",
        children: [
          { path: "/", element: <AppSurveyList /> },
          { path: "/form", element: <AppSurveyForm /> },
          { path: "/:id", element: <AppSurveyForm /> },
        ],
      },
      {
        path: "/ldu",
        children: [
          { path: "/", element: <AppLDUList /> },
          { path: "/form", element: <AppLDUForm /> },
          { path: "/:id", element: <AppLDUForm /> },
        ],
      },
      {
        path: "/kpi",
        children: [
          { path: "/", element: <Navigate to={"/kpi/master-kpi"} /> },
          {
            path: "/master-kpi",
            children: [
              {
                path: "/",
                element: <AppKPIMasterList />,
              },
              { path: "/:id", element: <AppKPIMasterForm /> },
              { path: "/form", element: <AppKPIMasterForm /> },
            ],
          },
          {
            path: "/user-kpi",
            children: [
              {
                path: "/",
                element: <AppKPIUserList />,
              },
              { path: "/:id", element: <AppKPIUserDetail /> },
            ],
          },
          {
            path: "/item-kpi",
            children: [
              {
                path: "/",
                element: <AppKPIItemList />,
              },
              { path: "/:id", element: <AppKPIItemForm /> },
              { path: "/form", element: <AppKPIItemForm /> },
            ],
          },
          {
            path: "/target",
            children: [
              { path: "/", element: <Navigate to={"/kpi/target/item"} /> },
              {
                path: "/item",
                children: [
                  { path: "/", element: <AppKPITargetItemList /> },
                  { path: "/:id", element: <AppKPITargetItemForm /> },
                  { path: "/form", element: <AppKPITargetItemForm /> },
                ],
              },
              {
                path: "/store",
                children: [
                  { path: "/", element: <AppKPITargetStoreList /> },
                  { path: "/:id", element: <AppKPITargetStoreForm /> },
                  { path: "/form", element: <AppKPITargetStoreForm /> },
                ],
              },
            ],
          },
          {
            path: "/hasil-perhitungan",
            element: <AppKPICalculationResult />,
          },
        ],
      },
      {
        path: "/stock-opname",
        children: [
          { path: "/", element: <AppStockOpnameList /> },
          { path: "/add", element: <AppStockOpnameAdd /> },
          { path: "/:id/detail", element: <AppStockOpnameDetail /> },
          { path: "/:id/edit", element: <AppStockOpnameEdit /> },
        ],
      },
      {
        path: "/pengaturan",
        children: [
          { path: "/", element: <Navigate to={"/pengaturan/data-master"} /> },
          {
            path: "/data-master",
            children: [
              {
                path: "/",
                element: <Navigate to={"/pengaturan/data-master/region"} />,
              },
              {
                path: "/dealer",
                children: [
                  {
                    path: "/",
                    element: (
                      <Navigate to={"/pengaturan/data-master/dealer/list"} />
                    ),
                  },
                  {
                    path: "/list",
                    element: <AppSettingsDataMasterDealerList />,
                  },
                  {
                    path: "/form",
                    element: <AppSettingsDataMasterDealerForm />,
                  },
                  {
                    path: "/:id",
                    element: <AppSettingsDataMasterDealerForm />,
                  },
                  {
                    path: "/prospek-dealer-baru",
                    element: <AppSettingsDataMasterNewProspectDealerList />,
                  },
                  {
                    path: "/prospek-dealer-baru/:id",
                    element: <AppSettingsDataMasterNewProspectDealerForm />,
                  },
                ],
              },
              {
                path: "/business-unit",
                children: [
                  {
                    path: "/",
                    element: <Navigate to={"/pengaturan/data-master/business-unit/list"} />,
                  },
                  {
                    path: "/list",
                    element: <AppSettingsDataMasterBusinessUnitList />,
                  },
                  {
                    path: "/form",
                    element: <AppSettingsDataMasterBusinessUnitForm />,
                  },
                  {
                    path: "/:id",
                    element: <AppSettingsDataMasterBusinessUnitForm />,
                  },
                  {
                    path: "/category",
                    children: [
                      {
                        path: "/",
                        element: (
                          <AppSettingsDataMasterBusinessUnitCategoryList />
                        ),
                      },
                      {
                        path: "/form",
                        element: (
                          <AppSettingsDataMasterBusinessUnitCategoryForm />
                        ),
                      },
                      {
                        path: "/:id",
                        element: (
                          <AppSettingsDataMasterBusinessUnitCategoryForm />
                        ),
                      },
                    ],
                  },
                  {
                    path: "/sub-category",
                    children: [
                      {
                        path: "/",
                        element: (
                          <AppSettingsDataMasterBusinessUnitSubCategoryList />
                        ),
                      },
                      {
                        path: "/form",
                        element: (
                          <AppSettingsDataMasterBusinessUnitSubCategoryForm />
                        ),
                      },
                      {
                        path: "/:id",
                        element: (
                          <AppSettingsDataMasterBusinessUnitSubCategoryForm />
                        ),
                      },
                    ],
                  },
                  {
                    path: "/channel",
                    children: [
                      {
                        path: "/",
                        element: (
                          <AppSettingsDataMasterBusinessUnitChannelList />
                        ),
                      },
                      {
                        path: "/form",
                        element: (
                          <AppSettingsDataMasterBusinessUnitChannelForm />
                        ),
                      },
                      {
                        path: "/:id",
                        element: (
                          <AppSettingsDataMasterBusinessUnitChannelForm />
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: "/store",
                children: [
                  { path: "/", element: <AppSettingsDataMasterStoreList /> },
                  {
                    path: "/form",
                    element: <AppSettingsDataMasterStoreForm />,
                  },
                  {
                    path: "/:id",
                    element: <AppSettingsDataMasterStoreForm />,
                  },
                ],
              },
              {
                path: "/lokasi",
                children: [
                  { path: "/", element: <AppSettingsDataMasterLocationList /> },
                  {
                    path: "/form",
                    element: <AppSettingsDataMasterLocationForm />,
                  },
                  {
                    path: "/:id",
                    element: <AppSettingsDataMasterLocationForm />,
                  },
                ],
              },
              {
                path: "/produk",
                children: [
                  { path: "/", element: <AppSettingsDataMasterProductList /> },
                  {
                    path: "/form",
                    element: <AppSettingsDataMasterProductForm />,
                  },
                  {
                    path: "/:id",
                    element: <AppSettingsDataMasterProductForm />,
                  },
                ],
              },
              {
                path: "/tipe-produk",
                children: [
                  {
                    path: "/",
                    element: <AppSettingsDataMasterProductTypeList />,
                  },
                  {
                    path: "/form",
                    element: <AppSettingsDataMasterProductTypeForm />,
                  },
                  {
                    path: "/:id",
                    element: <AppSettingsDataMasterProductTypeForm />,
                  },
                ],
              },
              {
                path: "/brand",
                children: [
                  { path: "/", element: <AppSettingsDataMasterBrandList /> },
                  {
                    path: "/form",
                    element: <AppSettingsDataMasterBrandForm />,
                  },
                  { path: "/:id", element: <AppSettingsDataMasterBrandForm /> },
                ],
              },
              {
                path: "/region",
                children: [
                  { path: "/", element: <AppSettingsDataMasterRegionList /> },
                  {
                    path: "/form",
                    element: <AppSettingsDataMasterRegionForm />,
                  },
                  {
                    path: "/:channel/:id",
                    element: <AppSettingsDataMasterRegionForm />,
                  },
                ],
              },
              {
                path: "/territory",
                children: [
                  {
                    path: "/",
                    element: <AppSettingsDataMasterTerritoryList />,
                  },
                  {
                    path: "/form",
                    element: <AppSettingsDataMasterTerritoryForm />,
                  },
                  {
                    path: "/:channel/:id",
                    element: <AppSettingsDataMasterTerritoryForm />,
                  },
                ],
              },
              {
                path: "/sub-territory",
                children: [
                  {
                    path: "/",
                    element: <AppSettingsDataMasterSubTerritoryList />,
                  },
                  {
                    path: "/form",
                    element: <AppSettingsDataMasterSubTerritoryForm />,
                  },
                  {
                    path: "/:id",
                    element: <AppSettingsDataMasterSubTerritoryForm />,
                  },
                ],
              },
              {
                path: "/area",
                children: [
                  { path: "/", element: <AppSettingsDataMasterAreaList /> },
                  {
                    path: "/form",
                    element: <AppSettingsDataMasterAreaForm />,
                  },
                  {
                    path: "/:channel/:id",
                    element: <AppSettingsDataMasterAreaForm />,
                  },
                ],
              },
              {
                path: "/cluster",
                children: [
                  { path: "/", element: <AppSettingsDataMasterClusterList /> },
                  {
                    path: "/form",
                    element: <AppSettingsDataMasterClusterForm />,
                  },
                  {
                    path: "/:id",
                    element: <AppSettingsDataMasterClusterForm />,
                  },
                ],
              },
            ],
          },
          {
            path: "/akun",
            children: [
              {
                path: "/",
                element: <Navigate to={"/pengaturan/akun/sales"} />,
              },
              {
                path: "/sales",
                children: [
                  { path: "/", element: <AppSettingsAccountSalesList /> },
                  { path: "/:id", element: <AppSettingsAccountSalesForm /> },
                  {
                    path: "/:id/dealer/:dealerId",
                    element: <AppSettingsAccountSalesDealerForm />,
                  },
                  {
                    path: "/:id/target-dealer/:dealerId",
                    element: <AppSettingsAccountSalesTargetDealer />,
                  },
                ],
              },
              {
                path: "/assistant-store-leader",
                children: [
                  {
                    path: "/",
                    element: <AppSettingsAccountAssistantStoreLeaderList />,
                  },
                  {
                    path: "/:id",
                    element: <AppSettingsAccountAssistantStoreLeaderForm />,
                  },
                  {
                    path: "/:id/store/:storeId",
                    element: (
                      <AppSettingsAccountAssistantStoreLeaderStoreForm />
                    ),
                  },
                ],
              },
              {
                path: "/pengguna",
                children: [
                  { path: "/", element: <AppSettingsAccountUserList /> },
                  { path: "/form", element: <AppSettingsAccountUserForm /> },
                  { path: "/:id", element: <AppSettingsAccountUserForm /> },
                ],
              },
              {
                path: "/peran",
                children: [
                  { path: "/", element: <AppSettingsAccountRoleList /> },
                  { path: "/form", element: <AppSettingsAccountRoleForm /> },
                  { path: "/:id", element: <AppSettingsAccountRoleForm /> },
                ],
              },
              {
                path: "/hak-akses",
                children: [
                  { path: "/", element: <AppSettingsAccountAccessList /> },
                  { path: "/form", element: <AppSettingsAccountAccessForm /> },
                  { path: "/:id", element: <AppSettingsAccountAccessForm /> },
                ],
              },
            ],
          },
          {
            path: "/push-notifikasi",
            children: [
              { path: "/", element: <AppSettingsPushNotificationList /> },
              { path: "/form", element: <AppSettingsPushNotificationForm /> },
              { path: "/:id", element: <AppSettingsPushNotificationForm /> },
            ],
          },
          {
            path: "/radius",
            element: <AppSettingsRadiusForm />,
          },
          {
            path: "/journey",
            element: <AppSettingsJourneyForm />,
          },
        ],
      },
      {
        // For Testing Layout Only
        path: "/default",
        children: [
          { path: "/", element: <Navigate to={"/default/list"} /> },
          { path: "/list", element: <AppDefaultList /> },
          { path: "/form", element: <AppDefaultForm /> },
        ],
      },
      {
        // For Testing Layout Only
        path: "/settings",
        element: <AppSettings />,
        children: [
          { path: "/", element: <Navigate to={"/settings/general"} /> },
          { path: "/general", element: <AppSettingsGeneral /> },
          { path: "/users", element: <AppSettingsUsers /> },
        ],
      },
    ],
  },
];

export default appRoutes;
