import React, { useEffect } from "react";
import WuiContainer from "../../../../../../@framework/wui/components/Container";
import PageHeader from "antd/es/page-header";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Card from "antd/es/card";
import Space from "antd/es/space";
import Button from "antd/es/button";
import WuiFormTitle from "../../../../../../@framework/wui/components/Form/Title";
import { useState } from "@hookstate/core";
import { AxiosError, AxiosResponse } from "axios";
import SalesRepository from "../../../../../../repositories/SalesRepository";
import DealerRepository from "../../../../../../repositories/DealerRepository";
import { Col,  Row } from "antd";
import Text from "antd/es/typography/Text";
import DatePicker from "antd/es/date-picker";
import moment from "moment";
import Table from "antd/es/table";
import WuiUploadImages from "../../../../../../@framework/wui/components/UploadImages";
import {openNotification, toFileList} from "../../../../../../functions/global";
import Modal from "antd/lib/modal/Modal";
import { SALES_CONSULTANT, SALES_PROMOTER } from "../../../../../../constant";
import {TChannels} from "../../../../../../utils";
import Typography from "antd/es/typography";
import {NumericFormat} from "react-number-format";
import {$clone} from "../../../../../../@framework/utilities";
import {TableGAP} from "../../../DataMaster/Dealer/Index/Form/Component";
import AppDefaultTableau from "../../../../../../components/Tableau";
import Form from "antd/lib/form";
import InputNumber from "antd/es/input-number";

const { Title } = Typography;

const AppSettingsAccountSalesDealerForm: React.FC<any> = () => {
    const { t } = useTranslation()
    const { id, dealerId } = useParams();
    const navigate = useNavigate();
    const pageTitle = useState('')
    const selectedChannel = useState<TChannels>('b2b')

    // Form
    const disable = useState(false);
    const [visitDate, setVisitDate] = React.useState(moment())

    const [sales, setSales] = React.useState<any>({})
    const [products, setProducts] = React.useState<any>([])
    const [financialInfo, setFinancialInfo] = React.useState<any>({})
    const [dealer, setDealer] = React.useState<any>({})
    const [photos, setPhotos] = React.useState<any>([])

    const showProductModal = useState(false)
    const tempActiveProduct: any = useState({})
    const [isLoading, setIsLoading] = React.useState(false)


    useEffect(() => {
        if (id) {
            getData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    
    useEffect(() => {
        if(id && sales.level){
            getReport(sales.level)
        }
    }, [visitDate]); // eslint-disable-line react-hooks/exhaustive-deps

    const getData = async () => {
        disable.set(true)
        let level = '';

        await SalesRepository.show(id, {with: ['brands']}).then((res: AxiosResponse) => {
            const data = res.data?.data || {}
            level = data.level
            setSales({
                ...data,
                brand_names: (data.brands || []).map((item: any) => item.name).join(', '),
            })
            pageTitle.set(data.name)
        }).catch((e: AxiosError) => {

        }) ;

        await DealerRepository.show(selectedChannel.get(), dealerId).then((res: AxiosResponse) => {
            const data = res.data?.data || {}
            setDealer(data);
        }).catch((e: AxiosError) => {
            
        });

        await getReport(level)
        await getFinancialInfo()

        disable.set(false)
    }

    const getFinancialInfo = async () => {
        await DealerRepository.financialInfo('b2b', dealerId)
        .then((res: AxiosResponse) => {
            let data = res.data?.data || {};
            setFinancialInfo(data)
            console.log(financialInfo)
        })
        .catch((e: AxiosError) => {
            openNotification("error", t("notification.error.default"));
        });
    };

    const getReport = async (level: string) => {
        disable.set(true)
        let payload = {
            dealer_id: dealerId,
            sales_id: id,
            visit_date: moment(visitDate).format('YYYY-MM-DD')
        }

        let params = {
            with: ['reportStocks.product', 'reportSoldProducts.product', 'photos']
        }
        setIsLoading(true)

        if(level === SALES_CONSULTANT){
            await SalesRepository.reportConsultant(payload, params).then((res: AxiosResponse) => {
                const data = res.data?.data || {}
                setPhotos(toFileList(data['photos'] || []));
                setProducts(data.reportProduct || []);
                disable.set(false)
                setIsLoading(false)
            }).catch((e: AxiosError) => {
            })
        } else if(level === SALES_PROMOTER){
            params = {
                with: ['clockInPhoto', 'photoBoothsAndShops', 'photoVisibilities', 'photoActivityMediaSocials', 'photoPlanograms', 'reportStocks.product', 'reportSoldProducts.product']
            }
            await SalesRepository.reportPromoter(payload, params).then((res: AxiosResponse) => {
                const data = res.data?.data || {}
                let allPhotoKeys = ['photo_booths_and_shops', 'photo_visibilities', 'photo_activity_media_socials', 'photo_planograms']
                let allPhoto: any = []
                allPhotoKeys.forEach((key: string) => {
                    if(Array.isArray(data[key])){
                        allPhoto = [...allPhoto, ...data[key]];
                    }
                })
                setPhotos(toFileList(allPhoto));
                setProducts(data.reportProduct || []);
                disable.set(false)
            }).catch((e: AxiosError) => {
                console.log(e)
            })
        }

        disable.set(false)

        setIsLoading(false)
    }

    const productColumns: any = [
        {
            title: 'Nama',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Kode',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'IMEI',
            key: 'imei',
            render: (text: any, record: any) => {
                if(record.imei_list){
                    return <Button type="primary" onClick={() => {
                        tempActiveProduct.set(record)
                        showProductModal.set(true)
                    }}>Lihat Detail IMEI</Button>
                } else {
                    return null
                }
            }
        },
        {
            title: 'Stock',
            dataIndex: 'stock',
            key: 'stock',
        },

        {
            title: 'Terjual',
            dataIndex: 'sold',
            key: 'sold',
        },
    ]

    return (
        <>
            <WuiContainer>
                <PageHeader
                    className="default-page-header"
                    onBack={() => navigate(-1)}
                    title={pageTitle.get()}
                />

                <Row gutter={[16, 16]}>
                    <Col className="gutter-row" md={{
                        span: 16,
                        order: 1
                    }} xs={{
                        span: 24,
                        order: 2
                    }}>
                        <Card title="Informasi Sales">
                            <Space style={{ width: '100%' }} direction="vertical" size={25}>
                                <div className="wui-form-group type-column">
                                    <WuiFormTitle title={"Level"} />

                                    <div className="input-section">
                                        <Text><strong>{sales.level}</strong></Text>
                                    </div>
                                </div>
                                <div className="wui-form-group type-column">
                                    <WuiFormTitle title={"Kode"} />

                                    <div className="input-section">
                                        <Text><strong>{sales.code}</strong></Text>
                                    </div>
                                </div>
                                <div className="wui-form-group type-column">
                                    <WuiFormTitle title={"Nama"} />

                                    <div className="input-section">
                                        <Text><strong>{sales.name}</strong></Text>
                                    </div>
                                </div>

                                <div className="wui-form-group type-column">
                                    <WuiFormTitle title={"Email"} />

                                    <div className="input-section">
                                        <Text><strong>{sales.email}</strong></Text>
                                    </div>
                                </div>

                                <div className="wui-form-group type-column">
                                    <WuiFormTitle title={"Brand"} />

                                    <div className="input-section">
                                        <Text><strong>{sales.brand_names}</strong></Text>
                                    </div>
                                </div>
                            </Space>
                        </Card>

                        <br />

                        <Card title="Informasi Dealer">
                            <Space style={{ width: '100%' }} direction="vertical" size={25}>
                                <div className="wui-form-group type-column">
                                    <WuiFormTitle title={"Kode"} />

                                    <div className="input-section">
                                        <Text><strong>{dealer.code}</strong></Text>
                                    </div>
                                </div>
                                <div className="wui-form-group type-column">
                                    <WuiFormTitle title={"Nama"} />

                                    <div className="input-section">
                                        <Text><strong>{dealer.name}</strong></Text>
                                    </div>
                                </div>

                                <div className="wui-form-group type-column">
                                    <WuiFormTitle title={"Klasifikasi"} />

                                    <AppDefaultTableau
                                        tab={"PerformanceBO_QVO"}
                                        filters={"Dealer=0010000001"}
                                        height={"400"}
                                    />
                                </div>

                                <div className="wui-form-group type-column">
                                    <Title level={5}><strong>Informasi Finansial</strong></Title>

                                    <div>
                                        <div className="mb8">
                                            <WuiFormTitle title="Limit Kredit" />
                                        </div>
                                        <Row
                                            wrap={false}
                                            className="mb18"
                                            justify="start"
                                            align="middle"
                                        >
                                            <Col flex="none" className="mr16">
                                                <strong>
                                                    1010 :
                                                </strong>
                                            </Col>
                                            <Col flex="auto">
                                                <NumericFormat
                                                    value={financialInfo?.credit_limit_1010 ?? 0}
                                                    thousandSeparator={"."}
                                                    decimalSeparator={","}
                                                    displayType="text"
                                                />
                                            </Col>
                                        </Row>
                                        <Row
                                            wrap={false}
                                            className="mb18"
                                            justify="start"
                                            align="middle"
                                        >
                                            <Col flex="none" className="mr16">
                                                <strong>
                                                    1220 :
                                                </strong>
                                            </Col>
                                            <Col flex="auto">
                                                <NumericFormat
                                                    value={financialInfo?.credit_limit_1220 ?? 0}
                                                    thousandSeparator={"."}
                                                    decimalSeparator={","}
                                                    displayType="text"
                                                />
                                            </Col>
                                        </Row>
                                    </div>

                                    <div>
                                        <div className="mb8">
                                            <WuiFormTitle title="Outstanding Payment" />
                                        </div>
                                        <Row
                                            wrap={false}
                                            className="mb18"
                                            justify="start"
                                            align="middle"
                                        >
                                            <Col flex="none" className="mr16">
                                                <strong>
                                                    1010 :
                                                </strong>
                                            </Col>
                                            <Col flex="auto">
                                                <NumericFormat
                                                    value={financialInfo?.outstanding_1010 ?? 0}
                                                    thousandSeparator={"."}
                                                    decimalSeparator={","}
                                                    displayType="text"
                                                />
                                            </Col>
                                        </Row>
                                        <Row
                                            wrap={false}
                                            className="mb18"
                                            justify="center"
                                            align="middle"
                                        >
                                            <Col flex="none" className="mr16">
                                                <strong>
                                                    1220 :
                                                </strong>
                                            </Col>
                                            <Col flex="auto">
                                                <NumericFormat
                                                    value={financialInfo?.outstanding_1220 ?? 0}
                                                    thousandSeparator={"."}
                                                    decimalSeparator={","}
                                                    displayType="text"
                                                />
                                            </Col>
                                        </Row>
                                    </div>

                                    <div>
                                        <div className="mb8">
                                            <WuiFormTitle title="Nota Kredit" />
                                        </div>
                                        <Row
                                            wrap={false}
                                            className="mb18"
                                            justify="start"
                                            align="middle"
                                        >
                                            <Col flex="none" className="mr16">
                                                <strong>
                                                    1010 :
                                                </strong>
                                            </Col>
                                            <Col flex="auto">
                                                <NumericFormat
                                                    value={financialInfo?.credit_note_1010 ?? 0}
                                                    thousandSeparator={"."}
                                                    decimalSeparator={","}
                                                    displayType="text"
                                                />
                                            </Col>
                                        </Row>
                                        <Row
                                            wrap={false}
                                            className="mb18"
                                            justify="center"
                                            align="middle"
                                        >
                                            <Col flex="none" className="mr16">
                                                <strong>
                                                    1220 :
                                                </strong>
                                            </Col>
                                            <Col flex="auto">
                                                <NumericFormat
                                                    value={financialInfo?.credit_note_1220 ?? 0}
                                                    thousandSeparator={"."}
                                                    decimalSeparator={","}
                                                    displayType="text"
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </div>

                                <TableGAP channel={'b2b'} dealerId={dealerId} salesId={id} type="sales" />
                            </Space>
                        </Card>

                        <br />

                        <div className="input-section">
                            <DatePicker
                                onChange={(value) => {
                                    if (value) {
                                        setVisitDate(value)
                                    }
                                }}
                                value={visitDate}
                                style={{ width: '250px', maxWidth: '100%' }}
                            />
                            {isLoading && 
                                <Text>&nbsp;&nbsp;&nbsp;&nbsp;Memuat...</Text>
                            }
                        </div>

                        <br />

                        <Card title="Foto">
                            <Space style={{ width: '100%' }} direction="vertical" size={25}>
                                <div className="wui-form-group">
                                    <div className="input-section">
                                        {!disable.get() && photos.length > 0 &&
                                            <WuiUploadImages disabled={true} value={photos}/>
                                        }
                                        {!disable.get() && photos.length === 0 &&
                                            <Text>Tidak ada foto</Text>
                                        }
                                    </div>
                                </div>
                            </Space>
                        </Card>

                        <br />

                        <Card title="Produk" bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}>
                            <Table columns={productColumns} dataSource={products} pagination={false} rowKey="id"></Table>
                        </Card>
                    </Col>
                </Row>

                <div className="wui-form-btn-group">
                    <Button className="wui-btn" size={"large"} onClick={() => navigate(-1)}>{t('common.button.back')}</Button>
                </div>
            </WuiContainer>

            <Modal
                title={`IMEI ${tempActiveProduct.get().name}`}
                centered
                onCancel={() => { showProductModal.set(false) }}
                visible={showProductModal.get()}
                footer={[
                    <Button key="back" onClick={() => { showProductModal.set(false) }}>
                        {t('common.button.back')}
                    </Button>,
                ]}
            >
                <Space direction="vertical" size={20}>
                    {
                        (tempActiveProduct.get().imei_list || []).map((item: any, index: number) => (
                            <div key={item}>
                                <h4 >Produk {index + 1}</h4>
                                <Text>{item}</Text>
                            </div>
                        ))
                    }
                </Space>
            </Modal>
        </>
    )
}

export default AppSettingsAccountSalesDealerForm
