import { useState } from "@hookstate/core";
import { Spin, Typography } from "antd";
import Button from "antd/es/button";
import Card from "antd/es/card";
import Input from "antd/es/input";
import PageHeader from "antd/es/page-header";
import Space from "antd/es/space";
import Form from "antd/lib/form";
import { AxiosError, AxiosResponse } from "axios";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import useAccess from "../../../../../../@framework/utilities/hooks/useAccess";
import Accessible from "../../../../../../@framework/wui/components/Accessible";
import WuiContainer from "../../../../../../@framework/wui/components/Container";
import WuiSelectMulti from "../../../../../../@framework/wui/components/Select/Multi";
import WuiSelectSingle from "../../../../../../@framework/wui/components/Select/Single";
import {
  handleBackendError,
  haveAccess,
  inputUppercase,
  openNotification,
  pluckArrayObject,
} from "../../../../../../functions/global";
import AccessRoleRepository from "../../../../../../repositories/AccessRoleRepository";
import BrandRepository from "../../../../../../repositories/BrandRepository";
import SuperiorRepository from "../../../../../../repositories/SuperiorRepository";
import UserRepository from "../../../../../../repositories/UserRepository";
import { permissions } from "../../../../../../constant/permissions";
import Checkbox from "antd/es/checkbox";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import Row from "antd/es/grid/row";
import Col from "antd/es/grid/col";
import ClusterRepository from "../../../../../../repositories/ClusterRepository";
import AreaRepository from "../../../../../../repositories/AreaRepository";
import _ from "lodash";
import { CheckOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  AREA_HIERARCHY_AREA,
  AREA_HIERARCHY_CLUSTER,
  AREA_HIERARCHY_REGION,
  AREA_HIERARCHY_SUBTERRITORY,
  AREA_HIERARCHY_TERRITORY,
} from "../../../../../../constant";
import SubTerritoryRepository from "../../../../../../repositories/SubTerritoryRepository";
import TerritoryRepository from "../../../../../../repositories/TerritoryRepository";
import RegionRepository from "../../../../../../repositories/RegionRepository";

let title = "Pengguna";

const { Title } = Typography;

interface IValidateEmail {
  isValid: boolean;
  errorMessage: any;
  loading: boolean;
}

const AppSettingsAccountUserForm: React.FC<any> = () => {
  const validateEmailRef = useRef<any>(null);
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const pageTitle = id
    ? t("common.text.editItem", { item: title })
    : t("common.text.addNewItem", { item: title });
  const acl = useAccess();

  // Form
  const [form] = Form.useForm();
  const disable = useState(false);
  const loading = useState(false);
  const email = Form.useWatch("email", form);
  const validateEmail = useState<IValidateEmail>({
    isValid: false,
    errorMessage: null,
    loading: false,
  });

  const [hierarchyB2b, setHierarchyB2b] = React.useState("");
  const [hierarchyB2c, setHierarchyB2c] = React.useState("");
  const [areaRenderB2b, setAreaRenderB2b] = React.useState(true);
  const [areaRenderB2c, setAreaRenderB2c] = React.useState(true);

  const channels = useState<any[]>([]);
  const channelOptions = [
    { label: "B2B", value: "B2B" },
    { label: "B2C", value: "B2C" },
    { label: "Online", value: "Online" },
  ];

  const channelOnChange = (checkedValues: CheckboxValueType[]) => {
    channels.set(checkedValues);
  };

  const onFinish = async (data: any) => {
    if (validateEmail.isValid.get()) {
      loading.set(true);

      let payload: any = {
        code: data.code,
        username: data.code,
        unique_code: data.unique_code,
        name: data.name,
        email: data.email,
        mobile_phone: data.mobile_phone,
        user_superiors: [],
      };

      if (data.b2b) {
        let additional: any = {};

        if (hierarchyB2b) {
          let key = hierarchyB2b.toLowerCase();
          additional[`${key}_id`] = data.b2b[key].value;
        }

        payload.user_superiors.push({
          channel: "B2B",
          superior_id: data.b2b.superior.value,
          access_role_id: data.b2b.accessRole.value,
          brand_ids: (data.b2b.brands || []).map((item: any) => item.id),
          ...additional,
        });
      }

      if (data.b2c) {
        let additional: any = {};

        if (hierarchyB2c) {
          let key = hierarchyB2c.toLowerCase();
          additional[`${key}_id`] = data.b2c[key].value;
        }

        payload.user_superiors.push({
          channel: "B2C",
          superior_id: data.b2c.superior.value,
          area_hierarchy: "Area",
          access_role_id: data.b2c.accessRole.value,
          ...additional,
        });
      }

      if (data.online) {
        payload.user_superiors.push({
          channel: "ONLINE",
          superior_id: data.online.superior.value,
          access_role_id: data.online.accessRole.value,
        });
      }

      await (!id
        ? UserRepository.create(payload)
        : UserRepository.update(id, payload)
      )
        .then((res: AxiosResponse) => {
          navigate(-1);
          if (!id) {
            openNotification(
              "success",
              t("notification.success.createItem", { item: title })
            );
          } else {
            openNotification(
              "success",
              t("notification.success.updateItem", { item: title })
            );
            loading.set(false);
          }
        })
        .catch((e: AxiosError) => {
          handleBackendError(e, t("notification.error.default"));
          loading.set(false);
        });
    }
  };

  const handleValidateEmail = () => {
    validateEmail.set((s) => {
      return {
        ...s,
        loading: true,
        isValid: false,
        errorMessage: null,
      };
    });

    if (validateEmailRef.current) {
      clearTimeout(validateEmailRef.current);
    }

    validateEmailRef.current = setTimeout(() => {
      UserRepository.validateEmail({ email: email })
        .then((res: AxiosResponse) => {
          const data = res.data?.data;

          form.setFieldsValue({
            code: data?.code,
            name: data?.name,
          });

          validateEmail.set((s) => {
            return {
              ...s,
              loading: false,
              isValid: true,
              errorMessage: null,
            };
          });
        })
        .catch((err: AxiosError) => {
          if (err.message !== "validateEmail canceled") {
            const message =
              err?.response?.data?.message || err?.response?.data?.email[0];
            if (message) {
              validateEmail.set((s) => {
                return {
                  ...s,
                  loading: false,
                  isValid: false,
                  errorMessage: message,
                };
              });
            } else {
              validateEmail.set((s) => {
                return {
                  ...s,
                  loading: false,
                  isValid: false,
                  errorMessage: "Gagal melakukan validasi email!",
                };
              });
            }
          }
        });
    }, 500);
  };

  useEffect(() => {
    if (email) {
      handleValidateEmail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    disable.set(true);

    await UserRepository.show(id, {
      with: [
        "userSuperiors.superior.channels",
        "userSuperiors.cluster",
        "userSuperiors.area",
        "userSuperiors.subterritory",
        "userSuperiors.territory",
        "userSuperiors.region",
        "userSuperiors.brands",
        "userSuperiors.accessRole",
      ],
    })
      .then((res: AxiosResponse) => {
        const data = res.data?.data || {};
        const dataChannels = _.map(data.user_superiors, "channel").map((item) =>
          item === "ONLINE" ? "Online" : item
        );

        channels.set(dataChannels);

        form.setFieldsValue({
          ...data,
          channel: dataChannels,
        });

        data.user_superiors.forEach((item: any) => {
          let itemChannel = "b2b";
          if (item.channel === "B2C") itemChannel = "b2c";
          if (item.channel === "ONLINE") itemChannel = "online";

          // let itemPayload = {}

          let superiorChannel: any = _.find(item?.superior?.channels, (v) => {
            return v.channel === item.channel;
          });

          if (superiorChannel) {
            if (item.channel === "B2B") {
              setHierarchyB2b(superiorChannel.area_hierarchy);
            } else if (item.channel === "B2C") {
              setHierarchyB2c(superiorChannel.area_hierarchy);
            }
          }

          if (item?.superior) {
            form.setFieldValue([itemChannel, "superior"], {
              value: item?.superior?.id,
              label: item?.superior?.name,
            });
          }
          if (item?.cluster) {
            form.setFieldValue([itemChannel, "cluster"], {
              value: item?.cluster?.id,
              label: item?.cluster?.name,
            });
          }
          if (item?.area) {
            form.setFieldValue([itemChannel, "area"], {
              value: item?.area?.id,
              label: item?.area?.name,
            });
          }
          if (item?.subterritory) {
            form.setFieldValue([itemChannel, "subterritory"], {
              value: item?.subterritory?.id,
              label: item?.subterritory?.name,
            });
          }
          if (item?.territory) {
            form.setFieldValue([itemChannel, "territory"], {
              value: item?.territory?.id,
              label: item?.territory?.name,
            });
          }
          if (item?.region) {
            form.setFieldValue([itemChannel, "region"], {
              value: item?.region?.id,
              label: item?.region?.name,
            });
          }
          if (item?.access_role) {
            form.setFieldValue([itemChannel, "accessRole"], {
              value: item?.access_role?.id,
              label: item?.access_role?.name,
            });
          }
          if (item?.brands) {
            let brands = pluckArrayObject(item?.brands, ["id", "name"]);
            form.setFieldValue([itemChannel, "brands"], brands);
          }
        });

        if (
          haveAccess(acl, [
            permissions.write_user_b2b,
            permissions.write_user_b2c,
          ])
        ) {
          disable.set(false);
        }
      })
      .catch((e: AxiosError) => {});
  };

  const setAreaHierarchySuperior = (
    superiorId: number | string,
    channel: string = "b2b"
  ) => {
    SuperiorRepository.show(superiorId, {
      with: ["channels"],
    })
      .then((res: AxiosResponse) => {
        let resp = res.data?.data;

        const superiorChannel: any = _.find(resp?.channels, (v) => {
          return v.channel === channel.toUpperCase();
        });

        if (superiorChannel) {
          if (channel === "b2b") {
            setHierarchyB2b(superiorChannel?.area_hierarchy || "");
          } else {
            setHierarchyB2c(superiorChannel?.area_hierarchy || "");
          }
        }
      })
      .catch((e: AxiosError) => {});
  };

  return (
    <>
      <WuiContainer>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
        />

        <Form
          form={form}
          initialValues={{ channel: [] }}
          layout={"vertical"}
          onFinish={onFinish}
          onValuesChange={(changedValues) => {
            if (changedValues?.b2b?.superior) {
              form.setFieldValue(["b2b", "cluster"], null);
              form.setFieldValue(["b2b", "area"], null);
              form.setFieldValue(["b2b", "subterritory"], null);
              form.setFieldValue(["b2b", "territory"], null);
              form.setFieldValue(["b2b", "region"], null);
              setAreaHierarchySuperior(
                changedValues?.b2b?.superior.value,
                "b2b"
              );

              setAreaRenderB2b(false);
              setTimeout(() => setAreaRenderB2b(true), 200);
            }

            if (changedValues?.b2c?.superior) {
              form.setFieldValue(["b2c", "area"], null);
              form.setFieldValue(["b2c", "territory"], null);
              form.setFieldValue(["b2c", "region"], null);
              setAreaHierarchySuperior(
                changedValues?.b2c?.superior.value,
                "b2c"
              );

              setAreaRenderB2c(false);
              setTimeout(() => setAreaRenderB2c(true), 200);
            }
          }}
        >
          <Row>
            <Col md={16} xs={24}>
              <Card>
                <Space
                  style={{
                    width: "100%",
                  }}
                  direction="vertical"
                  size={5}
                >
                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item name="unique_code" label={"Unique Code"}>
                        <Input
                          disabled={disable.get()}
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Unique Code",
                          })}
                        />
                      </Form.Item>
                    </div>

                    <div className="input-section">
                      <Form.Item name="code" label={"NIK"}>
                        <Input
                          disabled
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "NIK",
                          })}
                          onInput={inputUppercase.formatter}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item name="name" label={"Nama"}>
                        <Input
                          disabled
                          size={"large"}
                          placeholder={t("common.text.input", { item: "Nama" })}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="email"
                        label={"Email"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Email",
                            }),
                          },
                          {
                            type: "email",
                            message: t("validation.type.email", {
                              item: "Email",
                            }),
                          },
                        ]}
                        validateStatus={
                          validateEmail.errorMessage.get() ? "error" : ""
                        }
                        help={validateEmail.errorMessage.get()}
                      >
                        <Input
                          disabled={disable.get()}
                          size={"large"}
                          style={{
                            borderColor:
                              validateEmail.isValid.get() && !!email
                                ? "#52C41A"
                                : "",
                          }}
                          placeholder={t("common.text.input", {
                            item: "Email",
                          })}
                          status={
                            validateEmail.errorMessage.get() ? "error" : ""
                          }
                          suffix={
                            validateEmail.loading.get() ? (
                              <Spin
                                indicator={
                                  <LoadingOutlined
                                    style={{ fontSize: 16 }}
                                    spin
                                  />
                                }
                              />
                            ) : validateEmail.isValid.get() && !!email ? (
                              <CheckOutlined style={{ color: "#52C41A" }} />
                            ) : (
                              <div />
                            )
                          }
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="mobile_phone"
                        label={"Nomor Handphone"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Nomor Handphone",
                            }),
                          },
                          {
                            pattern: new RegExp("^[+][0-9]*$"),
                            message:
                              "Nomor handphone tidak valid. Contoh: +628131234567",
                          },
                          {
                            min: 11,
                            message: t("validation.type.min", {
                              item: "Nomor Handphone",
                              min: 11,
                            }),
                          },
                          {
                            max: 14,
                            message: t("validation.type.max", {
                              item: "Nomor Handphone",
                              max: 14,
                            }),
                          },
                        ]}
                      >
                        <Input
                          disabled={disable.get()}
                          size={"large"}
                          placeholder={t("common.text.input", {
                            item: "Nomor Handphone",
                          })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="channel"
                        label={"Channel"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Channel",
                            }),
                          },
                        ]}
                      >
                        <Checkbox.Group
                          options={channelOptions}
                          onChange={channelOnChange}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  {(channels.get() || []).includes("B2B") ? (
                    <>
                      <Title level={5}>B2B</Title>

                      <div className="wui-form-group type-column">
                        <div className="input-section">
                          <Form.Item
                            name={["b2b", "superior"]}
                            label={"Peran"}
                            rules={[
                              {
                                required: true,
                                message: t("validation.required", {
                                  item: "Peran",
                                }),
                              },
                            ]}
                          >
                            <WuiSelectSingle
                              disabled={disable.get()}
                              repository={SuperiorRepository}
                              selectParams={{
                                channel: "B2B",
                              }}
                            />
                          </Form.Item>
                        </div>
                      </div>

                      {areaRenderB2b &&
                        hierarchyB2b === AREA_HIERARCHY_CLUSTER && (
                          <div className="wui-form-group type-column">
                            <div className="input-section">
                              <Form.Item
                                name={["b2b", "cluster"]}
                                label={"Cluster"}
                                rules={[
                                  {
                                    required: true,
                                    message: t("validation.required", {
                                      item: "Cluster",
                                    }),
                                  },
                                ]}
                              >
                                <WuiSelectSingle
                                  disabled={disable.get()}
                                  repository={ClusterRepository}
                                  showOptionDescription
                                />
                              </Form.Item>
                            </div>
                          </div>
                        )}

                      {areaRenderB2b &&
                        hierarchyB2b === AREA_HIERARCHY_AREA && (
                          <div className="wui-form-group type-column">
                            <div className="input-section">
                              <Form.Item
                                name={["b2b", "area"]}
                                label={"Area"}
                                rules={[
                                  {
                                    required: true,
                                    message: t("validation.required", {
                                      item: "Area",
                                    }),
                                  },
                                ]}
                              >
                                <WuiSelectSingle
                                  disabled={disable.get()}
                                  repository={AreaRepository}
                                  selectParams={{
                                    channel: "b2b",
                                  }}
                                  showOptionDescription
                                />
                              </Form.Item>
                            </div>
                          </div>
                        )}

                      {areaRenderB2b &&
                        hierarchyB2b === AREA_HIERARCHY_SUBTERRITORY && (
                          <div className="wui-form-group type-column">
                            <div className="input-section">
                              <Form.Item
                                name={["b2b", "subterritory"]}
                                label={"Subterritory"}
                                rules={[
                                  {
                                    required: true,
                                    message: t("validation.required", {
                                      item: "Subterritory",
                                    }),
                                  },
                                ]}
                              >
                                <WuiSelectSingle
                                  disabled={disable.get()}
                                  repository={SubTerritoryRepository}
                                  showOptionDescription
                                />
                              </Form.Item>
                            </div>
                          </div>
                        )}

                      {areaRenderB2b &&
                        hierarchyB2b === AREA_HIERARCHY_TERRITORY && (
                          <div className="wui-form-group type-column">
                            <div className="input-section">
                              <Form.Item
                                name={["b2b", "territory"]}
                                label={"Territory"}
                                rules={[
                                  {
                                    required: true,
                                    message: t("validation.required", {
                                      item: "Territory",
                                    }),
                                  },
                                ]}
                              >
                                <WuiSelectSingle
                                  disabled={disable.get()}
                                  repository={TerritoryRepository}
                                  selectParams={{
                                    channel: "b2b",
                                  }}
                                  showOptionDescription
                                />
                              </Form.Item>
                            </div>
                          </div>
                        )}

                      {areaRenderB2b &&
                        hierarchyB2b === AREA_HIERARCHY_REGION && (
                          <div className="wui-form-group type-column">
                            <div className="input-section">
                              <Form.Item
                                name={["b2b", "region"]}
                                label={"Region"}
                                rules={[
                                  {
                                    required: true,
                                    message: t("validation.required", {
                                      item: "Region",
                                    }),
                                  },
                                ]}
                              >
                                <WuiSelectSingle
                                  disabled={disable.get()}
                                  repository={RegionRepository}
                                  selectParams={{
                                    channel: "b2b",
                                  }}
                                  showOptionDescription
                                />
                              </Form.Item>
                            </div>
                          </div>
                        )}

                      <div className="wui-form-group type-column">
                        <div className="input-section">
                          <Form.Item
                            name={["b2b", "accessRole"]}
                            label={"Hak Akses"}
                            rules={[
                              {
                                required: true,
                                message: t("validation.required", {
                                  item: "Hak Akses",
                                }),
                              },
                            ]}
                          >
                            <WuiSelectSingle
                              disabled={disable.get()}
                              repository={AccessRoleRepository}
                            />
                          </Form.Item>
                        </div>
                      </div>

                      <div className="wui-form-group type-column">
                        <div className="input-section">
                          <Form.Item
                            name={["b2b", "brands"]}
                            label={"Brand"}
                            rules={[
                              {
                                required: true,
                                message: t("validation.required", {
                                  item: "Brand",
                                }),
                              },
                            ]}
                          >
                            <WuiSelectMulti
                              disabled={disable.get()}
                              onChange={(value) => {
                                form.setFields([
                                  { name: "brands", value: value },
                                ]);
                              }}
                              repository={BrandRepository}
                              selectParams={{
                                selectAll: true,
                                selectAllLabel: "Semua Brand",
                              }}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {(channels.get() || []).includes("B2C") ? (
                    <>
                      <Title level={5}>B2C</Title>

                      <div className="wui-form-group type-column">
                        <div className="input-section">
                          <Form.Item
                            name={["b2c", "superior"]}
                            label={"Peran"}
                            rules={[
                              {
                                required: true,
                                message: t("validation.required", {
                                  item: "Peran",
                                }),
                              },
                            ]}
                          >
                            <WuiSelectSingle
                              disabled={disable.get()}
                              repository={SuperiorRepository}
                              selectParams={{
                                channel: "B2C",
                              }}
                            />
                          </Form.Item>
                        </div>
                      </div>

                      {areaRenderB2c &&
                        hierarchyB2c === AREA_HIERARCHY_AREA && (
                          <div className="wui-form-group type-column">
                            <div className="input-section">
                              <Form.Item
                                name={["b2c", "area"]}
                                label={"Area"}
                                rules={[
                                  {
                                    required: true,
                                    message: t("validation.required", {
                                      item: "Area",
                                    }),
                                  },
                                ]}
                              >
                                <WuiSelectSingle
                                  disabled={disable.get()}
                                  repository={AreaRepository}
                                  selectParams={{
                                    channel: "b2c",
                                  }}
                                  showOptionDescription
                                />
                              </Form.Item>
                            </div>
                          </div>
                        )}

                      {areaRenderB2c &&
                        hierarchyB2c === AREA_HIERARCHY_TERRITORY && (
                          <div className="wui-form-group type-column">
                            <div className="input-section">
                              <Form.Item
                                name={["b2c", "territory"]}
                                label={"Territory"}
                                rules={[
                                  {
                                    required: true,
                                    message: t("validation.required", {
                                      item: "Territory",
                                    }),
                                  },
                                ]}
                              >
                                <WuiSelectSingle
                                  disabled={disable.get()}
                                  repository={TerritoryRepository}
                                  selectParams={{
                                    channel: "b2c",
                                  }}
                                  showOptionDescription
                                />
                              </Form.Item>
                            </div>
                          </div>
                        )}

                      {areaRenderB2c &&
                        hierarchyB2c === AREA_HIERARCHY_REGION && (
                          <div className="wui-form-group type-column">
                            <div className="input-section">
                              <Form.Item
                                name={["b2c", "region"]}
                                label={"Region"}
                                rules={[
                                  {
                                    required: true,
                                    message: t("validation.required", {
                                      item: "Region",
                                    }),
                                  },
                                ]}
                              >
                                <WuiSelectSingle
                                  disabled={disable.get()}
                                  repository={RegionRepository}
                                  selectParams={{
                                    channel: "b2c",
                                  }}
                                  showOptionDescription
                                />
                              </Form.Item>
                            </div>
                          </div>
                        )}

                      <div className="wui-form-group type-column">
                        <div className="input-section">
                          <Form.Item
                            name={["b2c", "accessRole"]}
                            label={"Hak Akses"}
                            rules={[
                              {
                                required: true,
                                message: t("validation.required", {
                                  item: "Hak Akses",
                                }),
                              },
                            ]}
                          >
                            <WuiSelectSingle
                              disabled={disable.get()}
                              repository={AccessRoleRepository}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {(channels.get() || []).includes("Online") ? (
                    <>
                      <Title level={5}>Online</Title>

                      <div className="wui-form-group type-column">
                        <div className="input-section">
                          <Form.Item
                            name={["online", "superior"]}
                            label={"Peran"}
                            rules={[
                              {
                                required: true,
                                message: t("validation.required", {
                                  item: "Peran",
                                }),
                              },
                            ]}
                          >
                            <WuiSelectSingle
                              disabled={disable.get()}
                              repository={SuperiorRepository}
                              selectParams={{
                                channel: "ONLINE",
                              }}
                            />
                          </Form.Item>
                        </div>
                      </div>

                      <div className="wui-form-group type-column">
                        <div className="input-section">
                          <Form.Item
                            name={["online", "accessRole"]}
                            label={"Hak Akses"}
                            rules={[
                              {
                                required: true,
                                message: t("validation.required", {
                                  item: "Hak Akses",
                                }),
                              },
                            ]}
                          >
                            <WuiSelectSingle
                              disabled={disable.get()}
                              repository={AccessRoleRepository}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </Space>
              </Card>
            </Col>
          </Row>

          <div className="wui-form-btn-group">
            <Button
              className="wui-btn"
              size={"large"}
              onClick={() => navigate(-1)}
            >
              {t("common.button.cancel")}
            </Button>
            <Accessible
              access={[permissions.write_user_b2b, permissions.write_user_b2c]}
            >
              <Spin spinning={loading.get()}>
                <Button
                  className="wui-btn"
                  htmlType="submit"
                  type="primary"
                  size={"large"}
                  disabled={disable.get()}
                >
                  {t("common.button.save")}
                </Button>
              </Spin>
            </Accessible>
          </div>
        </Form>
      </WuiContainer>
    </>
  );
};

export default AppSettingsAccountUserForm;
