import React, {useEffect, useState} from "react";
import Menu, {MenuProps} from "antd/es/menu";
import {useLocation, useNavigate} from "react-router-dom";
import useAccess from "../../../utilities/hooks/useAccess";
import { haveAccess } from "../../../../functions/global";
import { Tooltip } from "antd";

const { SubMenu } = Menu;

declare type MenuMode = 'horizontal' | 'vertical' | 'inline';

interface Props {
    menus: any[]
    mode?: MenuMode;
    onChange?: () => void;
}

const WuiMainMenu: React.FC<Props> = ({
    menus,
    mode = "horizontal",
    onChange
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [rootSubmenuKeys, setRootSubmenuKeys] = useState(['']);
    const [currentMenu, setCurrentMenu] = useState('');
    const [openKeys, setOpenKeys] = React.useState(['']);
    const acl = useAccess();

    
    useEffect(() => {
        const tempSubMenuKeys = menus.map((menu) => menu.key);
        let pathName = location?.pathname;
        const lastChart = pathName.charAt(pathName.length - 1);
        if (lastChart === "/") {
            pathName = pathName.substring(0, pathName.length - 1);
        }
    
        let selectedMenuLink = findLinkMenu(menus, pathName);
    
        setRootSubmenuKeys(tempSubMenuKeys);
        setCurrentMenu(selectedMenuLink || pathName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menus, location]);

    const findLinkMenu = (allMenu: any[], currentPath: string): string => {
        let selectedMenu = "";
        allMenu.forEach((menu) => {
            if (currentPath.includes(menu.link)) {
            selectedMenu = menu.link;
            }
            if (menu.subMenus) {
            const link = findLinkMenu(menu.subMenus, currentPath);
            if (link) {
                selectedMenu = link;
            }
        }
        });
    
        return selectedMenu;
    };
    


    const menuHandleClick = (e: any) => {
        setCurrentMenu(e.key)

        navigate(e.key)

        if (onChange) {
            onChange()
        }
    };

    const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    const isAccessFromMobileApp = () => {
        return localStorage.getItem('is_mobile_app')
    }

    const getSubmenuAccess = (data: any[]) => {
        let show = false;
        data.forEach((item) => {
            let showSubmenu = false
            if(item.subMenus){
                item.subMenus.forEach((it: any) => {
                    if(haveAccess(acl, it.access) && !showSubmenu && (!isAccessFromMobileApp() ? true : it.showOnMobileApp)){
                        showSubmenu = true
                    }
                })
            } else {
                if(haveAccess(acl, item.access) && !show && (!isAccessFromMobileApp() ? true : item.showOnMobileApp)){
                    show = true
                }
            }

            if(showSubmenu){
                show = true
            }
        })

        return show
    }

    const renderSubMenu = (sub: any, parentUrl: any) => {
        const url = `${parentUrl}/${sub.key}`
        if (sub?.subMenus) {
            return (
                getSubmenuAccess(sub.subMenus) ?
                <SubMenu key={url} title={sub.title}>
                    {
                        sub.subMenus.map((subSub: any) => {
                            return renderSubMenu(subSub, url)
                        })
                    }
                </SubMenu> : null
            )
        } else {
            return (
                haveAccess(acl, sub.access) && (!isAccessFromMobileApp() ? true : sub.showOnMobileApp) ?
                    <Menu.Item className="dropdown-main-menu-item" key={url}>
                        <Tooltip placement="right" title={sub.title}>
                            {sub.title}
                        </Tooltip>
                    </Menu.Item>
                : null
            )
        } 
    }

    return (
        <Menu
            mode={mode}
            onClick={menuHandleClick}
            selectedKeys={[currentMenu]}
            onOpenChange={onOpenChange}
            openKeys={openKeys}
            className={'main-menu-component'}
        >
            {
                menus.map((item) => {
                    if (item?.subMenus) {
                        return (
                            (
                                getSubmenuAccess(item.subMenus) ? 
                                <SubMenu key={`/${item.key}`} title={item.title} icon={item.icon} popupClassName="dropdown-main-menu">
                                {
                                    item.subMenus.map((sub: any) => {
                                        const parentUrl = `/${item.key}`
                                        return renderSubMenu(sub, parentUrl)
                                    })
                                }
                                </SubMenu> 
                                : null
                            )
                            
                        )
                    } else {
                        return (
                            haveAccess(acl, item.access) && (!isAccessFromMobileApp() ? true : item.showOnMobileApp) ? 
                            <Menu.Item key={`/${item.key}`} icon={item.icon}>
                                {item.title}
                            </Menu.Item> : null
                        )
                    }
                })
            }
        </Menu>
    )
}

export default WuiMainMenu
