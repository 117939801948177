import {useTranslation} from "react-i18next";
import WuiSectionTitle from "../../../../../@framework/wui/components/Sections/Title";
import Button from "antd/es/button";
import {FileExcelOutlined, PlusOutlined} from "@ant-design/icons";
import React, { useEffect } from "react";
import WuiContainer from "../../../../../@framework/wui/components/Container";
import {useNavigate, useSearchParams} from "react-router-dom";
import Tabs from "antd/lib/tabs";
import TabPlan from "./Tabs/Plan";
import {useState} from "@hookstate/core";
import TabPermintaan from "./Tabs/Permintaan";
import Accessible from "../../../../../@framework/wui/components/Accessible";
import JourneyPlanRepository from "../../../../../repositories/JourneyPlanRepository";
import { haveAccess } from "../../../../../functions/global";
import useAccess from "../../../../../@framework/utilities/hooks/useAccess";
import { permissions } from "../../../../../constant/permissions";
var fileDownload = require('js-file-download');

const { TabPane } = Tabs;

const breadcrumbs = [
    {
        label: 'Journey Plan B2B',
        link: '/journey-plan'
    },
]

const AppJourneyPlanList: React.FC<any> = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    let fullPath = window.location.pathname;
    const [searchParams] = useSearchParams();

    const tabKey = useState(searchParams.get('tab') || 'Plan')

    const handleExportPlan = async () => {
        let params = {
            ...planParams.get(),
            export: 1
        }
        try{
            let res = await JourneyPlanRepository.export('b2b', params)
            await fileDownload(res.data, `${Date.now()} - Journey Plan .xlsx`)
        } catch(err){
        }
    }

    useEffect(() => {
        if(!haveAccess(acl, permissions.read_journey_plan_b2b)){
            tabKey.set('Permintaan')
        }
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    const planParams = useState({})
    const acl = useAccess()

    return (
        <>
            <WuiContainer>
                <WuiSectionTitle title={'Journey Plan B2B'} breadcrumbs={breadcrumbs}>
                    
                    {
                        tabKey.get() === 'Plan' ? (
                            <>
                                <Button icon={<FileExcelOutlined />} onClick={handleExportPlan}>Export Jadwal</Button>
                                <Accessible access={permissions.write_journey_plan_b2b}>
                                    <Button onClick={() => navigate(fullPath+'/form')} type="primary" icon={<PlusOutlined />}>{t('common.button.addNew')}</Button>
                                </Accessible>
                            </>
                            
                        ) : ''
                    }
                </WuiSectionTitle>

                <Tabs defaultActiveKey={tabKey.get()} onChange={(key) => {
                    tabKey.set(key)
                }}>
                    {
                        haveAccess(acl, permissions.read_journey_plan_b2b) &&
                        <TabPane tab="Plan" key="Plan">
                            <TabPlan active={tabKey.get() === 'Plan'} setParams={planParams.set}/>
                        </TabPane>
                    }
                   
                    {
                        haveAccess(acl, permissions.read_request_journey_plan_b2b) && 
                        <TabPane tab="Permintaan" key="Permintaan">
                            <TabPermintaan active={tabKey.get() === 'Permintaan'}/>
                        </TabPane>
                    }
                    
                </Tabs>


            </WuiContainer>
        </>
    )
}

export default AppJourneyPlanList