import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import WuiContainer from "../../../../@framework/wui/components/Container";
import WuiSectionTitle from "../../../../@framework/wui/components/Sections/Title";
import { $clone } from "../../../../@framework/utilities";
import AppDefaultTableau from "../../../../components/Tableau";
import moment from "moment";

var qs = require("qs");

const breadcrumbs = [
  {
    label: "Dashboard Head Quarter",
    link: "/dashboard/head-quarter",
  },
];

const AppDashboardHeadQuarter: React.FC<any> = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const setFilterDataToQuery = (isReset: boolean = false) => {
    let params = {};

    const queryParams = qs.stringify(params, { indices: false });

    if (queryParams) {
      setSearchParams(`?${queryParams}`);
    }

    getDataDashboard();
  };

  const getDataDashboard = async () => {};

  useEffect(() => {
    setFilterDataToQuery();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <WuiContainer>
        <WuiSectionTitle
          title={"Dashboard Head Quarter"}
          breadcrumbs={breadcrumbs}
        />

        <AppDefaultTableau
          name={"ErafosDashboard"}
          tab={"DailySalesReportEDMobile"}
          filters={[
            `YEAR(Zcal Day2)=${moment().year()}`,
            `MONTH(Zcal Day2)=${moment().format("M")}`,
          ]}
        />
      </WuiContainer>
    </>
  );
};

export default AppDashboardHeadQuarter;
