import React, { useEffect } from "react";
import WuiContainer from "../../../../@framework/wui/components/Container";
import PageHeader from "antd/es/page-header";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Card from "antd/es/card";
import Space from "antd/es/space";
import Input from "antd/es/input";
import Button from "antd/es/button";
import DatePicker from "antd/es/date-picker";
import moment from "moment";
import Form from "antd/lib/form";
import { Checkbox, Divider, List, Radio, Row, Spin } from "antd";
import { useState } from "@hookstate/core";
import { handleBackendError, haveAccess, openNotification } from "../../../../functions/global";
import { AxiosError, AxiosResponse } from "axios";
import SurveyRepository from "../../../../repositories/SurveyRepository";
import DealerRepository from "../../../../repositories/DealerRepository";
import WuiSelectMulti from "../../../../@framework/wui/components/Select/Multi";
import Select from "antd/es/select";
import Col from "antd/es/grid/col";
import { CloseOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { AREA_HIERARCHY_AREA, AREA_HIERARCHY_CLUSTER, AREA_HIERARCHY_OPTIONS, AREA_HIERARCHY_REGION, AREA_HIERARCHY_SUBTERRITORY, AREA_HIERARCHY_TERRITORY, SURVEY_TYPE_CHECK_BOX, SURVEY_TYPE_DROPDOWN, SURVEY_TYPE_MULTIPLE_CHOICE, SURVEY_TYPE_OPTIONS, SURVEY_TYPE_OPTION_LABELS, SURVEY_TYPE_SHORT_ANSWER } from "../../../../constant";
import { $clone } from "../../../../@framework/utilities";
import Text from "antd/es/typography/Text";
import Modal from "antd/lib/modal/Modal";
import Accessible from "../../../../@framework/wui/components/Accessible";
import useAccess from "../../../../@framework/utilities/hooks/useAccess";
import ClusterRepository from "../../../../repositories/ClusterRepository";
import AreaRepository from "../../../../repositories/AreaRepository";
import SubTerritoryRepository from "../../../../repositories/SubTerritoryRepository";
import TerritoryRepository from "../../../../repositories/TerritoryRepository";
import RegionRepository from "../../../../repositories/RegionRepository";
import { permissions } from "../../../../constant/permissions";

var fileDownload = require('js-file-download');
const { Option } = Select

let title = "Survei";

const AppSurveyForm: React.FC<any> = () => {
    const { t } = useTranslation()
    const { id } = useParams();
    const navigate = useNavigate();
    const pageTitle = id ? t('common.text.editItem', { item: title }) : t('common.text.addNewItem', { item: title })

    // Form
    const [form] = Form.useForm();
    const disable = useState(false);
    const loading = useState(false);

    const surveyFormList: any = useState([])
    const surveyFormAnswers: any = useState({})

    const tempActiveDealer: any = useState({})
    const tempDealers = useState([])
    const showDealerModal = useState(false)
    const tempQuestions = useState([])
    const acl = useAccess()
    const [renderSelectDealer, setRenderDealer] = React.useState(true)


    const onFinish = async (data: any) => {
        loading.set(true)

        let surveyQuestions = $clone(surveyFormList.get())
        let surveyAnswers = $clone(surveyFormAnswers.get())
        let payload: any = {
            "name": data.name,
            "from": `${moment(data.from).set({ h: 0, m: 0 }).format('YYYY-MM-DD[T]HH:mm:ssZ')}`,
            "to": `${moment(data.to).set({ h: 0, m: 0 }).format('YYYY-MM-DD[T]HH:mm:ssZ')}`,
            "dealer_ids": (data.dealers || []).map((item: any) => item.id),
            "survey_questions": surveyQuestions.map((item: any) => {
                return {
                    ...item,
                    answers: item.type === SURVEY_TYPE_SHORT_ANSWER ? [] : surveyAnswers[item.tempId],
                    tempId: null
                }
            }),
            'area_hierarchy_level': data.hierarchy,
            'area_hierarchy_ids': getArrayValue(data.hierarchy.toLowerCase()),
        }

        await (!id ? (SurveyRepository.create(payload)) : SurveyRepository.update(id, payload)).then((res: AxiosResponse) => {
            navigate(-1)

            if (!id) {
                openNotification('success', t('notification.success.createItem', { item: title }))
            } else {
                openNotification('success', t('notification.success.updateItem', { item: title }))
                loading.set(false);
            }
        }).catch((e: AxiosError) => {
            handleBackendError(e, t('notification.error.default'))
            loading.set(false);
        })
    }

    useEffect(() => {
        if (id) {
            getData();
        } else {
            initForm()
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getData = async () => {
        disable.set(true)

        await SurveyRepository.show(id, { with: ['dealers', 'surveyQuestions', 'reportConsultantSurveys.reportConsultant.dealer', 'reportConsultantSurveys.reportConsultantSurveyAnswers', 'surveyAreaHierarchy.areaHierarchy'] }).then((res: AxiosResponse) => {
            const data = res.data?.data || {}

            let modified: any = {
                from: moment(data.from),
                to: moment(data.to),
                hierarchy: data.area_hierarchy_level,
                dealers: (data.dealers || []).map((item: any) => (
                    {
                        name: item.name,
                        id: item.id
                    }
                )),
            }

            if(data.survey_area_hierarchy && modified.hierarchy){
                modified[modified.hierarchy.toLowerCase()] = data.survey_area_hierarchy.map((item: any) => ({
                    id: item.area_hierarchy.id,
                    name: item.area_hierarchy.name,
                }))
            }

            form.setFieldsValue({
                ...data,
                ...modified
            })

            tempQuestions.set(data.survey_questions)
            tempDealers.set(data.report_consultant_surveys)
            setHierarchy(modified.hierarchy)

            initForm(data.survey_questions)
            
            if(haveAccess(acl, permissions.write_survey_b2b)){
                disable.set(false)
            }
        }).catch((e: AxiosError) => {

        })
    }

    const initForm = (data = []) => {
        if (data.length > 0) {
            let answers: any = {}
            const list = data.map((item: any) => {
                const tempId = item.id
                answers[tempId] = item.answers
                return {
                    ...item, tempId
                }
            })
            surveyFormList.set(list)
            surveyFormAnswers.set(answers)
        } else {
            const tempId = Date.now().toString()
            const list = [{ tempId, type: SURVEY_TYPE_SHORT_ANSWER }]
            surveyFormList.set(list)
        }

    }

    const handleAddForm = () => {
        const tempId = Date.now().toString()
        const oldList = $clone(surveyFormList.get())
        const list = [...oldList, { tempId, type: SURVEY_TYPE_SHORT_ANSWER }]
        surveyFormList.set(list)
    }

    const handleExport = async () => {
        try {
            let res: AxiosResponse = await SurveyRepository.export(id)

            await fileDownload(res.data, `Survei ${form.getFieldValue("name")}.xlsx`)
        } catch (err) {

        }
    }

    const [hierarchy, setHierarchy] = React.useState('')

    const getArrayValue = (name: string) => {
        try {
            let arr = form.getFieldValue(name) || []
            return arr.map((item: any) => item.id)
        } catch (err) {
            console.log(err)
            return []
        }
    }

    return (
        <>
            <WuiContainer>
                <PageHeader
                    className="default-page-header"
                    onBack={() => navigate(-1)}
                    title={pageTitle}
                />
                <Form
                    form={form}
                    layout={"vertical"}
                    onFinish={onFinish}
                    onValuesChange={(changedValues) => {
                        if (changedValues.cluster || changedValues.area || changedValues.subterritory || changedValues.territory || changedValues.region) {
                            form.setFieldsValue({
                                ...form.getFieldsValue(true),
                                dealers: []
                            })
                            setRenderDealer(false);
                            setTimeout(() => setRenderDealer(true), 200)
                        }
                    }}
                >

                    <Row gutter={[16, 16]}>
                        <Col className="gutter-row" md={{
                            span: 16,
                            order: 1
                        }} xs={{
                            span: 24,
                            order: 2
                        }}>

                            <Card>
                                <Space style={{
                                    width: '100%'
                                }} direction="vertical" size={5}>
                                    <div className="wui-form-group type-column">
                                        <div className="input-section">
                                            <Form.Item
                                                name="name"
                                                label={"Nama Survei"}
                                                rules={[{
                                                    required: true,
                                                    message: t('validation.required', { item: "Nama Survei" })
                                                }]}
                                            >
                                                <Input disabled={disable.get()} size={"large"} placeholder={t('common.text.input', { item: 'Nama Survei' })} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="wui-form-group type-column">
                                        <div className="input-section">
                                            <Form.Item
                                                name="hierarchy"
                                                label={"Hirarki"}
                                                rules={[{
                                                    required: true,
                                                    message: t('validation.required', { item: 'Level' })
                                                }]}
                                            >
                                                <Select onChange={(value) => {
                                                    setHierarchy(value)
                                                    form.setFieldsValue({
                                                        ...form.getFieldsValue(true),
                                                        cluster: null,
                                                        region: null,
                                                        area: null,
                                                        territory: null,
                                                        subterritory: null,
                                                    })

                                                }} disabled={disable.get()} size={"large"} style={{
                                                    width: '100%',
                                                }} placeholder="Pilih Hirarki">
                                                    {
                                                        AREA_HIERARCHY_OPTIONS.map((item: any) =>
                                                            <Option key={item} value={item}>{item}</Option>
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>

                                    {
                                        hierarchy === AREA_HIERARCHY_CLUSTER &&
                                        <div className="wui-form-group type-column">
                                            <div className="input-section">
                                                <Form.Item
                                                    name="cluster"
                                                    label="Cluster"
                                                    rules={[{
                                                        required: true,
                                                        message: t('validation.required', { item: 'Cluster' })
                                                    }]}
                                                >
                                                    <WuiSelectMulti disabled={disable.get()} repository={ClusterRepository} showOptionDescription selectParams={{ selectAll: true, selectAllLabel: 'Semua Cluster'}}/>
                                                </Form.Item>
                                            </div>
                                        </div>
                                    }

                                    {
                                        hierarchy === AREA_HIERARCHY_AREA &&
                                        <div className="wui-form-group type-column">
                                            <div className="input-section">
                                                <Form.Item
                                                    name="area"
                                                    label="Area"
                                                    rules={[{
                                                        required: true,
                                                        message: t('validation.required', { item: 'Area' })
                                                    }]}
                                                >
                                                    <WuiSelectMulti disabled={disable.get()} repository={AreaRepository} showOptionDescription selectParams={{ selectAll: true, selectAllLabel: 'Semua Area', channel: 'b2b'}}/>
                                                </Form.Item>
                                            </div>
                                        </div>
                                    }

                                    {
                                        hierarchy === AREA_HIERARCHY_REGION &&
                                        <div className="wui-form-group type-column">
                                            <div className="input-section">
                                                <Form.Item
                                                    name="region"
                                                    label="Region"
                                                    rules={[{
                                                        required: true,
                                                        message: t('validation.required', { item: 'Region' })
                                                    }]}
                                                >
                                                    <WuiSelectMulti disabled={disable.get()} repository={RegionRepository} selectParams={{ channel: 'b2b' }} />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    }

                                    {
                                        hierarchy === AREA_HIERARCHY_TERRITORY &&
                                        <div className="wui-form-group type-column">
                                            <div className="input-section">
                                                <Form.Item
                                                    name="territory"
                                                    label="Territory"
                                                    rules={[{
                                                        required: true,
                                                        message: t('validation.required', { item: 'Territory' })
                                                    }]}
                                                >
                                                    <WuiSelectMulti disabled={disable.get()} repository={TerritoryRepository} showOptionDescription selectParams={{ selectAll: true, selectAllLabel: 'Semua Territory', channel: 'b2b'}}/>
                                                </Form.Item>
                                            </div>
                                        </div>
                                    }

                                    {
                                        hierarchy === AREA_HIERARCHY_SUBTERRITORY &&
                                        <div className="wui-form-group type-column">
                                            <div className="input-section">
                                                <Form.Item
                                                    name="subterritory"
                                                    label="Subterritory"
                                                    rules={[{
                                                        required: true,
                                                        message: t('validation.required', { item: 'Subterritory' })
                                                    }]}
                                                >
                                                    <WuiSelectMulti disabled={disable.get()} repository={SubTerritoryRepository} showOptionDescription selectParams={{ selectAll: true, selectAllLabel: 'Semua Subterritory'}}/>
                                                </Form.Item>
                                            </div>
                                        </div>
                                    }

                                    <div className="wui-form-group type-column">
                                        <div className="input-section">
                                            <Form.Item
                                                name="dealers"
                                                label={"Dealer"}
                                                rules={[{
                                                    required: true,
                                                    message: t('validation.required', { item: "Dealer" })
                                                }]}
                                            >
                                                {
                                                    renderSelectDealer ?
                                                        <WuiSelectMulti
                                                            placeholder="Pilih Dealer"
                                                            onChange={(value) => {
                                                                form.setFields([{ name: 'dealers', value: value }])
                                                            }}
                                                            repository={DealerRepository}
                                                            selectParams={
                                                                { 
                                                                    selectAll: true, 
                                                                    selectAllLabel: 'Semua Dealer', 
                                                                    cluster_id: getArrayValue('cluster'), 
                                                                    subterritory_id: getArrayValue('subterritory'),
                                                                    territory_id: getArrayValue('territory'),
                                                                    area_id: getArrayValue('area'), 
                                                                    region_id: getArrayValue('region'),
                                                                    channel: 'b2b'
                                                                }}
                                                            disabled={
                                                                !(form.getFieldValue('cluster') || form.getFieldValue('region') 
                                                                || form.getFieldValue('territory') || form.getFieldValue('subterritory') || form.getFieldValue('area')) ||
                                                                disable.get()} />
                                                        : <Input hidden />
                                                }
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="wui-form-group type-column">
                                        <div className="input-section">
                                            <Form.Item
                                                name="from"
                                                label={"Tanggal Mulai"}
                                                rules={[{
                                                    required: true,
                                                    message: t('validation.required', { item: "Tanggal Mulai" })
                                                }]}
                                            >
                                                <DatePicker
                                                    disabled={disable.get()}
                                                    disabledDate={(current) => {
                                                        let customDate = moment().format("YYYY-MM-DD");
                                                        return current && current < moment(customDate, "YYYY-MM-DD");
                                                    }}
                                                    format="DD-MM-YYYY" style={{ width: 400, maxWidth: '100%' }} size={"large"} onChange={() => { }} placeholder={t('common.text.input', { item: 'Tanggal' })} />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="wui-form-group type-column">
                                        <div className="input-section">
                                            <Form.Item
                                                name="to"
                                                label={"Tanggal Berakhir"}
                                                rules={[{
                                                    required: true,
                                                    message: t('validation.required', { item: "Tanggal Berakhir" })
                                                }]}
                                            >
                                                <DatePicker
                                                    disabled={disable.get()}
                                                    disabledDate={(current) => {
                                                        let customDate = moment().format("YYYY-MM-DD");
                                                        return current && current < moment(customDate, "YYYY-MM-DD");
                                                    }} format="DD-MM-YYYY" style={{ width: 400, maxWidth: '100%' }} size={"large"} onChange={() => { }} placeholder={t('common.text.input', { item: 'Tanggal' })} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Space>
                            </Card>

                            <br />


                            <Card title={'Pertanyaan'} className="card-title-required">
                                <Space style={{
                                    width: '100%'
                                }} direction="vertical">
                                    {
                                        surveyFormList.get().map((item: any, index: number) => {
                                            return <SurveyForm data={item} index={index} key={`${item.tempId}`}
                                                answers={surveyFormAnswers} disabled={disable.get()}
                                                list={surveyFormList} />
                                        })
                                    }
                                    <Accessible access={permissions.write_survey_b2b}>
                                        <Button icon={<PlusOutlined />} type="primary" ghost block onClick={handleAddForm}>Tambah Pertanyaan</Button>
                                    </Accessible>
                                </Space>
                            </Card>
                        </Col>

                        {
                            id &&
                            <Col className="gutter-row" md={{
                                span: 8,
                                order: 2
                            }} xs={{
                                span: 24,
                                order: 1
                            }}>
                                <Card title="Dealer yang mengisi" extra={tempDealers.get().length > 0 && <Button onClick={handleExport} type="primary" ghost>{t('common.button.export')}</Button>} bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <List
                                        dataSource={tempDealers.get()}
                                        renderItem={(item: any, dataIndex: number) => (
                                            <List.Item>
                                                <div>
                                                    <Text>{item.report_consultant?.dealer.name}</Text>
                                                    <div>
                                                        <Button type="link" className="p0" onClick={() => {
                                                            const dealer = $clone(tempDealers.get()[dataIndex])
                                                            tempActiveDealer.set(dealer)
                                                            showDealerModal.set(true)
                                                        }}>Lihat Detail</Button>
                                                    </div>
                                                </div>
                                            </List.Item>
                                        )}
                                    />

                                </Card>
                            </Col>
                        }
                    </Row>

                    {
                        id &&
                        <Modal
                            style={{ marginTop: 20, marginBottom: 20 }}
                            title={`Detail Jawaban Dealer ${tempActiveDealer.get().report_consultant?.dealer.name}`}
                            centered
                            onCancel={() => { showDealerModal.set(false) }}
                            visible={showDealerModal.get()}
                            footer={[
                                <Button key="back" onClick={() => { showDealerModal.set(false) }}>
                                    {t('common.button.back')}
                                </Button>,
                            ]}
                        >
                            <Space direction="vertical" size={20} style={{ width: '100%' }}>
                                {
                                    tempQuestions.get().map((item: any) => {
                                        let dealer = tempActiveDealer.get()
                                        let answers = dealer.report_consultant_survey_answers || []
                                        let answ = (answers.find((an: any) => item.id === an.survey_question_id) || {}).answer || '-';
                                        return <Space direction="vertical" size={5} key={item.question}>
                                            <Text>{item.question}</Text>
                                            <Text>Jawaban: <b>{answ}</b></Text>
                                        </Space>
                                    })
                                }

                            </Space>
                        </Modal>
                    }

                    <div className="wui-form-btn-group">
                        <Button className="wui-btn" size={"large"} onClick={() => navigate(-1)}>{t('common.button.cancel')}</Button>
                        <Accessible access={permissions.write_survey_b2b}>
                            <Spin spinning={loading.get()}>
                                <Button className="wui-btn" htmlType="submit" type="primary" size={"large"} disabled={disable.get()}>{t('common.button.save')}</Button>
                            </Spin>
                        </Accessible>
                    </div>
                </Form>
            </WuiContainer>
        </>
    )
}

const SurveyForm = (props: any) => {
    const data = props.data || {}
    const index = props.index || 0
    const surveyFormList = props.list
    const surveyFormAnswers = props.answers
    const answers = surveyFormAnswers.get()[data.tempId] || []
    const disabled = props.disabled

    const handleFormChange = (value: any, key: string, index: number) => {
        let list = $clone(surveyFormList.get())
        list[index][key] = value;
        if (key === 'type') {
            const tempId = list[index]['tempId']
            let answerObj = $clone(surveyFormAnswers.get())
            if (!answerObj[tempId]) {
                answerObj[tempId] = ['']
                surveyFormAnswers.set(answerObj)
            }
        }
        surveyFormList.set(list)
    }

    const handleDeleteForm = (tempId: string) => {
        const oldList = $clone(surveyFormList.get())
        const list = oldList.filter((item: any) => item.tempId !== tempId);
        surveyFormList.set(list)
    }

    const handleAddAnswerOption = (tempId: string) => {
        let answerObj = $clone(surveyFormAnswers.get())
        answerObj[tempId] = [...answerObj[tempId], '']
        surveyFormAnswers.set(answerObj)
    }

    const handleDeleteAnswerOption = (tempId: string, index: number) => {
        let answerObj = $clone(surveyFormAnswers.get())
        answerObj[tempId].splice(index, 1)
        surveyFormAnswers.set(answerObj)
    }

    const handleAnswerChange = (value: string, tempId: string, index: number) => {
        let answerObj = $clone(surveyFormAnswers.get())
        answerObj[tempId][index] = value;
        surveyFormAnswers.set(answerObj)
    }

    return <div>
        <Row gutter={[16, 16]}>
            <Col span={6}>
                <div className="wui-form-group">
                    <div className="input-section">
                        <Select disabled={disabled} size={"large"} style={{
                            width: '100%',
                        }} onChange={(value) => { handleFormChange(value, 'type', index) }} placeholder="Tipe" value={data.type}>
                            {
                                SURVEY_TYPE_OPTIONS.map((item: any) =>
                                    <Option key={item} value={item}>{SURVEY_TYPE_OPTION_LABELS[item]}</Option>
                                )
                            }
                        </Select>
                    </div>
                </div>
            </Col>

            <Col flex="auto">
                <div className="wui-form-group">
                    <div className="input-section">
                        <Input disabled={disabled} onChange={(e) => { handleFormChange(e.target.value, 'question', index) }} size={"large"} placeholder="Masukkan pertanyaan" defaultValue={data.question} />
                    </div>
                </div>
            </Col>
            {
                surveyFormList.get().length > 1 &&
                <Col span={1}>
                    <DeleteOutlined style={{ fontSize: 18, paddingTop: 8 }} onClick={() => handleDeleteForm(data.tempId)} />
                </Col>
            }
        </Row>
        <br />

        {
            data.type === SURVEY_TYPE_SHORT_ANSWER ?
                <div className="input-section">
                    <Input size={"large"} placeholder="Text jawaban" disabled={true} />
                </div>
                :
                <Space size={25} direction="vertical" style={{ width: '100%' }}>
                    {
                        answers.map((value: any, idx: number) =>
                            <Row gutter={[16, 0]} align="middle" key={`${data.tempId}_${idx}`}>
                                {
                                    data.type !== SURVEY_TYPE_DROPDOWN &&
                                    <Col>
                                        {
                                            data.type === SURVEY_TYPE_CHECK_BOX &&
                                            <Checkbox checked={false}></Checkbox>
                                        }
                                        {
                                            data.type === SURVEY_TYPE_MULTIPLE_CHOICE &&
                                            <Radio checked={false}></Radio>
                                        }
                                    </Col>
                                }

                                <Col flex="auto">
                                    <Input disabled={disabled} size={"large"} onBlur={(e) => handleAnswerChange(e.target.value, data.tempId, idx)} placeholder="Text jawaban" defaultValue={value} />
                                </Col>

                                <Col span={1}>
                                    {
                                        answers.length > 1 &&
                                        <Accessible access={permissions.write_survey_b2b}>
                                            <CloseOutlined style={{ fontSize: 18 }} onClick={() => handleDeleteAnswerOption(data.tempId, idx)} />
                                        </Accessible>
                                    }
                                </Col>
                            </Row>

                        )
                    }

                    <Accessible access={permissions.write_survey_b2b}>
                        <Button icon={<PlusOutlined />} type="primary" ghost onClick={() => handleAddAnswerOption(data.tempId)}>Tambah Pilihan</Button>
                    </Accessible>
                </Space>
        }
        <Divider />
    </div>
}

export default AppSurveyForm
