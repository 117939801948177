import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "antd/es/page-header";
import React from "react";
import WuiContainer from "../../../../../../@framework/wui/components/Container";
import { Col, Row } from "antd/lib/grid";
import Card from "antd/lib/card";
import Space from "antd/lib/space";
import Form from "antd/lib/form";
import WuiSelectSingle from "../../../../../../@framework/wui/components/Select/Single";
import AssistantStoreLeaderRepository from "../../../../../../repositories/AssistantStoreLeaderRepository";
import { useState } from "@hookstate/core";
import { AxiosError, AxiosResponse } from "axios";
import Button from "antd/es/button";
import Spin from "antd/lib/spin";
import DatePicker from "antd/lib/date-picker";
import Title from "antd/lib/typography/Title";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import JourneyPlanRepository from "../../../../../../repositories/JourneyPlanRepository";
import { DeleteOutlined } from "@ant-design/icons/lib/icons";
import Select from "antd/lib/select";
// import WuiSelectMulti from "../../../../../../@framework/wui/components/Select/Multi";
import moment from "moment";
import {
  handleBackendError,
  openNotification,
} from "../../../../../../functions/global";
// import MultipleDateelector from "./Dateelector";
import Accessible from "../../../../../../@framework/wui/components/Accessible";
import { B2C_JOURNEY_IMPORT } from "../../../../../../constant/import";
import WuiModalImport from "../../../../../../@framework/wui/components/Modal/Import";
import { Input } from "antd";
import _ from "lodash";
import { permissions } from "../../../../../../constant/permissions";

const { Option } = Select;

let title = "Journey Plan";

const AppJourneyPlanB2CFormPlan: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const pageTitle = id
    ? t("common.text.editItem", { item: title })
    : t("common.text.addNewItem", { item: title });

  // Form
  const [form] = Form.useForm();
  const disable = useState(false);
  const loading = useState(false);

  const [storeOptions, setStoreOptions] = React.useState<any>([]);

  const onFinish = async (data: any) => {
    loading.set(false);

    const journeyPlans = _.map(data.journey_plans, (journeyPlan) => {
      return {
        date: `${moment(journeyPlan.date).startOf('day').format(
          "YYYY-MM-DD[T]HH:mm:ss"
        )}+00:00`,
        stores: _.map(journeyPlan.stores, (store) => {
          return {
            store_id: _.get(store, "store.value"),
            description: _.get(store, "reason"),
          };
        }),
      };
    });

    let payload = {
      assistant_id: data.asl?.value,
      journey_plans: journeyPlans,
    };

    await (!id
      ? JourneyPlanRepository.create("b2c", payload)
      : JourneyPlanRepository.update(id, "b2c", payload)
    )
      .then((res: AxiosResponse) => {
        navigate(-1);

        if (!id) {
          openNotification(
            "success",
            t("notification.success.createItem", { item: title })
          );
        } else {
          openNotification(
            "success",
            t("notification.success.updateItem", { item: title })
          );
          loading.set(false);
        }
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));
        loading.set(false);
      });
  };

  const getAslStores = (id: any, clear: boolean = true) => {
    AssistantStoreLeaderRepository.show(id, "b2c", { with: ["stores"] })
      .then((res: AxiosResponse) => {
        let data = res.data?.data;

        if (data.assistant_stores) {
          if (clear) {
            form.setFieldsValue({
              ...form.getFieldsValue(true),
              journey_plans: [
                {
                  date: "",
                  stores: [
                    {
                      store: null,
                      reason: "",
                    },
                  ],
                },
              ],
            });
          }

          setStoreOptions(data.assistant_stores);
        }
      })
      .catch((e: AxiosError) => {
        console.log(e);
      });
  };

  const showImportModal = useState(false);

  const importModalCallback = async (files: File[]) => {
    const formData = new FormData();

    if (files) {
      files.forEach((file: any) => {
        formData.append("file_import", file);
      });
    }

    if (form.getFieldValue("asl")) {
      formData.append("assistant_id", form.getFieldValue("asl").value);
    }

    await JourneyPlanRepository.import("b2c", formData)
      .then((res: AxiosResponse) => {
        let data = res.data?.data;

        const asl = {
          value: data?.assistant?.id,
          label: data?.assistant?.name,
        };
        let formattedJourney = data?.journey_plans?.map((item: any) => {
          return {
            date: moment(item.date),
            stores: _.map(item.stores, (store) => {
              return {
                store: {
                  value: store.store.id,
                  label: store.store.name,
                },
                reason: store.description,
              };
            }),
          };
        });

        getAslStores(data.assistant.id, false);
        form.setFields([
          {
            name: "asl",
            value: asl,
          },
          {
            name: "journey_plans",
            value: formattedJourney,
          },
        ]);
        // setTimeout(() => {
        //   form.setFields([
        //     {
        //       name: "journey_plans",
        //       value: formatted,
        //     },
        //   ]);
        // });

        showImportModal.set(false);
        openNotification(
          "success",
          t("notification.success.importItem", { item: "Jadwal" })
        );
      })
      .catch((e: AxiosError) => {
        console.log(e);
        handleBackendError(e, t("notification.error.default"));
      });
  };

  const importModalDownload = async () => {
    window.open(B2C_JOURNEY_IMPORT.TEMPLATE_URL + `?${_.random(100, 900)}`);
  };

  return (
    <>
      <WuiContainer>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
          extra={[
            <Button
              key="1"
              type="primary"
              onClick={() => showImportModal.set(true)}
            >
              Import Jadwal
            </Button>,
          ]}
        />

        <Form
          form={form}
          layout={"vertical"}
          onFinish={onFinish}
          onValuesChange={(changedValues) => {
            if (changedValues.asl) {
              getAslStores(changedValues.asl.value);
            }
          }}
          initialValues={{
            journey_plans: [
              {
                date: "",
                stores: [
                  {
                    store: null,
                    reason: "",
                  },
                ],
              },
            ],
          }}
        >
          <Row gutter={[16, 16]}>
            <Col
              className="gutter-row"
              md={{
                span: 16,
                order: 1,
              }}
              xs={{
                span: 24,
                order: 2,
              }}
            >
              <Card title={""} className="mb16">
                <Space
                  style={{
                    width: "100%",
                  }}
                  direction="vertical"
                  size={0}
                >
                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="asl"
                        label={"Tugaskan ke"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Tugaskan ke",
                            }),
                          },
                        ]}
                      >
                        <WuiSelectSingle
                          repository={AssistantStoreLeaderRepository}
                          selectParams={{
                            channel: "b2c",
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Space>
              </Card>

              <Form.List name="journey_plans">
                {(fields, { add, remove }) => (
                  <Space
                    style={{
                      width: "100%",
                    }}
                    direction="vertical"
                    size={15}
                  >
                    {fields.map(({ key, name, ...restField }) => (
                      <Card title={""} key={key}>
                        <Row align={"middle"} justify={"space-between"}>
                          <Title level={5}>Tanggal {key + 1}</Title>
                          {key !== 0 ? (
                            <DeleteOutlined onClick={() => remove(name)} />
                          ) : (
                            ""
                          )}
                        </Row>
                        <div className="wui-form-group type-column">
                          <div className="input-section">
                            <Form.Item
                              {...restField}
                              name={[name, "date"]}
                              label={"Tanggal"}
                              rules={[
                                {
                                  required: true,
                                  message: t("validation.required", {
                                    item: "Tanggal",
                                  }),
                                },
                              ]}
                            >
                              <DatePicker
                                placeholder="Pilih Tanggal"
                                format="DD MMMM YYYY"
                                style={{ width: "100%" }}
                                size={"large"}
                                picker="date"
                                disabledDate={(current) => {
                                  let minDate = moment().startOf("month");
                                  let now = moment().format("YYYY MM DD");
                                  if (moment(now) > minDate) {
                                    minDate = moment(now);
                                  }
                                  return current && current < minDate;
                                }}
                              />
                            </Form.Item>
                          </div>
                        </div>

                        <Form.List name={[name, "stores"]}>
                          {(
                            fieldStores,
                            { add: addStore, remove: removeStore }
                          ) => (
                            <Space
                              style={{
                                width: "100%",
                              }}
                              direction="vertical"
                              size={15}
                            >
                              {fieldStores.map(
                                ({
                                  key: storeKey,
                                  name: storeName,
                                  ...restField
                                }) => (
                                  <Card title={""} key={storeKey}>
                                    <Row
                                      align={"middle"}
                                      justify={"space-between"}
                                    >
                                      <Title level={5}>
                                        Toko {storeKey + 1}
                                      </Title>
                                      {storeKey !== 0 ? (
                                        <DeleteOutlined
                                          onClick={() => removeStore(storeName)}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Row>
                                    <Row gutter={16}>
                                      <Col xs={24}>
                                        <div className="wui-form-group type-column">
                                          <div className="input-section">
                                            <Form.Item
                                              {...restField}
                                              name={[storeName, "store"]}
                                              label={"Toko yang dituju"}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: t(
                                                    "validation.required",
                                                    {
                                                      item: "Toko yang dituju",
                                                    }
                                                  ),
                                                },
                                              ]}
                                            >
                                              <Select
                                                labelInValue={true}
                                                disabled={
                                                  storeOptions.length === 0
                                                }
                                                size={"large"}
                                                placeholder="Pilih toko"
                                                style={{
                                                  width: "100%",
                                                }}
                                              >
                                                {storeOptions.map(
                                                  (item: any) => (
                                                    <Option
                                                      value={item.id}
                                                      key={item.id}
                                                    >
                                                      <div className="custom-dealer-option">
                                                        <div>
                                                          {item?.name} -{" "}
                                                          {item?.code}
                                                        </div>
                                                        <div
                                                          className={"address"}
                                                        >
                                                          {item?.address}
                                                        </div>
                                                      </div>
                                                    </Option>
                                                  )
                                                )}
                                              </Select>
                                            </Form.Item>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col xs={24}>
                                        <div className="wui-form-group type-column">
                                          <div className="input-section">
                                            <Form.Item
                                              {...restField}
                                              name={[storeName, "reason"]}
                                              label={"Alasan"}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: t(
                                                    "validation.required",
                                                    {
                                                      item: "Alasan",
                                                    }
                                                  ),
                                                },
                                              ]}
                                            >
                                              <Input
                                                disabled={disable.get()}
                                                size={"large"}
                                                placeholder={t(
                                                  "common.text.input",
                                                  { item: "Alasan" }
                                                )}
                                              />
                                            </Form.Item>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Card>
                                )
                              )}

                              <Form.Item className={"mt16"}>
                                <Button
                                  type="dashed"
                                  onClick={() => addStore()}
                                  block
                                  icon={<PlusOutlined />}
                                >
                                  Tambah Toko
                                </Button>
                              </Form.Item>
                            </Space>
                          )}
                        </Form.List>
                      </Card>
                    ))}

                    <Form.Item className={"mt16"}>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Tambah Tanggal
                      </Button>
                    </Form.Item>
                  </Space>
                )}
              </Form.List>
            </Col>
          </Row>

          <div className="wui-form-btn-group">
            <Button
              className="wui-btn"
              size={"large"}
              onClick={() => navigate(-1)}
            >
              {t("common.button.cancel")}
            </Button>
            <Accessible access={permissions.write_journey_plan_b2c}>
              <Spin spinning={loading.get()}>
                <Button
                  className="wui-btn"
                  htmlType="submit"
                  type="primary"
                  size={"large"}
                  disabled={disable.get()}
                >
                  {t("common.button.save")}
                </Button>
              </Spin>
            </Accessible>
          </div>
        </Form>
      </WuiContainer>

      <WuiModalImport
        show={showImportModal.get()}
        onUpload={importModalCallback}
        onCancel={() => showImportModal.set(false)}
        headerTitle="Import Jadwal"
        onDownload={importModalDownload}
        maxFileOnMB={10}
      />
    </>
  );
};

export default AppJourneyPlanB2CFormPlan;
