import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Form from "antd/lib/form";
import { useState } from "@hookstate/core";
import { TChannels } from "../../../../../../utils";
import WuiSectionTitle from "../../../../../../@framework/wui/components/Sections/Title";
import PageHeader from "antd/es/page-header";
import WuiContainer from "../../../../../../@framework/wui/components/Container";
import Row from "antd/es/grid/row";
import Col from "antd/es/grid/col";
import Card from "antd/es/card";
import Space from "antd/es/space";
import { Checkbox, Spin } from "antd";
import Button from "antd/es/button";
import Accessible from "../../../../../../@framework/wui/components/Accessible";
import WuiSelectSingle from "../../../../../../@framework/wui/components/Select/Single";
import SuperiorRepository from "../../../../../../repositories/SuperiorRepository";
import Input from "antd/es/input";
import Select from "antd/es/select";
import moment from "moment";
import DatePicker from "antd/es/date-picker";
import KPIMasterRepository from "../../../../../../repositories/KPIMasterRepository";
import UserRepository from "../../../../../../repositories/UserRepository";
import { AxiosError, AxiosResponse } from "axios";
import {
  handleBackendError,
  haveAccess,
  inputNumberThousandSeparator,
  openNotification,
} from "../../../../../../functions/global";
import { permissions } from "../../../../../../constant/permissions";
import { InputNumber } from "antd/es";
import _ from "lodash";
import KPITargetItemRepository from "../../../../../../repositories/KPITargetItemRepository";
import useAccess from "../../../../../../@framework/utilities/hooks/useAccess";

const { Option } = Select;

let title = "Target Item KPI";

//channel
const channelOptions: TChannels[] = ["B2B", "B2C"];

const permissionMapping: any = {
  B2B: {
    write: permissions.write_target_kpi_b2b,
  },
  B2C: {
    write: permissions.write_target_kpi_b2c,
  },
};

const AppKPITargetItemForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const acl = useAccess();
  const pageTitle = id
    ? t("common.text.editItem", { item: title })
    : t("common.text.addNewItem", { item: title });

  const breadcrumbs = [
    {
      label: "KPI",
      link: "/kpi",
    },
    {
      label: "Target",
      link: "/kpi/target",
    },
    id
      ? {
          label: "Detail Target Item KPI",
          link: `/kpi/target/${id}`,
        }
      : {
          label: "Tambah Target Item KPI",
          link: "/kpi/target/form",
        },
  ];

  // Form
  const [form] = Form.useForm();
  const channels = Form.useWatch("channels", form);
  const startDate = Form.useWatch("start_date", form);
  const endDate = Form.useWatch("end_date", form);
  const superior = Form.useWatch("superior", form);
  const user = Form.useWatch("user", form);
  const disable = useState(false);
  const loading = useState(false);

  const kpiItems = useState<any[]>([]);
  const kpiBrands = useState<any[]>([]);

  const handleFormValuesChanges = async (changes: any) => {
    if (changes?.channels) {
      form.setFieldValue("superior", null);
      form.setFieldValue("user", null);
      form.setFieldValue("kpi_master", null);
      form.setFieldValue("kpi_targets", []);
      form.setFieldValue("target_brands", []);
      form.setFieldValue("code", '')
    }
  };

  useEffect(() => {
    if (user) {
      getDataUser();
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleKpiItemsOnChange();
  }, [JSON.stringify(kpiItems.get())]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleKpiBrandsOnChange();
  }, [JSON.stringify(kpiBrands.get())]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleKpiItemsOnChange = () => {
    let kpiTargets = kpiItems.get()?.map((item: any) => {
      return {
        kpi_item_id: item?.id,
        name: item?.name,
      };
    });

    form.setFieldValue("kpi_targets", kpiTargets);
  };

  const handleKpiBrandsOnChange = () => {
    let brandTargets = kpiBrands.get()?.map((item: any) => {
      return {
        brand_id: item?.id,
        name: item?.name,
      };
    });

    form.setFieldValue("target_brands", brandTargets);
  };

  const onFinish = async (data: any) => {
    loading.set(true);

    let payload = {
      ...data,
      superior_id: data.superior?.value,
      user_id: data.user?.value,
      kpi_master_id: data.kpi_master?.value,
      start_date: data.start_date?.format("YYYY-MM-DD"),
      end_date: data.end_date?.format("YYYY-MM-DD"),
      detail: {
        kpi_targets: data.kpi_targets,
        target_brands: data.target_brands,
      },
    };

    const deletedKeys = ["code", "kpi_master", "user", "superior"];
    deletedKeys.map((key: string) => delete payload[key]);

    await (id
      ? KPITargetItemRepository.update(id, payload)
      : KPITargetItemRepository.create(payload)
    )
      .then((res: AxiosResponse) => {
        navigate(-1);

        if (!id) {
          openNotification(
            "success",
            t("notification.success.createItem", { item: title })
          );
        } else {
          openNotification(
            "success",
            t("notification.success.updateItem", { item: title })
          );
          loading.set(false);
        }
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));
        loading.set(false);
      });
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    disable.set(true);

    await KPITargetItemRepository.show(id, {
      with: ["superior", "channels", "kpiMaster", "user"],
    })
      .then((res: AxiosResponse) => {
        const data = res.data?.data || {};

        let dataTargetItem = [];
        let dataTargetBrand = [];

        if (data?.detail?.kpi_targets) {
          dataTargetItem = data?.detail?.kpi_targets?.map((item: any) => {
            return {
              name: item?.kpi_item?.name,
              kpi_item_id: item?.kpi_item_id,
              value: item?.value,
            };
          });
        }

        if (data?.detail?.target_brands) {
          dataTargetBrand = data?.detail?.target_brands?.map((item: any) => {
            return {
              name: item?.brand?.name,
              brand_id: item?.brand_id,
              value: item?.value,
            };
          });
        }

        form.setFieldsValue({
          channels: data.channels?.map((item: any) => item.channel),
          superior: {
            value: data.superior?.id,
            label: data.superior?.name,
          },
          user: {
            value: data.user?.id,
            label: data.user?.name,
          },
          kpi_master: {
            value: data.kpi_master?.id,
            label: `${data.kpi_master?.code} - ${data.kpi_master?.name}`,
          },
          code: data.user?.code,
          start_date: moment(data?.start_date),
          end_date: moment(data?.end_date),
          kpi_targets: dataTargetItem,
          target_brands: dataTargetBrand,
        });

        if (
          haveAccess(acl, [
            permissions.write_target_kpi_b2b,
            permissions.write_target_kpi_b2c,
          ])
        ) {
          disable.set(false);
        }
      })
      .catch((e: AxiosError) => {
        handleBackendError(e);
      });
  };

  const getDataUser = async () => {
    await UserRepository.show(user?.value)
      .then((res: AxiosResponse) => {
        const data = res.data?.data || {};

        form.setFieldValue("code", data?.code);
      })
  };

  return (
    <>
      <WuiContainer>
        <WuiSectionTitle title={""} breadcrumbs={breadcrumbs} />

        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
        />

        <Form
          form={form}
          layout={"vertical"}
          onValuesChange={handleFormValuesChanges}
          onFinish={onFinish}
          initialValues={{
            channels: [
              channelOptions.filter((item) =>
                haveAccess(acl, permissionMapping[item].write)
              )[0],
            ],
            start_date: moment().startOf("M"),
            end_date: moment().endOf("M"),
          }}
        >
          <Row gutter={[16, 16]}>
            <Col
              className="gutter-row"
              md={{
                span: 16,
                order: 1,
              }}
              xs={{
                span: 24,
                order: 2,
              }}
            >
              <Card className="mb16">
                <Space
                  style={{
                    width: "100%",
                  }}
                  direction="vertical"
                  size={5}
                >
                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="channels"
                        label="Channel"
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Channel",
                            }),
                          },
                        ]}
                      >
                        <Checkbox.Group>
                          {channelOptions.map((channel) => {
                            return (
                              <Checkbox
                                value={channel}
                                disabled={
                                  disable.get() ||
                                  !haveAccess(
                                    acl,
                                    permissionMapping[channel].write
                                  )
                                }
                              >
                                {channel}
                              </Checkbox>
                            );
                          })}
                        </Checkbox.Group>
                      </Form.Item>
                    </div>
                  </div>

                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="superior"
                        label="Peran"
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Role",
                            }),
                          },
                        ]}
                      >
                        <WuiSelectSingle
                          disabled={disable.get()}
                          selectParams={{
                            strict_channel: channels,
                          }}
                          repository={SuperiorRepository}
                          placeholder={"Pilih Peran"}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item name="code" label={"Nik"}>
                        <Input
                          disabled={true}
                          size={"large"}
                          placeholder={"NIK Pengguna"}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="user"
                        label={"Pengguna"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Pengguna",
                            }),
                          },
                        ]}
                      >
                        <WuiSelectSingle
                          repository={UserRepository}
                          selectParams={{
                            channel: channels,
                            superiors: superior?.value,
                          }}
                          placeholder={"Pilih Pengguna"}
                          disabled={disable.get()}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <Row gutter={[16, 16]}>
                    <Col xs={24} md={12} lg={12}>
                      <div className="wui-form-group type-column">
                        <div className="input-section">
                          <Form.Item
                            name="start_date"
                            label={"Tanggal Mulai"}
                            rules={[
                              {
                                required: true,
                                message: t("validation.required", {
                                  item: "Tanggal Mulai",
                                }),
                              },
                            ]}
                          >
                            <DatePicker
                              disabled={disable.get()}
                              disabledDate={(current) => {
                                let customDate = moment().format("YYYY-MM-DD");
                                return (
                                  current &&
                                  current < moment(customDate, "YYYY-MM-DD")
                                );
                              }}
                              format="D MMMM YYYY"
                              style={{ width: 400, maxWidth: "100%" }}
                              size={"large"}
                              onChange={() => {}}
                              placeholder={t("common.text.input", {
                                item: "Tanggal Mulai",
                              })}
                              allowClear={false}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Col>

                    <Col xs={24} md={12} lg={12}>
                      <div className="wui-form-group type-column">
                        <div className="input-section">
                          <Form.Item
                            name="end_date"
                            label={"Tanggal Berakhir"}
                            rules={[
                              {
                                required: true,
                                message: t("validation.required", {
                                  item: "Tanggal Berakhir",
                                }),
                              },
                            ]}
                          >
                            <DatePicker
                              disabled={disable.get()}
                              disabledDate={(current) => {
                                let customDate = startDate.format("YYYY-MM-DD");
                                return (
                                  current &&
                                  current < moment(customDate, "YYYY-MM-DD")
                                );
                              }}
                              format="D MMMM YYYY"
                              style={{ width: 400, maxWidth: "100%" }}
                              size={"large"}
                              onChange={() => {}}
                              placeholder={t("common.text.input", {
                                item: "Tanggal Berakhir",
                              })}
                              allowClear={false}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="kpi_master"
                        label={"Kode - Nama KPI"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", { item: "KPI" }),
                          },
                        ]}
                      >
                        <WuiSelectSingle
                          repository={KPIMasterRepository}
                          selectParams={{
                            channel: channels,
                            start_date: startDate?.format("YYYY-MM-DD") ?? null,
                            end_date: endDate?.format("YYYY-MM-DD") ?? null,
                            superior: superior?.value
                          }}
                          placeholder={"Pilih Kode - Nama KPI"}
                          onChange={(value, options: any) => {
                            kpiItems.set(options["data-items"]);
                            kpiBrands.set(options["data-brands"]);
                          }}
                          disabled={disable.get()}
                          customOptions={(item: any) => {
                            return (
                              <Option
                                key={item.id}
                                value={item.id}
                                data-items={item?.kpi_items}
                                data-brands={item?.brands}
                              >
                                {item?.code} - {item?.name}
                              </Option>
                            );
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Space>
              </Card>

              {_.includes(channels, "B2B") && !_.includes(channels, "B2C") ? (
                <Card title={"Target Brand"} className="mb16">
                  <Form.List name="target_brands">
                    {(fields, { add, remove }) => (
                      <Space
                        style={{
                          width: "100%",
                        }}
                        direction="vertical"
                        size={16}
                      >
                        {fields.map(({ key, name, ...restField }) => (
                          <div key={key}>
                            <Input.Group>
                              <Row gutter={8}>
                                <Col span={14}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "name"]}
                                    rules={[{ required: true, message: "" }]}
                                    className={"mb0"}
                                  >
                                    <Input disabled={true} />
                                  </Form.Item>
                                </Col>
                                <Col span={10}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "value"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: t("validation.required", {
                                          item: "Nilai",
                                        }),
                                      },
                                    ]}
                                    className={"mb0"}
                                  >
                                    <InputNumber
                                      disabled={disable.get()}
                                      style={{ width: "100%" }}
                                      formatter={inputNumberThousandSeparator.formatter}
                                      parser={inputNumberThousandSeparator.parser}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Input.Group>
                          </div>
                        ))}
                      </Space>
                    )}
                  </Form.List>
                </Card>
              ) : (
                ""
              )}

              <Card title={"Target Item KPI"} className="mb16">
                <Form.List name="kpi_targets">
                  {(fields, { add, remove }) => (
                    <Space
                      style={{
                        width: "100%",
                      }}
                      direction="vertical"
                      size={16}
                    >
                      {fields.map(({ key, name, ...restField }) => (
                        <div key={key}>
                          <Input.Group>
                            <Row gutter={8}>
                              <Col span={14}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "name"]}
                                  rules={[{ required: true, message: "" }]}
                                  className={"mb0"}
                                >
                                  <Input disabled={true} />
                                </Form.Item>
                              </Col>
                              <Col span={10}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "value"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: t("validation.required", {
                                        item: "Nilai",
                                      }),
                                    },
                                  ]}
                                  className={"mb0"}
                                >
                                  <InputNumber
                                    disabled={disable.get()}
                                    style={{ width: "100%" }}
                                    formatter={inputNumberThousandSeparator.formatter}
                                    parser={inputNumberThousandSeparator.parser}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Input.Group>
                        </div>
                      ))}
                    </Space>
                  )}
                </Form.List>
              </Card>
            </Col>
          </Row>

          <div className="wui-form-btn-group">
            <Button
              className="wui-btn"
              size={"large"}
              onClick={() => navigate(-1)}
            >
              {t("common.button.cancel")}
            </Button>
            <Accessible
              access={[
                permissions.write_target_kpi_b2b,
                permissions.write_target_kpi_b2c,
              ]}
            >
              <Spin spinning={loading.get()}>
                <Button
                  className="wui-btn"
                  htmlType="submit"
                  type="primary"
                  size={"large"}
                  disabled={disable.get()}
                >
                  {t("common.button.save")}
                </Button>
              </Spin>
            </Accessible>
          </div>
        </Form>
      </WuiContainer>
    </>
  );
};

export default AppKPITargetItemForm;
