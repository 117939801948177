import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "antd/es/page-header";
import React from "react";
import WuiContainer from "../../../../../../@framework/wui/components/Container";
import { Col, Row } from "antd/lib/grid";
import Card from "antd/lib/card";
import Space from "antd/lib/space";
import Form from "antd/lib/form";
import WuiSelectSingle from "../../../../../../@framework/wui/components/Select/Single";
import SalesRepository from "../../../../../../repositories/SalesRepository";
import { useState } from "@hookstate/core";
import { AxiosError, AxiosResponse } from "axios";
import Button from "antd/es/button";
import Spin from "antd/lib/spin";
import DatePicker from "antd/lib/date-picker";
import Title from "antd/lib/typography/Title";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import JourneyPlanRepository from "../../../../../../repositories/JourneyPlanRepository";
import { DeleteOutlined } from "@ant-design/icons/lib/icons";
import Select from "antd/lib/select";
import WuiSelectMulti from "../../../../../../@framework/wui/components/Select/Multi";
import moment from "moment";
import { handleBackendError, openNotification } from "../../../../../../functions/global";
import MultipleDateSelector from "./DateSelector";
import { SALES_CONSULTANT, SALES_SUPERIOR } from "../../../../../../constant";
import Accessible from "../../../../../../@framework/wui/components/Accessible";
import { JOURNEY_IMPORT } from "../../../../../../constant/import";
import WuiModalImport from "../../../../../../@framework/wui/components/Modal/Import";
import { permissions } from "../../../../../../constant/permissions";
import _ from "lodash";

const { Option } = Select

let title = "Journey Plan";

const AppJourneyPlanFormPlan: React.FC<any> = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const pageTitle = id ? t('common.text.editItem', { item: title }) : t('common.text.addNewItem', { item: title })

    // Form
    const [form] = Form.useForm();
    const disable = useState(false);
    const loading = useState(false);

    const [selectedMonth, setMonth] = React.useState<any>(null)

    const [dealerOptions, setDealerOptions] = React.useState<any>([])

    const onFinish = async (data: any) => {
        loading.set(false);

        let journey_plans: any = [];
        let dealer_ids: any = [];
        (data.journey_plans || []).forEach((item: any) => {
            dealer_ids.push(item.dealer.value)
            journey_plans.push({
                dealer_id: item.dealer.value,
                dates: (item.dates || []).map((it: any) => `${moment(it.value).format('YYYY-MM-DD[T]HH:mm:ss')}+00:00`)
            })
        })

        let payload = {
            sales_id: data.sales?.value,
            brand_ids: (data.brand || []).map((item: any) => item.id),
            dealer_ids,
            journey_plans
        }

        await (!id ? (JourneyPlanRepository.create('b2b', payload)) : JourneyPlanRepository.update(id, 'b2b', payload)).then((res: AxiosResponse) => {
            navigate(-1)

            if (!id) {
                openNotification('success', t('notification.success.createItem', { item: title }))
            } else {
                openNotification('success', t('notification.success.updateItem', { item: title }))
                loading.set(false);
            }
        }).catch((e: AxiosError) => {
            handleBackendError(e, t('notification.error.default'))
            loading.set(false);
        })
    }

    const getSalesBrandAndDealers = (id: any, clear: boolean = true) => {
        SalesRepository.show(id, { with: ['brands', 'dealers'] }).then((res: AxiosResponse) => {
            let data = res.data?.data;
            if (data.brands && clear) {
                form.setFieldsValue(
                    {
                        ...form.getFieldsValue(true),
                        brand: (data.brands || []).map((item: any) => (
                            {
                                name: item.name,
                                id: item.id
                            }
                        )),
                    }
                )
            }

            if (data.dealers) {
                if(clear){
                    form.setFieldsValue(
                        {
                            ...form.getFieldsValue(true),
                            journey_plans: [
                                {
                                    dealers: {},
                                    dates: ''
                                }
                            ]
                        }
                    )
                }
                

                setDealerOptions(data.dealers)
            }
        }).catch((e: AxiosError) => {
            console.log(e)
        })
    }

    const showImportModal = useState(false)

    const importModalCallback = async (files: File[]) => {
        const formData = new FormData();

        if (files) {
            files.forEach((file: any) => {
                formData.append('file_import', file);
            })
        }

        if(form.getFieldValue('sales')){
            formData.append('sales_id', form.getFieldValue('sales').value)
        }

        await JourneyPlanRepository.import('b2b', formData).then((res: AxiosResponse) => {
            let data = res.data?.data
            let month:any = null;
            let formatted = data?.journey_plans?.map((item: any) => {
                return {
                    dealer: {
                        value: item.dealer_id
                    },
                    dates: item.dates.map((date:any) => {
                        if(!month){
                            month = moment(date).startOf('month')
                        }
                        return {
                            label: moment(date).format('DD-MM-YYYY'),
                            value: moment(date).format('YYYY-MM-DD')
                        }
                    })
                }
            })

            setMonth(month)
            getSalesBrandAndDealers(data.sales.id, false)
            form.setFields([
                {
                    name: 'sales',
                    value: {
                        label: data.sales?.name,
                        value: data.sales?.id,
                    }
                },
                {
                    name: 'brand',
                    value: data.brand_ids
                },
                {
                    name: 'journey_plans',
                    value: formatted.map(() => ({}))
                },
                {
                    name: 'month',
                    value: month
                }
               
            ])
            setTimeout(() => {
                form.setFields([
                    {
                        name: 'journey_plans',
                        value: formatted
                    },
                    
                ])
            })
            
            showImportModal.set(false)
            openNotification('success', t('notification.success.importItem', { item: 'Jadwal' }))
        }).catch((e: AxiosError) => {
            console.log(e)
            handleBackendError(e, t('notification.error.default'))
        })
    }

    const importModalDownload = async () => {
        window.open(JOURNEY_IMPORT.TEMPLATE_URL + `?${_.random(100,900)}`)
    }

    return (
        <>
            <WuiContainer>
                <PageHeader
                    className="default-page-header"
                    onBack={() => navigate(-1)}
                    title={pageTitle}
                    extra={[
                        <Button key="1" type="primary" onClick={() => showImportModal.set(true)}>
                            Import Jadwal
                        </Button>,
                    ]}
                />

                <Form
                    form={form}
                    layout={"vertical"}
                    onFinish={onFinish}
                    onValuesChange={(changedValues) => {
                        if (changedValues.sales) {
                            getSalesBrandAndDealers(changedValues.sales.value)
                        }
                    }}
                    initialValues={{
                        journey_plans: [
                            {
                                dealers: {},
                                dates: ''
                            }
                        ]
                    }}
                >
                    <Row gutter={[16, 16]}>
                        <Col className="gutter-row" md={{
                            span: 16,
                            order: 1
                        }} xs={{
                            span: 24,
                            order: 2
                        }}>
                            <Card title={''} className="mb16">
                                <Space style={{
                                    width: '100%'
                                }} direction="vertical" size={0}>
                                    <div className="wui-form-group type-column">
                                        <div className="input-section">
                                            <Form.Item
                                                name="sales"
                                                label={"Tugaskan ke"}
                                                rules={[{
                                                    required: true,
                                                    message: t('validation.required', {item: "Tugaskan ke"})
                                                }]}
                                            >
                                                <WuiSelectSingle repository={SalesRepository} selectParams={{level: [SALES_CONSULTANT, SALES_SUPERIOR]}}/>
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="wui-form-group type-column">
                                        <div className="input-section">
                                            <Form.Item
                                                name="brand"
                                                label={"Brand"}
                                                rules={[{
                                                    required: true,
                                                    message: t('validation.required', {item: "Brand"})
                                                }]}
                                            >
                                                <WuiSelectMulti placeholder="Pilih Brand" disabled={true} />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="wui-form-group type-column">
                                        <div className="input-section">
                                            <Form.Item
                                                name="month"
                                                label={"Pada Bulan"}
                                                rules={[{
                                                    required: true,
                                                    message: t('validation.required', {item: "Bulan dan Tahun"})
                                                }]}
                                            >

                                                <DatePicker onChange={(value) => setMonth(value)} placeholder="Pilih Bulan" format="MMMM YYYY" style={{ width: '100%' }}
                                                    disabledDate={(current) => {
                                                        let customDate = moment().format("YYYY-MM-DD");
                                                        return current && current < moment(customDate, "YYYY-MM-DD");
                                                    }} size={'large'} picker="month" />
                                            </Form.Item>
                                        </div>
                                    </div>

                                </Space>
                            </Card>

                            <Form.List name="journey_plans">
                                {(fields, { add, remove }) => (
                                    <Space style={{
                                        width: '100%'
                                    }} direction="vertical" size={15}>
                                        {
                                            fields.map(({ key, name, ...restField }) => (
                                                <Card title={''} key={key}>
                                                    <Row align={"middle"} justify={"space-between"}>
                                                        <Title level={5}>Dealer {key + 1}</Title>
                                                        {
                                                            key !== 0 ? (
                                                                <DeleteOutlined onClick={() => remove(name)} />
                                                            ) : ''
                                                        }
                                                    </Row>

                                                    <div className="wui-form-group type-column">
                                                        <div className="input-section">
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'dealer']}
                                                                label={"Dealer yang dituju"}
                                                                rules={[{
                                                                    required: true,
                                                                    message: t('validation.required', {item: "Dealer yang dituju"})
                                                                }]}
                                                            >
                                                                <Select showSearch filterOption={(input, option) => !!_.get(option, 'label', '')?.toString().toLowerCase().includes(input.toLowerCase())} labelInValue={true} disabled={dealerOptions.length === 0} size={"large"} placeholder="Pilih dealer" style={{
                                                                    width: '100%'
                                                                }}>
                                                                    {
                                                                        dealerOptions.map((item: any) => 
                                                                        <Option value={item.id} label={`${item?.name} - ${item?.code}`} key={item.id}>
                                                                            <div className="custom-dealer-option">
                                                                                <div>{item?.name}  - {item?.code}</div>
                                                                                <div className={"address"}>{item?.address}</div>
                                                                            </div>
                                                                        </Option>
                                                                        )
                                                                    }
                                                                </Select>
                                                            </Form.Item>
                                                        </div>
                                                    </div>

                                                    <div className="wui-form-group type-column">
                                                        <div className="input-section">
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'dates']}
                                                                label={"Pilih Tanggal"}
                                                                rules={[{
                                                                    required: true,
                                                                    message: t('validation.required', {item: "Tanggal"})
                                                                }]}
                                                            >
                                                                <MultipleDateSelector selectedMonth={selectedMonth} />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </Card>
                                            ))}

                                        <Form.Item className={"mt16"}>
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                Tambah Dealer
                                            </Button>
                                        </Form.Item>
                                    </Space>
                                )}
                            </Form.List>
                        </Col>
                    </Row>


                    <div className="wui-form-btn-group">
                        <Button className="wui-btn" size={"large"} onClick={() => navigate(-1)}>{t('common.button.cancel')}</Button>
                        <Accessible access={permissions.write_journey_plan_b2b}>
                            <Spin spinning={loading.get()}>
                                <Button className="wui-btn" htmlType="submit" type="primary" size={"large"} disabled={disable.get()}>{t('common.button.save')}</Button>
                            </Spin>
                        </Accessible>
                    </div>
                </Form>
            </WuiContainer>

            <WuiModalImport
                show={showImportModal.get()}
                onUpload={importModalCallback}
                onCancel={() => showImportModal.set(false)}
                headerTitle="Import Jadwal"
                onDownload={importModalDownload}
                maxFileOnMB={10}
            />
        </>
    )
}

export default AppJourneyPlanFormPlan