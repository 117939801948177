import Table from "antd/lib/table";
import Moment from "react-moment";
import React from "react";

interface Props {
  dataSource?: any[];
}

const TableFraud: React.FC<Props> = ({ dataSource}) => {
  const columns = [
    
    {
      title: "No Balk",
      dataIndex: "no_balk",
      key: "no_balk",
    },
    {
      title: "NIK",
      dataIndex: "user_nik",
      key: "user_nik",
    },
    {
      title: "Name",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "Lokasi (Store)",
      dataIndex: "store_name",
      key: "store_name",
    },
    {
      title: "Tanggal Temuan Kasus",
      dataIndex: "finding_date",
      key: "finding_date",
      render: (text: any) => {
        return <Moment format="DD MMMM YYYY">{text}</Moment>;
      },
    },
    {
      title: "Tanggal Upload",
      dataIndex: "upload_date",
      key: "upload_date",
      render: (text: any) => {
        return <Moment format="DD MMMM YYYY">{text}</Moment>;
      },
    },
    
  ];

  return (
    <>
      <Table
        rowKey={"id"}
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
    </>
  );
};

export default TableFraud;
