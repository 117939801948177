import { api, createCancelTokenHandler } from "../@framework/services";
import { TChannels } from "../utils";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  select: function (params: any = null) {
    let url = `${endPoint()}/api/v1/b2c/select/stores`;
    if (params?.channel) {
      url = `${endPoint()}/api/v1/${params.channel}/select/stores`;
    }
    return api.get(url, {
      params,
    });
  },
  all: function (channel: TChannels = "b2c", params: any = null) {
    return api.get(`${endPoint()}/api/v1/${channel}/stores`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.all.name].handleRequestCancellation()
          .token,
    });
  },
  show: function (
    channel: TChannels = "b2c",
    id: number | string,
    params: any = null
  ) {
    return api.get(`${endPoint()}/api/v1/${channel}/store/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.show.name].handleRequestCancellation()
          .token,
    });
  },
  create: function (
    channel: TChannels = "b2c",
    payload: any,
    params: any = null
  ) {
    return api.post(`${endPoint()}/api/v1/${channel}/store`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.create.name].handleRequestCancellation()
          .token,
    });
  },
  update: function (
    channel: TChannels = "b2c",
    id: number | string,
    payload: any,
    params: any = null
  ) {
    return api.put(`${endPoint()}/api/v1/${channel}/store/${id}`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.update.name].handleRequestCancellation()
          .token,
    });
  },
  delete: function (
    channel: TChannels = "b2c",
    id: number | string,
    params: any = null
  ) {
    return api.delete(`${endPoint()}/api/v1/${channel}/store/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.delete.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;
