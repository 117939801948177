import { Space, Tag } from "antd";
import Button from "antd/lib/button";
import Table from "antd/lib/table";
import React, {useEffect} from "react";
import Moment from "react-moment";
import { NumericFormat } from "react-number-format";
import { ModalTransactionDetail } from "../../Modal";
import {Col, Row} from "antd/lib/grid";
import WuiFilterDateRange from "../../../../../../../../../../@framework/wui/components/Filter/DateRange";
import moment from "moment";
import {useSearchParams} from "react-router-dom";
import {TChannels} from "../../../../../../../../../../utils";
import {useState} from "@hookstate/core";
import DealerRepository from "../../../../../../../../../../repositories/DealerRepository";
import {AxiosError, AxiosResponse} from "axios";
import {openNotification} from "../../../../../../../../../../functions/global";
import {useTranslation} from "react-i18next";

interface Props {
  channel?: TChannels;
  dealerId?: number | string
}

const statusTagColor: any = {
  Ditolak: "error",
  Pending: "warning",
  Diterima: "success",
};

const TableTransaction: React.FC<Props> = (
    {
      channel = 'b2b',
      dealerId= 0
    }
  ) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const tablePage = useState<number>(parseInt(searchParams.get('page') || '1'))
  const tablePerPage = useState<number>(parseInt(searchParams.get('perPage') || '10'))
  const [tableLoading, setTableLoading] = React.useState<boolean>(false)
  const tableSort = useState<{
    sortField: any,
    sortOrder: any,
  }>({
    sortField: searchParams.get('sortField') || 'id',
    sortOrder: searchParams.get('sortOrder') || 'desc'
  })
  const tableData = useState([]);
  const totalData = useState<number>(0)

  const columns = [
    {
      title: "SO Date",
      dataIndex: "sales_order_date",
      key: "sales_order_date",
      render: (text: any, record: any) => {
        return <Moment format="DD/MM/YY">{text}</Moment>;
      },
    },
    {
      title: "SO No",
      dataIndex: "sales_order_no",
      key: "sales_order_no",
    },
    {
      title: "Order Ref No",
      dataIndex: "sales_order_ref_no",
      key: "sales_order_ref_no",
    },
    {
      title: "Dealer Code",
      dataIndex: "dealer_code",
      key: "dealer_code",
    },
    {
      title: "Dealer Name",
      dataIndex: "dealer_name",
      key: "dealer_name",
    },
    {
      title: "SO Status",
      dataIndex: "sales_order_status",
      key: "sales_order_status",
      render: (text: any, record: any) => {
        return <Tag color={statusTagColor[text]}>{text}</Tag>;
      },
    },
    {
      title: "Total Amount",
      dataIndex: "total_amount",
      key: "total_amount",
      render: (text: any) => {
        return (
            <NumericFormat
                prefix="Rp. "
                value={text}
                thousandSeparator={"."}
                decimalSeparator={","}
                displayType="text"
            />
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
          <Space size="middle">
            <Button type="link" onClick={() => handleShowDetailModal(record.id, record.sales_order_no)}>
              Lihat Detail
            </Button>
          </Space>
      ),
    },
  ];

  const getTableData = async (reset: boolean = false) => {
    setTableLoading(true)

    if (reset) tablePage.set(1);

    let params = {
      page: tablePage.get(),
      order_by: tableSort.sortField.get(),
      sorted_by: tableSort.sortOrder.get(),
      per_page: tablePerPage.get(),
      start_date: filterDate[0].startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      end_date: filterDate[1].endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    }

    await DealerRepository.allSalesTransaction(channel, dealerId, params).then((res: AxiosResponse) => {
      if (res.data.data) {
        tableData.set(res.data.data)
      }

      totalData.set(res.data?.total || 0)

      setTableLoading(false)
    }).catch((e: AxiosError) => {
      setTableLoading(false)
    })
  }

  const handleTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    tablePage.set(pagination.current)
    tablePerPage.set(pagination.pageSize)

    if (sorter.order) {
      tableSort.sortField.set(sorter.field)
      tableSort.sortOrder.set(sorter.order)
    } else {
      tableSort.sortField.set(null)
      tableSort.sortOrder.set(null)
    }

    getTableData()
  }

  // For DateRange Picker
  const [filterDate, setFilterDate] = React.useState<any>([
    (searchParams.get('start_date') ? moment((searchParams.get('start_date')), 'YYYY-MM-DD') : moment().startOf('month')),
    (searchParams.get('end_date') ? moment((searchParams.get('end_date')), 'YYYY-MM-DD') : moment().endOf('month'))
  ])

  const handleDateRangeCallback = (dates: any, dateString?: any) => {
    setFilterDate(dates)
  }

  const handleReset = () => {
    setFilterDate([moment().startOf('month'), moment().endOf('month')])
  }

  // Modal
  const modalSONumber = useState<string>('')
  const modalTableData = useState<any[]>([])
  const modalTableLoading = useState<boolean>(false)
  const [showDetail, setShowDetail] = React.useState<boolean>(false);

  const handleShowDetailModal = async (id: number, soNumber: string) => {
    modalTableLoading.set(true)
    modalSONumber.set(soNumber)

    await DealerRepository.showSalesTransaction(channel, dealerId, id).then((res: AxiosResponse) => {
      if (res.data.data) {
        modalTableData.set(res.data.data)
      }

      modalTableLoading.set(false)
      setShowDetail(true)
    }).catch((e: AxiosError) => {
      openNotification("error", t("notification.error.default"));
      modalTableLoading.set(false)
    })
  }


  useEffect(() => {
    getTableData(true)
    // eslint-disable-next-line
  }, [filterDate])

  useEffect(() => {
    getTableData(true)
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Space
          style={{
            width: "100%",
            overflowX: "scroll",
          }}
          direction="vertical"
          size={25}
      >
        <Row>
          <Col className="gutter-row" xs={12}>
            <WuiFilterDateRange
                defaultValue={filterDate}
                callback={handleDateRangeCallback}
            />
          </Col>
          <Col>
            <Button type="link" onClick={handleReset}>
              Reset
            </Button>
          </Col>
        </Row>

        <Table
            rowKey={"id"}
            bordered
            columns={columns}
            dataSource={tableData.get()}
            loading={tableLoading}
            onChange={handleTableChange}
            pagination={{
              current: tablePage.get(),
              simple: true,
              pageSize: tablePerPage.get(),
              total: totalData.get()
            }}
            scroll={{ x: "max-content" }}
        />
      </Space>

      <ModalTransactionDetail
        show={showDetail}
        handleClose={() => setShowDetail(false)}
        soNumber={modalSONumber.get()}
        tableData={modalTableData.get()}
      />
    </>
  );
};

export default TableTransaction;
