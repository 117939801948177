import { Button, Spin } from "antd";
import Upload from "antd/es/upload/Upload";
import Modal from "antd/lib/modal/Modal";
import { AxiosError, AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toFileList } from "../../../../../../../functions/global";
import IssueTrackingRepository from "../../../../../../../repositories/IssueTrackingRepository";

interface Props {
  issueId?: any;
  onClose: () => void;
}

const ModalIssueAttachment: React.FC<Props> = ({ issueId, onClose }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [attachments, setAttachments] = React.useState<any>([
    {
      uid: "-1",
      name: "image.png",
      status: "done",
      url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    },
    {
      uid: "-2",
      name: "image.png",
      status: "done",
      url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    },
    {
      uid: "-3",
      name: "document.pdf",
      status: "done",
      url: "https://www.clickdimensions.com/links/TestPDFfile.pdf",
    },
  ]);

  useEffect(() => {
    if (!!issueId) {
      getData();
    } else {
      setAttachments([]);
    }
    // eslint-disable-next-line
  }, [issueId]);

  const getData = async () => {
    setIsLoading(true);

    let params = {
      with: ["attachments"],
    };
    await IssueTrackingRepository.show("b2c", issueId || "", params)
      .then((res: AxiosResponse) => {
        if (res.data.data) {
          const data = res.data?.data;
          setAttachments(toFileList(data?.attachments || []));
        }

        setIsLoading(false);
      })
      .catch((e: AxiosError) => {
        setIsLoading(false);
      });
  };

  const handleOnPreview = async (file: any) => {
    let src = file.url;
    window.open(src, "_blank", "noreferrer");
  };

  return (
    <Modal
      title={`Detail Lampiran`}
      centered
      open={!!issueId}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          {t("common.button.back")}
        </Button>,
      ]}
    >
      {attachments.length ? (
        <Upload
          disabled={true}
          listType="picture-card"
          fileList={attachments}
          onPreview={handleOnPreview}
        />
      ) : (
        <>
          {
            <Spin spinning={isLoading}>
              <p>Tidak ada lampiran</p>
            </Spin>
          }
        </>
      )}
    </Modal>
  );
};

export default ModalIssueAttachment;
