import { SearchOutlined } from "@ant-design/icons";
import { useState } from "@hookstate/core";
import { Button, Col, Input, Row } from "antd";
import Space from "antd/es/space";
import Table from "antd/es/table";
import { AxiosError, AxiosResponse } from "axios";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useDebounce from "../../../../../../../@framework/utilities/hooks/useDebounce";
import WuiFilterDateRange from "../../../../../../../@framework/wui/components/Filter/DateRange";
import AssistantStoreLeaderRepository from "../../../../../../../repositories/AssistantStoreLeaderRepository";
import { TChannels } from "../../../../../../../utils";
import { ModalIssueAttachment } from '../Modal';

interface Props {
  channel: TChannels
  assistantId: number
  storeId: number
}

const TablePendingIssue: React.FC<Props> = (
    {
      channel = 'b2c',
      assistantId,
      storeId
    }
    ) => {
  const { t } = useTranslation();

  const tablePage = useState<number>(1);
  const tablePerPage = useState<number>(10);
  const [tableLoading, setTableLoading] = React.useState<boolean>(false);
  const tableSort = useState<{
    sortField: any;
    sortOrder: any;
  }>({
    sortField: "",
    sortOrder: "",
  });
  const tableData = useState<any[]>([]);
  const totalData = useState<number>(0);

  // For Search Input
  const [search, setSearch] = React.useState<string>("");
  const debouncedSearch = useDebounce<string>(search, 1000);

  // For DateRange Picker
  const [filterDate, setFilterDate] = React.useState<any>([moment().startOf('month'), moment().endOf('month')]);

  const handleDateRangeCallback = (dates: any, dateString?: any) => {
    setFilterDate(dates);
  };

  useEffect(() => {
    getTableData(true);
    // eslint-disable-next-line
  }, [debouncedSearch, filterDate, true]);

  const getTableData = async (reset: boolean = false) => {
    setTableLoading(true)
    if (reset) tablePage.set(1);
    let params = {
        assistant_id: assistantId,
        store_id: storeId,
        page: tablePage.get(),
        search: search,
        order_by: tableSort.sortField.get(),
        sorted_by: tableSort.sortOrder.get(),
        per_page: tablePerPage.get(),
        start_date: filterDate[0].format('YYYY-MM-DD'),
        end_date: filterDate[1].format('YYYY-MM-DD'),
        with: ['subject']
    }
    await AssistantStoreLeaderRepository.pendingIssue(channel, params).then((res: AxiosResponse) => {
        if (res.data.data) {
            tableData.set(res.data.data)
        }
        totalData.set(res.data?.total || 0)
        setTableLoading(false)
    }).catch((e: AxiosError) => {
        setTableLoading(false)
    })
  };

  const handleTableChange = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {
    tablePage.set(pagination.current);
    tablePerPage.set(pagination.pageSize);

    if (sorter.order) {
      tableSort.sortField.set(sorter.field);
      tableSort.sortOrder.set(sorter.order);
    } else {
      tableSort.sortField.set(null);
      tableSort.sortOrder.set(null);
    }

    getTableData();
  };

  const columns: any = [
    {
      title: "Tanggal",
      dataIndex: "created_at",
      key: "created_at",
      render: (text: any) => {
        return moment(text).format("D MMM YYYY");
      },
    },
    {
      title: "Deskripsi",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Lampiran",
      dataIndex: "attachment",
      key: "attachment",
      render: (text: any, record: any, index: number) => {
        return (
          <Button type={"primary"} onClick={() => handleShowAttachmentModal(record?.id)}>
            Lihat Lampiran
          </Button>
        );
      },
    },
  ];

  // Attachment Modal
  const showAttachmentModal = useState<any>(null)

  const handleShowAttachmentModal = (issueId: any) => {
      showAttachmentModal.set(issueId)
  }

  return (
    <>
      <Space
          style={{
            width: "100%",
          }}
          direction="vertical"
          size={25}
      >
        <Row>
          <Col md={12} xs={24}>
            <Input
                allowClear
                placeholder={t("common.filter.search.placeholder")}
                prefix={<SearchOutlined />}
                value={search}
                onChange={(value) => {
                  setSearch(value.target.value);
                }}
            />
          </Col>
        </Row>

        <Row>
          <Col md={12} xs={24}>
            <WuiFilterDateRange
                defaultValue={filterDate}
                callback={handleDateRangeCallback}
            />
          </Col>
        </Row>

        <Table
            rowKey={"id"}
            bordered
            columns={columns}
            dataSource={tableData.get()}
            loading={tableLoading}
            onChange={handleTableChange}
            pagination={{
              current: tablePage.get(),
              simple: true,
              pageSize: tablePerPage.get(),
              total: totalData.get(),
            }}
        />
      </Space>

      <ModalIssueAttachment
        issueId={showAttachmentModal.get()}
        onClose={() => showAttachmentModal.set(null)}
      />
    </>
  );
};

export default TablePendingIssue;
