import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useState } from "@hookstate/core";
import Button from "antd/es/button";
import Collapse from "antd/es/collapse";
import Col from "antd/es/grid/col";
import Row from "antd/es/grid/row";
import Input from "antd/es/input";
import Space from "antd/es/space";
import Table from "antd/es/table";
import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useIsMounted } from "../../../../../@framework/utilities/hooks";
import useDebounce from "../../../../../@framework/utilities/hooks/useDebounce";
import Accessible from "../../../../../@framework/wui/components/Accessible";
import WuiContainer from "../../../../../@framework/wui/components/Container";
import WuiSectionTitle from "../../../../../@framework/wui/components/Sections/Title";
import WuiSelectMulti from "../../../../../@framework/wui/components/Select/Multi";
import {
    CHANNEL_TYPE_SELECT_OPTIONS
} from "../../../../../constant";
import KPIItemRepository from "../../../../../repositories/KPIItemRepository";
import {AxiosError, AxiosResponse} from "axios";
import {permissions} from "../../../../../constant/permissions";
import Text from "antd/es/typography/Text";
import {handleBackendError, openNotification} from "../../../../../functions/global";
import WuiModalConfirmation from "../../../../../@framework/wui/components/Modal/Confirmation";

const channelOptions = CHANNEL_TYPE_SELECT_OPTIONS

const { Panel } = Collapse;

var qs = require('qs');

const breadcrumbs = [
    {
        label: 'KPI',
        link: '/kpi'
    },
    {
        label: 'Item KPI',
        link: '/kpi/item-kpi'
    },
]

const AppKPIItemList: React.FC<any> = () => {
    const {t} = useTranslation()
    const isMounted = useIsMounted()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    let fullPath = window.location.pathname;

    // Table
    const tablePage = useState<number>(parseInt(searchParams.get('page') || '1'))
    const tablePerPage = useState<number>(parseInt(searchParams.get('perPage') || '10'))
    const [tableLoading, setTableLoading] = React.useState<boolean>(false)
    const tableSort = useState<{
        sortField: any,
        sortOrder: any,
    }>({
        sortField: searchParams.get('sortField'),
        sortOrder: searchParams.get('sortOrder')
    })
    const tableData = useState<any[]>([]);
    const totalData = useState<number>(0)

    const getTableData = async (reset: boolean = false) => {
        setTableLoading(true)

        if (reset) tablePage.set(1);

        let params = {
            page: tablePage.get(),
            search: debouncedSearch,
            order_by: tableSort.sortField.get(),
            sorted_by: tableSort.sortOrder.get(),
            per_page: tablePerPage.get(),
        }

        if(!params.order_by){
            params.order_by = 'created_at';
            params.sorted_by = 'descend';
        }

        const channels = _.map(selectedChannels, "name");

        if (channels) {
            Object.assign(params, {
                channel: channels,
            });
        }

        await KPIItemRepository.all(params).then((res: AxiosResponse) => {
            if (res.data.data) {
                tableData.set(res.data.data)
            }

            totalData.set(res.data?.total || 0)

            setTableLoading(false)
        }).catch((e: AxiosError) => {

            setTableLoading(false)
        })
    }

    const columns: any = [
        {
            title: "Nama",
            dataIndex: "name",
            key: "name",
            sorter: true,
            defaultSortOrder: tableSort.sortField.get() === "name" && tableSort.sortOrder.get(),
        },
        {
            title: "Channel",
            dataIndex: "channel",
            key: "channel",
            sorter: false,
            render: (text: any, record: any) => {
                return (
                    <>
                        {
                            record.is_b2b && record.is_b2c ? (
                                'B2B & B2C'
                            ) : (record.is_b2b ? 'B2B' : 'B2C')
                        }
                    </>
                )
            }
        },
        {
            title: "Action",
            key: "action",
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Link to={fullPath + "/" + record.id}>Lihat Detail</Link>
                    <Accessible access={[permissions.delete_item_kpi_b2b, permissions.delete_item_kpi_b2c]}>
                        <Text
                            className="cursor-pointer"
                            type={"danger"}
                            onClick={() => setDeleteModal(record.id)}
                        >
                            Hapus
                        </Text>
                    </Accessible>
                </Space>
            ),
        },
    ]

    // Modal
    const showDeleteModal = useState(false);
    const selectedDeleteId = useState(0);

    const setDeleteModal = (id: any) => {
        selectedDeleteId.set(id);
        showDeleteModal.set(true);
    };

    const deleteModalCallback = async () => {
        if (selectedDeleteId.get()) {
            await KPIItemRepository.delete(selectedDeleteId.get())
            .then((res: AxiosResponse) => {
                showDeleteModal.set(false);
                selectedDeleteId.set(0);
                openNotification(
                    "success",
                    t("notification.success.deleteItem", { item: "Kpi Item" })
                );
                getTableData(true);
            })
            .catch((e: AxiosError) => {
                showDeleteModal.set(false);
                selectedDeleteId.set(0);
                handleBackendError(e, t("notification.error.default"));
            });
        }
    };

    const handleTableChange = (
        pagination: any,
        filters: any,
        sorter: any,
        extra: any
    ) => {
        tablePage.set(pagination.current);
        tablePerPage.set(pagination.pageSize);

        if (sorter.order) {
            tableSort.sortField.set(sorter.field);
            tableSort.sortOrder.set(sorter.order);
        } else {
            tableSort.sortField.set(null);
            tableSort.sortOrder.set(null);
        }

        setFilterDataToQuery();
    };

    // For Search Input
    const [search, setSearch] = React.useState<string>('')
    const debouncedSearch = useDebounce<string>(search, 1000)

    // For Filter
    const localSelectedChannels = JSON.parse(
        localStorage.getItem("kpi.master.channels") || "[]"
    );
    const [selectedChannels, setSelectedChannels] = React.useState<any[]>([]);

    useEffect(() => {
        if (!isMounted) {
            setFilterDataToQuery()
        }
        // eslint-disable-next-line
    }, [debouncedSearch])

    useEffect(() => {
        setFilterDataToQuery()
        // eslint-disable-next-line
    }, [selectedChannels])

    const setFilterDataToQuery = () => {
        let params = {}
        let reset = false;

        if (debouncedSearch) {
            Object.assign(params, {
                keyword: debouncedSearch
            })

            if(debouncedSearch !== searchParams.get('keyword')){
                reset = true
            }
        }

        if (tablePage.get() !== 1 && !reset) {
            Object.assign(params, {
                page: tablePage.get(),
            });
        }

        if (tablePerPage.get() !== 10 && !reset) {
            Object.assign(params, {
                perPage: tablePerPage.get(),
            });
        }

        if (tableSort.sortField.get() && !reset) {
            Object.assign(params, {
                sortField: tableSort.sortField.get(),
            });
        }

        if (tableSort.sortOrder.get() && !reset) {
            Object.assign(params, {
                sortOrder: tableSort.sortOrder.get(),
            });
        }

        if (selectedChannels) {
            const multiValue = _.map(selectedChannels, "name");

            Object.assign(params, {
                channels: multiValue,
            });
        }

        const queryParams = qs.stringify(params , { indices: false });

        if (queryParams) {
            setSearchParams(`?${queryParams}`)
        } else {
            navigate('')
        }

        getTableData(reset)
    }

    const getFilterDataFromQuery = () => {
        const keyword = searchParams.get("keyword");

        if (keyword) {
            setSearch(keyword);
        }

        if (!localSelectedChannels) {
            searchParams.delete("channels");
        } else {
            const items = _.map(searchParams.getAll("channels"), (item) =>
                item
            );
            if (items) {
                let itemFromStorage = _.filter(localSelectedChannels, (data) => {
                    return items.includes(data.name);
                });
                setSelectedChannels(itemFromStorage);
            } else {
                localStorage.removeItem("kpi.master.channels");
            }
        }
    };

    const handleReset = () => {
        setSelectedChannels([])
    }

    useMemo(() => {
        getFilterDataFromQuery();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <WuiContainer>
                <WuiSectionTitle title={'Item KPI'} breadcrumbs={breadcrumbs}>
                    <Accessible access={[permissions.read_item_kpi_b2b, permissions.write_item_kpi_b2c]}>
                        <Button onClick={() => navigate(fullPath+'/form')} type="primary" icon={<PlusOutlined />}>{t('common.button.addNew')}</Button>
                    </Accessible>
                </WuiSectionTitle>

                <Row gutter={[10, 10]} className="mb16">
                    <Col className="gutter-row" span={6} xs={24} md={12} lg={10}>
                        <Input
                            allowClear
                            placeholder={t('common.filter.search.placeholder')}
                            prefix={<SearchOutlined />}
                            value={search}
                            onChange={(value) => {
                                setSearch(value.target.value)
                            }}
                        />
                    </Col>
                </Row>

                <Collapse
                    className="mb16"
                    defaultActiveKey={['1']}
                    expandIconPosition={"right"}
                >
                    <Panel header="Filter" key="1">
                        <Row className="mb6" gutter={[10, 10]}>
                            <Col className="gutter-row" xs={24} md={12} lg={6}>
                                <WuiSelectMulti
                                    value={selectedChannels}
                                    onChange={(val: any) => {
                                        tablePage.set(1);
                                        setSelectedChannels(val);
                                        localStorage.setItem('kpi.master.channels', JSON.stringify(val))
                                    }}
                                    options={channelOptions}
                                    placeholder={'Semua Channel'}
                                />
                            </Col>

                            <Col>
                                <Button type="link" onClick={handleReset}>Reset</Button>
                            </Col>
                        </Row>
                    </Panel>
                </Collapse>

                <Table
                    rowKey={"id"}
                    bordered
                    columns={columns}
                    dataSource={tableData.get()}
                    loading={tableLoading}
                    onChange={handleTableChange}
                    pagination={{
                        current: tablePage.get(),
                        simple: true,
                        pageSize: tablePerPage.get(),
                        total: totalData.get(),
                    }}
                />
            </WuiContainer>

            <WuiModalConfirmation
                show={showDeleteModal.get()}
                onOk={deleteModalCallback}
                onCancel={() => showDeleteModal.set(false)}
            />
        </>
    )
}

export default AppKPIItemList