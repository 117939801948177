import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PageHeader from "antd/es/page-header";
import { useNavigate, useParams } from "react-router-dom";
import WuiContainer from "../../../../../../@framework/wui/components/Container";
import Card from "antd/lib/card";
import Space from "antd/lib/space";
import Input from "antd/es/input";
import Form from "antd/lib/form";
import { useState } from "@hookstate/core";
import Button from "antd/es/button";
import AreaRepository from "../../../../../../repositories/AreaRepository";
import { AxiosError, AxiosResponse } from "axios";
import {
  handleBackendError,
  haveAccess, inputUppercase,
  openNotification,
} from "../../../../../../functions/global";
import Spin from "antd/lib/spin";
import { Col, Row } from "antd/lib/grid";
import WuiSelectSingle from "../../../../../../@framework/wui/components/Select/Single";
import SubTerritoryRepository from "../../../../../../repositories/SubTerritoryRepository";
import Accessible from "../../../../../../@framework/wui/components/Accessible";
import useAccess from "../../../../../../@framework/utilities/hooks/useAccess";
import { TChannels } from "../../../../../../utils";
import TerritoryRepository from "../../../../../../repositories/TerritoryRepository";
import { permissions } from "../../../../../../constant/permissions";
import WuiFormTitle from "../../../../../../@framework/wui/components/Form/Title";
import { Radio } from "antd/es";

let title = "Area";

const channelTypes: TChannels[] = ["b2b", "b2c"];

const AppSettingsDataMasterAreaForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const { channel, id } = useParams();
  const navigate = useNavigate();
  const pageTitle = id
    ? t("common.text.editItem", { item: title })
    : t("common.text.addNewItem", { item: title });

  // Form
  const [form] = Form.useForm();
  const disable = useState(false);
  const loading = useState(false);
  const acl = useAccess();

  const channelState = useState<TChannels>(channel || channelTypes[0]);
  const permissionMapping: any = {
    b2b: {
      write: permissions.write_area_b2b,
      read: permissions.read_area_b2b,
    },
    b2c: {
      write: permissions.write_area_b2c,
      read: permissions.read_area_b2c,
    },
  };

  const onFinish = async (data: any) => {
    loading.set(true);

    let payload = {
      code: data.code,
      name: data.name,
    };

    if (channelState.get() === "b2b") {
      Object.assign(payload, {
        subterritory_id: data.subterritory?.value,
      });
    }

    if (channelState.get() === "b2c") {
      Object.assign(payload, {
        territory_id: data.territory?.value,
      });
    }

    await (!id
      ? AreaRepository.create(payload, channelState.get())
      : AreaRepository.update(id, payload, channelState.get())
    )
      .then((res: AxiosResponse) => {
        navigate(-1);

        if (!id) {
          openNotification(
            "success",
            t("notification.success.createItem", { item: title })
          );
        } else {
          openNotification(
            "success",
            t("notification.success.updateItem", { item: title })
          );
          loading.set(false);
        }
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));
        loading.set(false);
      });
  };

  useEffect(() => {
    if (id) {
      getData();
    }
    if (!channel) {
      init();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const init = () => {
    const channelFilter = channelTypes.filter((type) =>
      haveAccess(acl, permissionMapping[type].write)
    );

    if (channelFilter.length > 0) {
      channelState.set(channelFilter[0]);
    }
  };

  const getData = async () => {
    if (!haveAccess(acl, permissionMapping[channelState.get()].read)) {
      return;
    }

    disable.set(true);

    await AreaRepository.show(id, channelState.get(), {
      with: channelState.get() === "b2b" ? "subterritory" : "territory",
    })
      .then((res: AxiosResponse) => {
        const value = {
          code: res.data?.data?.code,
          name: res.data?.data?.name,
        };

        if (channelState.get() === "b2b") {
          Object.assign(value, {
            subterritory: {
              value: res.data?.data?.subterritory.id,
              label: res.data?.data?.subterritory.name,
            },
          });
        }

        if (channelState.get() === "b2c") {
          Object.assign(value, {
            territory: {
              value: res.data?.data?.territory.id,
              label: res.data?.data?.territory.name,
            },
          });
        }

        form.setFieldsValue(value);

        if (haveAccess(acl, permissionMapping[channelState.get()].write)) {
          disable.set(false);
        }
      })
      .catch((e: AxiosError) => {
        openNotification("error", t("notification.error.default"));
      });
  };

  return (
    <>
      <WuiContainer>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
        />

        <Form form={form} layout={"vertical"} onFinish={onFinish}>
          <Row>
            <Col md={16} xs={24}>
              <Card title={t("common.text.itemInformation", { item: title })}>
                <Space
                  style={{
                    width: "100%",
                  }}
                  direction="vertical"
                  size={5}
                >
                  {id && (
                    <div className="wui-form-group type-column">
                      <div className="input-section">
                        <Form.Item name="id" label={"ID"}>
                          <Input
                            defaultValue={id}
                            disabled
                            size={"large"}
                            placeholder={t("common.text.input", { item: "ID" })}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  )}
                  <div className="wui-form-group type-column mb24">
                    <WuiFormTitle title={"Channel"} />

                    <div className="input-section">
                      <Radio.Group
                        onChange={(e) => channelState.set(e.target.value)}
                        defaultValue={channelState.get()}
                        disabled={disable.get() || !!id || !!channel}
                      >
                        {channelTypes
                          .filter((type) =>
                            haveAccess(acl, permissionMapping[type].write)
                          )
                          .map((type) => {
                            return (
                              <Radio key={type} value={type}>
                                {type.toUpperCase()}
                              </Radio>
                            );
                          })}
                      </Radio.Group>
                    </div>
                  </div>
                  {channelState.get() === "b2b" ? (
                    <div className="wui-form-group type-column">
                      <div className="input-section">
                        <Form.Item
                          name="subterritory"
                          label={"Sub Territory"}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: "Sub Territory",
                              }),
                            },
                          ]}

                        >
                          <WuiSelectSingle
                            disabled={disable.get()}
                            repository={SubTerritoryRepository}
                            showOptionDescription
                          />
                        </Form.Item>
                      </div>
                    </div>
                  ) : (
                    <div className="wui-form-group type-column">
                      <div className="input-section">
                        <Form.Item
                          name="territory"
                          label={"Territory"}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: "Territory",
                              }),
                            },
                          ]}
                        >
                          <WuiSelectSingle
                            disabled={disable.get()}
                            repository={TerritoryRepository}
                            selectParams={{
                              channel: "b2c",
                            }}
                            showOptionDescription
                          />
                        </Form.Item>
                      </div>
                    </div>
                  )}

                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="code"
                        label={"Kode"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", { item: "Kode" }),
                          },
                        ]}
                      >
                        <Input
                          disabled={disable.get()}
                          size={"large"}
                          placeholder={t("common.text.input", { item: "Kode" })}
                          onInput={inputUppercase.formatter}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="name"
                        label={"Area"}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", { item: "Area" }),
                          },
                        ]}
                      >
                        <Input
                          disabled={disable.get()}
                          size={"large"}
                          placeholder={t("common.text.input", { item: "Area" })}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Space>
              </Card>
            </Col>
          </Row>

          <div className="wui-form-btn-group">
            <Button
              className="wui-btn"
              size={"large"}
              onClick={() => navigate(-1)}
            >
              {t("common.button.cancel")}
            </Button>

            <Accessible access={permissionMapping[channelState.get()].write}>
              <Spin spinning={loading.get()}>
                <Button
                  className="wui-btn"
                  htmlType="submit"
                  type="primary"
                  size={"large"}
                  disabled={disable.get()}
                >
                  {t("common.button.save")}
                </Button>
              </Spin>
            </Accessible>
          </div>
        </Form>
      </WuiContainer>
    </>
  );
};

export default AppSettingsDataMasterAreaForm;
