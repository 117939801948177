/* eslint-disable eqeqeq */
/* eslint-disable no-mixed-operators */
import React, { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "antd/es/page-header";
import WuiContainer from "../../../../@framework/wui/components/Container";
import WuiSectionTitle from "../../../../@framework/wui/components/Sections/Title";
import Row from "antd/es/grid/row";
import Col from "antd/es/grid/col";
import Card from "antd/es/card/Card";
import WuiFormTitle from "../../../../@framework/wui/components/Form/Title";
import Text from "antd/es/typography/Text";
import Space from "antd/es/space";
import Form from "antd/lib/form";
import { useState } from "@hookstate/core";
import { Spin, Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { FileExcelOutlined, UploadOutlined } from "@ant-design/icons";
import Button from "antd/es/button";
import WuiModalConfirmation from "../../../../@framework/wui/components/Modal/Confirmation";
import moment from "moment";
import StockOpnameRepository from "../../../../repositories/StockOpnameRepository";
import { AxiosError, AxiosResponse } from "axios";
import {
  handleBackendError,
  openNotification,
  toFileList,
} from "../../../../functions/global";
import WuiLoadingScreen from "../../../../@framework/wui/components/LoadingScreen";
import RejectRevisionStockOpnameModal from "../../../../components/reject-revision-stock-opname-modal";
import RequestRevisionStockOpnameModal from "../../../../components/request-revision-stock-opname-modal";
import { STOCK_OPNAME_STATUS_REJECTED, STOCK_OPNAME_STATUS_REQUEST_REVISION } from "../../../../constant";
import { useReactToPrint } from "react-to-print";
import { StockOpname, StockOpnameRevision } from "../interface";
import StockOpnameDetailProductTable from "../../../../components/stock-opname-detail-product-table";
import StockOpnameDetailManualProductTable from "../../../../components/stock-opname-detail-manual-product-table";
import WuiUploadImages from "../../../../@framework/wui/components/UploadImages";
import { permissions } from "../../../../constant/permissions";
import Accessible from "../../../../@framework/wui/components/Accessible";
import { StockOpnameProduct } from "../../../../components/stock-opname-product-export/interface";
import StockOpnameProductDetailExport from "../../../../components/stock-opname-product-export/detail";

let title = "Stock Opname";

const breadcrumbs = [
  {
    label: "Stock Opname",
    link: "/stock-opname",
  },
  {
    label: "Tambah",
    link: "/stock-opname/form",
  },
];

const AppStockOpnameDetail: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const pageTitle = t("common.text.editItem", { item: title });

  // Form
  const [form] = Form.useForm();
  const importProductData = useState<StockOpnameProduct[]>([]);
  const manualProductData = useState<StockOpnameProduct[]>([]);
  const [data, setData] = React.useState<StockOpname | null>(null);
  const [loadingData, setLoadingData] = React.useState<boolean>(false);
  const [importTableLoading, setImportTableLoading] =
    React.useState<boolean>(false);
  const [manualTableLoading, setManualImportTableLoading] =
    React.useState<boolean>(false);
  const [revisionData, setRevisionData] =
    React.useState<StockOpnameRevision | null>(null);

  const [submitLoading, setSubmitLoading] = React.useState<boolean>(false);
  const [hasInit, setHasInit] = React.useState<boolean>(false);
  const [isOnEdit, setIsOnEdit] = React.useState(false);
  const totalDataManualProduct = useState<number>(0);
  const totalDataImportProduct = useState<number>(0);
  const [activeRevisionNumber, setActiveRevisionNumber] = React.useState(0);

  // Tabs
  const tabKey = useState("Good Stock");
  const manualProductTabKey = useState("Good Stock");

  // Import Modal
  const exportProductRef = useRef(null);
  const handleTableProductExport = useReactToPrint({
    content: () => exportProductRef.current,
    documentTitle: `stock-opname-${moment().format("DD-MMMM-YYYY")}`,
    pageStyle:
      "@media print { @page {margin-header: 0; margin-footer: 0; counter-increment: none;} }",
  });

  const renderTableProductButton = () => {
    return (
      <Space wrap>
        <Button icon={<FileExcelOutlined />} onClick={handleTableProductExport}>
          Export
        </Button>
      </Space>
    );
  };

  const getData = useCallback(async () => {
    if (!id) {
      return;
    }

    setLoadingData(true);

    await StockOpnameRepository.show(id, {
      with: ["store", "revisions"],
    })
      .then((response: AxiosResponse) => {
        const responseData: StockOpname = response.data.data;
        setData(responseData);
        setActiveRevisionNumber(responseData.latest_revision_number);
        setHasInit(true);
        getRevisionData(responseData.latest_revision_id);
      })
      .catch((e: AxiosError) => {});
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const getRevisionManualProductData = useCallback(
    async (params: any) => {
      if (!id) {
        return;
      }

      setManualImportTableLoading(true);

      await StockOpnameRepository.manualProducts(id, params)
        .then((response: AxiosResponse) => {
          manualProductData.set(response.data.data);
          totalDataManualProduct.set(response.data.total);
        })
        .catch((e: AxiosError) => {})
        .finally(() => {
          setManualImportTableLoading(false);
        });
    },
    [id] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const getRevisionImportProductData = useCallback(
    async (params: any) => {
      if (!id) {
        return;
      }

      setImportTableLoading(true);

      await StockOpnameRepository.importProducts(id, params)
        .then((response: AxiosResponse) => {
          importProductData.set(response.data.data);
          totalDataImportProduct.set(response.data.total);
        })
        .catch((e: AxiosError) => {})
        .finally(() => {
          setImportTableLoading(false);
        });
    },
    [id] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const [loadingAttachment, setLoadingAttachment] = React.useState(false);
  const getRevisionData = useCallback(
    async (id: number, withProduct: boolean = false) => {
      if (revisionData && revisionData.id === id) {
        return;
      }

      setLoadingAttachment(true);
      await StockOpnameRepository.revision(id, {
        with: ["revisionUser.userSuperiors.accessRole", "attachments"],
      })
        .then((response: AxiosResponse) => {
          const responseData: StockOpnameRevision = response.data.data;

          form.setFieldsValue({
            attachments: toFileList(responseData.attachments),
          });

          if (withProduct) {
            const params = {
              page: 1,
              per_page: 5,
              order_by: "created_at",
              sorted_by: "desc",
              revision_number: responseData.revision_number,
            };

            getRevisionImportProductData({ ...params, status: tabKey.get() });
            getRevisionManualProductData({
              ...params,
              status: manualProductTabKey.get(),
            });
          }

          setRevisionData(responseData);
        })
        .catch((e: AxiosError) => {})
        .finally(() => {
          setLoadingData(false);
          setLoadingAttachment(false);
        });
    },
    [revisionData, tabKey.get(), manualProductTabKey.get()] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useMemo(() => {
    (async () => {
      await getData();
    })();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const [showRejectRevisionModal, setShowRejectRevisionModal] =
    React.useState<boolean>(false);
  const [showApproveRevisionModal, setShowApproveRevisionModal] =
    React.useState<boolean>(false);
  const [showRequestRevisionModal, setShowRequestRevisionModal] =
    React.useState<boolean>(false);

  const handleRejectRevision = useCallback(
    async (reason: string) => {
      await StockOpnameRepository.reject(id, {
        reason: reason,
      })
        .then((response: AxiosResponse) => {
          openNotification("success", "Berhasil menolak permintaan revisi");
          setShowRejectRevisionModal(false);
          navigate(-1);
        })
        .catch((e: AxiosError) => {
          handleBackendError(e, t("notification.error.default"));
        });
    },
    [id] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleApproveRevision = useCallback(async () => {
    await StockOpnameRepository.approve(id)
      .then((response: AxiosResponse) => {
        openNotification("success", "Berhasil menerima permintaan revisi");
        setShowApproveRevisionModal(false);
        navigate(-1);
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));
      });
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRequestRevision = useCallback(
    async (reason: string) => {
      await StockOpnameRepository.request(id, {
        reason: reason,
      })
        .then((response: AxiosResponse) => {
          openNotification("success", "Berhasil melakukan permintaan revisi");
          setShowRequestRevisionModal(false);
          navigate(-1);
        })
        .catch((e: AxiosError) => {
          handleBackendError(e, t("notification.error.default"));
        });
    },
    [id] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const getAccessRoleSuperior = useCallback((user: any) => {
    const findSuperior = user.user_superiors.find(
      (superior: any) => superior.channel === "B2C"
    );

    if (findSuperior) {
      return findSuperior.access_role.name;
    }

    return "-";
  }, []);

  const onFinish = useCallback(
    async (data: any) => {
      if (!revisionData) {
        return;
      }

      const formData = new FormData();
      setSubmitLoading(true);

      data.attachments.forEach((file: any) => {
        if (file?.status && file?.status === "done") {
          formData.append("attachments_keep[]", file.id);
          formData.append("attachments[]", file);
        } else {
          formData.append("attachments[]", file);
        }
      });

      await StockOpnameRepository.uploadRevisionAttachments(
        String(revisionData.id),
        formData
      )
        .then((response: AxiosResponse) => {
          openNotification("success", "Berhasil upload lampiran");
          setIsOnEdit(false);
          setSubmitLoading(false);
        })
        .catch((e: AxiosError) => {
          handleBackendError(e, t("notification.error.default"));
          setSubmitLoading(false);
        });
    },
    [revisionData] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      {data && (
        <div className="visually-hidden">
          <StockOpnameProductDetailExport
            ref={exportProductRef}
            hasInit={hasInit}
            stockOpnameData={data}
            revisionNumber={activeRevisionNumber}
          />
        </div>
      )}

      <WuiContainer>
        <WuiSectionTitle title={""} breadcrumbs={breadcrumbs} />

        <Row gutter={[16, 16]}>
          <Col md={24} xs={24}>
            <Row>
              <Col
                md={{
                  span: 16,
                  order: 1,
                }}
                xs={{
                  span: 24,
                  order: 2,
                }}
              >
                <PageHeader
                  className="default-page-header"
                  onBack={() => navigate(-1)}
                  title={pageTitle}
                  extra={
                    revisionData &&
                    revisionData.can_request && (
                      <Button
                        disabled={!revisionData.can_request}
                        type="primary"
                        onClick={() => setShowRequestRevisionModal(true)}
                      >
                        Ubah
                      </Button>
                    )
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col
            className="gutter-row"
            md={{
              span: 16,
              order: 1,
            }}
            xs={{
              span: 24,
              order: 2,
            }}
          >
            <div>
              <Card
                title={t("common.text.itemInformation", { item: "Toko" })}
                className="mb16"
              >
                {loadingData ? (
                  <WuiLoadingScreen />
                ) : (
                  <Space
                    style={{
                      width: "100%",
                    }}
                    direction="vertical"
                    size={25}
                  >
                    <Row gutter={[16, 16]}>
                      {id && data ? (
                        <Col
                          xs={24}
                          md={
                            data.latest_revision_status ===
                            STOCK_OPNAME_STATUS_REQUEST_REVISION
                              ? 8
                              : 24
                          }
                        >
                          <div className="wui-form-group type-column">
                            <WuiFormTitle title={"Kode"} />

                            <div className="input-section">
                              <Text strong>{data.code}</Text>
                            </div>
                          </div>
                        </Col>
                      ) : null}

                      {id &&
                        revisionData &&
                        revisionData.status ===
                          STOCK_OPNAME_STATUS_REQUEST_REVISION && (
                          <>
                            <Col xs={24} md={8}>
                              <div className="wui-form-group type-column">
                                <WuiFormTitle title={"Nama"} />

                                <div className="input-section">
                                  <Text strong>
                                    {revisionData.revision_user.name}
                                  </Text>
                                </div>
                              </div>
                            </Col>

                            <Col xs={24} md={8}>
                              <div className="wui-form-group type-column">
                                <WuiFormTitle title={"Peran"} />

                                <div className="input-section">
                                  <Text strong>
                                    {getAccessRoleSuperior(
                                      revisionData.revision_user
                                    )}
                                  </Text>
                                </div>
                              </div>
                            </Col>
                          </>
                        )}

                      <Col xs={24} md={8}>
                        <div className="wui-form-group type-column">
                          <WuiFormTitle title={"Tanggal"} />

                          <div className="input-section">
                            <Text strong>
                              {(id && revisionData
                                ? moment(revisionData.created_at)
                                : moment()
                              ).format("DD MMMM YYYY")}
                            </Text>
                          </div>
                        </div>
                      </Col>

                      <Col xs={24} md={8}>
                        <div className="wui-form-group type-column">
                          <WuiFormTitle title={"Jam"} />

                          <div className="input-section">
                            <Text strong>
                              {(id && revisionData
                                ? moment(revisionData.created_at)
                                : moment()
                              ).format("HH:mm")}{" "}
                              WIB
                            </Text>
                          </div>
                        </div>
                      </Col>

                      {revisionData && (
                        <Col xs={24} md={8}>
                          <div className="wui-form-group type-column">
                            <WuiFormTitle title={"Revisi"} />

                            <div className="input-section">
                              <Text strong>
                                Revisi ke {revisionData.revision_number}
                              </Text>
                            </div>
                          </div>
                        </Col>
                      )}

                      <Col xs={24} md={24}>
                        <div className="wui-form-group type-column">
                          <div className="input-section">
                            <WuiFormTitle title={"Kode Toko"} />
                            <Text strong>{data?.store?.code || "-"}</Text>
                          </div>
                        </div>
                      </Col>

                      <Col xs={24} md={24}>
                        <div className="wui-form-group type-column">
                          <div className="input-section">
                            <WuiFormTitle title={"Nama Toko"} />
                            <Text strong>{data?.store?.name || "-"}</Text>
                          </div>
                        </div>
                      </Col>

                      {id &&
                        revisionData &&
                        revisionData.status ===
                          STOCK_OPNAME_STATUS_REQUEST_REVISION &&
                        revisionData.revision_reason && (
                          <Col xs={24} md={24}>
                            <div className="wui-form-group type-column">
                              <WuiFormTitle title={"Alasan Revisi"} />

                              <div className="input-section">
                                <Text strong>
                                  {revisionData.revision_reason}
                                </Text>
                              </div>
                            </div>
                          </Col>
                        )}

                      {id &&
                        revisionData &&
                        revisionData.status ===
                          STOCK_OPNAME_STATUS_REJECTED &&
                        revisionData.reject_request_revision_reason && (
                          <Col xs={24} md={24}>
                            <div className="wui-form-group type-column">
                              <WuiFormTitle title={"Alasan Ditolak"} />

                              <div className="input-section">
                                <Text strong>
                                  {revisionData.reject_request_revision_reason}
                                </Text>
                              </div>
                            </div>
                          </Col>
                        )}

                      {id && data && revisionData && data.revisions ? (
                        <Col xs={24} md={24}>
                          <div className="wui-form-group type-column">
                            <WuiFormTitle title={"List Revisi"} />

                            <div className="input-section">
                              <Space size={15}>
                                {data.revisions.map((revision, index) => {
                                  const isActiveRevision =
                                    revision.revision_number ==
                                    revisionData.revision_number;
                                  return (
                                    <Text
                                      key={`${index}_revision`}
                                      style={{
                                        cursor: isActiveRevision
                                          ? "default"
                                          : "pointer",
                                        color: isActiveRevision
                                          ? "#000"
                                          : "#096DD9",
                                      }}
                                      onClick={() => {
                                        getRevisionData(revision.id, true);
                                        setActiveRevisionNumber(
                                          revision.revision_number
                                        );
                                      }}
                                    >
                                      Revisi {revision.revision_number}
                                    </Text>
                                  );
                                })}
                              </Space>
                            </div>
                          </div>
                        </Col>
                      ) : null}
                    </Row>
                  </Space>
                )}
              </Card>

              {revisionData && (
                <>
                  <Card
                    title={"Tabel Produk"}
                    className="mb16"
                    extra={renderTableProductButton()}
                  >
                    <Space
                      style={{
                        width: "100%",
                      }}
                      direction="vertical"
                      size={25}
                    >
                      <Tabs
                        defaultActiveKey={tabKey.get()}
                        onChange={(key) => {
                          tabKey.set(key);
                        }}
                      >
                        <TabPane
                          tab={"Good Stock"}
                          key="Good Stock"
                          disabled={importTableLoading}
                        >
                          <StockOpnameDetailProductTable
                            data={importProductData}
                            revisionData={revisionData}
                            onChangeTable={getRevisionImportProductData}
                            tableLoading={importTableLoading}
                            status={"Good Stock"}
                            tabKey={tabKey.get()}
                            totalData={totalDataImportProduct.get()}
                          />
                        </TabPane>

                        <TabPane
                          tab={"Damage"}
                          key="Damage"
                          disabled={importTableLoading}
                        >
                          <StockOpnameDetailProductTable
                            data={importProductData}
                            revisionData={revisionData}
                            onChangeTable={getRevisionImportProductData}
                            tableLoading={importTableLoading}
                            tabKey={tabKey.get()}
                            status={"Damage"}
                            totalData={totalDataImportProduct.get()}
                          />
                        </TabPane>

                        <TabPane
                          tab={"Demo"}
                          key="Demo"
                          disabled={importTableLoading}
                        >
                          <StockOpnameDetailProductTable
                            data={importProductData}
                            revisionData={revisionData}
                            onChangeTable={getRevisionImportProductData}
                            tableLoading={importTableLoading}
                            tabKey={tabKey.get()}
                            status={"Demo"}
                            totalData={totalDataImportProduct.get()}
                          />
                        </TabPane>
                      </Tabs>
                    </Space>
                  </Card>

                  <Card title={"Tabel Produk Manual"} className="mb16">
                    <Space
                      style={{
                        width: "100%",
                      }}
                      direction="vertical"
                      size={25}
                    >
                      <Tabs
                        defaultActiveKey={manualProductTabKey.get()}
                        onChange={(key) => {
                          manualProductTabKey.set(key);
                        }}
                      >
                        <TabPane
                          tab={"Good Stock"}
                          key="Good Stock"
                          disabled={importTableLoading}
                        >
                          <StockOpnameDetailManualProductTable
                            data={manualProductData}
                            revisionData={revisionData}
                            onChangeTable={getRevisionManualProductData}
                            tableLoading={manualTableLoading}
                            status={"Good Stock"}
                            tabKey={manualProductTabKey.get()}
                            totalData={totalDataManualProduct.get()}
                          />
                        </TabPane>

                        <TabPane
                          tab={"Damage"}
                          key="Damage"
                          disabled={importTableLoading}
                        >
                          <StockOpnameDetailManualProductTable
                            data={manualProductData}
                            revisionData={revisionData}
                            onChangeTable={getRevisionManualProductData}
                            tableLoading={manualTableLoading}
                            status={"Damage"}
                            tabKey={manualProductTabKey.get()}
                            totalData={totalDataManualProduct.get()}
                          />
                        </TabPane>

                        <TabPane
                          tab={"Demo"}
                          key="Demo"
                          disabled={importTableLoading}
                        >
                          <StockOpnameDetailManualProductTable
                            data={manualProductData}
                            revisionData={revisionData}
                            onChangeTable={getRevisionManualProductData}
                            tableLoading={manualTableLoading}
                            status={"Demo"}
                            tabKey={manualProductTabKey.get()}
                            totalData={totalDataManualProduct.get()}
                          />
                        </TabPane>
                      </Tabs>
                    </Space>
                  </Card>

                  <div className="wui-form-btn-group">
                    <Button
                      className="wui-btn"
                      size={"large"}
                      onClick={() => navigate(-1)}
                    >
                      {t("common.button.back")}
                    </Button>

                    {id &&
                      revisionData &&
                      revisionData.status ===
                        STOCK_OPNAME_STATUS_REQUEST_REVISION &&
                      revisionData.can_approve && (
                        <Accessible
                          access={permissions.write_request_stock_opname_b2c}
                        >
                          <>
                            <Button
                              htmlType="button"
                              className="wui-btn"
                              size={"large"}
                              onClick={() => setShowRejectRevisionModal(true)}
                            >
                              Tolak
                            </Button>

                            <Button
                              className="wui-btn"
                              htmlType="button"
                              type="primary"
                              size={"large"}
                              onClick={() => setShowApproveRevisionModal(true)}
                            >
                              Terima
                            </Button>
                          </>
                        </Accessible>
                      )}
                  </div>
                </>
              )}
            </div>
          </Col>

          <Col
            className="gutter-row"
            md={{
              span: 8,
              order: 1,
            }}
            xs={{
              span: 24,
              order: 2,
            }}
          >
            <Form
              preserve
              form={form}
              layout={"vertical"}
              onFinish={onFinish}
              requiredMark={false}
            >
              <Card
                id="stock-opname-attachments"
                title={t("common.text.attachment")}
                extra={
                  revisionData && (
                    <Button
                      className="wui-btn"
                      htmlType="submit"
                      type="primary"
                      size={"large"}
                      disabled={isOnEdit || !revisionData.can_upload_attachment}
                      onClick={() => setIsOnEdit(true)}
                    >
                      {t("common.button.addNew")}
                    </Button>
                  )
                }
              >
                <Form.Item
                  name="attachments"
                  label={"Lampiran"}
                  className="mb0"
                >
                  {loadingAttachment ? (
                    <Spin spinning={loadingAttachment} />
                  ) : (
                    revisionData &&
                    !loadingData && (
                      <WuiUploadImages
                        max={5}
                        listType={"picture"}
                        disabled={
                          !revisionData.can_upload_attachment || !isOnEdit
                        }
                        accept={"image/*, application/pdf, .docx"}
                        showUploadList={{
                          showRemoveIcon: false,
                        }}
                        customButton={
                          <Button
                            icon={<UploadOutlined />}
                            type="primary"
                            ghost
                            block
                            className="mt8"
                          >
                            Upload
                          </Button>
                        }
                      />
                    )
                  )}
                </Form.Item>

                {isOnEdit && (
                  <div className="mt16" style={{ textAlign: "right" }}>
                    <Button
                      className="wui-btn mr8"
                      type="default"
                      size={"large"}
                      onClick={() => setIsOnEdit(false)}
                    >
                      {t("common.button.cancel")}
                    </Button>
                    <Button
                      className="wui-btn"
                      htmlType="submit"
                      type="primary"
                      size={"large"}
                      loading={submitLoading}
                    >
                      {t("common.button.save")}
                    </Button>
                  </div>
                )}
              </Card>
            </Form>
          </Col>
        </Row>
      </WuiContainer>

      <WuiModalConfirmation
        show={showApproveRevisionModal}
        onOk={handleApproveRevision}
        onCancel={() => setShowApproveRevisionModal(false)}
        confirmLabel={"Ya"}
        isDanger={false}
      />

      <RequestRevisionStockOpnameModal
        show={showRequestRevisionModal}
        onOk={handleRequestRevision}
        onCancel={() => setShowRequestRevisionModal(false)}
      />
      <RejectRevisionStockOpnameModal
        show={showRejectRevisionModal}
        onOk={handleRejectRevision}
        onCancel={() => setShowRejectRevisionModal(false)}
      />
    </>
  );
};

export default AppStockOpnameDetail;
