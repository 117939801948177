import {useTranslation} from "react-i18next";
import {useIsMounted} from "../../../../../../@framework/utilities/hooks";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import WuiContainer from "../../../../../../@framework/wui/components/Container";
import Button from "antd/es/button";
import {SearchOutlined, UploadOutlined} from "@ant-design/icons";
import WuiSectionTitle from "../../../../../../@framework/wui/components/Sections/Title";
import React, {useEffect, useMemo} from "react";
import Col from "antd/es/grid/col";
import Input from "antd/es/input";
import Row from "antd/es/grid/row";
import useDebounce from "../../../../../../@framework/utilities/hooks/useDebounce";
import {useState} from "@hookstate/core";
import Table from "antd/es/table";
import Space from "antd/es/space";
import ProductRepository from "../../../../../../repositories/ProductRepository";
import {AxiosError, AxiosResponse} from "axios";
import Text from "antd/es/typography/Text";
import WuiModalConfirmation from "../../../../../../@framework/wui/components/Modal/Confirmation";
import {handleBackendError, openNotification} from "../../../../../../functions/global";
import WuiModalImport from "../../../../../../@framework/wui/components/Modal/Import";
import WuiTableSwitch from "../../../../../../@framework/wui/components/Table/Switch";
import Collapse from "antd/lib/collapse";
import _ from "lodash";
import WuiSelectMulti from "../../../../../../@framework/wui/components/Select/Multi";
import BrandRepository from "../../../../../../repositories/BrandRepository";
import { PRODUCT_IMPORT } from "../../../../../../constant/import";
import Accessible from "../../../../../../@framework/wui/components/Accessible";
import { permissions } from "../../../../../../constant/permissions";

const { Panel } = Collapse;

var qs = require('qs');

const breadcrumbs = [
    {
        label: 'Pengaturan',
    },
    {
        label: 'Data Master',
    },
    {
        label: 'Produk',
        link: '/pengaturan/data-master/produk'
    },
]

const AppSettingsDataMasterProductList: React.FC<any> = () => {
    const {t} = useTranslation()
    const isMounted = useIsMounted()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    let fullPath = window.location.pathname;

    const tablePage = useState<number>(parseInt(searchParams.get('page') || '1'))
    const tablePerPage = useState<number>(parseInt(searchParams.get('perPage') || '10'))
    const [tableLoading, setTableLoading] = React.useState<boolean>(false)
    const tableSort = useState<{
        sortField: any,
        sortOrder: any,
    }>({
        sortField: searchParams.get('sortField'),
        sortOrder: searchParams.get('sortOrder')
    })
    const tableData = useState([]);
    const totalData = useState<number>(0)

    // Filters
    const localSelectbrands = JSON.parse(localStorage.getItem('product.brands') || '[]')
    const [selectedBrands, setSelectedBrands] = React.useState<any[]>([])

    const getTableData = async (reset: boolean = false) => {
        setTableLoading(true)

        if (reset) tablePage.set(1);

        let params = {
            page: tablePage.get(),
            search: debouncedSearch,
            order_by: tableSort.sortField.get(),
            sorted_by: tableSort.sortOrder.get(),
            per_page: tablePerPage.get()
        }

        if (!params.order_by) {
            params.order_by = "created_at";
            params.sorted_by = "descend";
        }

        const brands = _.map(selectedBrands, 'id');

        if (brands) {
            Object.assign(params, {
                brand: brands
            })
        }

        await ProductRepository.all(params).then((res: AxiosResponse) => {
            if (res.data.data) {
                tableData.set(res.data.data)
            }

            totalData.set(res.data?.total || 0)

            setTableLoading(false)
        }).catch((e: AxiosError) => {

            setTableLoading(false)
        })
    }

    const handleTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        tablePage.set(pagination.current)
        tablePerPage.set(pagination.pageSize)

        if (sorter.order) {
            tableSort.sortField.set(sorter.field)
            tableSort.sortOrder.set(sorter.order)
        } else {
            tableSort.sortField.set(null)
            tableSort.sortOrder.set(null)
        }

        setFilterDataToQuery()
    }

    // For Search Input
    const [search, setSearch] = React.useState<string>('')
    const debouncedSearch = useDebounce<string>(search, 1000)

    useEffect(() => {
        if (!isMounted) {
            setFilterDataToQuery()
        }
        // eslint-disable-next-line
    }, [debouncedSearch])

    useEffect(() => {
        setFilterDataToQuery()
        // eslint-disable-next-line
    }, [selectedBrands])

    const getFilterDataFromQuery = () => {
        const keyword = searchParams.get('keyword')

        if (keyword) {
            setSearch(keyword)
        }

        if (!localSelectbrands) {
            searchParams.delete('brands')
        } else {
            const brands = _.map(searchParams.getAll('brands'), (item) => parseInt(item))
            if (brands) {
                let brandFromStorage = _.filter(localSelectbrands, (data) => {
                    return brands.includes(data.id)
                });
                setSelectedBrands(brandFromStorage)
            } else {
                localStorage.removeItem('product.brands')
            }
        }
    }

    useMemo (() => {
        getFilterDataFromQuery()
        // eslint-disable-next-line
    }, [])

    const setFilterDataToQuery = () => {
        let params = {}
        let reset = false;

        if (debouncedSearch) {
            Object.assign(params, {
                keyword: debouncedSearch
            })

            if(debouncedSearch !== searchParams.get('keyword')){
                reset = true
            } 
        }

        if (tablePage.get() !== 1 && !reset) {
            Object.assign(params, {
                page: tablePage.get()
            })
        }

        if (tablePerPage.get() !== 10 && !reset) {
            Object.assign(params, {
                perPage: tablePerPage.get()
            })
        }

        if (tableSort.sortField.get() && !reset) {
            Object.assign(params, {
                sortField: tableSort.sortField.get()
            })
        }

        if (tableSort.sortOrder.get() && !reset) {
            Object.assign(params, {
                sortOrder: tableSort.sortOrder.get()
            })
        }

        if (selectedBrands) {
            const multiValue = _.map(selectedBrands, 'id');

            Object.assign(params, {
                brands: multiValue
            })
        }


        const queryParams = qs.stringify(params , { indices: false });

        if (queryParams) {
            setSearchParams(`?${queryParams}`)
        } else {
            navigate('')
        }

        getTableData(reset)
    }

    const updateStatus = async (id: number, is_active: boolean) => {
        return new Promise(async (resolve, reject) => {
            await ProductRepository.updateStatus(id, {
                is_active: is_active
            }).then((res: AxiosResponse) => {
                resolve(res)
            }).catch((e: AxiosError) => {
                reject(e)
            })
        })
    }

    const columns: any = [
        {
            title: 'Nama',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            defaultSortOrder: tableSort.sortField.get() === 'name' && tableSort.sortOrder.get(),
        },
        {
            title: 'Kode',
            dataIndex: 'code',
            key: 'code',
            sorter: true,
            defaultSortOrder: tableSort.sortField.get() === 'code' && tableSort.sortOrder.get(),
        },
        {
            title: 'Tipe Produk',
            dataIndex: 'type_name',
            key: 'type_name',
            sorter: true,
            defaultSortOrder: tableSort.sortField.get() === 'type_name' && tableSort.sortOrder.get(),
        },
        {
            title: 'Brand',
            dataIndex: 'brand_name',
            key: 'brand_name',
            sorter: true,
            defaultSortOrder: tableSort.sortField.get() === 'brand_name' && tableSort.sortOrder.get(),
        },
        {
            title: 'Status',
            dataIndex: 'is_active',
            key: 'is_active',
            sorter: false,
            render: (text: any, record: any) => {
                return (
                    <Accessible access={[permissions.write_product_b2b, permissions.write_product_b2c]}>
                        <WuiTableSwitch id={record.id} disabled is_active={record.is_active} callback={updateStatus}/>
                    </Accessible>
                )
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Link to={fullPath+'/'+record.id}>Lihat Detail</Link>
                    {/*<Accessible access={[permissions.delete_product_b2b, permissions.delete_product_b2c]}>
                        <Text className="cursor-pointer" type={"danger"} onClick={() => setDeleteModal(record.id)}>Hapus</Text>
                    </Accessible>*/}
                </Space>
            ),
        },
    ];

    // Modal
    const showDeleteModal = useState(false)
    const selectedDeleteId = useState(0)

    const setDeleteModal = (id: any) => {
        selectedDeleteId.set(id);
        showDeleteModal.set(true)
    }

    const deleteModalCallback = async () => {
        if (selectedDeleteId.get()) {
            await ProductRepository.delete(selectedDeleteId.get()).then((res: AxiosResponse) => {
                showDeleteModal.set(false);
                selectedDeleteId.set(0)
                openNotification('success', t('notification.success.deleteItem', {item: 'Produk'}))
                getTableData(true)
            }).catch((e: AxiosError) => {
                showDeleteModal.set(false);
                selectedDeleteId.set(0)
                handleBackendError(e, t('notification.error.default'))
            });
        }
    }

    const showImportModal = useState(false)

    const importModalCallback = async (files: File[]) => {
        const formData = new FormData();

        if (files) {
            files.forEach((file: any) => {
                formData.append('file_import', file);
            })
        }

        await ProductRepository.import(formData).then((res: AxiosResponse) => {
            showImportModal.set(false)
            openNotification('success', t('notification.success.importItem', {item: 'Produk'}))
            getTableData(true)
        }).catch((e: AxiosError) => {
            handleBackendError(e, t('notification.error.default'))
        })
    }

    const importModalDownload = async () => {
        window.open(PRODUCT_IMPORT.TEMPLATE_URL + `?${_.random(100,900)}`)
    }

    const handleReset = () => {
        setSelectedBrands([])
    }

    return (
        <>
            <WuiContainer>
                <WuiSectionTitle breadcrumbs={breadcrumbs} title={'Produk'}>
                    <Accessible access={[permissions.write_product_b2b, permissions.write_product_b2c]}>
                        <Button icon={<UploadOutlined />} onClick={() => showImportModal.set(true)}>{t('common.button.import')}</Button>
                    </Accessible>
                </WuiSectionTitle>

                <Row gutter={[10, 10]} className="mb16">
                    <Col className="gutter-row" span={6} xs={24} md={12} lg={10}>
                        <Input
                            allowClear
                            placeholder={t('common.filter.search.placeholder')}
                            prefix={<SearchOutlined />}
                            value={search}
                            onChange={(value) => {
                                setSearch(value.target.value)
                            }}
                        />
                    </Col>
                </Row>

                <Collapse
                    className="mb16"
                    defaultActiveKey={['1']}
                    expandIconPosition={"right"}
                >
                    <Panel header="Filter" key="1">
                        <Row className="mb6" gutter={[10, 10]}>
                            <Col className="gutter-row" xs={24} md={24} lg={12}>
                                <WuiSelectMulti
                                    value={selectedBrands}
                                    onChange={
                                        (val: any) => {
                                            tablePage.set(1)
                                            setSelectedBrands(val)
                                            localStorage.setItem('product.brands', JSON.stringify(val))
                                        }
                                    }
                                    repository={BrandRepository}
                                    placeholder={t('select.placeholder', {item: "Brand"})}
                                />
                            </Col>
                            <Col>
                                <Button type="link" onClick={handleReset}>Reset</Button>
                            </Col>
                        </Row>
                    </Panel>
                </Collapse>

                <Table
                    rowKey={"id"}
                    bordered
                    columns={columns}
                    dataSource={tableData.get()}
                    loading={tableLoading}
                    onChange={handleTableChange}
                    pagination={{
                        current: tablePage.get(),
                        simple: true,
                        pageSize: tablePerPage.get(),
                        total: totalData.get()
                    }}
                />
            </WuiContainer>

            <WuiModalConfirmation
                show={showDeleteModal.get()}
                onOk={deleteModalCallback}
                onCancel={() => showDeleteModal.set(false)}
            />

            <WuiModalImport
                show={showImportModal.get()}
                onUpload={importModalCallback}
                onCancel={() => showImportModal.set(false)}
                onDownload={importModalDownload}
                headerTitle="Import Produk"
                maxFileOnMB={10}
            />
        </>
    );
}

export default AppSettingsDataMasterProductList;