import React from "react";
import WuiContainer from "../../../../@framework/wui/components/Container";
import WuiSectionTitle from "../../../../@framework/wui/components/Sections/Title";


const AppSettingsGeneral: React.FC<any> = () => {

    const breadcrumbs = [
        {
            label: 'Settings',
            link: '/settings'
        },
        {
            label: 'General',
            link: '/settings/general'
        },
    ]

    return (
        <>
            <WuiContainer>
                <WuiSectionTitle breadcrumbs={breadcrumbs} title={'Setting General'}/>
            </WuiContainer>
        </>
    )
}

export default AppSettingsGeneral
