import React, { useEffect, useRef } from "react";
import ActivityDetector from "react-activity-detector";
import { useAppSelector } from "../../../../stores/hooks";
import { passportService } from "../../../services";

const activityEvent: any = [
  "click",
  "mousemove",
  "keydown",
  "DOMMouseScroll",
  "mousewheel",
  "mousedown",
  "touchstart",
  "touchmove",
  "focus",
];

const REFRESH_AUTH_INTERVAL = 5; //on minutes

const WuiActivityDetector: React.FC = () => {
  const refreshAuthInterval = useRef<any>(null);
  const system = useAppSelector((state) => state.system);

  useEffect(() => {
    if (!system.isLoggedIn) {
      clearRefreshAuthInterval();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [system.isLoggedIn]);

  const setRefreshAuthInterval = () => {
    if (!refreshAuthInterval.current) {
      refreshAuthInterval.current = setInterval(() => {
        passportService.refreshOauthToken()
      }, REFRESH_AUTH_INTERVAL * 60 * 1000);
    }
  };

  const clearRefreshAuthInterval = () => {
    if (refreshAuthInterval.current) {
      clearInterval(refreshAuthInterval.current);
      refreshAuthInterval.current = null;
    }
  };

  const startRefreshAuthInterval = () => {
    if (!refreshAuthInterval.current) {
      passportService.refreshOauthToken()
    }
    setRefreshAuthInterval();
  };

  return (
    <ActivityDetector
      activityEvents={activityEvent}
      enabled={true}
      timeout={10 * 1000}
      onIdle={clearRefreshAuthInterval}
      onActive={startRefreshAuthInterval}
      name="default"
    />
  );
};

export default WuiActivityDetector;
