import { api, createCancelTokenHandler } from "../@framework/services";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  all: function (params: any = null) {
    return api.get(`${endPoint()}/api/v1/b2c/stock-opnames`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.all.name].handleRequestCancellation()
          .token,
    });
  },
  show: function (id: number | string, params: any = null) {
    return api.get(`${endPoint()}/api/v1/b2c/stock-opnames/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.show.name].handleRequestCancellation()
          .token,
    });
  },
  create: function (payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/v1/b2c/stock-opnames`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.create.name].handleRequestCancellation()
          .token,
    });
  },
  update: function (id: number | string, payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/v1/b2c/stock-opnames/${id}`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.update.name].handleRequestCancellation()
          .token,
    });
  },
  delete: function (id: number | string, params: any = null) {
    return api.delete(`${endPoint()}/api/v1/b2c/stock-opnames/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.delete.name].handleRequestCancellation()
          .token,
    });
  },
  import: function (payload: any, params: any = null) {
    return api.post(
      `${endPoint()}/api/v1/b2c/stock-opname-product-import`,
      payload,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[this.import.name].handleRequestCancellation()
            .token,
      }
    );
  },
  revision: function (id: number | string, params: any = null) {
    return api.get(`${endPoint()}/api/v1/b2c/stock-opname-revisions/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.revision.name].handleRequestCancellation()
          .token,
    });
  },
  importProducts: function (id: number | string, params: any = null) {
    return api.get(
      `${endPoint()}/api/v1/b2c/stock-opnames/${id}/import-products`,
      {
        params,
      }
    );
  },
  manualProducts: function (id: number | string, params: any = null) {
    return api.get(
      `${endPoint()}/api/v1/b2c/stock-opnames/${id}/manual-products`,
      {
        params,
      }
    );
  },
  approve: function (id: string, payload?: any, params: any = null) {
    return api.post(
      `${endPoint()}/api/v1/b2c/stock-opnames/${id}/approve-revision`,
      payload,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.approve.name
          ].handleRequestCancellation().token,
      }
    );
  },
  reject: function (id: string, payload: any, params: any = null) {
    return api.post(
      `${endPoint()}/api/v1/b2c/stock-opnames/${id}/reject-revision`,
      payload,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[this.reject.name].handleRequestCancellation()
            .token,
      }
    );
  },
  request: function (id: string, payload: any, params: any = null) {
    return api.post(
      `${endPoint()}/api/v1/b2c/stock-opnames/${id}/request-revision`,
      payload,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.request.name
          ].handleRequestCancellation().token,
      }
    );
  },
  uploadRevisionAttachments: function (
    id: string,
    payload: any,
    params: any = null
  ) {
    return api.post(
      `${endPoint()}/api/v1/b2c/stock-opname-revisions/${id}/attachments`,
      payload,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.uploadRevisionAttachments.name
          ].handleRequestCancellation().token,
      }
    );
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;
