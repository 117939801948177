import { SearchOutlined } from "@ant-design/icons";
import { useState } from "@hookstate/core";
import { Col, Input, Row } from "antd";
import Button from "antd/es/button";
import Card from "antd/es/card";
import PageHeader from "antd/es/page-header";
import Space from "antd/es/space";
import Table from "antd/es/table";
import Text from "antd/es/typography/Text";
import { AxiosError, AxiosResponse } from "axios";
import GoogleMapReact from "google-map-react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MdLocationPin } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";
import useDebounce from "../../../../../../@framework/utilities/hooks/useDebounce";
import WuiContainer from "../../../../../../@framework/wui/components/Container";
import WuiFormTitle from "../../../../../../@framework/wui/components/Form/Title";
// import Accessible from "../../../../../../@framework/wui/components/Accessible";
import AssistantStoreLeaderRepository from "../../../../../../repositories/AssistantStoreLeaderRepository";
import { handleBackendError, openNotification } from "../../../../../../functions/global";
import WuiModalConfirmation from "../../../../../../@framework/wui/components/Modal/Confirmation";

const AppSettingsAccountAssistantStoreLeaderForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const pageTitle = useState("");
  const channel = useState("b2c");
  const GOOGLE_MAP_API_KEY =
    process.env.REACT_APP_GOOGLE_MAP_API_KEY || "NO_KEY";

  // Form
  const disable = useState(false);

  const [search, setSearch] = React.useState<string>("");
  const debouncedSearch = useDebounce<string>(search, 1000);
  let fullPath = window.location.pathname;

  const [data, setData] = React.useState<any>({
    stores: [],
  });
  const [stores, setStores] = React.useState<any>([]);

  const [activeMarker, setActiveMarker] = React.useState("");

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const newStores = [...data.stores].filter((item: any) =>
      item.name.toLowerCase().includes(search.toLowerCase()) ||  item.code.toLowerCase().includes(search.toLowerCase()) 
    );
    setStores(newStores);
  }, [debouncedSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    disable.set(true);

    await AssistantStoreLeaderRepository.show(id, channel.get(), {
      with: ["area", "stores"],
    })
      .then((res: AxiosResponse) => {
        const data = res.data?.data || {};
        const superior = data.my_superior || {};

        setData({
          ...data,
          superior_code: superior.code || "-",
          superior_name: superior.name || "-",
          superior_email: superior.email || "-",
          stores: (data.stores || []).map((item: any, index: number) => {
            if (item.lng_lat) {
              let latLng = item.lng_lat.split(",");
              let lat = parseFloat(latLng[1]);
              let lng = parseFloat(latLng[0]);
              return { ...item, lat: lat, lng: lng };
            } else {
              return item;
            }
          }),
        });

        setStores(data.stores);

        pageTitle.set(data.name);

        disable.set(false);
      })
      .catch((e: AxiosError) => {});
  };

  const storeColumns = [
    {
      title: "Kode",
      dataIndex: "code",
      key: "code",
      width: "18%",
    },
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
      width: "60%",
    },
    {
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          <Link to={fullPath + "/store/" + record.id}>Lihat Detail</Link>
          <Text
              className="cursor-pointer"
              type={"danger"}
              onClick={() => setDeleteModal(record.id)}
          >
              Hapus
          </Text>
        </Space>
      ),
    },
  ];
  // Modal delete
  const showDeleteModal = useState(false)
  const selectedDeleteId = useState(0)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const setDeleteModal = (id: any) => {
      selectedDeleteId.set(id);
      showDeleteModal.set(true)
  }

  const deleteModalCallback = async () => {
    if (selectedDeleteId.get()) {
        await AssistantStoreLeaderRepository.deleteStore(id, selectedDeleteId.get()).then((res: AxiosResponse) => {
            showDeleteModal.set(false);
            selectedDeleteId.set(0)
            openNotification('success', t('notification.success.deleteItem', { item: 'Store' }))
            getData()
        }).catch((e: AxiosError) => {
            showDeleteModal.set(false);
            selectedDeleteId.set(0)
            handleBackendError(e, t('notification.error.default'))
        });
    }
}

  const bindResizeListener = (map: any, maps: any, bounds: any) => {
    maps.event.addDomListenerOnce(map, "idle", () => {
      maps.event.addDomListener(window, "resize", () => {
        map.fitBounds(bounds);
      });
    });
  };

  const handleApiLoaded = (map: any, maps: any, places: any) => {
    const bounds = getMapBounds(map, maps, places);
    map.fitBounds(bounds);
    if (places.length === 1) {
      map.setZoom(17);
    }
    bindResizeListener(map, maps, bounds);
  };

  const Marker = (props: any) => {
    const data = props.data || {};
    return (
      <div style={{ fontSize: "40px" }}>
        {activeMarker === data.id && (
          <div className="marker-info">
            <div>{data.name}</div>
            <div>
              <b>{data.code}</b>
            </div>
          </div>
        )}

        <MdLocationPin
          onClick={() => {
            if (activeMarker === data.id) {
              setActiveMarker("");
            } else {
              setActiveMarker(data.id);
            }
          }}
        />
      </div>
    );
  };

  const getMapBounds = (map: any, maps: any, places: any) => {
    const bounds = new maps.LatLngBounds();

    places.forEach((place: any) => {
      if (place.lat && place.lng) {
        bounds.extend(new maps.LatLng(place.lat, place.lng));
      }
    });
    return bounds;
  };

  return (
    <>
      <WuiContainer>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle.get()}
        />

        <Row gutter={[16, 16]}>
          <Col
            className="gutter-row"
            md={{
              span: 16,
              order: 1,
            }}
            xs={{
              span: 24,
              order: 2,
            }}
          >
            <Card title="Informasi Sales">
              <Space style={{ width: "100%" }} direction="vertical" size={25}>
                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Level"} />

                  <div className="input-section">
                    <Text strong>{data.level}</Text>
                  </div>
                </div>

                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Kode"} />

                  <div className="input-section">
                    <Text strong>{data.code}</Text>
                  </div>
                </div>

                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Nama"} />

                  <div className="input-section">
                    <Text strong>{data.name}</Text>
                  </div>
                </div>

                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Email"} />

                  <div className="input-section">
                    <Text strong>{data.email}</Text>
                  </div>
                </div>

                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Area"} />

                  <div className="input-section">
                    <Text strong>{data.area_name}</Text>
                  </div>
                </div>
              </Space>
            </Card>
          </Col>

          <Col
            className="gutter-row"
            md={{
              span: 8,
              order: 1,
            }}
            xs={{
              span: 24,
              order: 2,
            }}
          >
            <Card title="Informasi Superior">
              <Space style={{ width: "100%" }} direction="vertical" size={25}>
                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Kode"} />

                  <div className="input-section">
                    <Text strong>{data.superior_code}</Text>
                  </div>
                </div>

                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Nama"} />

                  <div className="input-section">
                    <Text strong>{data.superior_name}</Text>
                  </div>
                </div>

                <div className="wui-form-group type-column">
                  <WuiFormTitle title={"Email"} />

                  <div className="input-section">
                    <Text strong>{data.superior_email}</Text>
                  </div>
                </div>
              </Space>
            </Card>
          </Col>

          <Col className="gutter-row" span={24} order={3}>
            <Card bodyStyle={{ padding: 0 }}>
              {data.stores.length > 0 && (
                <div style={{ height: "475px", width: "100%" }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY }}
                    defaultCenter={{
                      lat: -6.2,
                      lng: 106.816666,
                    }}
                    defaultZoom={17}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) =>
                      handleApiLoaded(map, maps, data.stores)
                    }
                  >
                    {data.stores.map((item: any) => {
                      if (item.lat && item.lng) {
                        return (
                          <Marker
                            lat={item.lat}
                            lng={item.lng}
                            key={item.id}
                            data={item}
                          />
                        );
                      } else {
                        return null;
                      }
                    })}
                  </GoogleMapReact>
                </div>
              )}

              <div style={{ padding: "24px" }}>
                <h3>Store</h3>
                <Row gutter={[10, 10]} className="mb16">
                  <Col className="gutter-row" span={6} xs={24} md={12} lg={10}>
                    <Input
                      allowClear
                      placeholder={t("common.filter.search.placeholder")}
                      prefix={<SearchOutlined />}
                      value={search}
                      onChange={(value) => {
                        setSearch(value.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Table
                  columns={storeColumns}
                  dataSource={stores}
                  pagination={false}
                  rowKey="id"
                ></Table>
              </div>
            </Card>
          </Col>
        </Row>

        <div className="wui-form-btn-group">
          <Button
            className="wui-btn"
            size={"large"}
            onClick={() => navigate(-1)}
          >
            {t("common.button.back")}
          </Button>
        </div>
      </WuiContainer>

      <WuiModalConfirmation
          show={showDeleteModal.get()}
          onOk={deleteModalCallback}
          onCancel={() => showDeleteModal.set(false)}
      />
    </>
  );
};

export default AppSettingsAccountAssistantStoreLeaderForm;
