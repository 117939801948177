import React from "react";
import WuiContainer from "../../../../@framework/wui/components/Container";
import WuiSectionTitle from "../../../../@framework/wui/components/Sections/Title";

const breadcrumbs = [
  {
    label: "KPI",
    link: "/kpi",
  },
  {
    label: "Hasil Perhitungan",
    link: "/kpi/hasil-perhitungan",
  },
];

const AppKPICalculationResult: React.FC<any> = () => {
  return (
    <>
      <WuiContainer>
        <WuiSectionTitle
          title={"Hasil Perhitungan"}
          breadcrumbs={breadcrumbs}
        />

        <div>Hasil Perhitungan</div>
      </WuiContainer>
    </>
  );
};

export default AppKPICalculationResult;
