import PageHeader from "antd/es/page-header";
import React, { useEffect } from "react";
import WuiContainer from "../../../../../../@framework/wui/components/Container";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Card from "antd/lib/card";
import Space from "antd/lib/space";
import WuiFormTitle from "../../../../../../@framework/wui/components/Form/Title";
import Typography from "antd/lib/typography";
import RequestJourneyPlanRepository from "../../../../../../repositories/RequestJourneyPlanRepository";
import { AxiosError, AxiosResponse } from "axios";
import { useState } from "@hookstate/core";
import Button from "antd/es/button";
import Spin from "antd/lib/spin";
import moment from "moment";
import Modal from "antd/lib/modal";
import Input from "antd/es/input";
import Form from "antd/lib/form";
import { handleBackendError, openNotification } from "../../../../../../functions/global";
import Accessible from "../../../../../../@framework/wui/components/Accessible";
import { permissions } from "../../../../../../constant/permissions";

const { Text, Link } = Typography;

let title = "Permintaan";

const AppJourneyPlanFormPermintaan: React.FC<any> = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const pageTitle = id ? t('common.text.editItem', { item: title }) : t('common.text.addNewItem', { item: title })

    // Form Modal
    const [formModal] = Form.useForm();

    const formData = useState<any>({
        visit_date: '',
        new_dealer_name: '',
        new_dealer_address: '',
        new_dealer_email: '',
        pic_name: '',
        reason: '',
        sales_name: '',
        created_at: '',
        status: ''
    })

    const disable = useState(false);
    const loading = useState(false);

    useEffect(() => {
        if (id) {
            getData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getData = async () => {
        disable.set(true)

        await RequestJourneyPlanRepository.show(id, 'b2b', { with: 'dealer' }).then((res: AxiosResponse) => {
            let data = res.data?.data;
            if (data.dealer) {
                data.new_dealer_address = data.dealer.address
                data.new_dealer_email = data.dealer.email
                data.new_dealer_lng_lat = data.dealer.lng_lat
                data.new_dealer_name = data.dealer.name
                data.new_dealer_pic = data.dealer.pic_name
            }
            formData.set(data)

            disable.set(false)
        }).catch((e: AxiosError) => {

        })
    }

    const acceptRequestJourney = async () => {
        loading.set(true)

        let payload = {
            status: 'Diterima'
        }

        await RequestJourneyPlanRepository.updateStatus(id, 'b2b', payload).then((res: AxiosResponse) => {
            openNotification('success', t('notification.success.updateItem', { item: title }))
            loading.set(false);
            getData()
        }).catch((e: AxiosError) => {
            handleBackendError(e, t('notification.error.default'))
            loading.set(false);
        })
    }

    // Modal
    const rejectBtnLoading = useState(false)
    const rejectModalShow = useState(false)

    const rejectOnCancel = () => {
        rejectModalShow.set(false)
    }

    const rejectOnOk = async (data: any) => {
        rejectBtnLoading.set(true)

        let payload = {
            status: 'Ditolak',
            remark: data.remark
        }

        await RequestJourneyPlanRepository.updateStatus(id, 'b2b', payload).then((res: AxiosResponse) => {
            openNotification('success', t('notification.success.updateItem', { item: title }))
            rejectBtnLoading.set(false);
            rejectModalShow.set(false);
            formModal.resetFields()
            getData()
        }).catch((e: AxiosError) => {
            handleBackendError(e, t('notification.error.default'))
            rejectBtnLoading.set(false);
            rejectModalShow.set(false);
            formModal.resetFields()
        })
    }

    const getGoogleMapLink = () => {
        let data = formData.get()
        if (data.new_dealer_lng_lat) {
            let latLng = data.new_dealer_lng_lat.split(',')
            let lat = parseFloat(latLng[1])
            let lng = parseFloat(latLng[0])
            window.open(`http://www.google.com/maps/place/${lat},${lng}`)
        }
    }

    return (
        <>
            <WuiContainer>
                <PageHeader
                    className="default-page-header"
                    onBack={() => navigate(-1)}
                    title={pageTitle}
                />

                <Row gutter={[16, 16]}>
                    <Col className="gutter-row" md={{
                        span: 16,
                        order: 1
                    }} xs={{
                        span: 24,
                        order: 2
                    }}>
                        <Card title={t('common.text.itemInformation', { item: 'Dealer' })} className="mb16">
                            <Space style={{
                                width: '100%'
                            }} direction="vertical" size={25}>
                                <div className="wui-form-group type-column">
                                    <WuiFormTitle title={"Tanggal Kunjungan"} />
                                    <div className="input-section">
                                        <Text strong>{moment(formData.get().visit_date).format('DD MMMM YYYY')}</Text>
                                    </div>
                                </div>

                                <div className="wui-form-group type-column">
                                    <WuiFormTitle title={"Nama Dealer"} />
                                    <div className="input-section">
                                        <Text strong>{formData.get().new_dealer_name}</Text>
                                    </div>
                                </div>

                                <div className="wui-form-group type-column">
                                    <WuiFormTitle title={"Alamat Dealer"} />
                                    <div className="input-section">
                                        <Link strong onClick={getGoogleMapLink}><b>{formData.get().new_dealer_address}</b></Link>
                                    </div>
                                </div>

                                <div className="wui-form-group type-column">
                                    <WuiFormTitle title={"Email"} />
                                    <div className="input-section">
                                        <Text strong>{formData.get().new_dealer_email}</Text>
                                    </div>
                                </div>

                                <div className="wui-form-group type-column">
                                    <WuiFormTitle title={"Status"} />
                                    <div className="input-section">
                                        <Text strong>{formData.get().status}</Text>
                                    </div>
                                </div>

                                <div className="wui-form-group type-column">
                                    <WuiFormTitle title={"Alasan"} />
                                    <div className="input-section">
                                        <Text strong>{formData.get().reason}</Text>
                                    </div>
                                </div>

                                {
                                    formData.get().remark &&  
                                    <div className="wui-form-group type-column">
                                        <WuiFormTitle title={"Alasan Penolakan"} />
                                        <div className="input-section">
                                            <Text strong>{formData.get().remark}</Text>
                                        </div>
                                    </div>
                                }
                               
                            </Space>
                        </Card>

                        <Card title={t('common.text.itemInformation', { item: 'Sales' })}>
                            <Space style={{
                                width: '100%'
                            }} direction="vertical" size={25}>
                                <div className="wui-form-group type-column">
                                    <WuiFormTitle title={"Dibuat Oleh"} />
                                    <div className="input-section">
                                        <Text strong>{formData.get().sales_name}</Text>
                                    </div>
                                </div>

                                <div className="wui-form-group type-column">
                                    <WuiFormTitle title={"Tanggal Dibuat"} />
                                    <div className="input-section">
                                        <Text strong>{moment(formData.get().created_at).format('DD MMMM YYYY | HH:mm')}</Text>
                                    </div>
                                </div>
                            </Space>
                        </Card>
                    </Col>
                </Row>

                {
                    formData.get().status === 'Pending' ? (
                        <div className="wui-form-btn-group">
                            <Accessible access={[permissions.write_status_request_journey_plan_b2b]}>
                                <Button className="wui-btn" size={"large"} onClick={() => rejectModalShow.set(true)}>{t('common.button.reject')}</Button>
                            </Accessible>
                            <Accessible access={[permissions.write_status_request_journey_plan_b2b]}>
                                <Spin spinning={loading.get()}>
                                    <Button className="wui-btn" type="primary" size={"large"} disabled={disable.get()} onClick={acceptRequestJourney}>{t('common.button.accept')}</Button>
                                </Spin>
                            </Accessible>
                        </div>
                    ) : ''
                }
            </WuiContainer>


            <Modal
                title={`Tolak Permintaan ${formData.get().sales_name || ''}`}
                centered
                visible={rejectModalShow.get()}
                onOk={formModal.submit}
                onCancel={rejectOnCancel}
                footer={[
                    <Button key="back" onClick={rejectOnCancel}>
                        {t('common.button.back')}
                    </Button>,
                    <Button form="formModal" key="submit" htmlType={"submit"} type="primary" loading={rejectBtnLoading.get()}>
                        {t('common.button.send')}
                    </Button>,
                ]}
            >
                <Form
                    id={"formModal"}
                    form={formModal}
                    layout={"vertical"}
                    onFinish={rejectOnOk}
                >
                    <div className="content">
                        <div className="wui-form-group type-column">
                            <WuiFormTitle title={"Alasan Penolakan"} />
                            <div className="input-section">
                                <Form.Item
                                    name="remark"
                                    rules={[{
                                        required: true,
                                        message: 'Alasan harus diisi'
                                    }]}
                                >
                                    <Input disabled={disable.get()} size={"large"} placeholder={t('common.text.input', { item: 'Penolakan' })} />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                </Form>


            </Modal>
        </>
    )
}

export default AppJourneyPlanFormPermintaan