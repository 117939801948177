import { api, createCancelTokenHandler } from "../@framework/services";

const endPoint = () => process.env.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  select: function (params: any = null) {
    return api.get(`${endPoint()}/api/v1/b2b/select/sales`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.select.name].handleRequestCancellation()
          .token,
    });
  },
  all: function (params: any = null) {
    return api.get(`${endPoint()}/api/v1/b2b/sales`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.all.name].handleRequestCancellation()
          .token,
    });
  },
  show: function (id: number | string, params: any = null) {
    return api.get(`${endPoint()}/api/v1/b2b/sales/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.show.name].handleRequestCancellation()
          .token,
    });
  },
  create: function (payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/v1/b2b/sales`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.create.name].handleRequestCancellation()
          .token,
    });
  },
  reportConsultant: function (payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/v1/sales/report-consultant`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.reportConsultant.name].handleRequestCancellation()
          .token,
    });
  },
  reportPromoter: function (payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/v1/sales/report-promoter`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.reportPromoter.name].handleRequestCancellation()
          .token,
    });
  },
  update: function (id: number | string, payload: any, params: any = null) {
    return api.put(`${endPoint()}/api/v1/b2b/sales/${id}`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.update.name].handleRequestCancellation()
          .token,
    });
  },
  delete: function (id: number | string, params: any = null) {
    return api.delete(`${endPoint()}/api/v1/b2b/sales/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.delete.name].handleRequestCancellation()
          .token,
    });
  },
  deleteDealer: function (
    salesId: number | string,
    dealerId: number | string,
    params: any = null
  ) {
    return api.delete(
      `${endPoint()}/api/v1/sales-dealer/${salesId}/${dealerId}`,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.deleteDealer.name
          ].handleRequestCancellation().token,
      }
    );
  },
  importDealer: function (payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/v1/b2b/user-dealer-import`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.importDealer.name].handleRequestCancellation()
          .token,
    });
  },
  importTarget: function (payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/v1/b2b/user-target-import`, payload, {
      params,
      cancelToken:
      cancelTokenHandlerObject[this.importDealer.name].handleRequestCancellation()
          .token,
    });
  },
  allBrandTargetPeriod: function (id: number | string, params: any = null) {
    return api.get(`${endPoint()}/api/v1/b2b/sales/${id}/brand-target/period`, {
      params,
      cancelToken:
      cancelTokenHandlerObject[this.allBrandTargetPeriod.name].handleRequestCancellation()
          .token,
    });
  },
  allDealerTargetPeriod: function (id: number | string, params: any = null) {
    return api.get(`${endPoint()}/api/v1/b2b/sales/${id}/brand-target/dealer`, {
      params,
      cancelToken:
      cancelTokenHandlerObject[this.allDealerTargetPeriod.name].handleRequestCancellation()
          .token,
    });
  },
  allTargetPeriodDetail: function (id: number | string, dealerId: number | string, params: any = null) {
    return api.get(`${endPoint()}/api/v1/b2b/sales/${id}/brand-target/dealer/${dealerId}`, {
      params,
      cancelToken:
      cancelTokenHandlerObject[this.allTargetPeriodDetail.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;
