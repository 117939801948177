import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Form from "antd/lib/form";
import { useState } from "@hookstate/core";
import PageHeader from "antd/es/page-header";
import WuiContainer from "../../../../../@framework/wui/components/Container";
import WuiSectionTitle from "../../../../../@framework/wui/components/Sections/Title";
import Row from "antd/es/grid/row";
import Col from "antd/es/grid/col";
import Card from "antd/es/card";
import WuiFormTitle from "../../../../../@framework/wui/components/Form/Title";
import Tag from "antd/es/tag";
import { Checkbox, Spin } from "antd";
import { TChannels } from "../../../../../utils";
import Space from "antd/es/space";
import Select from "antd/es/select";
import WuiSelectMulti from "../../../../../@framework/wui/components/Select/Multi";
import SuperiorRepository from "../../../../../repositories/SuperiorRepository";
import WuiSelectSingle from "../../../../../@framework/wui/components/Select/Single";
import DatePicker from "antd/es/date-picker";
import moment from "moment";
import Input from "antd/es/input";
import Text from "antd/es/typography/Text";
import {
  DeleteOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import Button from "antd/es/button";
import { $clone } from "../../../../../@framework/utilities";
import Accessible from "../../../../../@framework/wui/components/Accessible";
import _ from "lodash";
import Table from "antd/es/table";
import Divider from "antd/es/divider";
import BrandRepository from "../../../../../repositories/BrandRepository";
import { permissions } from "../../../../../constant/permissions";
import { FormInstance, InputNumber } from "antd/es";
import KPIMasterRepository from "../../../../../repositories/KPIMasterRepository";
import { AxiosError, AxiosResponse } from "axios";
import {
  handleBackendError,
  haveAccess,
  inputNumberThousandSeparator,
  inputUppercase,
  openNotification,
} from "../../../../../functions/global";
import KPIItemRepository from "../../../../../repositories/KPIItemRepository";
import { OperatorForm } from "../Components";
import {
  KPI_MASTER_DEFAULT_OPERATORS,
  KPI_MASTER_STATUS_COLOR,
  // KPI_MASTER_STATUS_COMPLETED,
  KPI_MASTER_STATUS_DRAFT,
} from "../../../../../constant";
import BusinessUnitRepository from "../../../../../repositories/BusinessUnitRepository";
import useAccess from "../../../../../@framework/utilities/hooks/useAccess";

let title = "Master KPI";

const channelOptions: TChannels[] = ["B2B", "B2C"];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const permissionMapping: any = {
  b2b: {
    write: permissions.write_master_kpi_b2b,
  },
  b2c: {
    write: permissions.write_master_kpi_b2c,
  },
};

const AppKPIMasterForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const acl = useAccess();
  const pageTitle = id
    ? t("common.text.editItem", { item: title })
    : t("common.text.addNewItem", { item: title });
  const breadcrumbs = [
    {
      label: "KPI",
      link: "/kpi",
    },
    {
      label: "Master KPI",
      link: "/kpi/master-kpi",
    },
    id
      ? {
          label: "Detail Master KPI",
          link: `/kpi/master-kpi/${id}`,
        }
      : {
          label: "Tambah Master KPI",
          link: "/kpi/master-kpi/form",
        },
  ];

  // Form
  const [form] = Form.useForm();
  const channels = Form.useWatch("channels", form);
  const startDate = Form.useWatch("start_date", form);
  const status = useState(KPI_MASTER_STATUS_DRAFT);
  const saveAsDraft = useState(true);
  const disable = useState(false);
  const loading = useState(false);

  const handleFormValuesChanges = (data: any) => {
    if (data.channels) {
      form.setFieldValue("superior", null);
    }
  };

  const onFinish = async (data: any) => {
    loading.set(true);

    let payload = {
      ...data,
      superior_id: data.superior.value,
      start_date: data.start_date?.format("YYYY-MM-DD"),
      end_date: data.end_date?.format("YYYY-MM-DD"),
      brand_ids: _.map(data.brands, "id"),
      is_draft: saveAsDraft.get(),
    };

    const deletedKeys = ["superior", "brands"];
    deletedKeys.map((key: string) => delete payload[key]);

    const operators = [
      "process_focus",
      "result_focus",
      "business_acumen",
      "finance_parameter",
    ];

    operators.forEach((key: string) => {
      if (payload.detail[key]) {
        let tempOperator: any = [];

        payload.detail[key]?.forEach((item: any) => {
          let tempMultiplier: any = [];

          if (item?.multiplier) {
            item?.multiplier.forEach((multi: any) => {
              tempMultiplier.push({
                first_operator: multi?.first_operator,
                first_value: multi?.first_value,
                second_operator: multi?.second_operator,
                second_value: multi?.second_value,
                weight_percentage: multi?.weight_percentage,
              });
            });
          }

          tempOperator.push({
            kpi_item_id: item.kpi_item?.value,
            weight: item.weight,
            multiplier: tempMultiplier,
          });
        });

        payload.detail[key] = tempOperator;
      }
    });

    if (payload.detail?.business_units) {
      payload.detail.business_units = payload.detail?.business_units?.map(
        (item: any) => {
          return {
            business_unit_id: item?.business_unit.value,
            description: item?.description,
          };
        }
      );
    }

    if (payload.detail?.bu_balance_multiplier) {
      payload.detail.bu_balance_multiplier =
        payload.detail?.bu_balance_multiplier?.map((item: any) => {
          return {
            first_operator: item?.first_operator,
            first_value: item?.first_value,
            second_operator: item?.second_operator,
            second_value: item?.second_value,
            weight_percentage: item?.weight_percentage,
          };
        });
    }

    await (id
      ? KPIMasterRepository.update(id, payload)
      : KPIMasterRepository.create(payload)
    )
      .then((res: AxiosResponse) => {
        navigate(-1);

        if (!id) {
          openNotification(
            "success",
            t("notification.success.createItem", { item: title })
          );
        } else {
          openNotification(
            "success",
            t("notification.success.updateItem", { item: title })
          );
          loading.set(false);
        }
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));
        loading.set(false);
      });
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    await KPIMasterRepository.show(id, {
      with: ["superior", "channels", "brands"],
    })
      .then((res: AxiosResponse) => {
        const data = res.data?.data || {};

        let brands = data.detail?.brands?.map((item: any) => {
          return {
            name: item.brand?.name,
            id: item.brand?.id,
          };
        });

        let detailBrands = data.detail?.brands?.map((item: any) => {
          return {
            ...item,
            name: item.brand?.name,
          };
        });

        const operators = [
          "process_focus",
          "result_focus",
          "business_acumen",
          "finance_parameter",
        ];
        let detailOperatos: any = {};

        operators.forEach((key: string) => {
          let detailList = data.detail[key];

          if (data.detail[key]) {
            detailOperatos[key] = detailList?.map((item: any) => {
              return {
                ...item,
                kpi_item: {
                  value: item.kpi_item?.id,
                  label: item.kpi_item?.name,
                },
              };
            });
          }
        });

        let detailBusinessUnits = [];

        if (data.detail?.business_units) {
          detailBusinessUnits = data.detail?.business_units?.map(
            (item: any) => {
              return {
                ...item,
                business_unit: {
                  value: item.business_unit?.id,
                  label: item.business_unit?.name,
                },
              };
            }
          );
        }

        status.set(data?.status);

        if (data?.status !== KPI_MASTER_STATUS_DRAFT) {
          disable.set(true);
        }

        form.setFieldsValue({
          status: data?.status,
          channels: data.channels?.map((item: any) => item.channel),
          code: data?.code,
          name: data?.name,
          superior: {
            value: data.superior?.id,
            label: data.superior?.name,
          },
          brands: brands,
          start_date: moment(data?.start_date),
          end_date: moment(data?.end_date),
          detail: {
            ...data.detail,
            ...detailOperatos,
            brands: detailBrands,
            business_units: detailBusinessUnits,
          },
        });
      })
      .catch((e: AxiosError) => {
        handleBackendError(e);
      });
  };

  // Brand Table
  const brandTableData: any = useState<any[]>([]);

  const handleBrandOnChange = (value: any[]) => {
    let ids = value.map((item) => {
      return item.id;
    });
    let brandList = $clone(brandTableData.get()) ?? [];
    brandList = _.unionBy(value, brandList, "id");

    if (brandList) {
      brandList = brandList
        .filter((item: any) => {
          return ids.includes(item.id);
        })
        .map((item: any) => {
          return {
            ...item,
            brand_id: item.id,
            is_primary: false,
          };
        });
    }

    form.setFieldValue(["detail", "brands"], brandList);
    brandTableData.set(brandList);
  };

  return (
    <>
      <WuiContainer>
        <WuiSectionTitle title={""} breadcrumbs={breadcrumbs} />

        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
        />

        <Form
          form={form}
          layout={"vertical"}
          onFinish={onFinish}
          onValuesChange={handleFormValuesChanges}
          initialValues={{
            channels: [
              channelOptions.filter((item) =>
                haveAccess(acl, permissionMapping[item.toLowerCase()].write)
              )[0],
            ],
            start_date: moment().startOf("M"),
            end_date: moment().endOf("M"),
          }}
        >
          <Row gutter={[16, 16]}>
            <Col
              className="gutter-row"
              md={{
                span: 16,
                order: 1,
              }}
              xs={{
                span: 24,
                order: 2,
              }}
            >
              <Card className="mb16">
                <Space
                  style={{
                    width: "100%",
                  }}
                  direction="vertical"
                  size={5}
                >
                  <div className="wui-form-group type-column mb16">
                    <WuiFormTitle title={"Status"} />

                    <div className="input-section">
                      <Tag color={KPI_MASTER_STATUS_COLOR[status.get()]}>
                        {status.get()}
                      </Tag>
                    </div>
                  </div>

                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="channels"
                        label="Channel"
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Channel",
                            }),
                          },
                        ]}
                      >
                        <Checkbox.Group>
                          {channelOptions.map((channel) => {
                            return (
                              <Checkbox
                                value={channel}
                                disabled={
                                  disable.get() ||
                                  !haveAccess(
                                    acl,
                                    permissionMapping[channel.toLowerCase()].write
                                  )
                                }
                              >
                                {channel}
                              </Checkbox>
                            );
                          })}
                        </Checkbox.Group>
                      </Form.Item>
                    </div>
                  </div>

                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="code"
                        label="Kode"
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Kode",
                            }),
                          },
                        ]}
                      >
                        <Input
                          disabled={disable.get()}
                          size={"large"}
                          placeholder={t("common.text.input", { item: "Kode" })}
                          onInput={inputUppercase.formatter}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="name"
                        label="Nama"
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Nama",
                            }),
                          },
                        ]}
                      >
                        <Input
                          disabled={disable.get()}
                          size={"large"}
                          placeholder={t("common.text.input", { item: "Nama" })}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="wui-form-group type-column">
                    <div className="input-section">
                      <Form.Item
                        name="superior"
                        label="Peran"
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: "Role",
                            }),
                          },
                        ]}
                      >
                        <WuiSelectSingle
                          disabled={disable.get()}
                          selectParams={{
                            strict_channel: channels,
                          }}
                          repository={SuperiorRepository}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  {_.includes(channels, "B2B") &&
                  !_.includes(channels, "B2C") ? (
                    <div className="wui-form-group type-column">
                      <div className="input-section">
                        <Form.Item
                          name="brands"
                          label={"Brand"}
                          rules={[
                            {
                              required: true,
                              message: t("validation.required", {
                                item: "Brand",
                              }),
                            },
                          ]}
                        >
                          <WuiSelectMulti
                            disabled={disable.get()}
                            onChange={(value) => {
                              form.setFields([
                                { name: "brands", value: value },
                              ]);
                              handleBrandOnChange(value);
                            }}
                            repository={BrandRepository}
                            selectParams={{
                              selectAll: true,
                              selectAllLabel: "Semua Brand",
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <Row gutter={[16, 16]}>
                    <Col xs={24} md={12} lg={12}>
                      <div className="wui-form-group type-column">
                        <div className="input-section">
                          <Form.Item
                            name="start_date"
                            label={"Tanggal Mulai"}
                            rules={[
                              {
                                required: true,
                                message: t("validation.required", {
                                  item: "Tanggal Mulai",
                                }),
                              },
                            ]}
                          >
                            <DatePicker
                              disabled={disable.get()}
                              format="D MMMM YYYY"
                              style={{ width: 400, maxWidth: "100%" }}
                              size={"large"}
                              onChange={() => {}}
                              placeholder={t("common.text.input", {
                                item: "Tanggal Mulai",
                              })}
                              allowClear={false}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Col>

                    <Col xs={24} md={12} lg={12}>
                      <div className="wui-form-group type-column">
                        <div className="input-section">
                          <Form.Item
                            name="end_date"
                            label={"Tanggal Berakhir"}
                            rules={[
                              {
                                required: true,
                                message: t("validation.required", {
                                  item: "Tanggal Berakhir",
                                }),
                              },
                            ]}
                          >
                            <DatePicker
                              disabled={disable.get()}
                              disabledDate={(current) => {
                                let customDate = startDate.format("YYYY-MM-DD");
                                return (
                                  current &&
                                  current < moment(customDate, "YYYY-MM-DD")
                                );
                              }}
                              format="D MMMM YYYY"
                              style={{ width: 400, maxWidth: "100%" }}
                              size={"large"}
                              onChange={() => {}}
                              placeholder={t("common.text.input", {
                                item: "Tanggal Berakhir",
                              })}
                              allowClear={false}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Space>
              </Card>

              {_.includes(channels, "B2C") && !_.includes(channels, "B2B") && (
                <>
                  <Card title={"Business Accument"} className="mb16">
                    <OperatorForm
                      title={"Business Accument"}
                      name={"business_acumen"}
                      selectName={"kpi_item"}
                      selectLabel={"Item KPI"}
                      selectRepository={KPIItemRepository}
                      selectParams={{
                        channel: channels,
                      }}
                    />
                  </Card>

                  <Card
                    title={"BU Balance"}
                    className="mb16"
                    extra={
                      <Form.Item
                        name={["detail", "bu_balance_weight"]}
                        rules={[
                          {
                            required: true,
                            message: "BU balance weight is required",
                          },
                        ]}
                      >
                        <InputNumber
                          min={0}
                          max={100}
                          placeholder={"Bobot"}
                          style={{ width: 120 }}
                          addonAfter="%"
                          type="number"
                        />
                      </Form.Item>
                    }
                  >
                    <Form.List name={["detail", "business_units"]}>
                      {(business_units, { add, remove }) => (
                        <BUBalanceTable
                          data={business_units}
                          form={form}
                          add={add}
                          remove={remove}
                        />
                      )}
                    </Form.List>

                    <Divider />

                    <div className="mb16">
                      <Text type="warning">
                        <InfoCircleOutlined /> Text informasi untuk range
                      </Text>
                    </div>

                    <Form.List name={["detail", "bu_balance_multiplier"]}>
                      {(bu_balance_multiplier, { add, remove }) => {
                        return (
                          <Space
                            style={{
                              width: "100%",
                            }}
                            direction="vertical"
                            size={16}
                          >
                            {bu_balance_multiplier.map(
                              ({ key, name, ...restField }) => {
                                return (
                                  <Row key={key} gutter={8}>
                                    <Col span={4}>
                                      <Form.Item
                                        className="mb6"
                                        name={[name, "first_operator"]}
                                        rules={[
                                          {
                                            required: true,
                                            message: t("validation.required", {
                                              item: "Operator",
                                            }),
                                          },
                                        ]}
                                        {...restField}
                                      >
                                        <Select
                                          style={{ width: "100%" }}
                                          placeholder="Operator"
                                          options={KPI_MASTER_DEFAULT_OPERATORS}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                      <Form.Item
                                        className="mb6"
                                        name={[name, "first_value"]}
                                        rules={[
                                          {
                                            required: true,
                                            message: t("validation.required", {
                                              item: "Nilai",
                                            }),
                                          },
                                        ]}
                                        {...restField}
                                      >
                                        <InputNumber
                                          placeholder={"Nilai"}
                                          style={{ width: "100%" }}
                                          type="number"
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col className="pt4">-</Col>
                                    <Col span={4}>
                                      <Form.Item
                                        className="mb6"
                                        name={[name, "second_operator"]}
                                        {...restField}
                                      >
                                        <Select
                                          allowClear={true}
                                          style={{ width: "100%" }}
                                          placeholder="Operator"
                                          options={KPI_MASTER_DEFAULT_OPERATORS}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                      <Form.Item
                                        className="mb6"
                                        name={[name, "second_value"]}
                                        {...restField}
                                      >
                                        <InputNumber
                                          placeholder={"Nilai"}
                                          style={{ width: "100%" }}
                                          type="number"
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col className="pt4">=</Col>
                                    <Col span={6}>
                                      <Form.Item
                                        className="mb6"
                                        name={[name, "weight_percentage"]}
                                        rules={[
                                          {
                                            required: true,
                                            message: t("validation.required", {
                                              item: "Bobot",
                                            }),
                                          },
                                        ]}
                                        {...restField}
                                      >
                                        <InputNumber
                                          min={0}
                                          max={100}
                                          placeholder={"Bobot Persen"}
                                          style={{ width: "100%" }}
                                          addonAfter="%"
                                          type="number"
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col className="pt4">
                                      <DeleteOutlined
                                        onClick={() => remove(name)}
                                      />
                                    </Col>
                                  </Row>
                                );
                              }
                            )}
                            <Button
                              icon={<PlusOutlined />}
                              type="primary"
                              ghost
                              block
                              onClick={add}
                            >
                              Tambah Pengali
                            </Button>
                          </Space>
                        );
                      }}
                    </Form.List>
                  </Card>

                  <Card title={"Finance Parameter"} className="mb16">
                    <OperatorForm
                      title={"Finance Parameter"}
                      name={"finance_parameter"}
                      selectName={"kpi_item"}
                      selectLabel={"Item KPI"}
                      selectRepository={KPIItemRepository}
                      selectParams={{
                        channel: channels,
                      }}
                    />
                  </Card>
                </>
              )}

              {_.includes(channels, "B2B") && (
                <>
                  <Card
                    title={"Fokus Proses"}
                    className="mb16"
                    extra={
                      <Form.Item
                        name={["detail", "process_focus_weight"]}
                        className="mb0"
                        rules={[
                          {
                            required: true,
                            message: "Process focus weight is required",
                          },
                        ]}
                      >
                        <InputNumber
                          min={0}
                          max={100}
                          placeholder={"Bobot"}
                          style={{ width: 120 }}
                          addonAfter="%"
                          type="number"
                        />
                      </Form.Item>
                    }
                  >
                    <OperatorForm
                      title={"Fokus Proses"}
                      name={"process_focus"}
                      selectName={"kpi_item"}
                      selectLabel={"Item KPI"}
                      selectRepository={KPIItemRepository}
                      selectParams={{
                        channel: channels,
                      }}
                    />
                  </Card>

                  <Card
                    title={"Fokus Hasil"}
                    className="mb16"
                    extra={
                      <Form.Item
                        name={["detail", "result_focus_weight"]}
                        className="mb0"
                        rules={[
                          {
                            required: true,
                            message: "Result focus weight is required",
                          },
                        ]}
                      >
                        <InputNumber
                          min={0}
                          max={100}
                          placeholder={"Bobot"}
                          style={{ width: 120 }}
                          addonAfter="%"
                          type="number"
                        />
                      </Form.Item>
                    }
                  >
                    <OperatorForm
                      title={"Fokus Hasil"}
                      name={"result_focus"}
                      selectName={"kpi_item"}
                      selectLabel={"Item KPI"}
                      selectRepository={KPIItemRepository}
                      selectParams={{
                        channel: channels,
                      }}
                    />
                  </Card>
                </>
              )}

              {_.includes(channels, "B2B") && !_.includes(channels, "B2C") && (
                <Form.List name={["detail", "brands"]}>
                  {(brands, { add, remove }) => (
                    <BrandTable
                      form={form}
                      data={brands}
                      add={add}
                      remove={remove}
                    />
                  )}
                </Form.List>
              )}

              <Input.Group className="mt24">
                <Row align={"middle"}>
                  <Col span={6}>Variabel Pengali</Col>
                  <Col span={18}>
                    <Form.Item
                      className={"mb0"}
                      name={["detail", "multiplier_variable"]}
                      rules={[
                        {
                          required: true,
                          message: "Multiplier variable is required",
                        },
                      ]}
                    >
                      <InputNumber
                        formatter={inputNumberThousandSeparator.formatter}
                        parser={inputNumberThousandSeparator.parser}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Input.Group>
            </Col>
          </Row>

          <div className="wui-form-btn-group">
            <Button
              className="wui-btn"
              size={"large"}
              onClick={() => navigate(-1)}
            >
              {t("common.button.cancel")}
            </Button>
            <Accessible
              access={[
                permissions.write_master_kpi_b2b,
                permissions.write_master_kpi_b2c,
              ]}
            >
              <>
                {status.get() === KPI_MASTER_STATUS_DRAFT ? (
                  <Spin spinning={loading.get()}>
                    <Button
                      className="wui-btn"
                      size={"large"}
                      onClick={() => {
                        saveAsDraft.set(true);
                        form.submit();
                      }}
                    >
                      {"Simpan Sebagai Draft"}
                    </Button>
                  </Spin>
                ) : (
                  ""
                )}
                <Spin spinning={loading.get()}>
                  <Button
                    className="wui-btn"
                    type="primary"
                    size={"large"}
                    onClick={() => {
                      saveAsDraft.set(false);
                      form.submit();
                    }}
                  >
                    {t("common.button.save")}
                  </Button>
                </Spin>
              </>
            </Accessible>
          </div>
        </Form>
      </WuiContainer>
    </>
  );
};

const BrandTable = (prop: any) => {
  const data = prop.data;
  const [tableLoading] = React.useState<boolean>(false);

  const columns = [
    {
      title: "Brand",
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any, index: number) => {
        return (
          <Space
            style={{
              width: "100%",
              minWidth: 240,
            }}
            direction="vertical"
            size={0}
          >
            <Form.Item name={[index, "name"]} className={"mb0"}>
              <Input className={"p0"} bordered={false} readOnly={true} />
            </Form.Item>

            <Form.Item
              name={[index, "is_primary"]}
              className={"mb0"}
              valuePropName="checked"
            >
              <Checkbox checked={false}>
                <Text>Brand utama</Text>
              </Checkbox>
            </Form.Item>
          </Space>
        );
      },
    },
    {
      title: "Bobot",
      dataIndex: "bobot",
      key: "bobot",
      render: (text: any, record: any, index: number) => {
        return (
          <>
            <Form.Item
              name={[index, "weight_percentage"]}
              className={"mb0"}
              rules={[
                {
                  required: true,
                  message: "Bobot harus diisi",
                },
              ]}
            >
              <InputNumber
                min={0}
                max={100}
                placeholder={"Bobot"}
                style={{ width: "100%" }}
                addonAfter="%"
                type="number"
              />
            </Form.Item>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Table
        rowKey={"key"}
        columns={columns}
        dataSource={data}
        loading={tableLoading}
        pagination={false}
      />
    </>
  );
};

interface BUBalanceProps {
  data: any[];
  form: FormInstance;
  add: () => void;
  remove: (index: number) => void;
}

const BUBalanceTable: React.FC<BUBalanceProps> = ({
  data,
  form,
  add,
  remove,
}) => {
  // Table Column
  const columns: any = useState([
    {
      title: "Master BU",
      dataIndex: "business_unit_id",
      key: "business_unit_id",
      render: (text: any, record: any, index: number) => {
        return (
          <Form.Item
            name={[index, "business_unit"]}
            className={"mb0"}
            rules={[
              {
                required: true,
                message: "Business Unit Harus Diisi",
              },
            ]}
          >
            <WuiSelectSingle
              placeholder={`Pilih Master BU`}
              repository={BusinessUnitRepository}
              style={{ width: "100%" }}
              size={"middle"}
            />
          </Form.Item>
        );
      },
    },
    {
      title: "Keterangan",
      dataIndex: "description",
      key: "description",
      render: (text: any, record: any, index: number) => {
        return (
          <Form.Item name={[index, "description"]} className={"mb0"}>
            <Input placeholder={"Masukkan Keterangan"} />
          </Form.Item>
        );
      },
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (text: any, record: any, index: number) => {
        return <DeleteOutlined onClick={() => remove(index)} />;
      },
    },
  ]);

  return (
    <>
      <Table
        rowKey={"key"}
        columns={columns.get()}
        dataSource={data}
        pagination={false}
      />

      <Button
        icon={<PlusOutlined />}
        type="primary"
        ghost
        block
        className={"mt16"}
        onClick={() => add()}
      >
        Tambah Business Unit
      </Button>
    </>
  );
};

export default AppKPIMasterForm;
