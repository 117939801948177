import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { usePassportService } from "../../../../@framework/utilities/hooks";
import WuiLoadingScreen from "../../../../@framework/wui/components/LoadingScreen";
import { OAuth2TokenContract } from "../../../../@framework/services/passport.service";

const AuthLogin: React.FC<any> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleKeycloakCallback } = usePassportService();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    init();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const login = () => {
    const token: OAuth2TokenContract = {
      token_type: searchParams.get("token_type") || "",
      access_token: searchParams.get("access_token") || "",
      refresh_token: searchParams.get("refresh_token") || "",
      expires_in: Number(searchParams.get("expires_in")),
    };

    handleKeycloakCallback(token);

    if (searchParams.get("is_mobile_app")) {
      localStorage.setItem("is_mobile_app", "1");

      if (searchParams.get("channel")) {
        navigate(`/dashboard/${searchParams.get("channel")}`);
        return;
      }
    } // using for accesing dashboard from mobile

    navigate("/");
  };

  const init = () => {
    const error = searchParams.get("error");

    if (error === "true") {
      navigate(
        {
          pathname: "/login",
          search: searchParams.toString(),
        },
        { replace: true }
      );
    } else {
      login();
    }
  };

  return (
    <>
      <Helmet>
        <title>{t("auth.login.metaTitle")}</title>
      </Helmet>

      <WuiLoadingScreen />
    </>
  );
};

export default AuthLogin;
