import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import {TableFraud} from '../Table'

const ModalTransactionDetail = ({
    show,
    dataSource,
    handleClose,
}: {
    show: boolean;
    dataSource: any[],
    handleClose: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={`Detail Fraud`}
      centered
      visible={show}
      width="1000"
      onCancel={handleClose}
      bodyStyle={{ padding: 0 }}
      footer={[
        <Button type="primary" key="back" onClick={handleClose}>
          {t("common.button.back")}
        </Button>,
      ]}
    >
      <TableFraud dataSource={dataSource} />
    </Modal>
  );
};

export default ModalTransactionDetail;
