import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useIsMounted } from "../../../../@framework/utilities/hooks";
import WuiContainer from "../../../../@framework/wui/components/Container";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import WuiSectionTitle from "../../../../@framework/wui/components/Sections/Title";
import React, { useEffect, useMemo } from "react";
import Col from "antd/es/grid/col";
import Input from "antd/es/input";
import Row from "antd/es/grid/row";
import Button from "antd/es/button";
import Table from "antd/es/table";
import Tabs from "antd/es/tabs";
import Space from "antd/es/space";
import useDebounce from "../../../../@framework/utilities/hooks/useDebounce";
import { useState } from "@hookstate/core";
import { AxiosError, AxiosResponse } from "axios";
import Text from "antd/es/typography/Text";
import WuiModalConfirmation from "../../../../@framework/wui/components/Modal/Confirmation";
import {
  handleBackendError,
  haveAccess,
  openNotification,
} from "../../../../functions/global";
import moment from "moment";
import Collapse from "antd/lib/collapse";
import _ from "lodash";
import WuiSelectMulti from "../../../../@framework/wui/components/Select/Multi";
import BrandRepository from "../../../../repositories/BrandRepository";
import PromoRepository from "../../../../repositories/PromoRepository";
import Accessible from "../../../../@framework/wui/components/Accessible";
import { IChannel } from "../../../../utils";
import { permissions } from "../../../../constant/permissions";
import useAccess from "../../../../@framework/utilities/hooks/useAccess";

const { Panel } = Collapse;
const { TabPane } = Tabs;

var qs = require("qs");

const breadcrumbs = [
  {
    label: "Promo",
    link: "/promo",
  },
];

const channels: IChannel[] = [
  {
    key: "b2b",
    permission: {
      read: permissions.read_promotion_b2b,
      write: permissions.write_promotion_b2b,
      delete: permissions.delete_promotion_b2b
    },
  },
  {
    key: "b2c",
    permission: {
      read: permissions.read_promotion_b2c,
      write: permissions.write_promotion_b2c,
      delete: permissions.delete_promotion_b2c
    },
  },
];

const AppPromoList: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const acl = useAccess()
  let fullPath = window.location.pathname;

  const selectedChannel = useState<IChannel | null>(null);

  const tablePage = useState<number>(parseInt(searchParams.get("page") || "1"));
  const tablePerPage = useState<number>(
    parseInt(searchParams.get("perPage") || "10")
  );
  const [tableLoading, setTableLoading] = React.useState<boolean>(false);
  const tableSort = useState<{
    sortField: any;
    sortOrder: any;
  }>({
    sortField: searchParams.get("sortField"),
    sortOrder: searchParams.get("sortOrder"),
  });
  const tableData = useState([]);
  const totalData = useState<number>(0);

  // Filters
  const localSelectbrands = JSON.parse(
    localStorage.getItem("product.brands") || "[]"
  );
  const [selectedBrands, setSelectedBrands] = React.useState<any[]>([]);

  const getTableData = async (reset: boolean = false) => {
    setTableLoading(true);

    if (reset) tablePage.set(1);

    let params = {
      page: tablePage.get(),
      search: debouncedSearch,
      order_by: tableSort.sortField.get(),
      sorted_by: tableSort.sortOrder.get(),
      per_page: tablePerPage.get(),
    };

    if (!params.order_by) {
      params.order_by = "created_at";
      params.sorted_by = "descend";
    }

    const brands = _.map(selectedBrands, "id");

    if (brands) {
      Object.assign(params, {
        brands: brands,
      });
    }

    await PromoRepository.all(getSelectedChannelKey() ,params)
      .then((res: AxiosResponse) => {
        if (res.data.data) {
          tableData.set(res.data.data);
        }

        totalData.set(res.data?.total || 0);

        setTableLoading(false);
      })
      .catch((e: AxiosError) => {
        setTableLoading(false);
      });
  };

  const handleTableChange = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {
    tablePage.set(pagination.current);
    tablePerPage.set(pagination.pageSize);

    if (sorter.order) {
      tableSort.sortField.set(sorter.field);
      tableSort.sortOrder.set(sorter.order);
    } else {
      tableSort.sortField.set(null);
      tableSort.sortOrder.set(null);
    }

    setFilterDataToQuery();
  };

  const handleTabChange = (key: string) => {
    const tab = _.find(channels, ['key', key])
    if(tab) {
      selectedChannel.set(tab)
    }
    setFilterDataToQuery()
  }

  const getSelectedChannelKey = () => {
    return _.get(selectedChannel.get(), 'key', "")
  }

  const getSelectedChannelPermission = () => {
    return {
      read: _.get(selectedChannel.get(), 'permission.read'),
      write: _.get(selectedChannel.get(), 'permission.write'),
      delete: _.get(selectedChannel.get(), 'permission.delete'),
    }
  }

  // For Search Input
  const [search, setSearch] = React.useState<string>("");
  const debouncedSearch = useDebounce<string>(search, 1000);

  useEffect(() => {
    if (!isMounted) {
      setFilterDataToQuery();
    }
    // eslint-disable-next-line
  }, [debouncedSearch]);

  useEffect(() => {
    setFilterDataToQuery();
    // eslint-disable-next-line
  }, [selectedBrands]);

  const getFilterDataFromQuery = () => {
    const keyword = searchParams.get("keyword");

    if (keyword) {
      setSearch(keyword);
    }

    setInitialChannel()

    if (!localSelectbrands) {
      searchParams.delete("brands");
    } else {
      const brands = _.map(searchParams.getAll("brands"), (item) =>
        parseInt(item)
      );
      if (brands) {
        let brandFromStorage = _.filter(localSelectbrands, (data) => {
          return brands.includes(data.id);
        });
        setSelectedBrands(brandFromStorage);
      } else {
        localStorage.removeItem("product.brands");
      }
    }
  };

  const setInitialChannel = () => {
    const searchParamsChannel = searchParams.get("channel");
    const selectedSearchParamsChannel = _.find(channels, ['key', searchParamsChannel])
    if (selectedSearchParamsChannel) {
        selectedChannel.set(selectedSearchParamsChannel);
    } else {
      const defaultSelectedChannel = _.find(channels, (ch) =>  haveAccess(acl, ch.permission.read || ''));
      if (defaultSelectedChannel) {
        let params = {}
        Object.assign(params, {
          channel: defaultSelectedChannel.key,
        });
        const queryParams = qs.stringify(params, { indices: false });
        setTimeout(() => {
          setSearchParams(`?${queryParams}`, {replace: true});
        }, 100)
        selectedChannel.set(defaultSelectedChannel);
      }
    }
  };

  useMemo(() => {
    getFilterDataFromQuery();
    // eslint-disable-next-line
  }, []);

  const setFilterDataToQuery = () => {
    let params = {};
    let reset = false;

    if(getSelectedChannelKey()) {
      Object.assign(params, {
        channel: getSelectedChannelKey(),
      });
  
      if(getSelectedChannelKey() !== searchParams.get('channel')) {
        reset = true
      }
    }

    if (debouncedSearch) {
      Object.assign(params, {
        keyword: debouncedSearch,
      });

      if (debouncedSearch !== searchParams.get("keyword")) {
        reset = true;
      }
    }

    if (tablePage.get() !== 1 && !reset) {
      Object.assign(params, {
        page: tablePage.get(),
      });
    }

    if (tablePerPage.get() !== 10 && !reset) {
      Object.assign(params, {
        perPage: tablePerPage.get(),
      });
    }

    if (tableSort.sortField.get() && !reset) {
      Object.assign(params, {
        sortField: tableSort.sortField.get(),
      });
    }

    if (tableSort.sortOrder.get() && !reset) {
      Object.assign(params, {
        sortOrder: tableSort.sortOrder.get(),
      });
    }

    if (selectedBrands) {
      const multiValue = _.map(selectedBrands, "id");

      Object.assign(params, {
        brands: multiValue,
      });
    }

    const queryParams = qs.stringify(params, { indices: false });

    if (queryParams) {
      setSearchParams(`?${queryParams}`);
    } else {
      navigate("");
    }

    getTableData(reset);
  };

  const columns: any = [
    {
      title: "Nama Promo",
      dataIndex: "title",
      key: "title",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "title" && tableSort.sortOrder.get(),
    },
    {
      title: "Kategori Promo",
      dataIndex: "type",
      key: "type",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "type" && tableSort.sortOrder.get(),
    },
    {
      title: "Brand",
      dataIndex: "brand_name",
      key: "brand_name",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "brand_name" && tableSort.sortOrder.get(),
    },
    {
      title: "Tanggal Dibuat",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      defaultSortOrder:
        tableSort.sortField.get() === "created_at" && tableSort.sortOrder.get(),
      render: (text: any) => {
        return moment(text).format("D MMM YYYY | HH:mm");
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          <Link to={fullPath + getSelectedChannelKey() + "/" + record.id}>Lihat Detail</Link>
          <Accessible access={getSelectedChannelPermission().delete}>
            <Text
              className="cursor-pointer"
              type={"danger"}
              onClick={() => setDeleteModal(record.id)}
            >
              Hapus
            </Text>
          </Accessible>
        </Space>
      ),
    },
  ];

  // Modal
  const showDeleteModal = useState(false);
  const selectedDeleteId = useState(0);

  const setDeleteModal = (id: any) => {
    selectedDeleteId.set(id);
    showDeleteModal.set(true);
  };

  const deleteModalCallback = async () => {
    if (selectedDeleteId.get()) {
      await PromoRepository.delete(selectedDeleteId.get(), getSelectedChannelKey())
        .then((res: AxiosResponse) => {
          showDeleteModal.set(false);
          selectedDeleteId.set(0);
          openNotification(
            "success",
            t("notification.success.deleteItem", { item: "Promosi/Program" })
          );
          getTableData(true);
        })
        .catch((e: AxiosError) => {
          showDeleteModal.set(false);
          selectedDeleteId.set(0);
          handleBackendError(e, t("notification.error.default"));
        });
    }
  };

  const renderTabContent = () => {
    return (
      <>
        <Row gutter={[10, 10]} className="mb16">
          <Col className="gutter-row" span={6} xs={24} md={12} lg={10}>
            <Input
              allowClear
              placeholder={t("common.filter.search.placeholder")}
              prefix={<SearchOutlined />}
              value={search}
              onChange={(value) => {
                setSearch(value.target.value);
              }}
            />
          </Col>
        </Row>

        {getSelectedChannelKey() === "b2b" && <Collapse
          className="mb16"
          defaultActiveKey={["1"]}
          expandIconPosition={"right"}
        >
          <Panel header="Filter" key="1">
            <Row className="mb6" gutter={[10, 10]}>
              <Col className="gutter-row" xs={24} md={24} lg={12}>
                <WuiSelectMulti
                  value={selectedBrands}
                  onChange={(val: any) => {
                    tablePage.set(1);
                    setSelectedBrands(val);
                    localStorage.setItem("product.brands", JSON.stringify(val));
                  }}
                  repository={BrandRepository}
                  placeholder={t("select.placeholder", { item: "Brand" })}
                />
              </Col>
            </Row>
          </Panel>
        </Collapse>}

        <Table
          rowKey={"id"}
          bordered
          columns={getSelectedChannelKey() === "b2b" ? columns : columns.filter((col: any) => col.title !== "Brand")}
          dataSource={tableData.get()}
          loading={tableLoading}
          onChange={handleTableChange}
          pagination={{
            current: tablePage.get(),
            simple: true,
            pageSize: tablePerPage.get(),
            total: totalData.get(),
          }}
        />
      </>
    );
  };

  return (
    <>
      <WuiContainer>
        <WuiSectionTitle title={"Promo"} breadcrumbs={breadcrumbs}>
          <Accessible access={getSelectedChannelPermission().write}>
            <Button
              onClick={() => navigate(fullPath + "/form")}
              type="primary"
              icon={<PlusOutlined />}
            >
              {t("common.button.addNew")}
            </Button>
          </Accessible>
        </WuiSectionTitle>

        <Tabs
          defaultActiveKey={getSelectedChannelKey()}
          onChange={handleTabChange}
        >
         {channels.filter((tab) => haveAccess(acl, tab.permission.read || '')).map((tab) => {
            return (
              <TabPane tab={tab.key.toUpperCase()} key={tab.key}>
                {renderTabContent()}
              </TabPane>
            );
          })}
        </Tabs>
      </WuiContainer>

      <WuiModalConfirmation
        show={showDeleteModal.get()}
        onOk={deleteModalCallback}
        onCancel={() => showDeleteModal.set(false)}
      />
    </>
  );
};

export default AppPromoList;
