import React, {useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import moment from "moment";
import Col from "antd/es/grid/col";
import WuiFilterDateRange from "../../../../../../@framework/wui/components/Filter/DateRange";
import Button from "antd/es/button";
import Row from "antd/es/grid/row";
import Table from "antd/es/table";
import Card from "antd/es/card";
import {useState} from "@hookstate/core";
import DashboardRepository from "../../../../../../repositories/DashboardRepository";
import {AxiosError, AxiosResponse} from "axios";
import useDebounce from "../../../../../../@framework/utilities/hooks/useDebounce";

var qs = require('qs');

interface Props {
    active: boolean
    setParams?: Function
}

const DashboardB2CForecast: React.FC<Props> = (
    {
        active,
        setParams
    }
) => {
    const [searchParams, setSearchParams] = useSearchParams();
    // For DateRange Picker
    const [filterDate, setFilterDate] = React.useState<any>([
        (searchParams.get('start_date') ? moment((searchParams.get('start_date')), 'YYYY-MM-DD') : moment().startOf('month')),
        (searchParams.get('end_date') ? moment((searchParams.get('end_date')), 'YYYY-MM-DD') : moment().endOf('month'))
    ])

    const tablePage = useState<number>(parseInt(searchParams.get('page') || '1'))
    const tablePerPage = useState<number>(parseInt(searchParams.get('perPage') || '10'))
    const [tableLoading, setTableLoading] = React.useState<boolean>(false)
    const tableSort = useState<{
        sortField: any,
        sortOrder: any,
    }>({
        sortField: searchParams.get('sortField'),
        sortOrder: searchParams.get('sortOrder')
    })
    const tableData = useState<any[]>([]);
    const totalData = useState<number>(0)

    const getTableData = async (reset: boolean = false) => {
        setTableLoading(true)

        if (reset) tablePage.set(1);

        let params = {
            page: tablePage.get(),
            search: debouncedSearch,
            order_by: tableSort.sortField.get(),
            sorted_by: tableSort.sortOrder.get(),
            per_page: tablePerPage.get(),
            start_date: filterDate[0].format('YYYY-MM-DD'),
            end_date: filterDate[1].format('YYYY-MM-DD'),
        }

        if(!params.order_by){
            params.order_by = 'created_at';
            params.sorted_by = 'descend';
        }

        await DashboardRepository.allReportForecast(params).then((res: AxiosResponse) => {
            if (res.data.data) {
                tableData.set(res.data.data)
            }

            totalData.set(res.data?.total || 0)

            setTableLoading(false)
        }).catch((e: AxiosError) => {
            setTableLoading(false)
        })
    }

    const columns: any = [
        {
            title: "Code",
            dataIndex: "product_code",
            key: "product_code",
            sorter: true,
            defaultSortOrder: tableSort.sortField.get() === "product_code" && tableSort.sortOrder.get(),
        },
        {
            title: "Product",
            dataIndex: "product_name",
            key: "product_name",
            sorter: true,
            defaultSortOrder: tableSort.sortField.get() === "product_name" && tableSort.sortOrder.get(),
        },
        {
            title: "Store",
            dataIndex: "store_code",
            key: "store_code",
            sorter: true,
            defaultSortOrder: tableSort.sortField.get() === "store_code" && tableSort.sortOrder.get(),
        },
        {
            title: "Nama Toko",
            dataIndex: "store_name",
            key: "store_name",
            sorter: true,
            defaultSortOrder: tableSort.sortField.get() === "store_name" && tableSort.sortOrder.get(),
        },
        {
            title: "Start Date",
            dataIndex: "start_date",
            key: "start_date",
            sorter: true,
            defaultSortOrder: tableSort.sortField.get() === "start_date" && tableSort.sortOrder.get(),
            render: (text: any) => {
                return moment(text).format("D MMM YYYY");
            },
        },
        {
            title: "Coverage",
            dataIndex: "coverage_stock",
            key: "coverage_stock",
            sorter: true,
            defaultSortOrder: tableSort.sortField.get() === "coverage_stock" && tableSort.sortOrder.get(),
        },
        {
            title: "Inventory",
            dataIndex: "inventory",
            key: "inventory",
            sorter: true,
            defaultSortOrder: tableSort.sortField.get() === "inventory" && tableSort.sortOrder.get(),
        },
    ]

    const handleTableChange = (
        pagination: any,
        filters: any,
        sorter: any,
        extra: any
    ) => {
        tablePage.set(pagination.current);
        tablePerPage.set(pagination.pageSize);

        if (sorter.order) {
            tableSort.sortField.set(sorter.field);
            tableSort.sortOrder.set(sorter.order);
        } else {
            tableSort.sortField.set(null);
            tableSort.sortOrder.set(null);
        }

        setFilterDataToQuery();
    };


    // For Search Input
    const [search, setSearch] = React.useState<string>("");
    const debouncedSearch = useDebounce<string>(search, 1000);

    const handleDateRangeCallback = async (dates: any, dateString?: any) => {
        await setFilterDate(dates)
    }

    const setFilterDataToQuery = () => {
        let params = {
            tab: 'Forecast',
        }

        let reset = false;

        if (debouncedSearch) {
            Object.assign(params, {
                keyword: debouncedSearch,
            });

            if (debouncedSearch !== searchParams.get("keyword")) {
                reset = true;
            }
        }

        if (filterDate) {
            Object.assign(params, {
                start_date: filterDate[0].format('YYYY-MM-DD'),
                end_date: filterDate[1].format('YYYY-MM-DD')
            })
        }

        const queryParams = qs.stringify(params, { indices: false });

        if (queryParams) {
            setSearchParams(`?${queryParams}`)
        }

        getTableData(reset)
    }

    const getFilterDataFromQuery = () => {
        const keyword = searchParams.get("keyword");

        if (keyword) {
            setSearch(keyword);
        }

        setFilterDataToQuery()
    }

    const handleReset = () => {
        setFilterDate([moment().startOf('month'), moment().endOf('month')])
    }

    useEffect(() => {
        setFilterDataToQuery()
    }, [debouncedSearch, filterDate]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (active) {
            getFilterDataFromQuery()
        }
        // eslint-disable-next-line
    }, [active])

    return (
        <>
            <Row className="mb16" gutter={[15, 15]}>
                <Col className="gutter-row" xs={24} md={12} lg={8}>
                    <WuiFilterDateRange defaultValue={filterDate} callback={handleDateRangeCallback} />
                </Col>
                <Col>
                    <Button type="link" onClick={handleReset}>Reset</Button>
                </Col>
            </Row>

            <Card>
                <div className={'mb16'}>Product Forecast</div>

                <Table
                    rowKey={"id"}
                    bordered
                    columns={columns}
                    dataSource={tableData.get()}
                    loading={tableLoading}
                    onChange={handleTableChange}
                    pagination={{
                        current: tablePage.get(),
                        simple: true,
                        pageSize: tablePerPage.get(),
                        total: totalData.get(),
                    }}
                />
            </Card>
        </>
    )
}

export default DashboardB2CForecast
