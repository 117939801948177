import AppstoreOutlined from "@ant-design/icons/AppstoreOutlined";
import AuditOutlined from "@ant-design/icons/lib/icons/AuditOutlined";
import NodeIndexOutlined from "@ant-design/icons/lib/icons/NodeIndexOutlined";
import NotificationOutlined from "@ant-design/icons/lib/icons/NotificationOutlined";
import SettingOutlined from "@ant-design/icons/lib/icons/SettingOutlined";
import UnorderedListOutlined from "@ant-design/icons/lib/icons/UnorderedListOutlined";
import PushpinOutlined from "@ant-design/icons/PushpinOutlined";
import { BoxesIcon } from "../components/icons";
import { RadiusSettingOutlined, RiseOutlined } from "@ant-design/icons";
import { permissions } from "./permissions";

export const defaultMenus = [
  {
    title: "Dashboard",
    key: "dashboard",
    link: "/dashboard",
    access: [
      permissions.read_grafik_b2b_tab_a,
      permissions.read_grafik_b2b_tab_b,
      permissions.read_grafik_b2c_tab_a,
      permissions.read_grafik_b2c_tab_o2o,
      permissions.read_grafik_b2c_tab_forecast,
      permissions.read_grafik_online,
      permissions.read_grafik_headquarter,
    ],
    icon: <AppstoreOutlined />,
    showOnMobileApp: true,
    subMenus: [
      {
        title: "B2B",
        key: "B2B",
        access: [
          permissions.read_grafik_b2b_tab_a,
          permissions.read_grafik_b2b_tab_b,
        ],
        showOnMobileApp: true,
        link: "/dashboard/B2B",
      },
      {
        title: "B2C",
        key: "B2C",
        access: [
          permissions.read_grafik_b2c_tab_a,
          permissions.read_grafik_b2c_tab_o2o,
          permissions.read_grafik_b2c_tab_forecast,
        ],
        showOnMobileApp: true,
        link: "/dashboard/B2C",
      },
      {
        title: "Online",
        key: "online",
        access: permissions.read_grafik_online,
        showOnMobileApp: true,
        link: "/dashboard/online",
      },
      {
        title: "Head Quarter",
        key: "head-quarter",
        access: permissions.read_grafik_headquarter,
        showOnMobileApp: true,
        link: "/dashboard/head-quarter",
      },
    ],
  },
  {
    title: "Journey Plan",
    key: "journey-plan",
    link: "/journey-plan",
    access: [
      permissions.read_journey_plan_b2b,
      permissions.read_journey_plan_b2c,
      permissions.read_request_journey_plan_b2b,
      permissions.read_request_journey_plan_b2c,
    ],
    icon: <PushpinOutlined />,
    subMenus: [
      {
        title: "B2B",
        key: "B2B",
        access: permissions.read_journey_plan_b2b,
        link: "/journey-plan/B2B",
      },
      {
        title: "B2C",
        key: "B2C",
        access: permissions.read_journey_plan_b2c,
        link: "/journey-plan/B2C",
      },
    ],
  },
  {
    title: "Promo",
    key: "promo",
    access: [permissions.read_promotion_b2b, permissions.read_promotion_b2c],
    link: "/promo",
    icon: <NotificationOutlined />,
  },
  {
    title: "Issue Tracking",
    key: "issue-tracking",
    access: [
      permissions.read_issue_tracking_b2b,
      permissions.read_issue_tracking_b2c,
    ],
    link: "/issue-tracking",
    icon: <NodeIndexOutlined />,
  },
  {
    title: "Promotor",
    key: "promotor",
    access: [
      permissions.read_handover_stuff_b2b,
      permissions.read_uniform_request_b2b,
    ],
    link: "/promotor",
    icon: <AuditOutlined />,
  },
  {
    title: "Survei",
    key: "survei",
    access: [permissions.read_survey_b2b],
    link: "/survei",
    icon: <UnorderedListOutlined />,
  },
  {
    title: "LDU",
    key: "ldu",
    access: permissions.read_live_display_unit_b2c,
    link: "/ldu",
    icon: <RadiusSettingOutlined />,
  },
  {
    title: "KPI",
    key: "kpi",
    access: [
      permissions.read_master_kpi_b2b,
      permissions.read_master_kpi_b2c,
      permissions.read_user_kpi_b2b,
      permissions.read_user_kpi_b2c,
      permissions.read_item_kpi_b2b,
      permissions.read_item_kpi_b2c,
      permissions.read_target_kpi_b2b,
      permissions.read_target_kpi_b2c,
      permissions.read_store_kpi_b2c,
    ],
    link: "/kpi",
    icon: <RiseOutlined />,
    subMenus: [
      {
        title: "Master KPI",
        key: "master-kpi",
        access: [
          permissions.read_master_kpi_b2b,
          permissions.read_master_kpi_b2c,
        ],
        link: "/kpi/master-kpi",
      },
      {
        title: "User KPI",
        key: "user-kpi",
        access: [permissions.read_user_kpi_b2b, permissions.read_user_kpi_b2c],
        link: "/kpi/user-kpi",
      },
      {
        title: "Item KPI",
        key: "item-kpi",
        access: [permissions.read_item_kpi_b2b, permissions.read_item_kpi_b2c],
        link: "/kpi/item-kpi",
      },
      {
        title: "Target",
        key: "target",
        access: [
          permissions.read_target_kpi_b2b,
          permissions.read_target_kpi_b2c,
          permissions.read_store_kpi_b2c,
        ],
        link: "/kpi/target",
        subMenus: [
          {
            title: "Item KPI",
            key: "item",
            access: [
              permissions.read_target_kpi_b2b,
              permissions.read_target_kpi_b2c,
            ],
            link: "/kpi/target/item",
          },
          {
            title: "Store",
            key: "store",
            access: [permissions.read_store_kpi_b2c],
            link: "/kpi/target/store",
          },
        ],
      },
      {
        title: "Hasil Perhitungan",
        key: "hasil-perhitungan",
        access: permissions.ignore,
        link: "/kpi/hasil-perhitungan",
      },
    ],
  },
  {
    title: "Stock Opname",
    key: "stock-opname",
    access: permissions.read_stock_opname_b2c,
    link: "/stock-opname",
    icon: (
      <span
        role="img"
        aria-label="unordered-list"
        className="anticon anticon-unordered-list"
      >
        <BoxesIcon />
      </span>
    ),
  },
  {
    title: "Pengaturan",
    key: "pengaturan",
    link: "/pengaturan",
    icon: <SettingOutlined />,
    subMenus: [
      {
        title: "Data Master",
        key: "data-master",
        link: "/pengaturan/data-master",
        subMenus: [
          {
            title: "Dealer",
            key: "dealer",
            access: [
              permissions.read_dealer_b2b,
              permissions.read_dealer_prospect_b2b,
            ],
            link: "/pengaturan/data-master/dealer",
            subMenus: [
              {
                title: "List",
                key: "list",
                access: [permissions.read_dealer_b2b],
                link: "/pengaturan/data-master/dealer/list",
              },
              {
                title: "Prospek Dealer Baru",
                key: "prospek-dealer-baru",
                access: [permissions.read_dealer_prospect_b2b],
                link: "/pengaturan/data-master/dealer/prospek-dealer-baru",
              },
            ],
          },
          {
            title: "Business Unit",
            key: "business-unit",
            access: [
              permissions.read_business_unit_b2c,
              permissions.read_business_unit_category_b2c,
              permissions.read_business_unit_sub_category_b2c,
              permissions.read_business_unit_channel_b2c,
            ],
            link: "/pengaturan/data-master/business-unit",
            subMenus: [
              {
                title: "List",
                key: "list",
                access: [permissions.read_business_unit_b2c],
                link: "/pengaturan/data-master/business-unit/list",
              },
              {
                title: "Category",
                key: "category",
                access: [permissions.read_business_unit_category_b2c],
                link: "/pengaturan/data-master/business-unit/category",
              },
              {
                title: "Sub Category",
                key: "sub-category",
                access: [permissions.read_business_unit_sub_category_b2c],
                link: "/pengaturan/data-master/business-unit/sub-category",
              },
              {
                title: "Channel",
                key: "channel",
                access: [permissions.read_business_unit_channel_b2c],
                link: "/pengaturan/data-master/business-unit/channel",
              },
            ],
          },
          {
            title: "Store",
            key: "store",
            access: [permissions.read_store_b2c],
            link: "/pengaturan/data-master/store",
          },
          {
            title: "Lokasi",
            key: "lokasi",
            access: [permissions.read_location_b2c],
            link: "/pengaturan/data-master/lokasi",
          },
          {
            title: "Tipe Produk",
            key: "tipe-produk",
            access: [
              permissions.read_product_type_b2b,
              permissions.read_product_type_b2c,
            ],
            link: "/pengaturan/data-master/tipe-produk",
          },
          {
            title: "Produk",
            key: "produk",
            access: [
              permissions.read_product_b2b,
              permissions.read_product_b2c,
            ],
            link: "/pengaturan/data-master/produk",
          },
          {
            title: "Brand",
            key: "brand",
            access: [permissions.read_brand_b2b, permissions.read_brand_b2c],
            link: "/pengaturan/data-master/brand",
          },
          {
            title: "Region",
            key: "region",
            access: [permissions.read_region_b2b, permissions.read_region_b2c],
            link: "/pengaturan/data-master/region",
          },
          {
            title: "Territory",
            key: "territory",
            access: [
              permissions.read_territory_b2b,
              permissions.read_territory_b2c,
            ],
            link: "/pengaturan/data-master/territory",
          },
          {
            title: "Sub Territory",
            key: "sub-territory",
            access: [
              permissions.read_subterritory_b2b,
              permissions.read_subterritory_b2c,
            ],
            link: "/pengaturan/data-master/sub-territory",
          },
          {
            title: "Area",
            key: "area",
            access: [permissions.read_area_b2b, permissions.read_area_b2c],
            link: "/pengaturan/data-master/area",
          },
          {
            title: "Cluster",
            key: "cluster",
            access: [permissions.read_cluster_b2b],
            link: "/pengaturan/data-master/cluster",
          },
        ],
      },
      {
        title: "Akun",
        key: "akun",
        link: "/pengaturan/akun",
        subMenus: [
          {
            title: "Sales",
            key: "sales",
            access: [permissions.read_sales_b2b],
            link: "/pengaturan/akun/sales",
          },
          {
            title: "Assistant Store Leader",
            key: "assistant-store-leader",
            access: [permissions.read_assistant_store_leader_b2c],
            link: "/pengaturan/akun/assistant-store-leader",
          },
          {
            title: "Pengguna",
            key: "pengguna",
            access: [permissions.read_user_b2b, permissions.read_user_b2c],
            link: "/pengaturan/akun/pengguna",
          },
          {
            title: "Hak Akses",
            key: "hak-akses",
            access: [
              permissions.read_access_role_b2b,
              permissions.read_access_role_b2c,
            ],
            link: "/pengaturan/akun/hak-akses",
          },
          {
            title: "Peran",
            key: "peran",
            access: [
              permissions.read_superior_b2b,
              permissions.read_superior_b2c,
            ],
            link: "/pengaturan/akun/peran",
          },
        ],
      },
      {
        title: "Push Notifikasi",
        key: "push-notifikasi",
        access: [
          permissions.read_push_notification_b2b,
          permissions.read_push_notification_asl,
          permissions.read_push_notification_promoter,
          permissions.read_push_notification_superior,
          permissions.read_push_notification_sc,
          permissions.read_push_notification_b2c,
        ],
        link: "/pengaturan/push-notifikasi",
      },
      {
        title: "Radius",
        key: "radius",
        access: [
          permissions.read_setting_radius_b2b,
          permissions.read_setting_radius_b2c,
        ],
        link: "/pengaturan/radius",
      },
      {
        title: "Journey",
        key: "journey",
        access: [
          permissions.read_setting_journey_b2b,
          permissions.read_setting_journey_b2c,
        ],
        link: "/pengaturan/journey",
      },
    ],
  },
];
