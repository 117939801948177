import { DownOutlined, SearchOutlined, UploadOutlined } from "@ant-design/icons";
import { useState } from "@hookstate/core";
import Button from "antd/es/button";
import Col from "antd/es/grid/col";
import Row from "antd/es/grid/row";
import Input from "antd/es/input";
import Space from "antd/es/space";
import Table from "antd/es/table";
import Dropdown from "antd/lib/dropdown";
import Menu from "antd/lib/menu";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useIsMounted } from "../../../../../../../@framework/utilities/hooks";
import useDebounce from "../../../../../../../@framework/utilities/hooks/useDebounce";
import Accessible from "../../../../../../../@framework/wui/components/Accessible";
import WuiContainer from "../../../../../../../@framework/wui/components/Container";
import WuiModalConfirmation from "../../../../../../../@framework/wui/components/Modal/Confirmation";
import WuiModalImport from "../../../../../../../@framework/wui/components/Modal/Import";
import WuiSectionTitle from "../../../../../../../@framework/wui/components/Sections/Title";
import { DEALER_BRAND_IMPORT, DEALER_IMPORT, DEALER_PRODUCT_IMPORT } from "../../../../../../../constant/import";
import { permissions } from "../../../../../../../constant/permissions";
import { handleBackendError, openNotification } from "../../../../../../../functions/global";
import DealerRepository from "../../../../../../../repositories/DealerRepository";
import { TChannels } from "../../../../../../../utils";


var qs = require('qs');

const breadcrumbs = [
    {
        label: 'Pengaturan',
    },
    {
        label: 'Data Master',
    },
    {
        label: 'Dealer',
        link: '/pengaturan/data-master/dealer'
    },
]

const AppSettingsDataMasterDealerList: React.FC<any> = () => {
    const {t} = useTranslation()
    const isMounted = useIsMounted()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    let fullPath = "/pengaturan/data-master/dealer";
    const selectedChannel = useState<TChannels>('b2b')

    const tablePage = useState<number>(parseInt(searchParams.get('page') || '1'))
    const tablePerPage = useState<number>(parseInt(searchParams.get('perPage') || '10'))
    const [tableLoading, setTableLoading] = React.useState<boolean>(false)
    const tableSort = useState<{
        sortField: any,
        sortOrder: any,
    }>({
        sortField: searchParams.get('sortField') || 'id',
        sortOrder: searchParams.get('sortOrder') || 'desc'
    })
    const tableData = useState([]);
    const totalData = useState<number>(0)

    // Filters
    const getTableData = async (reset: boolean = false) => {
        setTableLoading(true)

        if (reset) tablePage.set(1);

        let params = {
            page: tablePage.get(),
            search: debouncedSearch,
            order_by: tableSort.sortField.get(),
            sorted_by: tableSort.sortOrder.get(),
            per_page: tablePerPage.get()
        }

        await DealerRepository.all(selectedChannel.get(), params).then((res: AxiosResponse) => {
            if (res.data.data) {
                tableData.set(res.data.data)
            }

            totalData.set(res.data?.total || 0)

            setTableLoading(false)
        }).catch((e: AxiosError) => {

            setTableLoading(false)
        })
    }

    const handleTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        tablePage.set(pagination.current)
        tablePerPage.set(pagination.pageSize)

        if (sorter.order) {
            tableSort.sortField.set(sorter.field)
            tableSort.sortOrder.set(sorter.order)
        } else {
            tableSort.sortField.set(null)
            tableSort.sortOrder.set(null)
        }

        setFilterDataToQuery()
    }

    // For Search Input
    const [search, setSearch] = React.useState<string>('')
    const debouncedSearch = useDebounce<string>(search, 1000)

    useEffect(() => {
        if (!isMounted) {
            setFilterDataToQuery()
        }
        // eslint-disable-next-line
    }, [debouncedSearch])

    useEffect(() => {
        setFilterDataToQuery()
        // eslint-disable-next-line
    }, [])

    const getFilterDataFromQuery = () => {
        const keyword = searchParams.get('keyword')

        if (keyword) {
            setSearch(keyword)
        }
    }

    useMemo (() => {
        getFilterDataFromQuery()
        // eslint-disable-next-line
    }, [])

    const setFilterDataToQuery = () => {
        let params = {}
        let reset = false;

        if (debouncedSearch) {
            Object.assign(params, {
                keyword: debouncedSearch
            })

            if(debouncedSearch !== searchParams.get('keyword')){
                reset = true
            } 
        }

        if (tablePage.get() !== 1 && !reset) {
            Object.assign(params, {
                page: tablePage.get()
            })
        }

        if (tablePerPage.get() !== 10 && !reset) {
            Object.assign(params, {
                perPage: tablePerPage.get()
            })
        }

        if (tableSort.sortField.get() && !reset) {
            Object.assign(params, {
                sortField: tableSort.sortField.get()
            })
        }

        if (tableSort.sortOrder.get() && !reset) {
            Object.assign(params, {
                sortOrder: tableSort.sortOrder.get()
            })
        }

        const queryParams = qs.stringify(params , { indices: false });

        if (queryParams) {
            setSearchParams(`?${queryParams}`)
        } else {
            navigate('')
        }

        getTableData(reset)
    }

    const columns: any = [
        {
            title: 'Kode',
            dataIndex: 'code',
            key: 'code',
            sorter: true,
            defaultSortOrder: tableSort.sortField.get() === 'code' && tableSort.sortOrder.get(),
        },
        {
            title: 'Nama',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            defaultSortOrder: tableSort.sortField.get() === 'name' && tableSort.sortOrder.get(),
        },
        {
            title: 'Action',
            key: 'action',
            render: (text: any, record: any) => (
                <Space size="middle">
                    <Link to={fullPath+'/'+record.id}>Lihat Detail</Link>
                    {/*<Accessible access={[permissions.delete_dealer_b2b]}>
                        <Text className="cursor-pointer" type={"danger"} onClick={() => setDeleteModal(record.id)}>Hapus</Text>
                    </Accessible>*/}
                </Space>
            ),
        },
    ];

    // Modal
    const showDeleteModal = useState(false)
    const selectedDeleteId = useState(0)

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const setDeleteModal = (id: any) => {
        selectedDeleteId.set(id);
        showDeleteModal.set(true)
    }

    const deleteModalCallback = async () => {
        if (selectedDeleteId.get()) {
            await DealerRepository.delete(selectedDeleteId.get()).then((res: AxiosResponse) => {
                showDeleteModal.set(false);
                selectedDeleteId.set(0)
                openNotification('success', t('notification.success.deleteItem', {item: 'Dealer'}))
                getTableData(true)
            }).catch((e: AxiosError) => {
                showDeleteModal.set(false);
                selectedDeleteId.set(0)
                handleBackendError(e, t('notification.error.default'))
            });
        }
    }

    const showImportModal = useState(false)

    const importModalCallback = async (files: File[]) => {
        const formData = new FormData();

        if (files) {
            files.forEach((file: any) => {
                formData.append('file_import', file);
            })
        }

        await DealerRepository.import(selectedChannel.get(), formData).then((res: AxiosResponse) => {
            showImportModal.set(false)
            openNotification('success', t('notification.success.importItem', {item: 'Dealer'}))
            getTableData(true)
        }).catch((e: AxiosError) => {
            handleBackendError(e, t('notification.error.default'))
        })
    }

    const importModalDownload = async () => {
        window.open(DEALER_IMPORT.TEMPLATE_URL + `?${_.random(100,900)}`)
    }

    const showImportProductModal = useState(false)

    const importProductModalCallback = async (files: File[]) => {
        const formData = new FormData();

        if (files) {
            files.forEach((file: any) => {
                formData.append('file_import', file); 
            })
        }

        await DealerRepository.importProduct(selectedChannel.get(), formData).then((res: AxiosResponse) => {
            showImportProductModal.set(false)
            openNotification('success', t('notification.success.importItem', {item: 'Dealer Produk'}))
            getTableData(true)
        }).catch((e: AxiosError) => {
            handleBackendError(e, t('notification.error.default'))
        })
    }

    const importProductModalDownload = async () => {
        window.open(DEALER_PRODUCT_IMPORT.TEMPLATE_URL + `?${_.random(100,900)}`)
    }

    const showImportBrandModal = useState(false)

    const importBrandModalCallback = async (files: File[]) => {
        const formData = new FormData();

        if (files) {
            files.forEach((file: any) => {
                formData.append('file_import', file); 
            })
        }

        await DealerRepository.importBrand(selectedChannel.get(), formData).then((res: AxiosResponse) => {
            showImportBrandModal.set(false)
            openNotification('success', t('notification.success.importItem', {item: 'Dealer Brand'}))
            getTableData(true)
        }).catch((e: AxiosError) => {
            handleBackendError(e, t('notification.error.default'))
        })
    }

    const importBrandModalDownload = async () => {
        window.open(DEALER_BRAND_IMPORT.TEMPLATE_URL + `?${_.random(100,900)}`)
    }

    return (
        <>
            <WuiContainer>
                <WuiSectionTitle breadcrumbs={breadcrumbs} title={'Dealer'}>
                    <Accessible access={[permissions.write_dealer_b2b]}>
                        <Dropdown
                            placement="bottomRight"
                            trigger={['click']}
                            overlay={() => {
                                return (
                                    <Menu>
                                        <Menu.Item key="1" icon={<UploadOutlined />} onClick={() => showImportModal.set(true)}>
                                            Dealer
                                        </Menu.Item>
                                        <Menu.Item key="2" icon={<UploadOutlined />}  onClick={() => showImportProductModal.set(true)}>
                                            Dealer Produk
                                        </Menu.Item>
                                        <Menu.Item key="3" icon={<UploadOutlined />}  onClick={() => showImportBrandModal.set(true)}>
                                            Dealer Brand
                                        </Menu.Item>
                                    </Menu>
                                )
                            }}
                        >
                            <Button>
                                Import <DownOutlined />
                            </Button>
                        </Dropdown>
                    </Accessible>
                </WuiSectionTitle>

                <Row gutter={[10, 10]} className="mb16">
                    <Col className="gutter-row" span={6} xs={24} md={12} lg={10}>
                        <Input
                            allowClear
                            placeholder={t('common.filter.search.placeholder')}
                            prefix={<SearchOutlined />}
                            value={search}
                            onChange={(value) => {
                                setSearch(value.target.value)
                            }}
                        />
                    </Col>
                </Row>

                <Table
                    rowKey={"id"}
                    bordered
                    columns={columns}
                    dataSource={tableData.get()}
                    loading={tableLoading}
                    onChange={handleTableChange}
                    pagination={{
                        current: tablePage.get(),
                        simple: true,
                        pageSize: tablePerPage.get(),
                        total: totalData.get()
                    }}
                />
            </WuiContainer>

            <WuiModalConfirmation
                show={showDeleteModal.get()}
                onOk={deleteModalCallback}
                onCancel={() => showDeleteModal.set(false)}
            />

            <WuiModalImport
                show={showImportModal.get()}
                onUpload={importModalCallback}
                onCancel={() => showImportModal.set(false)}
                onDownload={importModalDownload}
                headerTitle="Import Dealer"
                maxFileOnMB={10}
            />

            <WuiModalImport
                show={showImportProductModal.get()}
                onUpload={importProductModalCallback}
                onCancel={() => showImportProductModal.set(false)}
                onDownload={importProductModalDownload}
                headerTitle="Import Dealer Produk"
                maxFileOnMB={10}
            />

            <WuiModalImport
                show={showImportBrandModal.get()}
                onUpload={importBrandModalCallback}
                onCancel={() => showImportBrandModal.set(false)}
                onDownload={importBrandModalDownload}
                headerTitle="Import Dealer Brand"
                maxFileOnMB={10}
            />
        </>
    );
}

export default AppSettingsDataMasterDealerList;